import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ItemlistGrid from '../issueItems/itemsGrid';
import IssueAuthorization from '../issueItems/issueAuthorization';
import IssueMessageGrid from '../issueItems/IssueMessage';
import ReceivedItem from './ReceivedItem';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';

const CreateIssueReceived = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [Locationlist, setLocation] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [userData, setUserdata] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [selectedIssue, setSelectedIssue] = useState([]);
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const itemData = location.state;
    const userName = useSelector(getName);
    const [POTab, setPOTab] = useState(true);
    const [loader, setLoader] = useState(false);

    const todayDate = new Date().toISOString().substr(0, 10);
    const [formData, setFormData] = useState({
        issueNo: "",
        issuedBy: "",
        issueDate: "",
        description: "",
        remarks: "",
        refNo: "",
        requisitionDate: currentDatetimeUTC,
        deliveryDate: todayDate,
        currencyCode: "",
        exchangeRate: "",
        department: [],
        costCenter1: [],
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        tolocation: "",
        taskCode: "",
        subcatname: "",
    });

    useEffect(() => {
        getCurrency()
        getUser()
        getLocation()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location?.state;
        setFormData({
            recordId: item?.recordId,
            requisitionDate: item?.requisitionDate || currentDatetimeUTC,
            deliveryDate: item?.deliveryDate ? item.deliveryDate.substr(0, 10) : todayDate,
            requisitionNo: item?.requisitionNo,
            itemType: item?.type,
            quatotionNo: item?.contractNo,
            remarks: item?.remarks,
            requestor: item?.requestor,
            currencyCode: item?.currencyCode,
            costCenter2: item?.costCenter2,
            costCenter3: item?.costCenter3,
            costCenter4: item?.costCenter4,
            deliveryPoint: item?.deliveryPoint,
            contactPerson: item?.contactPerson,
            contatNo: item?.contatNo,
            taskCode: item?.projectCode,
            location: item?.location,
        });
        const selectedDepartment = department?.find(dep => dep.description === item?.department)
        if (selectedDepartment) {
            setFormData(prevState => ({
                ...prevState,
                department: { label: selectedDepartment.description, value: selectedDepartment }
            }));
        }
        const selectedLocation = Locationlist?.find(dep => dep.location === item?.costCenter1)
        if (selectedLocation) {
            setFormData(prevState => ({
                ...prevState,
                costCenter1: { label: selectedLocation.location, value: selectedLocation }
            }));
        }
    }, [location?.state, department, Locationlist]);
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=Requisition`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response?.data?.result?.length > 0) {
                setUser(response?.data?.result); // Only set the first record
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        setFormData({
            exchangeRate: filteredDenominations?.conversionRate
        })
        setFormData({ ...formData, exchangeRate: filteredDenominations?.conversionRate });
    }
    const getLocation = async () => {
        try {
            let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
            setLocation(res?.data?.result)
            let DepRes = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
            setDepartment(DepRes?.data?.result)
            let issueRes = await apiService.getApiParamater('GET_ISSUE_RECEIVED', param)
            setIssueList(issueRes?.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const getUser = async () => {
        try {
            const token = localStorage.getItem('Token');
            let userId = userManagement.getUserId(token)
            let Id = `?id=${userId}`
            const apiService2 = new ApiService2()
            let res = await apiService2.getApiParamater('GET_USERS', Id)
            setUserdata(res?.data?.result)
        } catch (error) {
            console.log(error)
        }

    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const staticOptions = [];
    const CreatePR = async () => {
        setLoader(true)
        if (!formData?.location) {
            toast.warning("Select Location");
            setLoader(false)
            return;
        }
        // if (!formData?.date) {
        //     toast.warning("Select received Date");
        //     return;
        // }

        try {
            const apiService = new ApiService();
            const selectedItems = requisitionData.filter(item => item.check);
            for (const item of selectedItems) {
                // const receivedDateUTC = new Date(item.data).toISOString();
                const requestData = {
                    issueNo: formData.issueNo.label,
                    itemCode: item.ItemNo,
                    received: item.check,
                    receivedDate: moment(item.data).format()
                };
                const response = await apiService.put("UPDATE_ISSUE_RECEIVED", requestData);
                if (response?.data === true) {
                    const updatedIssueDetails = selectedIssue.issueDetails.map(detail => ({
                        ...detail,
                        received: 1,
                        receivedDate: new Date().toISOString()
                    }));
                    const issueData = {
                        warehouse: formData.location.label,
                        divisionCode: formData.tolocation.label,
                        divisionName: formData.tolocation.label,
                        issueNo: formData.issueNo.label,
                        remarks: formData.remarks,
                        modifiedBy: userName,
                        issueDetailDtos: updatedIssueDetails
                    };
                    const response = await apiService.put("UPDATE_ITEM_ISSUE", issueData);
                    toast.success("Issue Received successfully");
                    setLoader(false)
                    navigate("/inventory/Issue/Received");
                }
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating Issue Received:", error);
            toast.error("Error creating Issue Received");
        }
    };
    const UpdatePR = async () => {

    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const handleDepChange = (selectedOption) => {
        setFormData({ ...formData, department: selectedOption });
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, tolocation: selectedOption });
    };
    const handleIssueNoChange = async (selectedOption) => {
        let IssueParam = `?IssueNo=${selectedOption.label}`
        const response = await apiService.getApiParamater('GET_ITEM_ISSUE', IssueParam);
        let selectedIssue = response?.data?.result[0]
        
        setSelectedIssue(selectedIssue)
        const selectedLocation = Locationlist?.find(dep => dep.description === selectedIssue.warehouse);
        const selectedToLocation = Locationlist?.find(dep => dep.description === selectedIssue.divisionName);
        const selectedDepartment = department?.find(dep => dep.description === selectedIssue.department);
        const utcDate = new Date(selectedIssue.issueDate);
        const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        setFormData({
            ...formData,
            issueNo: selectedOption,
            issuedBy: selectedIssue.issuedBy,
            issueDate: localDate,
            tolocation: selectedToLocation ? { label: selectedToLocation.description, value: selectedToLocation } : null,
            location: selectedLocation ? { label: selectedLocation.description, value: selectedLocation } : null,
            department: selectedDepartment ? { label: selectedDepartment.description, value: selectedDepartment } : null,
        });
    };
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='d-flex justify-content-between d-flex justify-content-between col mainInvHead'>Issue Received</h5>
                <div className='d-flex gap-3'>
                    {itemData?.eye === true ? " " :
                        <>
                            {location.state ? (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={UpdatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Update</div>
                                    </button>
                                }
                                </>
                            ) : (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={CreatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                                    </button>
                                }
                                </>
                            )}</>
                    }
                    {itemData?.eye === true ? " " : <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>}
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Issue Received Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="recordId" className="form-label  pt-2 start">Issue Note No</label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }} value={formData.issueNo}
                                    options={issueList?.map((dep) => ({ value: dep, label: dep?.issueNo }))}
                                    onChange={(selectedOption) => handleIssueNoChange(selectedOption)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Department</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} value={formData.department}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property Group</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Date </label>
                                {itemData?.eye === true ?
                                    <input
                                        className='form-Input w-70'
                                        type='date' disabled
                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        type='date'
                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                        name="requisitionDate"
                                        onChange={handleInputChange}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="description" className="form-label  pt-2 start">Description</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.description} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        name="description"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Currency</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled value={SelectedCurrency} /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        isSearchable={false}
                                        value={SelectedCurrency}
                                        onChange={(e) => handlecurChange(e)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="issuedBy" className="form-label  pt-2 start">Issued By</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.contactPerson} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="issuedBy"
                                        value={formData.issuedBy}
                                        onChange={handleInputChange}
                                        name="issuedBy"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Cost Center 3</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="exchangeRate" className="form-label  pt-2 start">Exchange Rate</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.exchangeRate} className="form-Input w-70" /> :
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        id="exchangeRate"
                                        value={formData.exchangeRate}
                                        onChange={handleInputChange}
                                        name="exchangeRate"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="refNo" className="form-label  pt-2 start">Ref No</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.refNo} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="refNo"
                                        value={formData.refNo}
                                        onChange={handleInputChange}
                                        name="refNo"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Outlets</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">From Location</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} value={formData.location} isDisabled />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="tolocation" className="form-label  pt-2 start">To Location</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.tolocation} className="form-Input w-70" /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} value={formData.tolocation}
                                        options={Locationlist?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="remarks" className="form-label  pt-2 start">Remarks</label>
                                {itemData?.eye === true ?
                                    <textarea disabled
                                        className="form-Input w-70"
                                        value={formData.remarks}
                                    /> :
                                    <textarea
                                        name="remarks"
                                        type='text'
                                        className="form-Input w-70"
                                        id="remarks"
                                        value={formData.remarks}
                                        onChange={handleInputChange}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <ReceivedItem onRequisitionData={handleRequisitionData} itemData={itemData} selectedIssue={selectedIssue} />
                )}
                {gridVisibility[1] && (
                    <IssueAuthorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <IssueMessageGrid handleMessage={handleMessage} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default CreateIssueReceived