import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ApiService from '../../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import user from "../../../assets/images/usr.png";
import moment from 'moment/moment';
import { ReactComponent as Edit } from '../../../assets/images/svgIcons/Edit_light.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./employee.css";
import { shiftEnums } from '../../../../Enums/config';

const EmployeeDetails = () => {
    const [data, setData] = useState([]);
    const { state } = useLocation();
    const EmployeData = state?.field;
    let navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);
    const [editSection, setEditSection] = useState('');
    const hiddenFile = useRef(null);
    const apiService = new ApiService();

    const [restaurants, setRestaurants] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedShift, setSelectedShift] = useState(null); // State for shift selection

    useEffect(() => {
        if (EmployeData) {
            const initialShift = shiftEnums.find(shift => shift.type === EmployeData?.shiftNumber);
            setFormData({
                id: EmployeData?.id || "",
                firstName: EmployeData?.employee_Name || "",
                lastName: EmployeData?.employee_Name || "",
                idNumber: EmployeData?.exp1 || "",
                gender: EmployeData?.gender || "",
                dateOfBirth: moment(EmployeData?.dateOfBirth).format("YYYY-MM-DD") || "",
                dateOfJoining: moment(EmployeData?.dateOfJoining).format("YYYY-MM-DD") || "",
                City: EmployeData?.city || "",
                State: EmployeData?.state || "Active",
                Shift: initialShift?.value || "",
                JobTitle: EmployeData?.jobTitle || "",
                BranchId: EmployeData?.branchId || 1,
                LicenseNumber: EmployeData?.LicenseNumber || "",
                address: EmployeData?.exp3 || "",
                phoneNumber: EmployeData?.phoneNumber || "",
                email: EmployeData?.email || "",
                images: EmployeData?.employeeDisplayPicture || user,
                restaurantName: EmployeData?.restaurantName || "",
                branchName: EmployeData?.branchName || "",
            });
            setSelectedRestaurant({
                value: EmployeData?.restaurantId,
                label: EmployeData?.restaurantName,
            });
            setSelectedBranch({
                value: EmployeData?.branchId,
                label: EmployeData?.branchName,
            });
            setSelectedShift(initialShift); // Set initial shift
            setData(EmployeData);
        }
    }, [EmployeData]);

    const [formData, setFormData] = useState({
        id: '',
        firstName: "",
        lastName: "",
        idNumber: "",
        gender: "",
        dateOfBirth: "",
        dateOfJoining: "",
        City: "",
        State: "Active",
        Shift: "",
        JobTitle: "",
        BranchId: 1,
        LicenseNumber: "",
        address: "",
        phoneNumber: "",
        email: "",
        images: [],
        restaurantName: "",
    });

    useEffect(() => {
        fetchRestaurantsAndBranches();
    }, []);

    const fetchRestaurantsAndBranches = async () => {
        try {
            const restaurantsResponse = await apiService.get('GET_RESTAURANT');
            if (restaurantsResponse?.data) {
                setRestaurants(restaurantsResponse?.data?.result);
            }
        } catch (error) {
            console.error('Error fetching restaurants:', error);
        }
    };

    const fetchBranches = async (restaurantId) => {
        try {
            const param = `?RestaurantId=${restaurantId}`;
            const branchesResponse = await apiService.getApiParamater('GET_BRANCH', param);
            if (branchesResponse?.data) {
                setBranches(branchesResponse?.data?.result);
            }
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = async (selectedOption, actionMeta) => {
        if (actionMeta.name === 'restaurant') {
            setSelectedRestaurant(selectedOption);
            setFormData({ ...formData, restaurantName: selectedOption.label });
            await fetchBranches(selectedOption.value);
            setSelectedBranch(null); // Clear the selected branch when the restaurant changes
        } else if (actionMeta.name === 'branch') {
            setSelectedBranch(selectedOption);
            setFormData({ ...formData, BranchId: selectedOption.value, branchName: selectedOption.label });
        } else if (actionMeta.name === 'shift') {
            
            setSelectedShift(selectedOption);
            setFormData({ ...formData, Shift: selectedOption.value });
        }
    };

    const handleUpdateCustomer = async (e) => {
        e.preventDefault();
        try {
            const formdata = new FormData();
            formdata.append('Name', formData.firstName + ' ' + formData.lastName);
            formdata.append('City', formData.City);
            for (const image of formData?.images) {
                if (image.file) {
                    formdata.append('EmployeeDisplayPicture', image?.file);
                }
            }
            formdata.append('IdNumber', formData.idNumber);
            formdata.append('id', formData.id);
            formdata.append('Gender', formData.gender);
            formdata.append('DateOfBirth', moment(formData.dateOfBirth).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
            formdata.append('DateOfJoining', moment(formData.dateOfJoining).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
            formdata.append('Shift', formData.Shift);
            formdata.append('State', formData.State);
            formdata.append('JobTitle', formData.JobTitle);
            formdata.append('BranchId', formData.BranchId);
            formdata.append('LicenseNumber', formData.LicenseNumber);
            formdata.append('ContactNumber', formData.phoneNumber);
            formdata.append('Address', formData.address);
            formdata.append('Email', formData.email);
            formdata.append('restaurantName', formData.restaurantName);

            const response = await apiService.putMultiPart("UPDATE_EMPLOYEE", formdata);

            if (response.data.statusCode === 204) {
                toast.success('Employee Updated successfully');
                setEditMode(false);
                setEditSection('');
                navigate(`/admin/employee`);
            } else {
                toast.warning('Waiting for menu update');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEditClick = (section) => {
        setEditMode(true);
        setEditSection(section);
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const renderEditButton = (section) => {
        return (
            <button className="editbtn" onClick={() => handleEditClick(section)}>
                <Edit /> Edit
            </button>
        );
    };

    const renderInputField = (label, name, value, type = 'text') => {
        return (
            <>
                <div className="col-lg-1 center">
                    <label htmlFor={`validationCustom${name}`} className="headr">
                        {label}
                    </label>
                </div>
                <div className="col-lg-3">
                    <input
                        type={type}
                        className="form-control"
                        id={`validationCustom${name}`}
                        name={name}
                        value={value}
                        onChange={handleInputChange}
                        disabled={!editMode}
                        required
                    />
                    <div className="valid-feedback">Looks good!</div>
                </div>
            </>
        );
    };

    return (
        <div className='p-lg-5'>
            <div className='Headtxt'>Employee Profile</div>
            <div className='py-5'>
                <div className='main-bG p-5'>
                    <div className='d-flex justify-content-between pb-4'>
                        <div className='titlepr'>Personal Information</div>
                        <div>
                            {editMode && editSection === 'personal' ? (
                                <div>
                                    <button className="editbtn" onClick={toggleEditMode}>
                                        <Edit /> {editMode ? 'Done' : 'Edit'}
                                    </button>
                                </div>
                            ) : (
                                renderEditButton('personal')
                            )}
                        </div>
                    </div>
                    <div className="row">
                        {renderInputField('First Name', 'firstName', formData.firstName)}
                        {renderInputField('Last Name', 'lastName', formData.lastName)}
                        {renderInputField('DOB', 'dateOfBirth', formData.dateOfBirth, 'date')}
                    </div>
                    <div className="row py-5">
                        {renderInputField('City / State', 'City', formData.City)}
                        {renderInputField('Country', 'State', formData.State)}
                        {renderInputField('ID Number', 'idNumber', formData.idNumber, 'number')}
                    </div>
                    <div className="row">
                        {renderInputField('Phone', 'phoneNumber', formData.phoneNumber, 'number')}
                        {renderInputField('Email', 'email', formData.email, "email")}
                        {renderInputField('Address', 'address', formData.address)}
                    </div>
                </div>
            </div>
            <div className='pb-5'>
                <div className='main-bG p-5'>
                    <div className="d-flex justify-content-between pb-4">
                        <div className="titlepr">Job Description</div>
                        {editMode && editSection === 'job' ? (
                            <div>
                                <button className="editbtn" onClick={toggleEditMode}>
                                    <Edit /> {editMode ? 'Done' : 'Edit'}
                                </button>
                            </div>
                        ) : (
                            renderEditButton('job')
                        )}
                    </div>
                    <div className="row">
                        {renderInputField('Job Title', 'JobTitle', formData.JobTitle)}
                        {renderInputField('Joining Date', 'dateOfJoining', formData.dateOfJoining, "date")}
                        {renderInputField('Salary', 'Salary', formData.Salary, "number")}
                    </div>
                    <div className="row py-5">
                        <div className="col-lg-1 center">
                            <label htmlFor="shift" className="headr">
                                Shift
                            </label>
                        </div>
                        <div className="col-lg-3">
                            <Select
                                name="shift"
                                options={shiftEnums?.map((shift) => ({ value: shift.type, label: shift?.label }))}
                                value={formData.Shift.label}
                                onChange={handleSelectChange}
                                isDisabled={!editMode}
                            />
                        </div>
                        <div className="col-lg-1 center">
                            <label className="headr">
                                Restaurant
                            </label>
                        </div>
                        <div className="col-lg-3">
                            <Select
                                name="restaurant"
                                options={restaurants.map(restaurant => ({
                                    value: restaurant.id,
                                    label: restaurant.name
                                }))}
                                value={selectedRestaurant}
                                onChange={handleSelectChange}
                                isDisabled={!editMode}
                            />
                        </div>
                        <div className="col-lg-1 center">
                            <label className="headr">
                                Branch                    </label>
                        </div>
                        <div className="col-lg-3">
                            <Select
                                name="branch"
                                options={branches?.map(branch => ({
                                    value: branch.id,
                                    label: branch.branchName
                                }))}
                                value={selectedBranch}
                                onChange={handleSelectChange}
                                isDisabled={!editMode}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex gap-3 justify-content-end'>
                <div><button className='delbtn'>Delete Profile</button></div>
                <div><button onClick={handleUpdateCustomer} className='delbtn'>Done</button></div>
            </div>
        </div>
    );
};

export default EmployeeDetails;
