import React, { useState, useEffect } from 'react';
import './addmenu.css';
import ImageUpload from "./imagUpload";
import CreatableSelect from 'react-select/creatable';
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import userManagement from '../../../../services/userManagement';

const AddItem = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    discount: '',
    Tax: '',
    description: '',
    price: '',
    category: '',
    SubCategory: '',
    addons: '',
    modifiers: '',
    variants: [{ name: '', price: '', tax: '', discount: '' }],
    Image: [],
    imageUrl: ""
  });
  
  const [categories, setCategories] = useState([]);
  const [addsOn, setAddsOn] = useState([]);
  const [modifier, setModifiers] = useState([]);

  useEffect(() => {
    GetAddsOn();
    GetModifier();
    fetchCategories();
    if (location.state && location.state.item) {
      const { item } = location.state;
      const adons = (
        item?.addOns?.map(addon => ({ label: addon?.name, value: addon?.id }))
      );
      setFormData({
        name: item.name || '',
        discount: item.discount || '',
        description: item.description || '',
        price: item.price || '',
        category: [{ label: item?.category?.name, value: item?.category?.id }] || '',
        delivery: item.delivery || '',
        addons: adons
      });
    }
  }, [location.state]);

  const handleCategoryChange = (selectedOption) => {
    setFormData({ ...formData, category: selectedOption?.label || selectedOption });
  };

  const fetchCategories = async () => {
    try {
      const apiService = new ApiService();
      const response = await apiService.get('GET_CATEGORY');
      const filteredcategory = response?.data?.result?.filter(item => item?.active === true);
      setCategories(filteredcategory);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleCategoryCreate = async (inputValue) => {
    try {
      let body = {
        id: 0,
        name: inputValue
      };
      const apiService = new ApiService();
      let Create_Response = await apiService.post('CREATE_CATEGORY', body);
      fetchCategories();
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleAddsOnChange = (selectedOption) => {
    setFormData({ ...formData, addons: selectedOption?.label || selectedOption });
  };

  const handleModifierChange = (selectedOption) => {
    setFormData({ ...formData, modifiers: selectedOption?.label || selectedOption });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVariantsChange = (index, e) => {
    const newVariants = [...formData.variants];
    newVariants[index][e.target.name] = e.target.value;
    setFormData({ ...formData, variants: newVariants });
  };

  const handleAddVariants = () => {
    setFormData({ ...formData, variants: [...formData.variants, { name: '', price: '', tax: '', discount: '' }] });
  };

  const handleRemoveVariants = (index) => {
    const newVariants = [...formData.variants];
    newVariants.splice(index, 1);
    setFormData({ ...formData, variants: newVariants });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      if (!formData?.Image) {
        toast.error("Upload Image");
      } else {
        
        try {
          ;
          const apiService = new ApiService();
          const formdata = new FormData();
          for (const image of formData?.Image) {
            if (image.file) {
              formdata.append('Images', image?.file);
            }
          }
          const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);
          ;
          setFormData({ ...formData, imageUrl: response?.data?.result });
        } catch (error) {
          toast.error("Something went wrong with the image. Please try again!");
        }
      }
      if (formData?.imageUrl) {
        let UserId = userManagement.getUserId();
        let body = {
          name: formData?.name,
          description: formData?.description,
          price: formData?.price,
          tax: formData?.Tax,
          discount: formData?.discount,
          categoryId: formData.category[0]?.value?.id,
          subCategoryId: formData?.SubCategory,
          active: true,
          imageUrl: formData?.imageUrl,
          menuItemMeta: [
            {
              menuItemId: 0,
              adsOnId: 0,
              modifierId: 0,
              variantId: 0,
              variantPrice: 0,
              metaType: 1
            }
          ],
          loggedInUserId: UserId
        }

        const apiService = new ApiService();
        const response = await apiService.post( body);

        if (response.data.statusCode === 201) {
          toast.success('Menu item created/updated successfully');
          navigate('/admin/menu');
        } else {
          toast.warning('Waiting for menu update');
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleImagesSelect = (images) => {
    setFormData({ ...formData, Image: images });
  }
  const handleImageRemove = (index) => {
    const updatedImages = [...formData.Image];
    updatedImages.splice(index, 1);
    setFormData({ ...formData, Image: updatedImages });
  }

  const GetAddsOn = async () => {
    try {
      const apiService = new ApiService();
      let res = await apiService.get("GET_ADDONS");
      setAddsOn(res.data.result);
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  };

const GetModifier = async () => {
    try {
        const apiService = new ApiService();
        let res = await apiService.get("GET_MODIFIER");
        const activeModifiers = res.data.result.filter(modifier => modifier.active === true);
        setModifiers(activeModifiers);
    } catch (error) {
        console.error('Error fetching Modifier data:', error);
    }
};


  return (
    <div className=' paddss pad-top'>
      <div className='p-lg-5'>
        <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
      </div>

      <form onSubmit={handleSubmit}>
        <div className=''>
          <div className="form-group row justify-content-between">
            <div className="col-md-5 col-lg-5">
              <label htmlFor="validationCustom01" className="form-label">Name</label>
              <input
                type="text"
                className="p-2 form-control"
                id="validationCustom01"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="validationCustom02" className="form-label">Description</label>
              <input
                type="text"
                className="p-2 form-control"
                id="validationCustom02"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>
          </div>

          <div className="form-group row mt-3 justify-content-between">
            <div className="col-md-5 col-lg-5">
              <label htmlFor="validationCustom05" className="form-label">
                Category
              </label>
              <CreatableSelect
                isMulti
                options={categories?.map((category) => ({ value: category, label: category.name }))}
                value={formData.category}
                onChange={handleCategoryChange}
                onCreateOption={handleCategoryCreate}
                placeholder="Select or create a category"
                className=''
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>

            <div className="col-md-6">
              <label htmlFor="validationCustom06" className="form-label">SubCategory</label>
              <input
                type="text"
                className="p-2 form-control"
                id="validationCustom06"
                name="SubCategory"
                value={formData.SubCategory}
                onChange={handleInputChange}
                required
              />
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>
          </div>

          <div className="form-group row mt-3 justify-content-between">
            <div className="col-md-5 col-lg-5">
              <label htmlFor="validationCustom03" className="form-label">Item code</label>
              <input
                type="text"
                className="p-2 form-control"
                id="validationCustom03"
                name="Itemcode"
                value={formData.Itemcode}
                onChange={handleInputChange}
                required
              />
              <div className="valid-feedback">
                Looks good!
              </div>
            </div>

            <div className="col-md-6">
              <div className='gap-3 d-flex justify-content-between'>
                <div>
                  <label htmlFor="validationCustom04" className="form-label">Price</label>
                  <input
                    type="number"
                    className="p-2 form-control"
                    id="validationCustom04"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="valid-feedback">
                    Looks good!
                  </div>
                </div>
                <div>
                  <label htmlFor="validationCustom07" className="form-label">Tax</label>
                  <input
                    type="number"
                    className="p-2 form-control"
                    id="validationCustom07"
                    name="Tax"
                    value={formData.Tax}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="valid-feedback">
                    Looks good!
                  </div>
                </div>
                <div>
                  <label htmlFor="validationCustom08" className="form-label">Discount</label>
                  <input
                    type="number"
                    className="p-2 form-control"
                    id="validationCustom08"
                    name="discount"
                    value={formData.discount}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="valid-feedback">
                    Looks good!
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* AddOns */}
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="addItem-head">Add-on For Item</div>
            <div className="col-lg-10">
              <label htmlFor="validationCustom05" className="form-label">
                Add-on
              </label>
              <CreatableSelect
                isMulti
                options={addsOn?.map((addson) => ({ value: addson, label: addson.name }))}
                value={formData.addons}
                onChange={handleAddsOnChange}
                onCreateOption={null}
                placeholder="Select Add-on"
                className=''
                required
                isCreatable={null}
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
          </div>
          {/* modifier */}
          <div className="col-lg-6">
            <div className="addItem-head">Add Modifiers for Item</div>
            <div className="col-lg-12">
              <label htmlFor="validationCustom05" className="form-label">
                Modifiers
              </label>
              <CreatableSelect
                isMulti
                options={modifier?.map((modifier) => ({ value: modifier, label: modifier.name }))}
                value={formData.modifiers}
                onChange={handleModifierChange}
                onCreateOption={null}
                placeholder="Select Modifiers"
                className=''
                isCreatable={null}
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
          </div>
        </div>
        {/* Add varients */}
        <div className="row mt-5">
          <div className="addItem-head">Add Variants For Item</div>
          {formData?.variants?.map((variants, index) => (
            <div key={index} className="form-group row mt-2 justify-content-between align-content-center">
              <div className="col-md-5">
                <label htmlFor={`variantsName${index}`} className="form-label">Name</label>
                <input
                  type="text"
                  className="p-2 form-control"
                  id={`variantsName${index}`}
                  name="name"
                  value={variants.name}
                  onChange={(e) => handleVariantsChange(index, e)}
                  required
                />
              </div>
              <div className="col-md-5">
                <div className='gap-3 d-flex justify-content-center'>
                  <div>
                    <label htmlFor={`variantsPrice${index}`} className="form-label">Price</label>
                    <input
                      type="number"
                      className="p-2 form-control"
                      id={`variantsPrice${index}`}
                      name="price"
                      value={variants.price}
                      onChange={(e) => handleVariantsChange(index, e)}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor={`variantsTax${index}`} className="form-label">Tax</label>
                    <input
                      type="number"
                      className="p-2 form-control"
                      id={`variantsTax${index}`}
                      name="tax"
                      value={variants.tax}
                      onChange={(e) => handleVariantsChange(index, e)}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor={`variantsDiscount${index}`} className="form-label">Discount</label>
                    <input
                      type="number"
                      className="p-2 form-control"
                      id={`variantsDiscount${index}`}
                      name="discount"
                      value={variants.discount}
                      onChange={(e) => handleVariantsChange(index, e)}
                      required
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-2 center pt-lg-4">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveVariants(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className='col-lg-2 col-md-2 addMoreBtn mx-3 mt-4 mb-3' onClick={handleAddVariants}>
            Add more
          </div>
        </div>
        <div className='row mt-5'>
          <div className='end'>
            <button className='addItem-btn' type="submit">Add Item</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddItem;
