import { React, useEffect, useState } from 'react'
import "./navBar.css"
import "../../../component/pages/menus/menuItemcard.css"
import ApiService from '../../../services/apiService';
import { useNavigate } from 'react-router';
const CostumerNavBar = () => {
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory] = useState("");
    const [categorymenu, setCategoryMenu] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCat, setSelectedSubCat] = useState("");
    const [isHovered, setIsHovered] = useState(null);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };

    }, []);


    const navigate = useNavigate();

    useEffect(() => {
        GetCategory();
    }, []);

    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_CATEGORY');
            const filteredcategory = res.data.result.filter(item => item?.active === true);
            setCategories(filteredcategory);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };

    const handleCategoryClick = async (selectedCategory) => {
        try {
            const apiService = new ApiService();
            let parameters = `?CategoryId=${selectedCategory}`;

            let res = await apiService.getApiParamater('GET_MENU', parameters);
            if (res?.data?.statusCode === 200) {
                const filteredmenu = res.data.result.filter(item => item?.active === true);
                setCategoryMenu(filteredmenu);
                navigate('/customer/menu', { state: { categorymenu: filteredmenu } });
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
    };

    const handleSubCategoryClick = async (selectedSubCat) => {
        try {
            const apiService = new ApiService();
            let parameters = `?SubCategoryId=${selectedSubCat}`;

            let res = await apiService.getApiParamater('GET_MENU', parameters);
            if (res?.data?.statusCode === 200) {
                const filteredmenu = res.data.result.filter(item => item?.active === true);
                setCategoryMenu(filteredmenu);
                navigate('/customer/menu', { state: { categorymenu: filteredmenu } });
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
    };

    const handlemenu = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_MENU');
            if (res?.data?.statusCode === 200) {
                const filteredmenu = res.data.result.filter(item => item?.active === true);
                setCategoryMenu(filteredmenu);
                navigate('/customer/menu', { state: { categorymenu: filteredmenu } });
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
        // Clear subcategories when "All Items" is clicked
        setSubCategories([]);
    };

    const GetSubCategory = async (categoryId) => {
        try {
            const apiService = new ApiService();
            let parameters = `?CategoryId=${categoryId}`;
            let res = await apiService.getApiParamater('GET_SUBCATEGORY', parameters);
            const filteredsub = res.data.result.filter(item => item?.active === true);
            setSubCategories(filteredsub);
        } catch (error) {
            console.error('Error fetching subcategory data:', error);
        }
    };

    const handleMouseEnter = (categoryId) => {
        setIsHovered(categoryId);
        GetSubCategory(categoryId);
    };

    const handleMouseLeave = () => {
        setIsHovered(null);
        setSubCategories([]);
    };

    const handleDocumentClick = (event) => {
        if (!event.target.closest('.nav-pills')) {
            handleMouseLeave();
        }
    };

    return (
        <div className="maoin-BG">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-pills overslid d-flex justify-content-between" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation" >
                                <button
                                    className={`nav-link ${selectedCategory ? 'active' : 'active'}`}
                                    onClick={() => handlemenu()}
                                    data-bs-toggle="pill"
                                    type="button"
                                    role="tab"
                                >
                                    All Items
                                </button>
                            </li>
                            {categories?.map((category, key) => (
                                <li className="nav-item" role="presentation" key={key}>
                                    <button
                                        className={`nav-link ${selectedCategory === category.id ? 'active' : ''}`}
                                        onClick={() => handleCategoryClick(category.id)}
                                        onMouseEnter={() => handleMouseEnter(category.id)}
                                        id={`pills-${category.name}-tab`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#pills-${category.name.replace(/\s+/g, '-')}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`pills-${category.name}`}
                                        aria-selected={selectedCategory === category?.id ? 'true' : 'false'}
                                    >
                                        {category.name}
                                    </button>
                                    {isHovered === category.id && (
                                        <div className="subcategories-dropdown">
                                            {subCategories?.map((subCategory, subKey) => (
                                                <div className='subcategories-dropdown-div' key={subKey}
                                                    onClick={() => handleSubCategoryClick(subCategory.id)}>{subCategory.name}</div>
                                            ))}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CostumerNavBar