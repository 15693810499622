import React, { useState } from 'react';
import Cake from '../../assets/images/CakeImg.png';
import './itemCard.css';
import Modal from 'react-modal';
import UpdateCartModal from './updateCartPopup';
import { useNavigate } from 'react-router';
import { connect } from "react-redux";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const UpdateItemCard = ({ val, addToCart, Currency }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let navigate = useNavigate();
  const [CartValue, setCartValue] = useState("");

  const openModal = (e) => {
    setCartValue(e)
    setIsModalOpen(true);
  }
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const truncatedAddress = val?.name?.length > 20 ? val?.name.substring(0, 25) + '...' : val?.name;
  const truncatedDescription = val?.description?.length > 20 ? val?.description.substring(0, 30) + '...' : val?.description;
  return (
    <div className=' py-3'>
      <div className='cashcard'>
        <div className='row'>
          <div className='col-lg-3'>
            <img className='itm_Img' src={val?.imageUrl === null || "" ? Cake :
              IMAGE_URL + val?.imageUrl} />
          </div>
          <div className='col-lg-9'>
            {/* <div className='card-Head'>{val?.name}</div> */}
            <div className="card-Head" title={val?.name}>{truncatedAddress || "--"}</div>
            <div className='card-subHead' title={val?.description}>{truncatedDescription}</div>
            <div className='card-Prize'>{Currency.currency.name} {val?.price}</div>
            <div className='d-flex justify-content-end'>
              <div className='AddCart-btn center p-2' onClick={() => openModal(val)}>
                Add to cart
              </div>
            </div>
          </div>
        </div>
        {/* Render the CartModal as a modal */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel='Cart Modal'
        >
          <UpdateCartModal CartValue={CartValue} closeModal={closeModal} addToCart={addToCart} />
          {/* <button onClick={closeModal}>Close</button> */}
        </Modal>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(UpdateItemCard);