import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLayout from '../pages/sideBarLayout';
import CashierHeader from './cashierHeader/cashHeader';

const MainLayout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Determine if the MainLayout is active
    const isMainLayoutActive = location.pathname === "/Cashier/ShiftOpen"
    // Apply or remove the body-with-sidebar class
    if (isMainLayoutActive) {
      document.body.classList.add('body-with-sidebar');
    } else {
      document.body.classList.remove('body-with-sidebar');
    }
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== "/Cashier/ShiftOpen" && (
        <SidebarLayout>
          <CashierHeader />
          <div className='pt-5'>
            <div className='pt-5'>
              {children}
            </div>
          </div>
        </SidebarLayout>
      )}
      {location.pathname === "/Cashier/ShiftOpen" && (
        <div>
          <CashierHeader />
          <div className='pt-5'>
            <div className='pt-5'>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
