import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CartSlider from '../../../Customer/Cart/imgSlider';
import "../../../Customer/Menu/Menu Card/menuCard.css";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { AddToCart } from '../../../../Redux/actions/cartAction';
import ApiService from '../../../services/apiService';
import { AddComboToCart } from '../../../../Redux/actions/comboAction';

const ComboPopup = ({ CartValue, Currency, closeModal }) => {
    const itemData = CartValue;
    
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [menuItems, setMenuItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState(null);

    useEffect(() => {
        fetchMenuItems();
    }, []);
    const fetchMenuItems = async () => {
        try {
            const apiService = new ApiService();
            const itemsPromises = itemData.comboItems?.map(async (comboItem) => {
                const res = await apiService.getApiParamater("GET_MENU", `?Id=${comboItem.itemId}`);
                return res.data.result;
            });
            const menuItemsData = await Promise.all(itemsPromises);
            const flattenedMenuItems = menuItemsData.flat();
            setMenuItems(flattenedMenuItems);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    const handleATC = () => {
            let selected = ({
                name: itemData.name,
                description: itemData.description,
                price: itemData.price,
                tax: itemData.tax,
                discount: itemData.discount,
                active: itemData.active,
                imageUrl: itemData.imageUrl,
                quantity: 1,
                comboItems: itemData.comboItems.map(comboItem => ({
                    itemId: comboItem.itemId,
                    metaItemIds: [],
                })),
                id: itemData.id,
                loggedInUserId: itemData.loggedInUserId
            });
        
        dispatch((AddComboToCart(selected)))
        closeModal(true);
    };

    return (
        <div className=''>
            <div className='container-fluid m-0 p-0 pt-5 px-5'>
                <div className="row py-lg-3">
                    <div className="col-lg-4">
                        <CartSlider val={itemData} />
                    </div>
                    <div className="col-lg-8 px-lg-5">
                        <div className="CartName">{itemData?.name}</div>
                        <div className="cartPrice pt-2">{Currency.currency.name}  {itemData?.price}</div>
                        {itemData.comboItems?.map(item => (
                            <div className="py-3">
                                <div key={item?.id} className="form-check d-flex justify-content-between py-3">
                                    <label className="form-check-label checkItem2">
                                        {item?.itemName } ({item?.variantName})

                                    </label>
                                    <div className="checkItem">{Currency.currency.name} {item?.variantPrice}</div>
                                </div>
                            </div>
                        ))}
                        <div className='row pt-lg-5'>
                            <div className='col-lg-12'>
                                <button className='w-100 AddBtn' onClick={handleATC}>
                                    Add to cart  ({Currency.currency.name}  {itemData?.price})
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ComboPopup);
