import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import "../Dashboard/dashboard.css";

const dataMonthly = [
  { name: 'Jan', wastage: 150 },
  { name: 'Feb', wastage: 230 },
  { name: 'Mar', wastage: 342 },
  { name: 'Apr', wastage: 453 },
  { name: 'May', wastage: 123 },
  { name: 'Jun', wastage: 453 },
  { name: 'Jul', wastage: 485 },
  { name: 'Aug', wastage: 234 },
  { name: 'Sep', wastage: 562 },
  { name: 'Oct', wastage: 651 },
  { name: 'Nov', wastage: 110 },
  { name: 'Dec', wastage: 80 },
  // Add data for other months
];

const dataWeekly = [
  { name: 'Week 1', wastage: 30 },
  { name: 'Week 2', wastage: 45 },
  { name: 'Week 3', wastage: 85 },
  { name: 'Week 4', wastage: 65 },
  // Add data for other weeks
];

const dataToday = [
  { name: 'Today', wastage: 10 },
  // Add data for other time intervals within the day
];

function CustomBarChart() {
  const [chartData, setChartData] = useState(dataMonthly); // Default to monthly data

  const handleChartChange = (option) => {
    switch (option) {
      case 'Monthly':
        setChartData(dataMonthly);
        break;
      case 'Weekly':
        setChartData(dataWeekly);
        break;
      case 'Today':
        setChartData(dataToday);
        break;
      default:
        break;
    }
  };

  return (
    <div className='dashcard'>
      <div className='d-flex justify-content-between py-3'>
        <div>
          <div className='chatrtHead'>Net Wastage</div>
          <div className='charthead2'>Lorem ipsum dolor sit amet, consectetur</div>
        </div>
        <div className='filterbg gap-3 d-flex'>
          <div><button onClick={() => handleChartChange('Monthly')} className='fltrbtn'>Monthly</button></div>
          <div><button onClick={() => handleChartChange('Weekly')} className='fltrbtn'>Weekly</button></div>
          <div><button onClick={() => handleChartChange('Today')} className='fltrbtn'>Today</button></div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="wastage" fill="#5b93ff" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CustomBarChart;
