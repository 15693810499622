import React, { useState, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Companylogo from './Companylogo';
import { HiPencil } from "react-icons/hi2";
import { FaEye } from "react-icons/fa";
import { MdOutlineEditOff } from "react-icons/md";

const CompanyGridMaster = ({ rowData, fetchCategoryData, canEditMaster, setSelectedRow, handleEdit }) => {
    const gridRef = useRef();
    const rowHeight = 50
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true,suppressMovable: true , width: 50 },
        {
            headerName: "Logo", field: "companyLogo", cellRenderer: Companylogo, cellClass: "logoCell",suppressMovable: true , width: 150,
        },
        { headerName: 'ID', field: 'companyId', filter: true, floatingfilter: true,suppressMovable: true , width: 100 },
        { headerName: 'Name', field: 'companyName', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        { headerName: 'Email', field: 'companyEmail', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        { headerName: 'Phone No', field: 'companyTel', filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        { headerName: 'P.o.box', field: 'companyPO', filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        { headerName: 'Trn', field: 'trn', filter: true, floatingfilter: true,suppressMovable: true , width: 100 },
        { headerName: 'Fax', field: 'companyFax', filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        { headerName: 'Web', field: 'companyWeb', filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        { headerName: 'City', field: 'companyCity', filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        { headerName: 'Country', field: 'companyCountry', filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        {
            headerName: 'Action', field: 'record_id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                // clicked: function (field, isActive) {
                //     updateActiveStatus(field, isActive);
                // },
            },suppressMovable: true , width: 130
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                record_id: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_COMPANY`, body);
            if (res?.data?.statusCode === 201) {
                console.log('Updated successfully');
                fetchCategoryData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const onCellClicked = (event) => {
        if (event.colDef.field === 'type') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);

        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    return (
        <div className='gridshad' style={{ height: 500 }}>
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    rowData={rowData}
                    ref={gridRef}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection={'single'} rowHeight={rowHeight}
                    columnDefs={columnDefs}
                    onSelectionChanged={onSelectionChanged}
                    onCellClicked={onCellClicked}
                />
            </div>
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        const { canEditMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {/* {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                } */}
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default CompanyGridMaster;
