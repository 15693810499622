import React, { useEffect, useState } from 'react';
import "../component/pages/orders/singleOrder/singleOrder.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import ApiService from '../services/apiService';
import SingleOrder from '../component/pages/orders/singleOrder/singleOrder';

const KitchenOrderDetail = ({ Currency }) => {

    const [orderDetails, setorders] = useState([]);
    const { state } = useLocation();
    const Itemdata = state
    useEffect(() => {
        getItemDetails();
    }, [Itemdata]);
    let navigate = useNavigate();
    const getItemDetails = async () => {
        const apiService = new ApiService();
        const itemNumber = Itemdata.replace(/^ORD-/i, '');
        let parameter = `?id=${itemNumber}`
        let res = await apiService.getApiParamater("GET_ORDERS", parameter);
        setorders(res?.data?.result?.[0]);
    }
    return (
        <div>
            {orderDetails?.orderItems?.map((val, key) => (
                <SingleOrder val={val} orderdata={orderDetails} />
            ))}
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(KitchenOrderDetail);