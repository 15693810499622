import React from 'react';
import "../singleOrder.css"

const PaySection = ({ val }) => {

    const getOrderTypeLabel = (orderType) => {
        switch (orderType) {
            case 0:
                return 'Dine In';
            case 1:
                return 'Takeout';
            case 2:
                return 'Delivery';
            case 3:
                return 'Online Order';
            case 4:
                return 'Drive Thru';
            case 5:
                return 'Catering';
            case 6:
                return 'Room Service';
            case 7:
                return 'Pickup';
            case 8:
                return 'Curbside Pickup';
            default:
                return '';
        }
    };
    const getPaymentStatusLabel = (paymentStatus) => {
        switch (paymentStatus) {
            case 0:
                return 'Pending';
            case 1:
                return 'Authorized';
            case 2:
                return 'Paid';
            case 3:
                return 'Refunded';
            case 4:
                return 'Failed';
            default:
                return '';
        }
    };
    const getOrderSourceLabel = (orderSource) => {
        switch (orderSource) {
            case 0:
                return 'Website';
            case 1:
                return 'Mobile App';
            case 2:
                return 'Phone';
            case 3:
                return 'In Person';
            case 4:
                return 'Third Party Delivery';
            case 5:
                return 'Cashier';
            case 6:
                return 'Waiter';
            default:
                return '';
        }
    };

    return (
        <div className='d-flex justify-content-between'>
            <div className=''>
                <div className='d-flex gap-3'>
                    <div className='itmmain'>Payment</div>
                    <div className='carddet center'>{getPaymentStatusLabel(val?.paymentStatus)}</div>
                </div>
                <div className='carddet'>{val?.customerName}</div>
            </div>
            <div className=''>
                <div className='itmmain end'>{getOrderSourceLabel(val?.orderSource)}</div>
                <div className='itmtitl'>{getOrderTypeLabel(val?.orderType)}</div>
            </div>
        </div>
    );
};

export default PaySection;
