import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import "../purchaseOrder/print.css"
import { FiPrinter } from "react-icons/fi";
import { PiDownloadThin } from "react-icons/pi";
import moment from 'moment';
// import Logo from "../../Assets/images/Qfinitylogos.png"
import Logo from "../../Assets/images/Pro_Logo.png"
import ApiService from '../../services/apiService';
import { useCurrency } from '../../Layouts/currencyContext';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../Redux/selecters';

const RequsitionPrint = ({ }) => {
    const location = useLocation();
    const CompareData = location.state;
    const [Items, setItems] = useState([]);
    const [suppliers, setSupplier] = useState();
    const currentDatetimeUTC = new Date().toISOString();
    const { currencyPrecision } = useCurrency();
    const companies = useSelector(getCompany);

    useEffect(() => {
        fetchSupplierData()
        setItems(CompareData?.prDetails)
    }, [location?.state]);
    const fetchSupplierData = async () => {
        try {
            const apiService = new ApiService()
            let param = `?SupplierName=${CompareData?.vendorName}`
            const response = await apiService.getApiParamater('GET_SUPPLIER', param);
            setSupplier(response?.data?.result[0]);
        } catch (error) {
            console.error("Error fetching Supplier data:", error);
        }
    };
    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className='container p-lg-5'>
            <div className='d-flex justify-content-between py-5'>
                <div className='' onClick={handleBackClick} ><button className='prntbtn'> <MdKeyboardDoubleArrowLeft size={20} /> Back</button></div>
                <div className=''><button className='downbtn'> <PiDownloadThin size={20} /></button></div>
            </div>
            <div className=''>
                <div className='printhead'>
                    <div className='d-flex justify-content-between'>
                        <div className='center'>
                            <img
                                src={companies?.selectedCompany?.logo || Logo}
                                alt="Logo"
                                style={{ height: "35px" }}
                            />
                            <span className="logoName" style={{ marginLeft: "8px" }}>
                                {companies?.selectedCompany?.logo
                                    ?.split('/')
                                    .pop()
                                    ?.split('.')
                                    .slice(0, -1)
                                    .join('.')
                                    ?.charAt(0)
                                    .toUpperCase() +
                                    companies?.selectedCompany?.logo
                                        ?.split('/')
                                        .pop()
                                        ?.split('.')
                                        .slice(0, -1)
                                        .join('.')
                                        ?.slice(1)
                                        .toLowerCase() }
                            </span>
                        </div>
                        <div className='mainhead'>
                            <u>Material Requisition </u>
                        </div>
                        <div className='headtx d-flex align-items-end'>
                            <div className='pt-1'>Date: {moment(CompareData?.requisitionDate).format('ll')}</div>
                        </div>
                    </div>
                </div>
                <div className='printboxIn p-3 d-flex gap-3'>
                    <div className='printsupbox w-50'>
                        <div className='d-flex justify-content-between'>
                            <div>Date</div>
                            <div>{moment(CompareData?.deliveryDate).format('ll')}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Requisition NO</div>
                            <div>{CompareData?.requisitionNo}</div>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div>Departments</div>
                            <div>{CompareData?.department || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Location</div>
                            <div>{CompareData?.costCenter1 || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Project</div>
                            <div>{CompareData?.reqType || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Remarks</div>
                            <div>{CompareData?.remarks || "---"}</div>
                        </div>
                    </div>
                    <div className='printsupbox w-50'>
                        <div className='d-flex justify-content-between'>
                            <div>Requestor</div>
                            <div>{CompareData?.requestor || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Contact Person</div>
                            <div>{CompareData?.contactPerson || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Contact No</div>
                            <div>{CompareData?.contatNo || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Job No</div>
                            <div>{"---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Status</div>
                            <div>{CompareData?.status === 1 ?
                                <div>Approved</div> : <>
                                    {CompareData?.status === 0 ?
                                        <div>Pending</div> : <>
                                            {CompareData?.status === 2 ?
                                                <div>Hold</div> :
                                                <>{CompareData?.status === 3 ?
                                                    <div>Rejected</div> : <div>Pending</div>
                                                }</>
                                            }
                                        </>
                                    }</>
                            }</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Delivery Point</div>
                            <div>{CompareData?.deliveryPoint || "---"}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Cost Center 2</div>
                            <div>{CompareData?.costCenter2 || "---"}</div>
                        </div>
                    </div>

                </div>
                <div className='printhead2 mb-5'>
                    <table>
                        <thead className='dynoTable' style={{ backgroundColor: '#5B93FF', color: '#fff' }}>
                            <tr>
                                <th>Sr no</th>
                                <th>Item No</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>UOM</th>
                                <th>Unit Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Items?.map((row, index) => (
                                <tr key={index}>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "80px" }} type="text" value={index + 1} disabled /></td>
                                    <td className='m-0 p-0'>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "110px" }} disabled value={row.itemCode} /></td>
                                    </td>
                                    <td className='m-0 p-0'>
                                        <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} /></td>
                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' disabled value={row.qty} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled value={row.baseAmount.toFixed(currencyPrecision) || 0} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled value={(row.qty * row.baseAmount).toFixed(currencyPrecision) || 0} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='p-5 row'>
                        <div className=' row d-flex justify-content-between'>
                            <div className='col-2'>
                                <div className='itmKIns center'>Admin</div>
                                <hr className='' />
                                <div className='itmKIns center'>Requested By</div>
                            </div>
                            <div className='col-2'>
                                <div className='itmKIns center'>Admin</div>
                                <hr className='' />
                                <div className='itmKIns center'>Reviewed By</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequsitionPrint;
