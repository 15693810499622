import React, { useState, useEffect, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg'
import { IoLink } from "react-icons/io5";
import { TbBallpenOff } from "react-icons/tb";
import { AiOutlinePrinter } from "react-icons/ai"
import { FaRegEye } from "react-icons/fa6"
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination';
import { clearRows } from '../../../Redux/actions/rowActions';
import { GrEdit } from "react-icons/gr";
import { useDispatch } from 'react-redux';
const DirectPurchaseList = ({ onRequisitionData, itemData }) => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    let navigate = useNavigate();
    const gridRef = useRef();
    const [pageSize, setPageSize] = useState(20);
    let dispatch = useDispatch()
    const [pagination, setPagination] = useState();
    useEffect(() => {
        getItems();
    }, []);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
                    navigate("/inventory/DirectPurchase/Update", { state: updatedData });
                } else {
                    navigate("/inventory/DirectPurchase/Update", { state: clickedRowData });
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
                    navigate("/inventory/DirectPurchase/Update", { state: updatedData });
                } else {
                    navigate("/inventory/DirectPurchase/Update", { state: selectedRow });
                }
            }
        }
    }
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/inventory/GRN/${selectedRow?.grnNo}`, { state: selectedRow })
        }
    }
    const handleEye = () => {
        if (selectedRow) {
            let eye = true
            const updatedData = { ...selectedRow, eye: eye };
            navigate("/inventory/DirectPurchase/Update", { state: updatedData })
        }
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_GRN', `?${params.toString()}`);
            let responseSort = res?.data?.result.reverse()
            const grnData = responseSort?.filter(dep => dep.grnNo === dep?.pOrderNo)
            setPagination(res.data.paginationData)
            setRowData(grnData.reverse() || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true,suppressMovable: true , width: 50 },
        { headerName: 'DP GRN No', field: 'grnNo', filter: true, floatingFilter: false ,suppressMovable: true , width: 150},
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingFilter: false },
        {
            headerName: "Invoice Date", field: "invoiceDate", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Invoice No', field: 'invoiceNo', filter: true, floatingFilter: false },
        { headerName: 'Location', field: 'warehouse', filter: true, floatingFilter: false },
        { headerName: 'Property', field: 'costCenter1', filter: true, floatingFilter: false },
        { headerName: 'Property Group', field: 'costCenter2', filter: true, floatingFilter: false },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        // {
        //     headerName: 'Actions', field: 'grnNo', cellRenderer: PrinterBtn,
        //     cellRendererParams: {
        //         handleEdit: handleEdit,
        //         handlePrint: handlePrint,
        //         handleEye: handleEye,
        //         clicked: function (field, isActive) {
        //             updateActiveStatus(field, isActive);
        //         },
        //     },
        // }
    ];
    const clearAllRows = () => {
        dispatch(clearRows());
    };
    const handlenew = () => {
        clearAllRows();
        navigate('/inventory/DirectPurchase/Create')
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'grnNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='justify-content-between pb-3 d-flex align-items-center'>
                        <h4 className='mainInvHead'>
                            Direct Purchase List
                        </h4>
                        <div className='d-flex justify-content-end gap-3'>
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>View</div>
                            </div>
                            <div
                                onClick={selectedRow && selectedRow.status === 1 ? handleEdit : null}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status !== 1 ? 'disabled' : ''
                                    }`}
                                style={{
                                    cursor: selectedRow && selectedRow.status === 1 ? 'pointer' : 'not-allowed',
                                }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div
                                onClick={handlePrint}
                                className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <AiOutlinePrinter color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Print</div>
                            </div>
                            <div onClick={handlenew} className='mainbtn  flex-end  gap-3'>
                                <IoAddSharp color='#fff' className='center mt-1 mx-2' />
                                <div>Add New</div>
                            </div>
                        </div>
                    </div>
                    <div className='gridshad'>
                        <div className="ag-theme-quartz " style={{ height: 650 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                onCellClicked={onCellClicked}
                            />
                        </div>
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </>
            )}
        </div>
    );
};
// class BtnCellRenderer extends Component {
//     constructor(props) {
//         super(props);
//         this.btnClickedHandler = this.btnClickedHandler.bind(this);
//         this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
//         this.btnEyeClickedHandler = this.btnEyeClickedHandler.bind(this);
//         this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
//     }

//     btnClickedHandler() {
//         const { value, clicked } = this.props;
//         clicked(value, !this.props.data.isActive); // Toggle isActive value
//     }
//     btnEditClickedHandler() {
//         const { data, handleEdit } = this.props;
//         handleEdit(data);
//     }
//     btnEyeClickedHandler() {
//         const { data, handleEye } = this.props;
//         handleEye(data);
//     }
//     btnPrintClickedHandler() {
//         const { data, handleEye } = this.props;
//         handleEye(data);
//     }
//     render() {
//         const { isActive } = this.props.data; // Extract isActive from rowData
//         return (
//             <div className='d-flex gap-3'>
//                 {/* <div><Link /></div> */}
//                 {this.props.data?.status === 1 ?
//                     <div><TbBallpenOff size={28} /></div>
//                     :
//                     <div onClick={this.btnEditClickedHandler}><Edit /></div>
//                 }
//                 <div>
//                     <IoLink size={30} />
//                 </div>
//             </div>
//             // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
//         )
//     }
// };
// class PrinterBtn extends Component {
//     btnClickedHandler() {
//         const { value, clicked } = this.props;
//         clicked(value);
//     }
//     constructor(props) {
//         super(props);
//         this.btnEyeClickedHandler = this.btnEyeClickedHandler.bind(this);
//         this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
//         this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
//     }

//     btnPrintClickedHandler() {
//         const { data, handlePrint } = this.props;
//         handlePrint(data);
//     }
//     btnEyeClickedHandler() {
//         const { data, handleEye } = this.props;
//         handleEye(data);
//     }
//     btnEditClickedHandler() {
//         const { data, handleEdit } = this.props;
//         handleEdit(data);
//     }
//     render() {
//         return (
//             <div className='d-flex gap-3'>
//                 {this.props.data?.status === 1 ?
//                     <div><TbBallpenOff size={23} /></div>
//                     :
//                     <div><Edit onClick={this.btnEditClickedHandler} size={20} /></div>
//                 }
//                 <div>
//                     {/* <FaRegEye onClick={this.btnEyeClickedHandler} size={25} /> */}
//                 </div>
//                 <div>
//                     <AiOutlinePrinter onClick={this.btnPrintClickedHandler} size={25} />
//                 </div>
//             </div>
//         )
//     }
// }
export default DirectPurchaseList;
