import React, { useEffect, useState } from 'react';
import "./table.css";
import ApiService from '../../../services/apiService';
import { TableStatus } from '../../../../Enums/config';
import { useNavigate } from 'react-router-dom';

const Reservations = () => {
    const [reservationData, setReservationData] = useState([]);
    const apiService = new ApiService();
    const navigate = useNavigate();

    useEffect(() => {
        fetchReservations();
    }, []);

    const fetchReservations = async () => {
        try {
            let Params = `?Status=${TableStatus?.Reserved}`;
            let reservationData = await apiService.getApiParamater("GET_TABLE", Params);
            setReservationData(reservationData?.data?.result);
        } catch (error) {
            console.error(error);
        }
    };

    const handleEdit = (reservation) => {
        navigate('/Cashier/Tables/NewReservation', { state: { reservation } });
    };

    const handleCancel = async (reservationId) => {
        try {
            let body = {
                id: reservationId,
                status: TableStatus.Available
            }
            const response = await apiService.put("UPDATE_TABLE", body);
            if (response.status === 200) {
                fetchReservations();
            }
        } catch (error) {
            console.error("Failed to cancel reservation:", error);
        }
    };

    const handleClientArrived = async (reservationId) => {
        try {
            let body = {
                id: reservationId,
                status: TableStatus.Available
            }
            const response = await apiService.put("UPDATE_TABLE", body);
            if (response.status === 200) {
                fetchReservations();
            }
        } catch (error) {
            console.error("Failed to mark client as arrived:", error);
        }
    };

    return (
        <div className='container-fluid'>
            <div className='row justify-content-between p-5'>
                {reservationData <= 0 ?
                 <div className='mt-5 d-flex justify-content-center' >
                    <h4 className='mt-4'>
                        No Reservation
                    </h4>
                </div>
                    :
                    <>
                        {reservationData?.map((reservation, index) => (
                            <div key={index} className='chairBX borderShadow col-lg-3 m-3 p-3 justify-space-between'>
                                <div className='row justify-content-between'>
                                    <div className='col'>
                                        <div className='firstText'>Client Name</div>
                                    </div>
                                    <div className='col'>
                                        <div className='SecondText d-flex justify-content-end'>{reservation?.reservedByName}</div>
                                    </div>
                                    <hr />
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col'>
                                        <div className='firstText'>Mobile No</div>
                                    </div>
                                    <div className='col'>
                                        <div className='SecondText d-flex justify-content-end'>{reservation?.reservedByPhone}</div>
                                    </div>
                                    <hr />
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col'>
                                        <div className='firstText'>Table No</div>
                                    </div>
                                    <div className='col'>
                                        <div className='SecondText d-flex justify-content-end'>T-{reservation.id}</div>
                                    </div>
                                    <hr />
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col'>
                                        <div className='firstText'>Area</div>
                                    </div>
                                    <div className='col'>
                                        <div className='SecondText d-flex justify-content-end'>{reservation.area}</div>
                                    </div>
                                    <hr />
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col'>
                                        <div className='firstText'>Time</div>
                                    </div>
                                    <div className='col'>
                                        <div className='SecondText d-flex justify-content-end'>{reservation.time}</div>
                                    </div>
                                    <hr />
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col'>
                                        <div className='firstText'>Date</div>
                                    </div>
                                    <div className='col'>
                                        <div className='SecondText d-flex justify-content-end'>{reservation.date}</div>
                                    </div>
                                    <hr />
                                </div>

                                <div className='row justify-content-between'>
                                    <div className='col'>
                                        <div className='addItem-btn' onClick={() => handleEdit(reservation)}>
                                            Edit
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='addItem-btn' onClick={() => handleCancel(reservation.id)}>
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4 justify-content-between'>
                                    <div className='col'>
                                        <div className='addItem-btn' onClick={() => handleClientArrived(reservation.id)}>
                                            Client Arrived
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>

                }
            </div>
        </div>
    );
};

export default Reservations;
