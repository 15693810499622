import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Brush,
    AreaChart,
    Area,
    ResponsiveContainer,
} from 'recharts';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import "./report.css"

const ChartLine = ({ Currency }) => {

    const data = [
        {
            name: 'Sun',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Mon',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Tue',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Wed',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Thu',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Fri',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Sat',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <ResponsiveContainer width="100%" height={550}>
                <AreaChart
                    width={500}
                    height={200}
                    data={data}
                    syncId="anyId"
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="pv" stroke="#82ca9d" fill="#82ca9d" />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8"  />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ChartLine);
