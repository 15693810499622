import {
  ADD_TO_ORDER,
  REMOVE_FROM_ORDER,
  UPDATE_ORDER_ITEM_QUANTITY,
  UPDATE_ORDER_ITEM,
  UPDATE_ORDER_ITEM_DISCOUNT,
  DOWNGRADE_ORDER_ITEM_QUANTITY,
  CLEAR_ORDER
} from '../actions/types';

const initialState = {
  items: [],
};

const orderEditReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case ADD_TO_ORDER:
      const newIndex = state.items.length;
      const newItem = { ...action.payload, index: newIndex, metaitemindex: action.payload.index };
      return {
        ...state,
        items: [...state.items, newItem],
      };

    case REMOVE_FROM_ORDER:
      if (action.payload.index === undefined) {
        
        return {
          ...state,
          items: state.items.filter(item => item.itemData.id !== action.payload.itemId),
        };
      } else {
        return {
          ...state,
          items: state.items.filter(
            (item, i) => item.itemData.id !== action.payload.itemData.id || i !== action.payload.index
          ),
        };
      }
    case UPDATE_ORDER_ITEM:
      return {
        ...state,
        items: state.items.map((item, index) => {
          if (index === action.payload.index) {

            return {
              ...item,
              itemData: {
                ...item.itemData,
                voidItem: action.payload.voidItem,
              },
            };
          }
          return item;
        }),
      };

    case UPDATE_ORDER_ITEM_QUANTITY:
      return {
        ...state,
        items: state.items.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        }),
      };
    case UPDATE_ORDER_ITEM_DISCOUNT:
      return {
        ...state,
        items: state.items?.map((item, index) => {
          if (index === action.payload.index) {

            return {
              ...item,
              itemData: {
                ...item.itemData,
                menuItemDiscount: action.payload.discount,
                selectedPromotionDetails: action.payload.selectedPromotionDetails,
                discountType: action.payload.discountType,
                discountId: action.payload.discountId,
                promotionId: action.payload.promotionId,
              }
            };
          }

          return item;
        })
      };
    case DOWNGRADE_ORDER_ITEM_QUANTITY:
      return {
        ...state,
        items: state.items.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          }
          return item;
        }),
      };

    case CLEAR_ORDER:
      return {
        ...state,
        items: [],
      };

    default:
      return state;
  }
};

export default orderEditReducer;
