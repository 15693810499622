import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import { AiOutlinePrinter } from "react-icons/ai"
import moment from 'moment';
import { IoAddSharp } from "react-icons/io5";
import { GrEdit } from "react-icons/gr";
import { hasPermission } from "../../services/roleService"
import { FaRegEye } from "react-icons/fa6";
import Pagination from '../pagination/pagination';

const InvoiceList = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [selectedRow, setSelectedRow] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [pagination, setPagination] = useState();
    const StatusFilter = [
        { value: 'All', label: 'All' },
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Approved' },
        { value: '5', label: 'Hold' },
        { value: '2', label: 'Rejected' },
    ];
    const [formData, setFormData] = useState({
        Status: { label: 'All', value: '' },
    });
    const handleClearFilters = () => {
        setFormData({
            Status: [{ label: 'All', value: '' }],
        });
    };
    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
        if (formData) {
            getItems();
        }
    }, [pageSize, formData]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('Purchase Requisition', 'edit');
    // const canCreateItemMaster = checkPermission('Purchase Requisition', 'create');

    const canEditItemMaster = true;
    const canCreateItemMaster = true;

    const handlePrint = (data) => {
        navigate(`/inventory/invoice/Print/${data?.invoiceNo}`, { state: data })
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            if (formData?.Status?.value === 'All') {
                const params = new URLSearchParams();
                params.append('PageNo', newPageNo || 1);
                params.append('PageSize', pageSize || 20);
                const res = await apiService.getApiParamater('GET_INVOICE', `?${params.toString()}`);
                setPagination(res.data.paginationData)
                setRowData(res?.data?.result || []);
                setLoading(false);
            } else {
                const params = new URLSearchParams();
                params.append('PageNo', newPageNo || 1);
                params.append('PageSize', pageSize || 20);
                params.append('Status', formData?.Status?.value);
                const res = await apiService.getApiParamater('GET_INVOICE', `?${params.toString()}`);
                setPagination(res.data.paginationData)
                setRowData(res?.data?.result || []);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const Comparison = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Invoice No', field: 'invoiceNo', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'PO No', field: 'pOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'GRN No', field: 'grnNo', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        {
            headerName: 'Invoice Date', field: 'invoiceDate', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Vendor Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'Property', field: 'costCenter1', filter: true, floatingfilter: true, suppressMovable: true, width: 170 },
        { headerName: 'Property Group', field: 'remarks', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        {
            headerName: 'Due Date', field: 'dueDate', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === 3 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }, suppressMovable: true, width: 160
        }
    ];
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handlenew = () => {
        navigate('/inventory/invoice/Create')
    }
    const handleStatusFilter = (selectedOption) => {
        setFormData({ ...formData, Status: selectedOption });
    }
    const handleEye = () => {
        if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate(`/inventory/invoice/${selectedRow?.invoiceNo}`, { state: updatedData });
        }
    }
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...clickedRowData, eye: true };
                    navigate(`/inventory/invoice/${clickedRowData?.invoiceNo}`, { state: updatedData });
                } else {
                    navigate(`/inventory/invoice/${selectedRow?.invoiceNo}`, { state: clickedRowData });
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...selectedRow, eye: true };
                    navigate(`/inventory/invoice/${clickedRowData?.invoiceNo}`, { state: updatedData });
                } else {
                    navigate(`/inventory/invoice/${selectedRow?.invoiceNo}`, { state: selectedRow });
                }
            }
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'invoiceNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    return (
        <div className='p-4'>
            <div className=' justify-content-between d-flex align-items-center pb-4'>
                <div className='mainInvHead'>Supplier Invoice</div>
                <div className='col-3'>
                    <Select
                        className="p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                        value={formData.Status}
                        options={StatusFilter}
                        onChange={handleStatusFilter}
                    />
                </div>
                <div className='d-flex justify-content-end gap-2'>
                    <div
                        onClick={handleEye}
                        className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <FaRegEye color='#fff' className='center mt-1' />
                        <div className='center'>View</div>
                    </div>
                    <div
                        onClick={selectedRow && selectedRow.status === 1 ? handleEdit : null}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status !== 1 ? 'disabled' : ''
                            }`}
                        style={{
                            cursor: selectedRow && selectedRow.status === 1 ? 'pointer' : 'not-allowed',
                        }}
                    >
                        <GrEdit color='#fff' className='center mt-1 ' />
                        <div className='center'>Edit</div>
                    </div>
                    <div
                        onClick={handlePrint}
                        className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <AiOutlinePrinter color='#fff' className='center mt-1 ' />
                        <div className='center'>Print</div>
                    </div>
                    <div className='col center'>
                        {canCreateItemMaster === true ?
                            <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div>
                            </button>
                            :
                            <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div></button>
                        }
                    </div>
                </div>
            </div>
            <div className='gridshad'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        columnDefs={Comparison}
                        frameworkComponents={{ frameworkComponents }}
                        onCellClicked={onCellClicked}
                        onSelectionChanged={onSelectionChanged}
                    />
                </div>
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    btnPrintClickedHandler() {
        const { data, handlePrint } = this.props;
        handlePrint(data);
    }
    render() {
        return (
            <div className='d-flex gap-3'>
                {/* <div><AiOutlinePrinter onClick={this.btnPrintClickedHandler} size={30} /></div>
                <div><Link /></div> */}
                {/* <div><Edit /></div> */}
            </div>
        )
    }
}
export default InvoiceList; 