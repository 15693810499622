import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "../../pages/cashierMenu.css"
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import userManagement from '../../../services/userManagement';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../Redux/selecters';
import Select from 'react-select';
import { CashEnums } from '../../../../Enums/config';
import { generateUniqueVoucherNumber } from '../../../services/globalService';

const DayOpeningForm = ({ Currency }) => {

    let navigate = useNavigate();
    const location = useLocation();
    const [Currencies, setCurrency] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [noteQuantities, setNoteQuantities] = useState({});
    const [fillQuantities, setFillQuantities] = useState({});
    const [userIds, setUserId] = useState('');
    let currency = Currency.currency.name
    const userId = useSelector(getUserId);

    const Shift = [
        { value: '1', label: 'Morning Shift' },
        { value: '2', label: 'Evening Shift' },
        { value: '3', label: 'Night Shift' },
    ];

    useEffect(() => {
        GetCurrency()
    }, []);

    useEffect(() => {
        const initialQuantities = {};
        separateAndCountValues(Currencies, currency)?.forEach(item => {
            initialQuantities[item.value] = item.count;
        });
        setQuantities(initialQuantities);
    }, [Currencies, currency]);

    const [formData, setFormData] = useState({
        userName: "",
        date: "",
        amount: "",
        counter: "",
        shift: "",
        note: "",
        branchId: ""
    });
    const totalAmount = Object.keys(quantities).reduce((total, value) => {
        return total + (value * quantities[value]);
    }, 0);

    const CreateShiftOpen = async () => {
        try {
            const now = new Date();
            const currentTime = `${now.getUTCHours().toString().padStart(2, '0')}:${now.getUTCMinutes().toString().padStart(2, '0')}:${now.getUTCSeconds().toString().padStart(2, '0')}`;

            const cashMeta = separateAndCountValues(Currencies, currency)?.map(item => ({
                currencyDenominationId: item.currencyDenominationId,
                quantity: quantities[item.value] || 0
            }));
            
            const body = {
                userId: userId,
                counterId: parseInt(formData?.counter),
                branchId: parseInt(formData?.branchId),
                date: formData?.date ? moment(formData.date).format("YYYY-MM-DD") : null,
                amount: parseFloat(totalAmount),
                remarks: formData?.note || "",
                type: CashEnums.DayOpen,
                loggedInUserId: userId,
                cashMeta: cashMeta,
                time: currentTime,
                voucherNo: generateUniqueVoucherNumber()

            };
            const apiService = new ApiService();
            let res = await apiService.post("CREATE_CASH", body);
            
            if (res?.data?.statusCode === 201) {
                toast?.success("Cash Open Created Successfully");
            }
        } catch (error) {
            console.error('Error fetching Cash data:', error);
        }
    };
    const GetCurrency = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CURRENCY');
            setCurrency(res?.data?.result);
        } catch (error) {
            console.error('Error fetching currency data:', error);
            toast.error('Error fetching currency data');
        }
    };
    const separateAndCountValues = (denominations) => {
        const filteredDenominations = denominations?.find(currency => currency.default === true);
        return filteredDenominations?.currencyDenomination?.map(denomination => {
            const count = denomination?.count || 0;
            return {
                currencyDenominationId: denomination?.currencyDenominationId,
                value: denomination?.value,
                count: count,
                type: denomination?.type
            };
        });

    };
    const incrementQuantity = (value) => {
        setQuantities(prevState => ({
            ...prevState,
            [value]: (prevState[value] || 0) + 1
        }));
    };
    const decrementQuantity = (value) => {
        setQuantities(prevState => ({
            ...prevState,
            [value]: Math.max((prevState[value] || 0) - 1, 0)
        }));
    };
    function getCurrentDate() {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        const yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return yyyy + '-' + mm + '-' + dd;
    }
    const cashDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 1);
    const coinDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 0);
    const handleShiftChange = (selectedOption) => {
        setFormData({ ...formData, shift: selectedOption?.value });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div className='p-lg-5'>
            <div className='p-lg-5'>
                <div className='row'>
                    <div className='col-lg-6 col-sm-6'>
                        <form>
                            <div className='pb-lg-5'>
                                <div className="form-group row">
                                    <div className="col-md-8 col-lg-8">
                                        <label htmlFor="validationCustom01" className="form-label">User</label>
                                        <input
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom01"
                                            name="user"
                                            value={userId}
                                            disabled
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <div className="col-md-8">
                                        <label htmlFor="validationCustom04" className="form-label">Date</label>
                                        <input
                                            type="date"
                                            className="p-2 form-control"
                                            id="validationCustom04"
                                            name="date"
                                            value={getCurrentDate()}
                                            required
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <div className="col-md-8">
                                        <label htmlFor="validationCustom08" className="form-label">Amount</label>
                                        <input
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom08"
                                            name="amount"
                                            value={totalAmount}
                                            required
                                            disabled
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <div className="col-md-8 col-lg-8">
                                        <label htmlFor="validationCustom06" className="form-label">Note</label>
                                        <textarea
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom06"
                                            name="note"
                                            value={formData.note}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='end'>
                                    <div className='addItem-btn' onClick={() => CreateShiftOpen()}>Save</div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {cashDenominations?.length === 0 ? " " :
                    <div className='col-lg-3'>
                        <div className=''>
                            <div>
                                Cash Denominations
                            </div>
                            <div className='borderMain mt-3 p-4'>
                                {cashDenominations?.map((item, index) => (
                                    <div className='row p-2 my-3 innerBorder' key={index}>
                                        <div className='col-3'>{item.value} X</div>
                                        <div className='col-6 d-flex justify-content-between'>
                                            <div className="vr"></div>
                                            <div className="quantity-control d-flex justify-content-around center">
                                                <div onClick={() => decrementQuantity(item.value)} className="addbtn center">
                                                    -
                                                </div>
                                                <div className="center px-3">{quantities[item.value] || 0}</div>
                                                <div onClick={() => incrementQuantity(item.value)} className="addbtn center">
                                                    +
                                                </div>
                                            </div>
                                            <div className="vr"></div>
                                        </div>
                                        <div className='col-3'>{item.value * (quantities[item.value] || 0)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
                {coinDenominations?.length === 0 ? " " :
                    <div className='col-lg-3'>
                        <div className=''>
                            <div>
                                Coin Denominations
                            </div>
                            <div className='borderMain mt-3 p-4'>
                                {coinDenominations?.map((item, index) => (
                                    <div className='row p-2 my-3 innerBorder' key={index}>
                                        <div className='col-3'>{item.value} X</div>
                                        <div className='col-6 d-flex justify-content-between'>
                                            <div className="vr"></div>
                                            <div className="quantity-control d-flex justify-content-around center">
                                                <div onClick={() => decrementQuantity(item.value)} className="addbtn center">
                                                    -
                                                </div>
                                                <div className="center px-3">{quantities[item.value] || 0}</div>
                                                <div onClick={() => incrementQuantity(item.value)} className="addbtn center">
                                                    +
                                                </div>
                                            </div>
                                            <div className="vr"></div>
                                        </div>
                                        <div className='col-3'>{item.value * (quantities[item.value] || 0)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(DayOpeningForm);