import React, { useState, Component, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNavigate } from 'react-router-dom'
import ApiService from '../../../services/apiService';
import moment from 'moment/moment';
import Modal from 'react-bootstrap/Modal';
import logo from "../../../assets/images/Qfinitylogos.png"
import jsPDF from 'jspdf';
import { getUserId } from '../../../../Redux/selecters';
import { useSelector } from 'react-redux';
import { CashEnums, shiftEnums } from '../../../../Enums/config';
import userManagement from '../../../services/userManagement';

const OpenCashList = () => {

    let navigate = useNavigate();
    const [day, setDayOpen] = useState([]);
    const [value, setvalue] = useState();
    const modalRef = useRef();
    const userId = useSelector(getUserId);
    const token = localStorage.getItem('Token');
    useEffect(() => {
        GetDayOpen();
    }, []);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const GetDayOpen = async () => {
        try {
            const apiService = new ApiService(); 
            let param = `?Type=${CashEnums?.ShiftOpen}&UserId=${userId}&loggedInUserId=${userId}&GetMultiple=${true}`
            let res = await apiService.getApiParamater("GET_CASH", param);
            setDayOpen(res.data.result.reverse());
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const handleShow = (rowData) => {
        setvalue(rowData);
        setShow(true);
    }
    const columnDefs = [
        { headerName: 'Id', field: 'id', filter: true, floatingFilter: true, minWidth: 340 },
        // { headerName: 'Branch Name', field: 'branchId', filter: true, floatingFilter: true, minWidth: 290 },
        { 
            headerName: 'Voucher Code', 
            field: 'voucherNo', 
            filter: true, 
            floatingFilter: true, 
            minWidth: 290,
            valueGetter: (params) => 'VOC-' + params.data.voucherNo // Add prefix "VOC"
        },
                {
            headerName: 'From Date',
            field: 'openingDate',
            filter: true,
            floatingFilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator, minWidth: 230
        },
        { headerName: 'Cash Amount', field: 'amount', filter: true, floatingFilter: true, minWidth: 330 },
        {
            headerName: 'Details', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    handleShow(field)
                },
            },
             minWidth: 310
        },
    ];
    const handleDownload = () => {
        const pdf = new jsPDF();

        // Set font
        pdf.setFont('Poppins');

        const logoWidth = 50;
        const logoHeight = 20;
        const logoX = (pdf.internal.pageSize.width - logoWidth) / 2;
        const logoY = 10;
        pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        // Add title
        pdf.setFontSize(18);
        pdf.setTextColor(0, 0, 255);
        // Add content
        pdf.setFontSize(12);
        pdf.setTextColor(0);
        const startX = 20;
        let currentY = logoY + logoHeight + 30;

        pdf.text(`Date: ${moment(value?.openingDate).format('L')}`, startX, currentY);
        currentY += 10;
        pdf.text(`Time: ${moment(value?.openingDate).format('LT')}`, startX, currentY);
        currentY += 10;
        pdf.text(`Voucher No: ${value?.voucherNo}`, startX, currentY);
        currentY += 10;
        pdf.text(`From User: ${value?.userId}`, startX, currentY);
        currentY += 10;
        pdf.text(`Amount: ${value?.amount}`, startX, currentY);
        currentY += 10;
        pdf.text('Shift: Morning', startX, currentY);
        currentY += 10;
        pdf.text('Branch: f-10 Islamabad', startX, currentY);
        currentY += 10;
        pdf.text(`ID: ${value?.id}`, startX, currentY);
        currentY += 10;

        // Save the PDF with a specific name
        pdf.save(`Cash_${value?.id}_recipt.pdf`);
    };
    const transformed = day?.map(day => {
        return {
            ...day,
            id: `ORD-${day.id}`,
            amount: Number(day.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        };
    });
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    return (
        <div className='px-lg-5'>
            <div className='row d-flex justify-content-between  m-5'>
                <div className='col-lg-2 col-sm-4 d-inline-flex gap-3'>
                    <div className='btn-border'>
                        PDF
                    </div>
                    <div className='btn-border'>
                        CSV
                    </div>
                </div>
            </div>
            <div className='px-5'>
        <div className='brdor  mt-3' style={{ }}>

                <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={transformed}
                        rowHeight={60}
                        pagination={true}
                        suppressRowClickSelection={true}
                        frameworkComponents={{ frameworkComponents }}
                    />
                </div>
                </div>

            </div>
            <Modal show={show} onHide={handleClose} size="md" centered ref={modalRef}>
                {/* <Modal.Header closeButton> */}
                <div className='d-flex justify-content-between px-4 pt-4' closeButton>
                    <div>
                        <h6> Date</h6>
                        {moment.utc(value?.openingDate).local().format('L')}
                    </div>
                    <div className='center'><img src={logo} /></div>
                    <div>
                        <h6> Time</h6>
                        {moment.utc(value?.openingDate).local().format('LT')}
                    </div>
                </div>
                {/* <Modal.Title className='center'>Table Reservation</Modal.Title> */}
                {/* </Modal.Header> */}
                <Modal.Body>
                    <div className='p-4'>
                        <div class="separators">
                            <hr />
                            <div className='itmKIns'>Voucher No : VOC-{value?.voucherNo}</div>
                            <hr />
                        </div>
                        <h6 className='center pt-3'>From User : {userManagement.getUserName(token)}</h6>
                        <div class="separators">
                            <hr />
                        </div>
                        <h6 className='center'>Amount : {value?.amount}</h6>
                        <div class="separators">
                            <hr />
                        </div>
                        {/* <h6 className='center'>Shift : Morning</h6>
                        <div class="separators">
                            <hr />
                        </div>
                        <h6 className='center'>Branch : f-10 Islamabad</h6> */}
                        {/* <div class="separators">
                            <hr />
                        </div> */}
                        <h6 className='center'>ID : {value?.id}</h6>
                        <div class="separators">
                            <hr />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='center'>
                    <button className='saveButton' onClick={handleDownload}>
                        Print
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { data, clicked } = this.props;
        clicked(data);
    }

    render() {
        return (
            <button onClick={this.btnClickedHandler} className="delbtn">View</button>
        )
    }
}

export default OpenCashList;
