import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/images/svgIcons/logo.svg';
import logo from "../../assets/images/Qfinitylogos.png"
import Baikingulogo from "../../assets/images/Bingo.png"
import BlueLogo from '../../assets/images/blueCoco.png';
import { useLocation } from 'react-router-dom';
import "./header.css"

const CostumerHeader = () => {
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState(location.pathname);

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid px-lg-5">
                <a className="navbar-brand" href="/">
                    {/* <img src={Baikingulogo} /> */}
                    {/* <img src={BlueLogo} /> */}
                    <img src={logo} />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-5 px-lg-5">
                        <li className={`nav-item ${currentUrl === '/customer/menu' ? 'nav-active' : ''}`}>
                            <a className="nav-link" href="/customer/menu">Menu</a>
                        </li>
                        {/* <li className={`nav-item ${currentUrl === '/customer/offers' ? 'nav-active' : ''}`}>
                            <a className="nav-link" href="/customer/offers">Offers</a>
                        </li> */}
                        <li className={`nav-item ${currentUrl === '/customer/menu/combo' ? 'nav-active' : ''}`}>
                            <a className="nav-link" href='/customer/menu/combo'>Combos</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default CostumerHeader;
