import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import OrderIcon from "../../../assets/images/orderManament.png"

const CustomPieChart = ({ val }) => {
  const calculatePercentage = (count, totalOrders) =>
    ((count / totalOrders) * 100).toFixed(2);

  const onDeliveryPerc = calculatePercentage(
    val?.onDeliveryCount,
    val?.totalOrders
  );
  const deliveredPerc = calculatePercentage(
    val?.deliveredCount,
    val?.totalOrders
  );
  const canceledPerc = calculatePercentage(
    val?.canceledCount,
    val?.totalOrders
  );

  const dynamicData = [
    { name: 'On Delivery', value: parseFloat(onDeliveryPerc), color: '#FF6D4C' },
    { name: 'Canceled', value: parseFloat(canceledPerc), color: '#000000' },
    { name: 'Delivered', value: parseFloat(deliveredPerc), color: '#2BC155' },
  ];

  const hasOrders = dynamicData.some(entry => entry.value > 0);

  if (!hasOrders) {
    return <div className=''>
      <img src={OrderIcon} />
      <div className=' ord-in-que'>
        No Order Delivered yet
      </div>
    </div>;
  }

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={dynamicData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={80}
          innerRadius={50}
          paddingAngle={3}
        >
          {dynamicData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
