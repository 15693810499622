import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ApiService from '../../../services/apiService';
import OrderIcon from "../../../assets/images/orderManament.png";
import "./chart.css";

const LineChartComp = ({ selectedFilter }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        getChartData();
    }, [selectedFilter]);

    const getChartData = async () => {
        try {
            const apiService = new ApiService();
            const parameters = `?GetBy=${selectedFilter}`;
            const res = await apiService.getApiParamater("DASH_THIRD_CHART", parameters);

            const transformedData = res?.data?.result?.reduce((acc, entry) => {
                const { name, deliveryPlatform, orderCount } = entry;

                if (!acc[name]) {
                    acc[name] = { name };
                }

                acc[name][deliveryPlatform] = orderCount;

                return acc;
            }, {});

            const formattedData = Object.values(transformedData)?.map(entry => {
                // Map the keys in the transformed data to match the chartData structure
                return {
                    name: entry.name,
                    UberEats: entry?.UberEats || 0,
                    Talabat: entry.Glovo || 0,
                    Yum: entry?.Yum || 0,
                    Bolt: entry.Bolt || 0,
                };
            });

            setChartData(formattedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const hasData = chartData.some(entry => Object.values(entry).some(value => value > 0));

    return (
        <div className='container-fluid'>
            {!hasData ? (
                <div className='center'>
                    <div className='p-lg-5'>
                        <img src={OrderIcon} alt="Order Icon" />
                        <div className='ord-in-que'>
                            No Orders Yet
                        </div>
                    </div>
                </div>
            ) : (
                <ResponsiveContainer width="100%" height={425}>
                    <LineChart
                        data={chartData}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                    >
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="UberEats" stroke="#8884d8" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="Glovo" stroke="#82ca9d" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="Yum" stroke="#ffc658" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="Bolt" stroke="#ff7300" activeDot={{ r: 8 }} />
                    </LineChart>

                </ResponsiveContainer>
            )}
        </div>
    );
};

export default LineChartComp;