import React, { useState, useEffect, Component } from 'react'
import 'ag-grid-community/styles/ag-theme-quartz.css'
// import ApiService from '../../services/apiService'
import { AiOutlinePrinter } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'
import 'ag-grid-community/styles/ag-grid.css'
import { AgGridReact } from 'ag-grid-react'
import { FaRegEye } from "react-icons/fa6"
import moment from 'moment'
import { GrEdit } from "react-icons/gr";
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../../../INVENTORY/Pages/pagination/pagination'
import ApiService from '../../services/apiServices'
const DRNListing = () => {
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const apiService = new ApiService();
    let navigate = useNavigate();
    useEffect(() => {
        getDRN();
    }, []);
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY") || " ";
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/Sales/DRN/Print`, { state: selectedRow })
        }
    }
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
                    navigate(`/Sales/DRN/${clickedRowData.dnNo}`, { state: updatedData });
                } else {
                    navigate(`/Sales/DRN/${clickedRowData.dnNo}`, { state: updatedData })
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
                    navigate(`/Sales/DRN/${selectedRow.dnNo}`, { state: updatedData });
                } else {
                    navigate(`/Sales/DRN/${selectedRow.dnNo}`, { state: updatedData })
                }
            }
        }
    };
    const getDRN = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            // params.append('PageNo', newPageNo || 1);
            // params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_DN', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            const sortedData = res?.data?.result.slice().sort((a, b) => {
                const idA = parseInt(a.dnNo.split('-')[2]);
                const idB = parseInt(b.dnNo.split('-')[2]);
                return idB - idA;
            });
            setRowData(sortedData || []);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    }
    const handleEye = () => {
        if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate(`/Sales/DRN/${selectedRow.dnNo}`, { state: updatedData });
        };
    };
    const SaleData = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'DN No', field: 'dnNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        {
            headerName: 'SO No', field: 'sOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
        },
        {
            headerName: 'Date', field: 'docDate', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Customer ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Customer Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        {
            headerName: 'Delivery Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Approval date', field: 'modifiedOn', filter: true, floatingfilter: true, suppressMovable: true, width: 160,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
    ];
    const handlenew = () => {
        navigate('/Sales/DRN/create')
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'dnNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    }
    return (
        <div className='px-4  pt-3'>
            <div className='justify-content-between d-flex'>
                <h5 className='col mainInvHead'>Delivery Note</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEye}
                        className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <FaRegEye color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>View</div>
                    </div>
                    <div
                        onClick={selectedRow && selectedRow.status === 1 ? null : handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status === 1 ? 'disabled' : ''
                            }`}
                        style={{ cursor: selectedRow && selectedRow.status === 1 ? 'not-allowed' : 'pointer' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div
                        onClick={handlePrint}
                        className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <AiOutlinePrinter color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Print</div>
                    </div>
                    <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                        <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div>
                    </button>
                </div>
            </div>
            <div className='gridshad mt-3'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        columnDefs={SaleData}
                        onSelectionChanged={onSelectionChanged}
                        onCellClicked={onCellClicked}
                    />
                </div>
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getDRN} />
            </div>
        </div>
    );
};
export default DRNListing; 