import { React, useState, useEffect } from 'react'
import AdminHeader from '../header/adminHeader'
import { ReactComponent as Add } from '../../../assets/images/svgIcons/Add_round_white.svg';
import CostumerList from './customerList';
import { useNavigate } from 'react-router-dom';

const Costumer = () => {
    let navigate = useNavigate();

    const AddCostumer = () => {
        navigate('/admin/AddCustomer');
    }

    return (
        <div className='container-fluid '>
            <div className='p-lg-5'>
                <div className=' d-flex justify-content-between' >
                    <div className='col-3'>
                        <input className='form-control' type='search' placeholder='Search Customer' />
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className='refbtn' onClick={AddCostumer}>
                            <Add  /> Add Customer
                        </div>
                    </div>
                </div>
                <div className=''>
                    <CostumerList/>
                </div>
            </div>
        </div>
    )
}
export default Costumer