import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import Plus from "../../Assets/images/plusIcon.png"
import { ReactComponent as Add } from '../../Assets/svg/Add.svg';
import { RiSubtractLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { RxCross1 } from "react-icons/rx";
import ApiService2 from '../../../POS/services/apiService';
import { IoAddSharp } from "react-icons/io5";
import { useCurrency } from '../../Layouts/currencyContext';
const DirectPurchaseGrid = ({ onRequisitionData, itemData, selectedCheck }) => {
    
    const [itemOptions, setItemOptions] = useState([]);
    const [descriptionOptions, setDescriptionOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [vat, setvat] = useState([]);
    const [items, setItems] = useState([]);
    const apiService = new ApiService();
    const apiService2 = new ApiService2();
    const { currencyPrecision } = useCurrency();

    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        totalQty: '',
        baseQty: '',
        baseAmount: '',
        stock: '',
        amount: '',
        discount: '',
        vat: 0,
        vatDes: '',
        vatAmount: '',
        expDate: '',
        inventoryAccountCode: "",
        inventoryAccountName: "",
    }]);
    // const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.qty * row.baseAmount) || 0), 0)
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount - row?.vatAmount) || 0), 0)
    const totalVat = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    const netAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    useEffect(() => {
        getVat();
        if (itemData) {
            getItemsUpdate();
        }
        if (selectedCheck) {
            getItemsUpdate();
        }
    }, [itemData, selectedCheck]);
    useEffect(() => {
        const getItems = async () => {
            const formattedRows = rows?.map((detail, index) => {
                const selectedVat = vat?.find(tax => tax.description === detail?.vatDes);
                let amount = detail.qty * detail.baseAmount
                const perAmount = (amount / 100) * selectedVat?.rate
                let ExcludedAmount = amount + perAmount
                return {
                    requisitionNo: detail.grnNo,
                    srno: index + 1,
                    ItemNo: detail.ItemNo,
                    recordId: detail.ItemNo,
                    itemDescription: detail.itemDescription,
                    branch: '',
                    qty: detail.qty,
                    uom: detail.uom,
                    baseQty: detail.baseQty,
                    baseAmount: detail.baseAmount,
                    specification: detail.specification,
                    amount: selectedCheck === true ? ExcludedAmount : amount,
                    stock: detail.qty * detail.baseQty,
                    discount: detail.discount,
                    vat: detail.vatPerc,
                    vatDes: detail.vatDes,
                    vatAmount: detail.vatAmount,
                    expDate: detail.expDate,
                    inventoryAccountCode: "",
                    inventoryAccountName: "",
                };
            }).filter(row => row !== null);

            if (formattedRows.length > 0) {
                setRows(formattedRows);
                onRequisitionData(formattedRows);
            }
        }
        if (itemData) {
            getItems();
        }
    }, [itemData, selectedCheck]);
    const getVat = async () => {
        const vat = await apiService.get('GET_INV_TAX');
        setvat(vat.data.result)
    }
    const getItemsUpdate = async () => {
        try {
            if (itemData?.grnDetails) {
                const res = await apiService.get('GET_ITEM_PACK');
                const formattedRows = itemData?.grnDetails.map(detail => {
                    const selecteditem = res.data.result?.find(item => item.itemDescription === detail.itemDescription);
                    const selectedVat = vat?.find(tax => tax.description === selecteditem?.vat);
                    let amount = detail.qty * detail.unitPrice
                    const perAmount = (amount / 100) * selectedVat?.rate
                    let ExcludedAmount = amount + perAmount
                    return {
                        requisitionNo: itemData.requisitionNo || "",
                        srno: detail.srno,
                        ItemNo: detail.itemCode,
                        recordId: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        qty: detail.qty,
                        uom: detail.uom,
                        stock: selecteditem.convToInvUOM * detail.qty,
                        baseAmount: detail.unitPrice,
                        baseQty: selecteditem.convToInvUOM,
                        specification: detail.specification,
                        amount: selectedCheck === true ? ExcludedAmount : amount,
                        vat: selectedVat?.rate || 0,
                        vatDes: selectedVat?.description,
                        expDate: detail.expDate,
                        vatAmount: perAmount
                    };
                }).filter(row => row !== null);

                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } else {
                const formattedRows = rows?.map((detail, index) => {
                    const selectedVat = vat?.find(tax => tax.description === detail?.vatDes);
                    let amount = detail.qty * detail.baseAmount
                    const perAmount = (amount / 100) * selectedVat?.rate
                    let ExcludedAmount = amount + perAmount
                    return {
                        requisitionNo: detail.grnNo,
                        srno: index + 1,
                        ItemNo: detail.ItemNo,
                        recordId: detail.ItemNo,
                        itemDescription: detail.itemDescription,
                        branch: '',
                        qty: detail.qty,
                        uom: detail.uom,
                        baseQty: detail.baseQty,
                        baseAmount: detail.baseAmount,
                        specification: detail.specification,
                        amount: selectedCheck === true ? ExcludedAmount : amount,
                        stock: detail.qty * detail.baseQty,
                        discount: detail.discount,
                        vat: detail.vatPerc,
                        vatDes: detail.vatDes,
                        vatAmount: detail.vatAmount,
                        expDate: detail.expDate,
                        inventoryAccountCode: "",
                        inventoryAccountName: "",
                    };
                }).filter(row => row !== null);

                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    useEffect(() => {
        fetchData();
        getVat()
    }, []);
    const fetchData = async () => {
        try {
            const response = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            const selecteditem = response.data.result?.filter(rec => rec.exp9 === '1')
            if (response?.data?.result?.length > 0) {
                setApiData(selecteditem);
                setItemOptions(selecteditem.map(item => ({
                    value: item.itemNo,
                    label: item.itemNo
                })));
                setDescriptionOptions(selecteditem.map(item => ({
                    value: item.itemDescription,
                    label: item.itemDescription
                })));
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleInputChange = (index, fieldName, value) => {
        const isDuplicate = rows.some((row, rowIndex) => rowIndex !== index && (row.ItemNo === value || row.itemDescription === value));
        if (isDuplicate) {
            toast.error('Please choose a different item.');
            return;
        }
        const updatedRows = rows?.map((row, rowIndex) => {
            if (rowIndex === index) {
                const updatedRow = { ...row, [fieldName]: value, srno: ("0" + (index + 1)).slice(-2) };
                if (fieldName === 'ItemNo') {

                    const selectedItem = apiData?.find(item => item.itemNo === value);
                    const selectedVat = vat?.find(tax => tax?.description === selectedItem?.vat);
                    if (selectedItem) {
                        updatedRow.itemDescription = selectedItem.itemDescription;
                        updatedRow.ItemNo = selectedItem.itemNo;
                        updatedRow.requisitionNo = selectedItem.itemDescription;
                        updatedRow.uom = selectedItem.baseUnit;
                        updatedRow.specification = selectedItem.specification;
                        updatedRow.vat = selectedVat?.rate || 0;
                        const quantity = parseFloat(updatedRow.qty);
                        const unitPrice = parseFloat(updatedRow.baseAmount);
                        let amount = quantity * unitPrice || 0
                        const perAmount = (amount / 100) * selectedVat?.rate || 0
                        updatedRow.vatAmount = perAmount;
                        updatedRow.vatDes = selectedVat?.description || "---";
                        updatedRow.baseAmount = selectedItem?.avgPrice || 0;
                        updatedRow.baseQty = parseFloat(selectedItem.convToInvUOM);
                        updatedRow.inventoryAccountCode = selectedItem?.acCode;
                        updatedRow.inventoryAccountName = selectedItem?.acName;
                    }
                } else if (fieldName === 'itemDescription') {
                    const selectedItem = apiData?.find(item => item.itemDescription === value);
                    const selectedVat = vat?.find(tax => tax?.description === selectedItem?.vat);
                    if (selectedItem) {
                        updatedRow.specification = selectedItem.specification;
                        updatedRow.recordId = selectedItem.itemNo;
                        updatedRow.ItemNo = selectedItem.itemNo;
                        updatedRow.uom = selectedItem.baseUnit;
                        updatedRow.baseQty = parseFloat(selectedItem.convToInvUOM);
                        updatedRow.vatDes = selectedVat?.description;
                        updatedRow.baseAmount = selectedItem?.avgPrice || 0;
                        const quantity = parseFloat(updatedRow.qty);
                        const unitPrice = parseFloat(updatedRow.baseAmount);
                        let amount = quantity * unitPrice || 0
                        const perAmount = (amount / 100) * selectedVat?.rate || 0
                        updatedRow.vatAmount = perAmount;
                        updatedRow.vat = selectedVat?.rate || 0;
                        updatedRow.inventoryAccountCode = selectedItem?.acCode;
                        updatedRow.inventoryAccountName = selectedItem?.acName;
                    }
                }
                if (fieldName === 'qty') {
                    const quantity = parseFloat(value);
                    const selectedItem = apiData?.find(data => data?.itemDescription === row?.itemDescription);
                    const baseQty = selectedItem ? selectedItem.convToInvUOM : 1;
                    updatedRow.baseQty = parseFloat(baseQty);
                }
                if (fieldName === 'qty' || fieldName === 'baseAmount') {
                    const selectedItem = apiData?.find(data => data?.itemDescription === row?.itemDescription);
                    const selectedVat = vat?.find(tax => tax?.description === selectedItem?.vat);
                    const quantity = parseFloat(updatedRow.qty);
                    const unitPrice = parseFloat(updatedRow.baseAmount);
                    let amount = quantity * unitPrice || 0
                    const perAmount = (amount / 100) * selectedVat?.rate
                    let ExcludedAmount = amount + perAmount
                    updatedRow.amount = selectedCheck ? ExcludedAmount : amount;
                    updatedRow.stock = updatedRow.baseQty * quantity || 0;
                    updatedRow.vatDes = selectedVat?.description;
                    updatedRow.vatAmount = perAmount;
                }
                return updatedRow;
            }
            return row;
        });
        setRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const addRow = () => {
        const newRow = {
            srno: '',
            ItemNo: '',
            requisitionNo: '',
            itemDescription: '',
            branch: '',
            qty: 0,
            uom: '',
            baseQty: 0,
            baseAmount: 0,
            amount: 0,
            stock: 0,
            bol: '',
            expDate: null
        };
        const updatedRows = [...rows, newRow];
        setRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const removeRow = () => {
        if (rows.length > 1) {
            const updatedRows = [...rows];
            updatedRows.pop();
            setRows(updatedRows);
            onRequisitionData(updatedRows);
        }
    };
    const handleDateChange = (index, expDate) => {
        const updatedRows = [...rows];
        updatedRows[index].expDate = expDate;
        const updatedSelectedRows = rows.map((row, i) =>
            i === index ? { ...row, expDate: expDate } : row
        );
        setRows(updatedSelectedRows);
        onRequisitionData(updatedSelectedRows);
    };
    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th></th>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                            <th>expDate</th>
                        </tr>
                    </thead>

                    <tbody>
                        {Array.isArray(rows) && rows.map((row, index) => (
                            <tr key={index}>
                                {itemData?.requisitionNo ?
                                    <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                        <div className='center'> <RxCross1 size={15} /></div></td> :
                                    <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                        {itemData?.eye === true ?
                                            <div className='center'><RxCross1 size={15} /></div> :
                                            <div onClick={() => handleRemoveRow(index)} className='center'><RxCross1 size={15} /></div>
                                        }
                                    </td>
                                }
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.srno} disabled style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'>
                                    {itemData?.eye === true ?
                                        <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.ItemNo} style={{ width: "100px" }} /></td>
                                        :
                                        <Select
                                            isDisabled={itemData?.eye === true ? true : false}
                                            options={itemOptions}
                                            value={{ value: row.ItemNo, label: row.ItemNo }}
                                            onChange={selectedOption => handleInputChange(index, 'ItemNo', selectedOption.value)} />
                                    }
                                </td>
                                <td className='m-0 p-0'>
                                    {itemData?.eye === true ?
                                        <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.itemDescription} style={{ width: "120px" }} /></td>
                                        : <Select
                                            isDisabled={itemData?.eye === true ? true : false}
                                            options={descriptionOptions}
                                            value={{ value: row.itemDescription, label: row.itemDescription }}
                                            onChange={selectedOption => handleInputChange(index, 'itemDescription', selectedOption.value)}
                                        />}
                                </td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.specification} style={{ width: "120px" }}
                                    onChange={e => handleInputChange(index, 'specification', e.target.value)} disabled={itemData?.eye === true ? true : false} /></td>
                                <td className='m-0 p-0'><input className='p-2' onWheel={(e) => e.target.blur()} disabled={itemData?.eye === true ? true : false}
                                    type="number" value={row.qty} style={{ width: "90px" }} onChange={e => handleInputChange(index, 'qty', e.target.value)} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "140px" }} value={row.uom} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.stock || 0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()} disabled={itemData?.eye === true ? true : false}
                                    value={row.baseAmount} style={{ width: "120px" }} onChange={e => handleInputChange(index, 'baseAmount', e.target.value)} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.discount} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "120px" }} value={(row?.vat === undefined ? 0 : row?.vat + "%") || 0} onChange={e => handleInputChange(index, 'bol', e.target.value)} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' style={{ width: "120px" }} disabled type="text" value={(row.amount === NaN ? 0 : row.amount || 0) || 0} onChange={e => handleInputChange(index, 'amount', e.target.value)} /></td>
                                <td className='m-0 p-0'><input className='p-2' type='date' value={row?.expDate ? row.expDate.split('T')[0] : ''} disabled={itemData?.eye === true ? true : false}
                                    onChange={(e) => handleDateChange(index, e.target.value)} style={{ width: "130px" }} /></td>
                            </tr>
                        ))}
                    </tbody>

                </table>
                <div className='d-flex gap-5'>
                    {itemData?.eye === true ? "" :
                        <button className='mainbtn  mt-4 gap-3' onClick={addRow}><IoAddSharp color='#fff' className='center mt-1 mx-2' /><div>Add</div>  </button>
                    }
                </div>
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount.toFixed(currencyPrecision) || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVat.toFixed(currencyPrecision) || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{netAmount.toFixed(currencyPrecision) || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default DirectPurchaseGrid;
