import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import DocumentGrid from './grid';
import { hasPermission } from "../../services/roleService"
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { PropagateLoader } from 'react-spinners';
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";

const DocumentMaster = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(20);
    const [rowData, setRowData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pagination, setPagination] = useState();
    useEffect(() => {
        if (pageSize) {
            fetchCurrencyData();
        }
    }, [pageSize]);
    const [formData, setFormData] = useState({
        recordId: "",
        typeCode: "",
        name: "",
    });
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('Department Master', 'edit');
    // const canCreateItemMaster = checkPermission('Department Master', 'create');
    const handleEdit = () => {
        if (selectedRow) {
            setFormData({
                recordId: selectedRow?.record_Id,
                name: selectedRow?.description || "",
                typeCode: selectedRow?.description_BI || "",
            });
        }
    };
    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        fetchCurrencyData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    const fetchCurrencyData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', `?${params.toString()}`);
            const packingData = res.data?.result;
            setPagination(res.data.paginationData);
            setLoading(false);
            setRowData(packingData);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching packing data:", error);
        }
    };
    const handleSubmit = async () => {
        if (!formData.typeCode || !formData.name) {
            if (!formData.name) { toast.warning("Enter Department Name") }
            if (!formData.typeCode) { toast.warning("Enter Department type") }
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                description: formData?.name,
                description_BI: formData?.typeCode
            }
            const response = await apiService.post('CREATE_DEPARTMENT_MASTER', data);

            if (response?.data?.statusCode === 201) {
                toast.success(response?.data?.message)
                fetchCurrencyData();
                setFormData({
                    typeCode: "",
                    name: "",
                });
            } else if (response?.data?.statusCode === 208) {
                toast?.warning(response?.data?.message)
                fetchCurrencyData();
            }
        } catch (error) {
            toast.error("Please try Again!")
        }
    };
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                record_Id: formData?.recordId,
                description: formData?.name,
                description_BI: formData?.typeCode,
            }
            const res = await apiService.put(`UPDATE_DEPARTMENT_MASTER`, body);

            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                fetchCurrencyData()
                setFormData({
                    recordId: "",
                    typeCode: "",
                    name: "",
                })
                setSelectedRow(null)
                window.history.replaceState({}, '')
            } else {
                console.error('Failed to update form data');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            typeCode: "",
            name: "",
        })
        setSelectedRow(null)
        fetchCurrencyData()
    };
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Department Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className=''>
                        {selectedRow ? (
                            <>
                                {canEditItemMaster && (
                                    <button className='mainbtn ' onClick={updateFormData}><FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                )}
                            </>
                        ) : (
                            <>
                                {canCreateItemMaster === true ?
                                    <button className='mainbtn ' onClick={handleSubmit}>
                                        <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                    <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                        <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                    </button>
                                }
                            </>
                        )}
                    </div>
                    <div className=''>
                        <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    </div>
                </div>
            </div>
            <div className='py-lg-2'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className="form-group row">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Department Code *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateItemMaster === true ? false : true}
                                name="typeCode"
                                value={formData.typeCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">Department Name  *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom02"
                                name="name"
                                disabled={canCreateItemMaster === true ? false : true}
                                value={formData?.name}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg mt-3'>
                {loading ? (
                    <div className='center'>
                        <PropagateLoader color="#6E5199" size={15} />
                    </div>
                ) : (
                    <>
                        <DocumentGrid rowData={rowData} fetchCurrencyData={fetchCurrencyData} canEditItemMaster={canEditItemMaster}
                            setSelectedRow={setSelectedRow} handleEdit={handleEdit}/>
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchCurrencyData} />
                    </>
                )}
            </div>
        </div>
    )
}

export default DocumentMaster;
