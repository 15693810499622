import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import Plus from "./../../Assets/images/plusIcon.png"
import { useNavigate } from 'react-router-dom';
import { FiPrinter } from "react-icons/fi";
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';

const AuthorizationList = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const apiService = new ApiService();
    const apiService2 = new ApiService2();
    const userName = useSelector(getName);
    const [Userdata, setUserdata] = useState();

    useEffect(() => {
        getApprovalUser();
    }, []);

    const getApprovalUser = async () => {
        try {
            const res = await apiService2.get('GET_USERS');
            const selecteduser = res?.data?.result?.find(dep => dep.name === userName)
            setUserdata(selecteduser)
            let param = `?Type=${"Price Comparison"}&Company=${selecteduser.company}&Department=IT`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER_LIST', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setRowData(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const Comparison = [
        {
            headerName: 'Sr No', field: 'index', filter: true, floatingfilter: true, minWidth: 310,
            valueGetter: (params) => params.node.rowIndex + 1,
        },
        { headerName: 'User Code', field: 'userCode', filter: true, floatingfilter: true, minWidth: 310 },
        { headerName: 'Approval Date', field: 'docdate', filter: true, floatingfilter: true, minWidth: 210 },
        { headerName: 'User Name', field: 'userNo', filter: true, floatingfilter: true, minWidth: 210 },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingfilter: true, minWidth: 210 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, minWidth: 210,
            cellRenderer: function (params) {
                return (<button class="btn ">Pending</button>);
            }
        },
    ];

    return (
        <div className='px-5'>
            <div className='brderbox'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        pagination={false}
                        suppressExcelExport={true}
                        domLayout='autoHeight'
                        suppressRowClickSelection={true}
                    />
                </div>
            </div>
        </div>
    );
};
export default AuthorizationList; 