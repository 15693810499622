import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { HiPencil } from "react-icons/hi2";

const DocumentGrid = ({ rowData, fetchCurrencyData, canEditItemMaster, loading, setSelectedRow ,handleEdit}) => {
    let navigate = useNavigate();
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true,suppressMovable: true , width: 50 },
        { headerName: "Department Code", field: "description_BI", filter: true, floatingfilter: true, editable: false, minWidth: 500 },
        { headerName: "Department Name", field: "description", filter: true, floatingfilter: true, editable: false, minWidth: 500 },
        {
            headerName: 'Status', field: 'record_Id', cellRenderer: BtnCellRenderer, minWidth: 500,
            cellRendererParams: {
                handleEdit: handleEdit,
                canEditItemMaster: canEditItemMaster,
                // clicked: function (field, isActive) {
                //     updateActiveStatus(field, isActive);
                // },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {

            const apiService = new ApiService();
            let body = {
                record_Id: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_DEPARTMENT_MASTER`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                // You may want to refresh the data after successful update
                fetchCurrencyData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'description_BI') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);

        }
    };
    return (
        <div className='gridshad' style={{ height: 550 }}>
            <div className="ag-theme-quartz" style={{ height: 550 }}>
                <AgGridReact
                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    rowData={rowData}
                    rowSelection={'single'}
                    columnDefs={columnDefs}
                    onSelectionChanged={onSelectionChanged}
                    onCellClicked={onCellClicked}
                />
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        const { canEditItemMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {/* {canEditItemMaster && (
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={25} /></div>
                )} */}
                {/* {canEditItemMaster && ( */}
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive} // Set checked attribute based on isActive value
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                {/* )} */}
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default DocumentGrid;
