import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { setRows, addRow, removeRow, removeSingleRow, updateRow, clearRows } from '../../../Redux/actions/rowActions';
import { IoAddSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { useCurrency } from '../../Layouts/currencyContext';
const RequisitionGrid = ({ onRequisitionData, itemData, selectedLocation }) => {
    const rows = useSelector(state => state.rows.rows);
    const dispatch = useDispatch();
    const [itemOptions, setItemOptions] = useState([]);
    const [itemRows, setItemRows] = useState([]);
    const [descriptionOptions, setDescriptionOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const apiService = new ApiService();
    const initialMount = useRef(true);
    const [rowsUpdated, setRowsUpdated] = useState(false);
    const { currencyPrecision } = useCurrency();

    useEffect(() => {
        const getItems = async () => {
            try {
                if (initialMount.current && itemData?.prDetails && itemData.prDetails.length > 0) {
                    const res = await apiService.get('GET_ITEM_PACK');
                    const formattedRows = itemData?.prDetails?.map(detail => {
                        const selecteditem = res.data.result?.find(rec => rec.itemDescription === detail.itemDescription)
                        return {
                            requisitionNo: detail.requisitionNo,
                            srno: detail.srno,
                            itemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            branch: '',
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: selecteditem.convToInvUOM * detail.qty,
                            convRate: selecteditem.convToInvUOM,
                            baseAmount: detail.unitPrice.toFixed(currencyPrecision),
                            specification: detail.specification,
                            amount: detail.amount.toFixed(currencyPrecision),
                            stock: detail.stock,
                            bol: ''
                        };
                    }).filter(row => row !== null);
                    if (formattedRows.length > 0) {
                        dispatch(setRows(formattedRows));
                        onRequisitionData(formattedRows);
                    }
                    if (selectedLocation && selectedLocation.label && rows[0].itemNo && !rowsUpdated) {
                        updateRowsWithNewStock();
                        setRowsUpdated(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };
        if (itemData) {
            getItems();
        }
    }, [itemData, dispatch, onRequisitionData, selectedLocation]);
    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            itemRows.forEach((row, index) => {
                dispatch(updateRow(index, row));
            });
        }
    }, [itemRows, dispatch]);
    useEffect(() => {
        onRequisitionData(rows);
    }, [rows]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const itemResponse = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            let response = itemResponse.data.result.reverse()
            const selecteditem = response?.filter(rec => rec.exp9 === '1')
            if (itemResponse?.data?.result?.length > 0) {
                setApiData(selecteditem);
                setItemOptions(selecteditem?.map(item => ({
                    value: item.itemNo,
                    label: item.itemNo
                })));
                setDescriptionOptions(selecteditem?.map(item => ({
                    value: item.itemDescription,
                    label: item.itemDescription
                })));
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleInputChange = async (index, fieldName, value) => {
        try {
            const isDuplicate = rows.some((row, rowIndex) => rowIndex !== index && (row.itemNo === value || row.itemDescription === value));
            if (isDuplicate) {
                toast.error('Please choose a different item.');
                return;
            }
            if (selectedLocation.length === 0) {
                toast.warning("Select Location")
                return;
            }
            const updatedRows = [...rows];
            const updatedRow = { ...updatedRows[index], [fieldName]: value, srno: ("0" + (index + 1)).slice(-2) };
            if (fieldName === 'itemNo' || fieldName === 'itemDescription') {
                const queryParam = fieldName === 'itemNo' ? `itemNo=${value}` : `ItemDescription=${value}`;
                const selecteditmstock = await apiService.getApiParamater('GET_ITEM_PACK', `?isActive=true&Warehouse=${selectedLocation.label}&${queryParam}`);
                const selectedItem = apiData.find(item => item[fieldName] === value);
                if (selectedItem) {
                    updatedRow.itemDescription = selectedItem.itemDescription;
                    updatedRow.requisitionNo = selectedItem.itemDescription;
                    updatedRow.uom = selectedItem.baseUnit;
                    updatedRow.itemNo = selectedItem.itemNo;
                    updatedRow.specification = selectedItem.specification;
                    updatedRow.convRate = selectedItem.convToInvUOM;
                    updatedRow.baseQty = 0;
                    updatedRow.stock = selecteditmstock?.data?.result?.[0].stock || 0;
                    updatedRow.supplierId = selectedItem?.supplier;
                    updatedRow.baseAmount = selecteditmstock?.data?.result?.[0]?.avgPrice.toFixed(currencyPrecision) || 0
                }
            }
            if (fieldName === 'qty') {
                const quantity = parseFloat(value);
                const selectedItem = apiData.find(data => data.itemNo === updatedRow.itemNo);
                const baseQty = selectedItem ? parseFloat(selectedItem.convToInvUOM) : 1;
                updatedRow.baseQty = (!isNaN(quantity) && quantity !== 0) ? (quantity * baseQty) : 0;
            }
            if (fieldName === 'qty' || fieldName === 'baseAmount') {
                const quantity = parseFloat(updatedRow.qty);
                const unitPrice = parseFloat(updatedRow.baseAmount);
                updatedRow.amount = (!isNaN(quantity) && !isNaN(unitPrice)) ? (unitPrice * quantity).toFixed(currencyPrecision) : 0;
            }
            updatedRows[index] = updatedRow;
            dispatch(setRows(updatedRows));
            setItemRows(updatedRows);
            onRequisitionData(updatedRows);
        } catch (error) {
            console.error('Error handling input change:', error);
        }
    };
    const addNewRow = () => {
        const newRow = {
            srno: '',
            itemNo: '',
            requisitionNo: '',
            itemDescription: '',
            branch: '',
            qty: '',
            uom: '',
            baseQty: '',
            convRate: '',
            baseAmount: '',
            amount: '',
            stock: '',
            bol: '',
            recordId: '',
            specification: '',
            supplierId: '',
        };
        dispatch(addRow(newRow));
        const updatedRows = [...rows, newRow];
        setItemRows(updatedRows);
        onRequisitionData(updatedRows);
        dispatch(setRows(updatedRows)); // Ensure Redux state is updated here
    };
    const handleRemoveRow = (index) => {
        dispatch(removeSingleRow(index));
        const updatedRows = rows
            .filter((_, i) => i !== index)
            .map((row, i) => ({
                ...row,
                srno: i + 1
            }));
        onRequisitionData(updatedRows);
        setItemRows(updatedRows)
        dispatch(setRows(updatedRows));

    };
    const updateRowsWithNewStock = async () => {
        try {
            const updatedRows = rows.map(row => ({ ...row }));
            const apiPromises = updatedRows.map(async (row, index) => {
                const queryParam = `itemNo=${row.itemNo}`;
                const response = await apiService.getApiParamater(
                    'GET_ITEM_PACK',
                    `?isActive=true&Warehouse=${selectedLocation.label}&${queryParam}`
                );
                if (response?.data?.statusCode === 200) {
                    const itemData = response.data.result[0];
                    updatedRows[index].stock = itemData.stock || 0;
                    updatedRows[index].stockType = itemData.stockType || "---";
                    updatedRows[index].itemDescription = itemData.itemDescription;
                    updatedRows[index].uom = itemData.baseUnit;
                    updatedRows[index].supplierId = itemData.supplier;
                    updatedRows[index].baseQty = parseFloat(itemData.convToInvUOM);
                    updatedRows[index].baseAmount = parseFloat(itemData.avgPrice || 0);
                } else if (response?.data?.statusCode === 204) {
                    updatedRows[index] = {
                        ...row,
                        stock: 0,
                    };
                }
            });
            await Promise.all(apiPromises);
            dispatch(setRows([...updatedRows]));
            onRequisitionData(updatedRows);
        } catch (error) {
            console.error('Error updating rows with new stock:', error);
        }
    }
    return (
        <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
            <table>
                <thead className='dynoTable'>
                    <tr>
                        <th></th>
                        <th>Sr no</th>
                        <th>Item No</th>
                        <th>Description</th>
                        <th>Item Specs</th>
                        <th>Qty</th>
                        <th>Packing UOM</th>
                        <th>Inventory Qty</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>Stock</th>
                        <th>BalForPo</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(itemRows) && rows?.map((row, index) => (
                        <tr key={index}>
                            {itemData?.eye ?
                                <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)", cursor: "not-allowed" }}>
                                    <div className='center'>
                                        <RxCross1 size={25} disabled />
                                    </div>
                                </td> :
                                <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                    <div onClick={() => handleRemoveRow(index)} className='center'>
                                        <RxCross1 size={25} />
                                    </div>
                                </td>
                            }
                            <td className='m-0 p-0'><input className='p-2' type="text" style={{ width: "80px" }} value={row.srno} disabled /></td>
                            <td className='m-0 p-0'>
                                {itemData?.eye ?
                                    <input className='p-2' type='text' disabled value={row?.itemNo} />
                                    :
                                    <Select
                                        options={itemOptions} className='p-0 m-0'
                                        value={{ value: row.itemNo, label: row.itemNo }}
                                        onChange={selectedOption => handleInputChange(index, 'itemNo', selectedOption.value)} />
                                }
                            </td>
                            <td className='m-0 p-0'>
                                {itemData?.eye ?
                                    <input className='p-2' disabled value={row.itemDescription} />
                                    :
                                    <Select
                                        options={descriptionOptions}
                                        value={{ value: row.itemDescription, label: row.itemDescription }}
                                        onChange={selectedOption => handleInputChange(index, 'itemDescription', selectedOption.value)}
                                    />
                                }
                            </td>
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' style={{ width: "130px" }} type="text" value={row.specification} onChange={e => handleInputChange(index, 'specification', e.target.value)} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.qty} style={{ width: "100px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' onWheel={(e) => e.target.blur()}
                                    type="number" value={row.qty} style={{ width: "100px" }} onChange={e => handleInputChange(index, 'qty', e.target.value)} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.uom} style={{ width: "130px" }} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.baseQty} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="number" style={{ width: "130px" }} value={row.baseQty} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.baseAmount} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()}
                                    value={row.baseAmount} style={{ width: "130px" }} onChange={e => handleInputChange(index, 'baseAmount', e.target.value)} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.amount || 0} style={{ width: "120px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "120px" }} value={row.amount || 0} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.stock} style={{ width: "100px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.stock} style={{ width: "100px" }} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.bol} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.bol} style={{ width: "130px" }} /></td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
            {itemData?.eye ? "" :
                <div className='row mt-5'>
                    <div className='col-2'>
                        <button className='mainbtn ' onClick={addNewRow}>
                            <IoAddSharp color='#fff' className='center mt-1 mx-2' /> <div>Add Item</div>
                        </button>
                    </div>
                    {/* <div className=' col-2'>
                        <button className='mainbtn ' onClick={removeLastRow}>
                            Remove
                        </button>
                    </div> */}
                </div>
            }
        </div>
    );
};

export default RequisitionGrid;
