const initialState = {
    companies: [],
    selectedCompany: null, // Add selected company to state
};

const CompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COMPANIES':
            return {
                ...state,
                companies: action.payload,
            };
        case 'UPDATE_SELECTED_COMPANY': 
            return {
                ...state,
                selectedCompany: action.payload,
            };
        default:
            return state;
    }
};

export default CompanyReducer;