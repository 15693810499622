import { useEffect, useRef } from 'react';
import { newInstance } from '@jsplumb/browser-ui';
import { useDrop } from 'react-dnd';
import ApiService from '../../../../../services/apiService';
import userManagement from '../../../../../services/userManagement';
import BG from "../../../../../assets/images/Logos/RestaurantSeatingPlan.png"
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const TableContainer = ({ children, setInstance, moveBox, GetTable, area }) => {
    const [, containerRef] = useDrop(
        () => ({
            accept: 'BOX',
            drop(item, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset();
                const left = Math.round(item.left + delta.x);
                const top = Math.round(item.top + delta.y);
                handleDrop(item.id, left, top, item?.val);
                moveBox(item.id, left, top, item?.val);
                return undefined;
            },
        }),
        []
    );
    const handleDrop = async (id, left, top, val) => {
        const UserId = userManagement.getUserId();
        const body = {
            name: val?.name,
            seatCapacity: val?.seatCapacity,
            position: val?.position,
            area: val?.area,
            id: id,
            loggedInUserId: UserId,
            topPosition: top,
            leftPosition: left,
        };
        const apiService = new ApiService();
        const response = await apiService.put('UPDATE_TABLE', body);
        GetTable();
    };
    useEffect(() => {
        const jsPlumbInstance = newInstance({
            container: document.getElementById('container'),
            elementsDraggable: false,
        });
        setInstance(jsPlumbInstance);

        return () => {
            // Clean up jsPlumbInstance if needed
        };
    }, [setInstance]);

    return (
        <div
            id="container"
            ref={containerRef}
            className='TopIMG'
            style={{
                position: 'relative',
                backgroundImage: `url(${area?.imageUrl ? IMAGE_URL + area.imageUrl : BG})`,
                backgroundSize: 'cover',
                width: '100%',
                height: '630px'
            }}
        >
            {children}
        </div>
    );
};

export default TableContainer;