import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

import './payment.css';
import Item2 from '../../assets/images/P1.png';
import CostumerHeader from '../Header/header';
import StripeForm from './StripeForm';
import { PaymentElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { invokeBroadCastMessage } from '../../services/signalRService';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { clearCart } from '../../../Redux/actions/cartAction';

const stripePromise = loadStripe('pk_test_51Kku1CLBbHIb8JaUQmSNv3YkbJHrbH1u2GaA2mkAOChyuEVYFAVHi7UotKNDnt2cSBBsYz9ebgndtPdkoXToInXP00fLEPzoyA');
// const stripePromise = loadStripe('pk_test_51Kku1CLBbHIb8JaUQmSNv3YkbJHrbH1u2GaA2mkAOChyuEVYFAVHi7UotKNDnt2cSBBsYz9ebgndtPdkoXToInXP00fLEPzoyA');
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const CardDetails = ({ Currency }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    const dispatch = useDispatch()
    const data = state?.cartItems || state?.cartData;
    const comboCart = state?.comboCart;
    
    const [totalPrice, setTotalPrice] = useState()
    const [cardDetails, setCardDetails] = useState({
        cardOwner: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
        saveCardDetails: false,
    });
    let totalItemPrice = 0;

    const cartItems = state?.cartItems;
    const [totalQuantity, setTotalQuantity] = useState(0);

    useEffect(() => {
        let calculatedTotalPrice = 0;
        let calculatedTotalQuantity = 0;

        data?.forEach((val) => {
            const itemPrice = val?.selectedVariant ? (val?.selectedVariant?.variantPrice) : (val?.itemData?.price) + (calculateAddOnsPrice(val?.selectedAdOn) + calculateAddOnsPrice(val?.selectedModifier)) * val?.quantity;
            calculatedTotalPrice += itemPrice;
            calculatedTotalQuantity += val?.quantity;
        });

        setTotalPrice(calculatedTotalPrice);
        setTotalQuantity(calculatedTotalQuantity);
    }, [data]);

    const calculateAddOnsPrice = (addOns) => {
        return addOns.reduce((total, addOn) => total + addOn.metaItemPrice, 0);
    }; const calculateModifierPrice = (addOns) => {


        return addOns.reduce((total, addOn) => total + parseInt(addOn.metaItemPrice), 0);
    };

    {
        data?.map((val) => {
            // Existing code for each item...

            // Increment totalItemPrice with the price of the current item
            totalItemPrice += (val?.itemData?.price + val?.selectedAdOn?.price) * val?.quantity

            // Existing code...
        })
    }
    useEffect(() => {
        setTotalPrice(totalItemPrice);
    }, [totalItemPrice]);

    const totalTax = data?.reduce((acc, item) => {
        if (!item.itemData.taxIncluded || item.itemData.taxIncluded === null) {
            acc += (parseInt(item.itemData.tax) || 0);
        }

        acc += item.selectedModifier.reduce((modifierAcc, modifier) => {
            if (!modifier.metaItemTaxIncluded || modifier.metaItemTaxIncluded === null) {
                modifierAcc += (parseInt(modifier.metaItemTax) || 0);
            }
            return modifierAcc;
        }, 0);

        acc += item.selectedAdOn.reduce((addonAcc, addon) => {
            if (!addon.metaItemTaxIncluded || addon.metaItemTaxIncluded === null) {
                addonAcc += (parseInt(addon.metaItemTax) || 0);
            }
            return addonAcc;
        }, 0);
        return acc;
    }, 0);
    // Calculate total discount including item, selected modifiers, and selected addons
    const totalDiscount = data.reduce((acc, item) => {
        // Include item discount
        acc += (parseInt(item.itemData.discount) || 0);

        // Include selected modifier discount
        acc += item.selectedModifier.reduce((modifierAcc, modifier) => {
            modifierAcc += (parseInt(modifier.metaItemDiscount) || 0);
            return modifierAcc;
        }, 0);

        // Include selected addon discount
        acc += item.selectedAdOn.reduce((addonAcc, addon) => {
            addonAcc += (parseInt(addon.metaItemDiscount) || 0);
            return addonAcc;
        }, 0);

        return acc;
    }, 0);

    // const handleInputChange = (e) => {
    //     const { name, value, type, checked } = e.target;
    //     setCardDetails((prevDetails) => ({
    //         ...prevDetails,
    //         [name]: type === 'checkbox' ? checked : value,
    //     }));
    // };

    // useEffect(() => {
    //     const initializeStripe = async () => {
    //         const stripe = await stripePromise;
    //         const elements = stripe.elements();
    //         const cardElement = elements.create('card');
    //         cardElement.mount('#card-element');
    //     };

    //     initializeStripe();
    // }, [stripePromise]);

    // const handlePayment = async () => {
    //     try {
    //         const stripe = await stripePromise;
    //         const elements = stripe.elements();

    //         // Assuming you have an element for card details with id 'card-element'
    //         const cardElement = elements.create('card');
    //         cardElement.mount('#card-element');

    //         // Step 1: Create a Payment Method
    //         const { paymentMethod, error: createPaymentMethodError } = await stripe.createPaymentMethod({
    //             type: 'card',
    //             card: cardElement,
    //         });
    //         
    //         if (createPaymentMethodError) {
    //             console.error(createPaymentMethodError);
    //             toast.error('Error creating Payment Method. Please check your card details.', {
    //                 position: 'top-right',
    //                 autoClose: 5000,
    //             });
    //             return;
    //         }

    //         // Step 2: Create payment intent on the server with the Payment Method ID
    //         const amountInCents = Math.round(data[0]?.itemData?.price * 100);
    //         const intentResponse = await axios.post('https://51.104.48.182/api/payment/create-payment-intent', {
    //             amount: amountInCents,
    //             currency: 'usd',
    //             paymentMethod: paymentMethod.id,
    //         });

    //         const { client_secret } = intentResponse.data;

    //         // Step 3: Confirm the card payment using Stripe SDK
    //         const { paymentIntent, error: confirmPaymentError } = await stripe.confirmCardPayment(client_secret);

    //         // Step 4: Handle the payment result
    //         if (confirmPaymentError) {
    //             console.error(confirmPaymentError);
    //             toast.error('Payment failed. Please try again.', {
    //                 position: 'top-right',
    //                 autoClose: 5000,
    //             });
    //         } else if (paymentIntent.status === 'succeeded') {
    //             toast.success('Payment successful!', {
    //                 position: 'top-right',
    //                 autoClose: 5000,
    //             });

    //             // Additional logic for order creation or UI update
    //         }
    //     } catch (error) {
    //         console.error("Stripe", error);
    //         toast.error('Something went wrong. Please try again.', {
    //             position: 'top-right',
    //             autoClose: 5000,
    //         });
    //     }
    //     // const handlePayment = async () => {
    //     //     const stripe = await stripePromise;

    //     //     try {
    //     //         const response = await axios.post('/api/create-payment-intent', {
    //     //             items: data,
    //     //         });

    //     //         const { clientSecret } = response.data;

    //     //         const result = await stripe.confirmCardPayment(clientSecret, {
    //     //             payment_method: {
    //     //                 card: stripe.elements.getElement('card'), // Use stripe.elements.getElement
    //     //                 billing_details: {
    //     //                     name: cardDetails.cardOwner,
    //     //                 },
    //     //             },
    //     //         });

    //     //         if (result.error) {
    //     //             console.error(result.error.message);
    //     //             toast.error(result.error.message, {
    //     //                 position: 'top-right',
    //     //                 autoClose: 5000,
    //     //             });
    //     //         } else {
    //     //             // ... rest of your code ...
    //     //         }
    //     //     } catch (error) {
    //     //         console.error(error);
    //     //         toast.error('Something went wrong. Please try again.', {
    //     //             position: 'top-right',
    //     //             autoClose: 5000,
    //     //         });
    //     //     }
    //     // };
    // }
    // const handlePayment = async () => {
    //     try {
    //         
    //         const amountInCents = Math.round(data[0]?.itemData?.price * 100);
    //         const intentResponse = await axios.post('https://51.104.48.182/api/payment/create-payment-intent', {
    //             amount: amountInCents,
    //             currency: 'usd',
    //         });
    //         const totalOrderAmount = data.reduce((total, item) => total + item.itemData.price, 0);
    //         const currentDate = new Date();

    //         const datareq = {
    //             date: currentDate,
    //             customerName: cardDetails?.cardOwner,
    //             orderType: "Dine-In",
    //             orderSource: "--",
    //             orderAmount: totalOrderAmount,
    //             tax: 1,
    //             orderStatus: "Accepted",
    //             totalDiscount: 0,
    //             amountCollected: totalOrderAmount,
    //             amountReturned: 0,
    //             orderItems: data?.map(item => ({
    //                 itemName: item?.itemData?.name,
    //                 amount: item?.itemData?.price,
    //                 itemDiscount: 0,  // You may need to calculate this based on your business logic
    //                 quantity: item?.quantity,
    //                 kitchenInstructions: item?.specialInstructions,
    //                 addOns: [
    //                     {
    //                         name: item?.selectedAdOn?.name,
    //                         price: item?.selectedAdOn?.price
    //                     }
    //                 ]
    //             }))
    //         };
    //         const apiService = new ApiService();

    //         let res = await apiService.post("CREATE_ORDER", datareq);
    //         if (res?.data?.statusCode === 201) {
    //             // Show success toast
    //             navigate('/costumer/wait');
    //             toast.success("Order Created Successfully");
    //         }
    //         else {
    //             toast.warning('Waiting for Order');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };
    const handlePaymentSuccess = (field) => {
        
        invokeBroadCastMessage("New Order Placed");
        navigate(`/Cashier/orderDetails/${data[0]?.itemData?.id}`, { state: data });
        dispatch(clearCart())

    };

    return (
        <div className=''>
            <CostumerHeader />
            <div className='px-lg-5'>
                <div className='px-lg-5'>
                    <div className='px-lg-5'>
                        <div className='row p-lg-5'>
                            <div className='col-lg-6 p-lg-5'>
                                <div className='headTxt'>Order Summary</div>
                                <div className='lineBreak py-lg-3'></div>
                                {data?.map((val, key) => (
                                    <div className='row pt-4' key={key}>
                                        <div className='col-lg-2'>
                                            <img src={val?.itemData?.imageUrl === null || "" ? Item2 :
                                                IMAGE_URL + val?.itemData?.imageUrl} alt='Item' className='itmImg' />
                                        </div>
                                        <div className='col-lg-7'>
                                            <div className='bodytxt'>{val?.itemData?.name || 'Pizza'} ({val?.selectedVariant?.metaItemName})</div>
                                            <div className='bodytxt mainclr'>Qty: {val?.quantity || '2'}</div>
                                        </div>
                                        <div className='bodytxt col-3'>{`${Currency.currency.name}  ${(val?.selectedVariant ? (val?.selectedVariant?.variantPrice) : (val?.itemData?.price) + (calculateAddOnsPrice(val?.selectedAdOn) + calculateModifierPrice(val?.selectedModifier) || '250'))}`}</div>
                                        {val?.selectedModifier?.map((item) => (
                                            <div className='row p-2'>
                                                <div className=' bodytxtm mainclrm offset-2 col-lg-3 col-ms-3'>
                                                    {item?.metaItemName}
                                                </div>
                                                <div className='offset-lg-5 bodytxtm col-lg-2 col-ms-2'>
                                                    {(item?.metaItemPrice).toFixed(2)}
                                                </div>

                                            </div>
                                        ))}
                                        {val?.selectedAdOn.map((item) => (
                                            <div className='row'>
                                                <div className=' bodytxtm mainclrm offset-2 col-lg-3 col-ms-2'>
                                                    {item?.metaItemName}
                                                </div>
                                                <div className='offset-lg-5 bodytxtm  col-lg-2 col-ms-2'>
                                                    {(item?.metaItemPrice).toFixed(2)}
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                ))}
                                {comboCart?.map((val, key) => (
                                    <div className='row pt-4' key={key}>
                                        <div className='col-lg-2'>
                                            <img src={val?.imageUrl === null || "" ? Item2 :
                                                IMAGE_URL + val?.imageUrl} alt='Item' className='itmImg' />
                                        </div>
                                        <div className='col-lg-7'>
                                            <div className='bodytxt'>{val?.name || 'Pizza'}</div>
                                            <div className='bodytxt mainclr'>Qty: {val?.quantity || '2'}</div>
                                        </div>
                                        <div className='bodytxt d-flex justify-content-end col-3'>{Currency.currency.name}  {val?.price.toFixed(2)}</div>
                                    </div>
                                ))}
                                <div className='lineBreak py-lg-3'></div>
                                <div className='form-group pt-5'>
                                    <div className='input-group gap-5'>
                                        <input
                                            type='text'
                                            name='cardNumber'
                                            placeholder='Gift or discount code'
                                            className='form-control-Payment w-75'
                                            required
                                        />
                                        <button type='button' className='aplybtn'>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                                <div className='lineBreak py-lg-4'></div>
                                <div className='d-flex justify-content-between py-2'>
                                    <div className='bodytxt'>Subtotal</div>
                                    <div className='bodytxt'>{`${Currency.currency.name}  ${state?.totalRate?.toFixed(2)}`}</div>
                                </div>
                                <div className='d-flex justify-content-between py-1'>
                                    <div className='bodytxt'>Tax</div>
                                    <div className='bodytxt'>{`${Currency.currency.name}  ${totalTax.toFixed(2)}`} </div>
                                </div>
                                <div className='d-flex justify-content-between py-1'>
                                    <div className='bodytxt'>Discount</div>
                                    <div className='bodytxt'>{`${Currency.currency.name}  ${totalDiscount.toFixed(2)}`}</div>
                                </div>
                                <div className='lineBreak py-lg-2'></div>
                                <div className='d-flex justify-content-between pt-lg-4'>
                                    <div>
                                        <div className='bodytxt'>Total</div>
                                        <div className='mainclr'>{`Including all taxes and discounts`}</div>
                                    </div>
                                    <div className='totalprice'>{`${Currency.currency.name}  ${(parseInt(state?.totalRate) + parseInt(totalTax)) - parseInt(totalDiscount)}`}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 p-lg-5">

                                <Elements stripe={stripePromise}>
                                    <StripeForm
                                        handlePaymentSuccess={handlePaymentSuccess}
                                        data={data}
                                        comboCart={comboCart}
                                        cardDetails={cardDetails}
                                        total={state?.totalRate}
                                    />

                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(CardDetails);