import React, { useState } from 'react';
import Cake from '../../assets/images/CakeImg.png';
import '../itemCard.css';
import { useNavigate } from 'react-router';
import "./category.css"
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const CategoryCard = ({ val, handleMenuClick }) => {
    const [CartValue, setCartValue] = useState("");

    const truncatedAddress = val?.name?.length > 20 ? val?.name.substring(0, 30) + '...' : val?.name;

    const handleClick = () => { 
        handleMenuClick(val);
    };

    return (
        <div className=' py-2'>
            <div className='catCard' onClick={handleClick} >
                <div className='d-flex justify-content-around'>
                    <div>
                        <img className='itm_Img' src={val?.imageUrl === "" ? Cake :
                            IMAGE_URL + val?.imageUrl} />
                        {/* <img className='catImg' src={Cake} /> */}
                    </div>
                    <div className='center'>
                        <div className="card-Head" title={val?.name}>{truncatedAddress || "--"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryCard;
