import Button from 'react-bootstrap/Button';
import { React, useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import ApiService from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Next } from '../../../assets/images/svgIcons/pagination_right.svg';
import { ReactComponent as Previous } from '../../../assets/images/svgIcons/pagination_left.svg';
import "../AdminCustomer/customer.css"
import moment from 'moment/moment';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import EmployeeGrid from './gridTable/grid';

const EmployeeList = ({ selectedRow }) => {


    const [show, setShow] = useState(false);
    const [CostValue, setDelCost] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1); // Add this line for pageNumber
    const [data, setData] = useState([]); // Add this line for data state
    const [selectedRowData, setSelectedRowData] = useState(null);

    const handleSelectedRow = (rowData) => {
        setSelectedRowData(rowData);
        selectedRow(rowData)
    };

    let navigate = useNavigate();
    const handleClose = () => setShow(false);

    const handlePagination = (newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const handleShow = (e) => {
        setDelCost(e)
        setShow(true);
    }
    useEffect(() => {
        GetCostumer(pageNumber);
    }, [pageNumber]);

    const DeleteCostumer = async () => {
        const apiService = new ApiService();
        let paramaters = `${CostValue?.id}`;
        let res = await apiService.delete("DELETE_EMPLOYEE", paramaters);
        if (res?.data?.statusCode === 204) {
            setShow(false)
            window.location.reload(false);
            toast.success("Employee Successfully Deleted");
        }
        else {
            toast.warning('Waiting for Employee update');
        }
    }

    const GetCostumer = async (page) => {
        const apiService = new ApiService();
        try {
            let res = await apiService.get("GET_EMPLOYEE");
            setData(res?.data?.result.reverse());
            // Update total pages based on API response
            setTotalPages(res?.data?.result?.totalPages || 1);
        } catch (error) {
            console.error("Error fetching customer data:", error);
            // Handle error, you might want to set total pages to 1 or display an error message
            setTotalPages(1);
        }
    }
    const handleEdit = (val) => {
        navigate('/admin/Addemployee', { state: { val } });
    }
    const singleItem = (e) => {
        navigate(`/admin/employee/${e?.name}`, { state: e });
    }
    const renderPageLinks = () => {
        const links = [];
        for (let i = 1; i <= totalPages; i++) {
            links.push(
                <li key={i} className={`page-item ${i === pageNumber ? '' : ''}`}>
                    <a className="page-link pag" href="#" onClick={() => handlePagination(i)}>
                        {i}
                    </a>
                </li>
            );
        }
        return links;
    }

    return (
        <div className='row'>
            <div className='pt-4 col-lg-12 '>
                <EmployeeGrid employee={data} onRowSelect={handleSelectedRow} />
            </div>
            <Modal show={show} onHide={handleClose} value={CostValue}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete {CostValue?.name}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() => DeleteCostumer(CostValue)}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default EmployeeList