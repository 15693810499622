
import { ADD_ADDON,REMOVE_ADDON,UPDATE_ADDON_QUANTITY } from "./types";
// Action Creators
export const addAddon = (addon) => ({
    type: ADD_ADDON,
    payload: addon
});

export const removeAddon = (id) => ({
    type: REMOVE_ADDON,
    payload: id
});

export const updateAddonQuantity = (id, quantity) => ({
    type: UPDATE_ADDON_QUANTITY,
    payload: { id, quantity }
});
