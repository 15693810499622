import React, { useState, useEffect } from 'react';
import "../../../../Customer/Menu/NavBar/navBar.css";
import "../menuItemcard.css";
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import ItemGrid from './grid/grid';
import userManagement from "../../../../services/userManagement";
import ImageUpload from './imagUpload';

const CreateAddOn = () => {
    const [menu, setMenu] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [tax, setTax] = useState([]);

    const [formData, setFormData] = useState({
        addOn: {
            name: '',
            price: '0',
            vat: '0',
            discount: '0',
        },
        images: [],
        imageUrl: ''
    });

    useEffect(() => {
        GetAddsOn();
        GetTax();
    }, [searchTerm]);

    const GetTax = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_TAX");
            setTax(res.data.result);
        } catch (error) {
            console.error('Error fetching tax data:', error);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            addOn: {
                ...formData.addOn,
                [e.target.name]: e.target.value,
            },
        });
    };

    const handleCreate = async () => {
        try {
            const apiService = new ApiService();
            let UserId = userManagement.getUserId();
            const formdata = new FormData();
            for (const image of formData?.images) {
                
                if (image.file) {
                 formdata.append('Images', image?.file);
                }
            }
            let response = await apiService.postMultiPart("UPLOAD_IMG", formdata);
            if (response?.data?.statusCode === 201 || response?.data?.statusCode === 200) {
                const imageUrl = response?.data?.result;
                const datareq = {
                    loggedInUserId: UserId,
                    name: formData.addOn.name,
                    image: imageUrl,
                    price: parseInt(formData.addOn.price),
                    tax: parseInt(0),
                    discount: parseInt(formData?.addOn?.discount)
                };

                if (formData.addOn.name && formData.addOn.price) {
                    let res = await apiService.post("CREATE_ADDONS", datareq);
                    if (res?.data?.statusCode === 201) {
                        toast.success("AddOns Created Successfully");
                        GetAddsOn();
                        setFormData({
                            addOn: {
                                name: '',
                                price: '',
                                vat: '',
                                discount: '',
                            },
                            images: [],
                            imageUrl: ''
                        });
                    } else {
                        toast.warning('Waiting for Response');
                    }
                } else {
                    toast.error("Fill in all the fields to create add-ons");
                }
            } else {
                toast.error("Image upload failed");
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("An error occurred");
        }
    };

    const GetAddsOn = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_ADDONS', parameters);
            setMenu(res?.data.result);
        } catch (error) {
            console.error('Error fetching AddsOn data:', error);
        }
    };

    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    }
    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        if (index !== null) {
            updatedImages.splice(index, 1);
        }
        setFormData({ ...formData, images: updatedImages });
    };

    return (
        <div className="p-lg-5 container">
            <div className="catlist p-5">
            <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
                <div className="form-group row justify-content-between align-content-center">
                    <div className="col-md-6">
                        <label htmlFor="validationCustomName" className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control w-75"
                            placeholder="Enter Name"
                            id="validationCustomName"
                            name="name"
                            value={formData.addOn.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="gap-2 d-flex">
                            <div className='w-75'>
                                <label htmlFor="validationCustomPrice" className="form-label">Price</label>
                                <input
                                    type="number"
                                    placeholder="Price"
                                    className="form-control"
                                    id="validationCustomPrice"
                                    name="price"
                                    value={formData.addOn.price}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='w-25'>
                                <label htmlFor="validationCustomPrice" className="form-label"></label>
                                <div className="addItem-btn p-3 mt-1" onClick={handleCreate}>
                                    Save
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="col-lg-6 pb-3 pt-3">
                    <label htmlFor="Search" className="form-label">Add-on Search by name</label>
                    <input
                        type='Search'
                        className='form-control w-75'
                        id='validationCustom01'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className='valid-feedback'>Looks good!</div>
                </div>
                <div>
                    <ItemGrid addson={menu} GetAddsOn={GetAddsOn} />
                </div>
            </div>
        </div>
    );
};

export default CreateAddOn;
