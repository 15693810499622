// import React, { useState, useEffect, Component, useCallback, useRef } from 'react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import { AgGridReact } from 'ag-grid-react';
// import jsPDF from 'jspdf';
// import ApiService from '../../../../services/apiService';
// import moment from 'moment';
// import Modal from 'react-bootstrap/Modal';
// import "./grid.css"
// const GridReport = ({ data }) => {

//     const gridRef = useRef();
//     const [customer, setCustomer] = useState([]);
//     const [orderData, setorders] = useState([]);
//     const [show, setShow] = useState(false); 
//     const handleClose = () => setShow(false);
//     const [value, setvalue] = useState();
//     const modalRef = useRef();

//     const getItemDetails = async (Itemdata) => {
//         const apiService = new ApiService();
//         let parameter = `?id=${Itemdata?.orderId}`
//         let res = await apiService.getApiParamater("GET_ORDERS", parameter);
//         setorders(res?.data?.result?.[0]);
//     }
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const apiService = new ApiService();
//                 const res = await apiService.get('GET_ORDERS');
//                 if (Array.isArray(res?.data?.result)) {
//                     res.data.result.reverse();
//                     setCustomer(res.data.result);
//                 } else {
//                     console.error("Invalid data format received from the API");
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
//         fetchData();
//     }, []);
//     const dateFormatter = (params) => {
//         const date = moment(params.value);
//         return date.format("MMM Do YYYY");
//     };
//     const mapOrderType = (orderType) => {
//         switch (orderType) {
//             case 0:
//                 return "Dine-In";
//             case 1:
//                 return "Take-out";
//             case 2:
//                 return "Delivery";
//             case 3:
//                 return "Online Order";
//             case 4:
//                 return "Drive through";
//             case 5:
//                 return "Catering";
//             case 6:
//                 return "Room Service";
//             case 7:
//                 return "Pickup";
//             case 8:
//                 return "Curbside Pickup";
//             default:
//                 return "Unknown Order Type";
//         }
//     };
//     const mapOrderStatus = (orderStatus) => {
//         switch (orderStatus) {
//             case 0:
//                 return "New Order";
//             case 1:
//                 return "Accepted";
//             case 2:
//                 return "Preparing";
//             case 3:
//                 return "Ready For Delivery";
//             case 4:
//                 return "Ready For Pick up";
//             case 5:
//                 return "Out For Delivery";
//             case 6:
//                 return "Delivered";
//             case 7:
//                 return "Cancelled";
//             case 8:
//                 return "On-Hold";
//             default:
//                 return "Unknown Order Status";
//         }
//     };
//     const dateComparator = (date1, date2) => {
//         const momentDate1 = moment(date1);
//         const momentDate2 = moment(date2);
//         return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
//     };
//     const mapOrderSource = (orderSource) => {
//         switch (orderSource) {
//             case 0:
//                 return "Website";
//             case 1:
//                 return "Mobile App";
//             case 2:
//                 return "Phone";
//             case 3:
//                 return "In Person";
//             case 4:
//                 return "Third Party Delivery";
//             case 5:
//                 return "Cashier";
//             case 6:
//                 return "Waiter";
//             default:
//                 return "Unknown Order Source";
//         }
//     };
//     const mapPaymentType = (paymentStatus) => {
//         switch (paymentStatus) {
//             case 0:
//                 return "Pending";
//             case 1:
//                 return "Authorized";
//             case 2:
//                 return "Paid";
//             case 3:
//                 return "Refunded";
//             case 4:
//                 return "Failed";
//             default:
//                 return "Unknown Order Type";
//         }
//     };
//     const handleShow = (rowData) => {
//         setvalue(rowData);
//         getItemDetails(rowData)
//         setShow(true);
//     }
//     const colDefs = [
//         { headerName: "Order Number", field: "orderId", filter: true, floatingFilter: true, minWidth: 180 },
//         {
//             headerName: "Order Date",
//             field: 'paymentDate',
//             filter: true,
//             floatingFilter: true,
//             valueFormatter: dateFormatter,
//             comparator: dateComparator,
//         },
//         { headerName: "Customer Name", field: "customerName", filter: true, floatingFilter: true, minWidth: 200 },
//         {
//             field: "orderType",
//             filter: true,
//             floatingFilter: true,
//             cellRenderer: (params) => mapOrderType(params?.value),
//         },
//         {
//             headerName: "Payment type",
//             field: "paymentStatus",
//             filter: true,
//             floatingFilter: true,
//             cellRenderer: (params) => mapPaymentType(params?.value),
//         },
//         {
//             field: "orderSource",
//             filter: true,
//             floatingFilter: true,
//             cellRenderer: (params) => mapOrderSource(params?.value),
//         },
//         {
//             field: "paymentAmount",
//             filter: true,
//             floatingFilter: true,
//             valueFormatter: (params) => params?.value?.toLocaleString(),
//         },
//         {
//             field: "orderStatus",
//             filter: true,
//             floatingFilter: true,
//             cellRenderer: (params) => mapOrderStatus(params?.value),
//             filterParams: {
//                 textCustomComparator: (filter, value, filterText) => {
//                     const displayedText = mapOrderStatus(value);
//                     return displayedText.toLowerCase().includes(filterText.toLowerCase());
//                 },
//             },
//         },
//         {
//             headerName: 'Details', field: 'orderId', cellRenderer: BtnCellRenderer,
//             cellRendererParams: {
//                 clicked: function (field) {
//                     handleShow(field)
//                 },
//             },
//         },
//     ];
//     const onBtnCSVExport = useCallback(() => {
//         gridRef.current.api.exportDataAsCsv();
//     }, []);

//     const onBtExcelExport = useCallback(() => {
//         const params = {
//             fileName: 'Report.xlsx',
//         };
//         gridRef.current.api.exportDataAsExcel(params);
//     }, []);

//     const onBtnPDFExport = useCallback(() => {
//         const doc = new jsPDF();
//         const data = gridRef.current.api.getDataAsCsv();
//         doc.text(data, 10, 10);
//         doc.save('Report.pdf');
//     }, []);
//     const handleDownload = () => {
//         const pdf = new jsPDF();

//         // Set font
//         pdf.setFont('Poppins');

//         const logoHeight = 20;
//         const logoY = 10;

//         // Add title
//         pdf.setFontSize(18);
//         pdf.setTextColor(0, 0, 255);
//         // Add content
//         pdf.setFontSize(12);
//         pdf.setTextColor(0);
//         const startX = 20;
//         let currentY = logoY + logoHeight + 30;

//         pdf.text(`Date: ${moment(value?.openingDate).format('L')}`, startX, currentY);
//         currentY += 10;
//         pdf.text(`Time: ${moment(value?.openingDate).format('LT')}`, startX, currentY);
//         currentY += 10;
//         pdf.text(`Voucher No: ${value?.voucherNo}`, startX, currentY);
//         currentY += 10;
//         pdf.text(`From User: ${value?.userId}`, startX, currentY);
//         currentY += 10;
//         pdf.text(`Amount: ${value?.amount}`, startX, currentY);
//         currentY += 10;
//         pdf.text('Shift: Morning', startX, currentY);
//         currentY += 10;
//         pdf.text('Branch: f-10 Islamabad', startX, currentY);
//         currentY += 10;
//         pdf.text(`ID: ${value?.id}`, startX, currentY);
//         currentY += 10;

//         // Save the PDF with a specific name
//         pdf.save(`Cash_${value?.id}_recipt.pdf`);
//     };

//     const frameworkComponents = {
//         btnCellRenderer: BtnCellRenderer,
//     };
//     return (
//         <div className=''>
//             <div className='earn'>
//                 <div className='gap-2 end d-flex'>
//                     <button className='btn-border' onClick={onBtExcelExport}>Excel</button>
//                     <button className='btn-border' onClick={onBtnCSVExport}>CSV</button>
//                     <button className='btn-border' onClick={onBtnPDFExport}>PDF</button>
//                 </div>
//             </div>
//             <div className="ag-theme-alpine mt-3" style={{ height: 700 }}>
//                 <AgGridReact
//                     rowData={data}
//                     ref={gridRef}
//                     enableFilter={true}
//                     floatingFilter={true}
//                     rowSelection={'single'}
//                     columnDefs={colDefs}
//                     pagination={true}
//                     suppressExcelExport={true}
//                     domLayout='autoHeight'
//                     suppressRowClickSelection={true}
//                     frameworkComponents={{ frameworkComponents }}
//                 />
//             </div>
//             <Modal show={show} onHide={handleClose} size="lg" centered ref={modalRef}>
//                 {/* <Modal.Header closeButton> */}
//                 <div className='d-flex justify-content-between px-4 pt-4' closeButton>
//                     <div>
//                         <h6> Date</h6>
//                         {moment(orderData?.orderDate).format('L')}
//                     </div>
//                     {/* <div className='center'><img src={logo} /></div> */}
//                     <div>
//                         <h6> Time</h6>
//                         {moment(orderData?.orderDate).format('LT')}
//                     </div>
//                 </div>
//                 {/* <Modal.Title className='center'>Table Reservation</Modal.Title> */}
//                 {/* </Modal.Header> */}
//                 <Modal.Body>
//                     <div className='p-4'>
//                         <div className='mx-3 px-2 pt-3 '>
//                             <div className='row tabllbox'>
//                                 <div className='col' style={{ fontSize: "14px", fontWeight: "500" }}>Item Name</div>
//                                 <div className='col' style={{ fontSize: "14px", fontWeight: "500" }}>Quantity</div>
//                                 <div className='col' style={{ fontSize: "14px", fontWeight: "500" }}>Type</div>
//                                 <div className='col' style={{ fontSize: "14px", fontWeight: "500" }}>Price</div>
//                             </div>
//                             <div className=''>
//                                 {orderData?.orderItems?.map((orderItem, itemIndex) => {
//                                     const matchingMetaItems = value?.orderItemsMeta?.filter(metaItem => metaItem.menuItemId === orderItem.itemId && metaItem.metaItemIndex === itemIndex);
//                                     return (
//                                         <>
//                                             <div className={`row my-3 ${orderItem?.voidItem ? '' : ''}`}>
//                                                 <div className='col' style={orderItem?.voidItem ? { color: 'red' } : {}}>
//                                                     {orderItem?.menuItemName || "Beef Cheese Burger"}
//                                                     <div className={`d-flex gap-2`}>
//                                                         {matchingMetaItems?.map(metaItem => (
//                                                             <div>{metaItem.metaItemName}</div>
//                                                         ))}
//                                                     </div>
//                                                 </div>
//                                                 <div className='col' style={orderItem?.voidItem ? { color: 'red' } : {}}>{orderItem?.quantity}</div>
//                                                 <div className='col' style={orderItem?.voidItem ? { color: 'red' } : {}}>{mapOrderType(value?.orderType)}</div>
//                                                 <div className='col' style={orderItem?.voidItem ? { color: 'red' } : {}}>{orderItem?.menuItemPrice}</div>
//                                                 {orderItem?.voidItem ?
//                                                     <div style={orderItem?.voidItem ? { color: 'red' } : {}}>{orderItem?.voidItem ? 'Voided' : ''}</div>
//                                                     : ''
//                                                 }

//                                             </div>
//                                             <div class="separators">
//                                                 <hr />
//                                             </div>
//                                         </>
//                                     );
//                                 })}
//                             </div>

//                         </div>
//                         <div className='p-3'>
//                             <div className='d-flex justify-content-between detail'>
//                                 <h6 className='center'>Tax</h6>
//                                 <h6 className='center'>{orderData?.calculatedTax || "0"}</h6>
//                             </div>
//                             <div className='d-flex justify-content-between detail my-3'>
//                                 <h6 className='center'>Discount</h6>
//                                 <h6 className='center'>{orderData?.totalDiscount || "0"}</h6>
//                             </div>
//                             <div className='d-flex justify-content-between detail'>
//                                 <h6 className='center'>Total price</h6>
//                                 <h6 className='center'>{orderData?.orderAmount || "0"}</h6>
//                             </div>
//                             <div className='tabllbox center my-3'>
//                                 Other Details
//                             </div>
//                             {value?.tableId ?
//                                 <div className='d-flex justify-content-between detail'>
//                                     <h6 className='center'>Table No</h6>
//                                     <h6 className='center'>{value?.tableId || "0"}</h6>
//                                 </div> : " "
//                             }
//                             <div className='d-flex justify-content-between detail my-3'>
//                                 <h6 className='center'>Customer</h6>
//                                 <h6 className='center'>{value?.customerName || "Guest"}</h6>
//                             </div>
//                             <div className='d-flex justify-content-between detail'>
//                                 <h6 className='center'>Payment Type</h6>
//                                 <h6 className='center'>{mapPaymentType(value?.paymentStatus)}</h6>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer className='center'>
//                     <button className='saveButton' onClick={handleDownload}>
//                         Invoice
//                     </button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// };

// class BtnCellRenderer extends Component {
//     constructor(props) {
//         super(props);
//         this.btnClickedHandler = this.btnClickedHandler.bind(this);
//     }

//     btnClickedHandler() {
//         const { data, clicked } = this.props;
//         clicked(data);
//     }

//     render() {
//         return (
//             <button onClick={this.btnClickedHandler} className="delbtn">View</button>
//         )
//     }
// }

// export default GridReport;



import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import "./grid.css"
import { RiSkipLeftFill, RiSkipRightFill } from "react-icons/ri";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { SlRefresh } from "react-icons/sl";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ApiService from '../../../../services/apiService';
const GridReport = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [StockType, setStockType] = useState();
    const [SupplierList, setSuppliers] = useState();
    const [PageNo, setPageNo] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [Items, setItems] = useState([]);
    const [pagination, setPagination] = useState();
    const [totalpages, setTotalPages] = useState();
    const [toDate, setToDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [dateFilter, setdateFilter] = useState(false);
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const FilterStatus = [
        { value: '0', label: 'Partial Delievered' },
        { value: '1', label: 'Completely Delivered' },
        { value: '2', label: 'Not Delivered' }
    ];
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const [formData, setFormData] = useState({
        startdate: "",
        endDate: "",
        PoNum: "",
        Filterby: "",
        VendorName: [{ label: 'Select Supplier ', value: '0' }],
        StockType: [{ label: 'Select Stock Type', value: '0' }],
        Status: [{ label: 'Filter By', value: '' }],

        ItemType: [{ label: 'Select Stock Type', value: '0' }],
        Items: [{ label: 'Select Items', value: '0' }],
    });
    const handleClearFilters = () => {
        setFormData({
            startdate: "",
            endDate: "",
            Filterby: "",
            ItemType: [{ label: 'Select Stock Type', value: '0' }],
            Items: [{ label: 'Select Items', value: '0' }],
        });
        setFromDate("")
        setToDate(" ")
        setdateFilter(false)
    };

    useEffect(() => {
        if (formData || pageSize) {
            getItems();
        }
        if (dateFilter === true) {
            getItems();
        } else {
            return
        }
    }, [pageSize, formData, dateFilter, toDate, fromDate]);
    useEffect(() => {
        getItems()
    }, []);
    const getItems = async (pageChange) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            let newPageNo = pagination?.currentPage;
            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            } else if (pageChange === "skipNext") {
                newPageNo += 10;
            } else if (pageChange === "skipPrev") {
                newPageNo -= 10;
            }
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_ORDERS', `?${params.toString()}`);
            // const res = await apiService.get('GET_ORDERS');
            
            const modifiedData = res?.data?.result?.map((item, index) => ({
                ...item,
                id: `ORD-24-${item?.id?.toString().padStart(4, '0')}`
            }));
            setRowData(modifiedData?.reverse() || []);
            setPagination(res.data.paginationData);
            const totalPages = Math.ceil(res.data.paginationData.totalRecords / res.data.paginationData.pageSize);
            setTotalPages(totalPages)

            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.isValid() ? date.format("DD-MM-YYYY") : null;
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const mapOrderType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Dine-In";
            case 1:
                return "Take-Away";
            case 2:
                return "Delivery";
            case 3:
                return "Online Order";
            case 4:
                return "Drive through";
            case 5:
                return "Catering";
            case 6:
                return "Room Service";
            case 7:
                return "Pickup";
            case 8:
                return "Curbside Pickup";
            default:
                return "Unknown Order Type";
        }
    };
    const Comparison = [
        { headerName: 'Order No', field: 'id', filter: true, floatingFilter: true, minWidth: 110 },
      {
            headerName: 'Date', field: 'orderDate', filter: true, floatingFilter: true, minWidth: 110, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Customer', field: 'customerName', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Rider', field: 'riderName', filter: true, floatingFilter: true, minWidth: 110 },

       

        { headerName: 'Amount', field: 'orderAmount', filter: true, floatingFilter: true, minWidth: 110 },
        { headerName: 'Shift', field: 'calculatedTax', filter: true, floatingFilter: true, minWidth: 110 },
        {
            headerName: 'Counter', field: 'paymentStatus', filter: true, floatingFilter: true, minWidth: 110,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<div>Pending</div>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<div>Authorized</div>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<div>Paid</div>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<div>Rejected</div>);
                } else {
                    return params.value;
                }
            }
        },
         {
            headerName: 'Delivery Date', field: 'deliveryDate', filter: true, floatingFilter: true, minWidth: 150, filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Delivery Status', field: 'deliveryStatus', filter: true, floatingFilter: true, minWidth: 110 },

    ];
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'TotalSale.csv'
        });
    }, []);

    const onBtExcelExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel({
            fileName: 'TotalSale.xlsx'
        });
    }, []);

    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const comparisonFields = [
            'orderId',
            'requisitionNo',
            'docDate',
            'vendorName',
            'terms',
            'status',
            'deliveryDate',
            'deliveryStatus',
        ];

        const translateStatus = (status) => {
            switch (status) {
                case 1:
                    return 'Approved';
                case 0:
                    return 'Pending';
                case 2:
                    return 'Hold';
                case 3:
                    return 'Rejected';
                default:
                    return status;
            }
        };

        // Add the report title
        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Order Report", 72, 12);
        doc.text("__________________________", 60, 15);

        // Get data from the grid and format it
        const rowData = [];
        const columnHeaders = comparisonFields.map(field => Comparison.find(col => col.field === field)?.headerName);

        gridRef.current.api.forEachNode((node) => {
            const row = comparisonFields.map(field => {
                let value = node.data[field];
                if (field === 'status') {
                    value = translateStatus(value);
                }
                return value;
            });
            rowData.push(row);

            // Get order items and format them
            const orderItems = node.data.orderItems.map(item => [
                item.itemCode,
                item.itemDescription,
                item.uom,
                item.qty,
                item.unitPrice,
            ]);

            // Append order items under each order
            rowData.push(['', 'Order Items:']);
            orderItems.forEach(item => rowData.push(['', ...item]));
        });

        // Add table to PDF
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 5,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 5,
            },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 15 },
                2: { cellWidth: 15 },
                3: { cellWidth: 15 },
                4: { cellWidth: 15 },
                5: { cellWidth: 15 },
                6: { cellWidth: 15 },
                7: { cellWidth: 20 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 10 },
                11: { cellWidth: 10 },
                12: { cellWidth: 10 },
            },
            startY: 20,
            didDrawPage: (data) => {
                doc.setFontSize(18);
                doc.text("Total Sale Report", 72, 12);
                doc.text("__________________________", 60, 15);
            }
        });

        // Save the PDF
        doc.save('TotalSale.pdf');
    }, []);


    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };
    const handleSupplierChange = (selectedOption) => {
        setFormData({ ...formData, VendorName: selectedOption });
    }

    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleToDate = (e) => {
        setToDate(e.target.value);
    };
    const handleDateFilterSelection = (e) => {
        setdateFilter(e);
    };
    return (
        <div className='pt-2'>
            <div className='pageBodr'>
                <div className='row'>
                    <div className='col-6 d-flex gap-5'>
                        <div className='arrows d-flex gap-5'>
                            {totalpages < 10 ?
                                <div className='center'><RiSkipLeftFill size={30} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><RiSkipLeftFill size={30} onClick={() => getItems("skipPrev")} /></div>
                            }
                            {pagination?.currentPage === 1 ?
                                <div className='center'><FaAngleLeft size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleLeft size={25} onClick={() => getItems("prev")} /></div>
                            }
                            <div className='d-flex gap-3 center'>
                                <div className=''>{pagination?.currentPage || "0"}</div>
                                <div className=''>of</div>
                                <div className=''>{totalpages || "0"}</div>
                            </div>
                            {pagination?.currentPage === totalpages ?
                                <div className='center'><FaAngleRight size={25} style={{ color: "#ABA3A3" }} /></div> :
                                <div className='center'><FaAngleRight size={25} onClick={() => getItems("next")} /></div>
                            }
                            {totalpages > 10 ?
                                <div className='center'><RiSkipRightFill size={30} onClick={() => getItems("skipNext")} /></div> :
                                <div className='center'><RiSkipRightFill size={30} style={{ color: "#ABA3A3" }} /></div>
                            }
                        </div>
                        <div className="gap-4 d-flex">
                            <div className='center'>Page Size</div>
                            <div>
                                <select
                                    className="form-select"
                                    style={{ color: "#5B93FF" }}
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 '>
                        <div className='end arrows d-flex gap-4'>
                            <div className='center' onClick={handleClearFilters}><SlRefresh size={30} /></div>
                            <div className='center'>
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                        <PiDownloadSimple size={30} />
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3'>
                                        <DropdownItem onClick={onBtnCSVExport}>Export CSV</DropdownItem>
                                        <DropdownItem onClick={onBtExcelExport}>Export Excel</DropdownItem>
                                        <DropdownItem onClick={onBtPDFExport}>Export PDF</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className='center'><HiOutlinePrinter size={30} /></div>
                            <div className='center'><TfiEmail size={28} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='brdor'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        suppressExcelExport={true}
                        ref={gridRef}
                    />
                </div>
            </div>
        </div>
    );
};
export default GridReport; 