import React, { useEffect, useState } from 'react';
import "./singleOrder.css";
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import SingleOrderItem from "./item/item";
import Progress from './Progress/progress';
import PaySection from './paymentSection/paymentSection';
import ApiService from '../../../../services/apiService';

const CashOrderDetails = ({ Currency }) => {
    const [orderDetails, setOrders] = useState({});
    const { state } = useLocation();
    const itemData = state;

    useEffect(() => {
        getItemDetails();
    }, []);

    const getItemDetails = async () => {
        const apiService = new ApiService();
        const itemNumber = itemData.replace(/^ORD-/i, '');
        let parameter = `?id=${itemNumber}`;
        let res = await apiService.getApiParamater("GET_ORDERS", parameter);
        setOrders(res?.data?.result?.[0] || {});
    };

    return (
        <div className='container p-lg-5'>
            <div className='pt-5'>
                <Progress val={orderDetails} />
            </div>
            <div className='p-lg-4'>
                {/* Display Order Items */}
                {orderDetails.orderItems && orderDetails.orderItems.map((val, key) => (
                    <SingleOrderItem
                        key={key}
                        val={val}
                        orderdata={orderDetails}
                        Currency={Currency}
                    />
                ))}

                {/* Display Combos */}
                {orderDetails.orderCombos && orderDetails.orderCombos.map((combo, key) => (
                    <SingleOrderItem
                        key={key}
                        val={combo}
                        orderdata={orderDetails}
                        Currency={Currency}
                        isCombo={true}
                    />
                ))}
                {orderDetails.orderAddOns && orderDetails.orderAddOns.map((addOn, key) => (
                    <SingleOrderItem
                        key={key}
                        val={addOn}
                        orderdata={orderDetails}
                        Currency={Currency}
                        isAddOn={true}
                    />
                ))}
            </div>
            <div>
                <PaySection val={orderDetails} />
            </div>
            <div className="separator py-3">
                <hr />
            </div>
            <div>
                <div className='itmmain'>Order Summary</div>
                <div className='d-flex justify-content-between pt-3'>
                    <div className='itmtitl'>Discount</div>
                    <div className='itmtitl'>{orderDetails?.discount || 0} {Currency.currency.name}</div>
                </div>
                <div className='d-flex justify-content-between pt-3'>
                    <div className='itmtitl'>Tax</div>
                    <div className='itmtitl'>{orderDetails?.calculatedTax}  {Currency.currency.name}</div>
                </div>
                {orderDetails?.amountCollected &&
                    <div className='d-flex justify-content-between pt-3'>
                        <div className='itmtitl'>Amount Collected</div>
                        <div className='text-success'> + {orderDetails?.amountCollected} {Currency?.currency?.code}</div>
                    </div>
                }
                {orderDetails?.amountReturned &&
                    <div className='d-flex justify-content-between pt-3'>
                        <div className='itmtitl'>Amount Returned</div>
                        <div className='text-danger'> - {orderDetails?.amountReturned} {Currency?.currency?.code}</div>
                    </div>
                }
                <div className='d-flex justify-content-between pt-3'>
                    <div className='itmtitl'>Total</div>
                    <div className='itmpay'> {orderDetails?.orderAmount || "0"}  {Currency.currency.name}</div>
                </div>
            </div>
            <div className='row'>
                <div className='row'>

                    <div className='itmpay py-4'>Order Information</div>
                    <div className='col-6 infobex p-4'>
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Customer name </div>
                            <div>{orderDetails?.customerName || "Guest"}</div>
                        </div>
                        {orderDetails?.refundReason &&
                            <div className='d-flex justify-content-between pt-3'>
                                <div>Refunded Reason </div>
                                <div>{orderDetails?.refundReason || "-----"}</div>
                            </div>
                        }
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Payment Type</div>
                            <div>{orderDetails?.orderType == 1 ? "Cash" : orderDetails?.orderType === 2 ? "Card" : "Stripe"}</div>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Table No</div>
                            <div>{orderDetails?.tableId}</div>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Order NO</div>
                            <div>{orderDetails?.id || "0"}</div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='itmpay py-4'>Order Information</div>
                    <div className='col-6 infobex p-4'>
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Customer name </div>
                            <div>{orderDetails?.customerName || "Guest"}</div>
                        </div>
                        {orderDetails?.refundReason &&
                            <div className='d-flex justify-content-between pt-3'>
                                <div>Refunded Reason </div>
                                <div>{orderDetails?.refundReason || "-----"}</div>
                            </div>
                        }
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Payment Type</div>
                            <div>{orderDetails?.orderType == 1 ? "Cash" : orderDetails?.orderType === 2 ? "Card" : "Stripe"}</div>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Table No</div>
                            <div>{orderDetails?.tableId}</div>
                        </div>
                        <div className='d-flex justify-content-between pt-3'>
                            <div>Order NO</div>
                            <div>{orderDetails?.id || "0"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(CashOrderDetails);
