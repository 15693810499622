import React, { useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../../../services/apiService';

const UserGrid = ({  }) => {

    const [users, setUsers] = useState([]);

    const [colDefs, setColDefs] = useState([
        { headerName: "User Name", field: "userName", filter: true, floatingFilter: true },
        { headerName: "Role", field: "roleName", filter: true, floatingFilter: true },
        { headerName: "Branch", field: "branchName", filter: true, floatingFilter: true },
        { headerName: "Mobile Number", field: "phoneNumber", filter: true, floatingFilter: true },
        { headerName: "ID Number", field: "idNum", filter: true, floatingFilter: true },
        { headerName: "Gender", field: "gender", filter: true, floatingFilter: true },
    ])
    useEffect(() => {
        GetUsers()
    }, []);

    const GetUsers = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_USERS");
            
            setUsers(res.data.result);
        } catch (error) {
            console.error('Error fetching User data:', error);
        }
    };

    return (
        <div className='brdor  mt-3' style={{ }}>
            <div className="ag-theme-alpine" style={{ height:1000, backgroundColor: "#5B93FF" }}>
                <AgGridReact
                    rowData={users}
                    columnDefs={colDefs}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection="multiple"
                    pagination={true}
                />
            </div>
        </div>
    )
}
export default UserGrid;