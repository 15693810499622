import { React, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import ApiService from '../../../../services/apiService';
import userManagement from '../../../../services/userManagement';
import ImageUpload from './imagUpload';
import { ReactComponent as Delete } from '../../../../assets/images/svgIcons/Delete.svg';
import { ReactComponent as AddOn } from '../../../../assets/images/svgIcons/addon.svg';
import { connect } from "react-redux";
import { getUserId } from '../../../../../Redux/selecters';
import { useSelector } from 'react-redux';

const AdminMaster = ({ Currency }) => {

    let navigate = useNavigate();
    const location = useLocation();
    const { state } = useLocation();
    const val = state?.item
    const UserId = useSelector(getUserId);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        kitchen: '',
        Tax: [],
        description: '',
        price: '',
        category: '',
        subCategory: '',
        addons: [],
        modifiers: [],
        variants: [],
        images: [],
        imageUrl: "",
        endTime: '',
        startTime: ''
    });
    

    const [categories, setCategories] = useState([]);
    const [kitchenList, setKitchenList] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [variants, setVariants] = useState([]);
    const [tax, setTax] = useState([]);
    const [modifier, setModifiers] = useState([]);
    const [weekdays, setWeekdays] = useState("");
    const [selectedVariant, setSelectedVariant] = useState();
    const [vatExclusive, setVatExclusive] = useState(false);
    const [isActive, setIsActive] = useState(false);
    
    useEffect(() => {

        const setMetaDataInFormData = (item) => {
            const modifiers = item?.menuItemMeta?.filter(meta => meta.metaType === 3)
                .map(modifier => ({
                    value: {
                        name: modifier.metaItemName,
                        price: modifier.metaItemPrice,
                        tax: modifier.metaItemTax,
                        discount: modifier.metaItemDiscount,
                        id: modifier.metaItemId,
                    },
                    label: modifier.metaItemName
                }));

            const variants = item?.menuItemMeta?.filter(meta => meta.metaType === 4)
                .map(variant => ({
                    value: {
                        name: variant.metaItemName,
                        price: variant.variantPrice,
                        id: variant.metaItemId,
                    },
                    label: variant.metaItemName,
                    price: variant.variantPrice
                }));
            setIsActive(item?.active)
            
            setVatExclusive(item?.taxIncluded || false)
            setFormData({
                id: item?.id || '',
                name: item.name || '',
                description: item.description || '',
                price: item.price || '',
                category: {
                    label: val?.categoryId,
                    value: { id: val?.categoryId } || ''
                },
                variants: variants,
                modifiers: modifiers,
                imageUrl: item?.imageUrl,
                Tax: item?.tax || '',
                kitchen: {
                    label: val?.kitchenId,
                    value: { id: val?.kitchenId } || ''
                },
                subCategory: {
                    label: val?.subCategoryId,
                    value: { id: val?.subCategoryId } || ''
                },
            });

        };

        GetModifier();
        fetchCategories();
        fetchVariants();
        GetTax();
        fetchKitchen()
        if (location.state && location.state.item) {
            const { item } = location.state;
            ;
            setMetaDataInFormData(item);
        }
    }, [location.state]);

    useEffect(() => {
        fetchSubCategories()
    }, [formData.category])
    const handleAddVariants = (selectedOption) => {
        setSelectedVariant(selectedOption);
    };
    const handlePriceChange = (event) => {
        const { value } = event.target;
        setSelectedVariant((prevSelectedVariant) => ({
            ...prevSelectedVariant, price: value || 0,
        }));
    };
    const handleConfirm = () => {
        if (!selectedVariant || !selectedVariant.price || selectedVariant.price.trim() === "") {
            toast.error("Please select a variant with a valid price.");
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                variants: [...prevFormData.variants, selectedVariant],
            }));
            setSelectedVariant();
        }
    };
    const handleDeleteVariant = (index) => {
        setFormData((prevFormData) => {
            const updatedVariants = [...prevFormData.variants];
            updatedVariants.splice(index, 1);
            return { ...prevFormData, variants: updatedVariants };
        });
    };
    const fetchVariants = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_VARIANTS');
            const updatedvariant = response?.data?.result.filter(item => item?.active === true);
            setVariants(updatedvariant);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, category: selectedOption || selectedOption });

    };
    const handleSubCategoryChange = (selectedOption) => {
        setFormData({ ...formData, subCategory: selectedOption || selectedOption });
    };
    const handleKitchenChange = (selectedOption) => {
        setFormData({ ...formData, kitchen: selectedOption || selectedOption });
    };
    const fetchKitchen = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_KITCHEN');
            const updatedKitchen = response?.data?.result;
            setKitchenList(updatedKitchen);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const fetchCategories = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_CATEGORY');
            const updatedCategories = response?.data?.result.filter(item => item?.active === true);
            setCategories(updatedCategories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const fetchSubCategories = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?CategoryId=${formData.category?.value?.id}`;
            const response = await apiService.getApiParamater('GET_SUBCATEGORY', parameters);
            const filteredSub = response?.data?.result.filter(item => item?.active === true);
            setSubCategories(filteredSub);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const handleVATChange = (selectedOption) => {
        setFormData({ ...formData, Tax: selectedOption?.value || selectedOption });
    };
    const handleVATSelection = (e) => {
        setVatExclusive(e);
    };
    const handleModifierChange = (selectedOption) => {
        setFormData({ ...formData, modifiers: selectedOption || [] });
    };
    const handleRemoveModifier = (index) => {
        const updatedmodifiers = [...formData.modifiers];
        updatedmodifiers.splice(index, 1);
        setFormData({ ...formData, modifiers: updatedmodifiers });
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const GetTax = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_TAX");
            const filteredTax = res.data.result.filter(item => item?.active === true);
            setTax(filteredTax);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };
    const GetModifier = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get("GET_MODIFIER");
            const activeModifiers = res.data.result.filter(modifier => modifier.active === true);
            setModifiers(activeModifiers);
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };
    
    const UploadImage = async () => {
        if (!formData?.images && !formData?.imageUrl) {
            toast.error("Upload Image");
        } else if (formData?.images) {
            try {
                const apiService = new ApiService();
                const formdata = new FormData();
                for (const image of formData?.images) {
                    if (image.file) {
                        formdata.append('Images', image?.file);
                    }
                }
                const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                if (response?.data?.statusCode === 201) {
                    setFormData({ ...formData, imageUrl: response?.data?.result });

                    handleSubmit(response?.data?.result)
                }
                else toast.error(response?.data?.msg)
            } catch (error) {
                toast.error("Something went wrong with the image. Please try again!");
            }
        }
    }
    const handleSubmit = async (imgurl) => {
        try {
            if (imgurl) {
                let body = {
                    id: parseInt(formData?.id),
                    name: formData?.name,
                    description: formData?.description,
                    price: parseInt(formData?.price),
                    tax: parseInt(formData?.Tax?.taxPercentage),
                    categoryId: formData.category?.value?.id,
                    subCategoryId: formData.subCategory?.value?.id,
                    kitchenId: formData?.kitchen?.value?.id,
                    taxIncluded: vatExclusive,
                    active: isActive,
                    imageUrl: imgurl,
                    menuItemMeta: [],
                    loggedInUserId: UserId
                }

                // Add modifiers to menuItemMeta
                if (formData?.modifiers && formData.modifiers.length > 0) {
                    formData.modifiers.forEach(modifier => {
                        body.menuItemMeta.push({
                            metaItemId: modifier.value.id,
                            metaType: 3
                        });
                    });
                }

                // Add variants to menuItemMeta
                if (formData?.variants && formData.variants.length > 0) {
                    formData.variants.forEach(variant => {
                        body.menuItemMeta.push({
                            metaItemId: variant.value.id,
                            variantPrice: parseInt(variant?.price),
                            metaType: 4
                        });
                    });
                }

                const apiService = new ApiService();

                let response = await apiService.post("CREATE_MENU", body);
                if (response.data.statusCode === 201) {
                    toast.success('Menu item created successfully');
                    navigate('/admin/menu');
                } else {
                    toast.warning('Waiting for menu Create');
                }
            } else {
                toast.warning('Upload Image');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleUpdateImg = async () => {
        try {

            if (!formData?.images && !formData?.imageUrl) {
                toast.error("Upload Image");
            } else if (formData?.images) {
                try {
                    const apiService = new ApiService();
                    const formdata = new FormData();
                    for (const image of formData?.images) {
                        if (image.file) {
                            formdata.append('Images', image?.file);
                        }
                    }
                    const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                    if (response?.data?.statusCode === 201) {
                        setFormData({ ...formData, imageUrl: response?.data?.result });
                        handleUpdate(formData?.imageUrl)
                    }
                    else toast.error(response?.data?.msg)
                } catch (error) {
                    toast.error("Something went wrong with the image. Please try again!");
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleUpdate = async () => {
        try {
            if (formData?.imageUrl) {
                let body = {
                    id: parseInt(formData?.id),
                    name: formData?.name,
                    description: formData?.description,
                    price: parseInt(formData?.price),
                    tax: parseInt(formData?.Tax),
                    categoryId: formData.category?.value?.id,
                    subCategoryId: formData.subCategory?.value?.id,
                    active: isActive,
                    imageUrl: formData?.imageUrl,
                    menuItemMeta: [],
                    loggedInUserId: val?.loggedInUserId,
                    kitchenId: formData?.kitchen?.value?.id,
                    taxIncluded: vatExclusive,
                }

                // Add modifiers to menuItemMeta
                if (formData?.modifiers && formData.modifiers.length > 0) {
                    formData.modifiers.forEach(modifier => {
                        body.menuItemMeta.push({
                            metaItemId: modifier.value.id,
                            metaType: 3
                        });
                    });
                }

                // Add variants to menuItemMeta
                if (formData?.variants && formData.variants.length > 0) {
                    formData.variants.forEach(variant => {
                        body.menuItemMeta.push({
                            metaItemId: variant.value.id,
                            variantPrice: parseInt(variant?.price),
                            metaType: 4
                        });
                    });
                }

                const apiService = new ApiService();
                let response = await apiService.put("UPDATE_MENU", body);
                if (response.data.statusCode === 200) {
                    toast.success('Menu item updated successfully');
                    navigate('/admin/menu');
                } else {
                    toast.warning('Waiting for menu update');
                }
            } else {
                toast.warning('Upload Image again');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    }
    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    }
    const handleSwitchChange = () => {
        setIsActive(!isActive);
    };
    return (
        <div className="p-5">
            <div className="p-5">
                <div className='row rows'>
                    <div className='col-lg-6 col6 creatBox'>
                        <div className='headtext'>Basic Information</div>
                        <div className='orngbox'>
                            <div className="">
                                <label htmlFor="validationCustom01" className="form-label texthead">Your Item Name *</label>
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom01"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className=" py-3">
                                <label htmlFor="validationCustom02" className="form-label texthead">
                                    Select Category *
                                </label>
                                <Select
                                    defaultValue={formData.category}
                                    onChange={handleCategoryChange}
                                    options={categories?.map((category) => ({ value: category, label: category.name }))}
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="">
                                <label htmlFor="validationCustom03" className="form-label texthead">
                                    Item Subcategory *
                                </label>
                                <Select
                                    defaultValue={formData.subCategory}
                                    onChange={handleSubCategoryChange}
                                    options={subCategories?.map((subcategory) => ({ value: subcategory, label: subcategory.name }))}
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="py-3">
                                <label htmlFor="validationCustom04" className="form-label texthead">Kitchen</label>
                                <Select
                                    defaultValue={formData.kitchen}
                                    onChange={handleKitchenChange}
                                    options={kitchenList?.map((kitchen) => ({ value: kitchen, label: kitchen.name }))}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="pt-3">
                                <label htmlFor="validationCustom05" className="form-label texthead">Your Item Discription *</label>
                                <textarea
                                    type="text"
                                    className="p-2 form-control txrea"
                                    id="validationCustom05"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="py-3 d-flex justify-content-between">
                                <div className="d-flex text-center">
                                    <div className="headtes p-2" htmlFor="flexSwitchCheckDefault">
                                        {isActive ? 'Active' : 'Inactive'}
                                    </div>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            checked={isActive}
                                            onChange={handleSwitchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col6'>
                        <div className='creatBox'>
                            <div className='headtext'>Upload Item Picture Here</div>
                            <div>
                                <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
                            </div>
                        </div>
                        <div>
                            <div className='headtext mt-4'>Item Schedule</div>
                            <div className='creatBox'>
                                <label htmlFor="validationCustom06" className="form-label texthead">Select Hours</label>
                                <div className="d-flex justify-content-center  gap-3">
                                    <input
                                        type="time"
                                        className="p-2 form-control"
                                        id="validationCustom06"
                                        name="startTime"
                                        value={formData.startTime}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <input
                                        type="time"
                                        className="p-2 form-control"
                                        id="validationCustom07"
                                        name="endTime"
                                        value={formData.endTime}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <label htmlFor="validationCustom08" className="form-label texthead mt-3">Select Days</label>
                                <div className='d-flex justify-content-between '>
                                    <div className={`categ ${weekdays === "Sun" && 'active'}`} onClick={() => setWeekdays("Sun")}>Sun</div>
                                    <div className={`categ ${weekdays === "Mon" && 'active'}`} onClick={() => setWeekdays("Mon")}>Mon</div>
                                    <div className={`categ ${weekdays === "Tue" && 'active'}`} onClick={() => setWeekdays("Tue")}>Tue</div>
                                    <div className={`categ ${weekdays === "Wed" && 'active'}`} onClick={() => setWeekdays("Wed")}>Wed</div>
                                    <div className={`categ ${weekdays === "Thu" && 'active'}`} onClick={() => setWeekdays("Thu")}>Thu</div>
                                    <div className={`categ ${weekdays === "Fri" && 'active'}`} onClick={() => setWeekdays("Fri")}>Fri</div>
                                    <div className={`categ ${weekdays === "Sat" && 'active'}`} onClick={() => setWeekdays("Sat")}>Sat</div>
                                    <div className='d-flex center'><input type="checkbox" id="allDays" name="AllDays" value="AllDays" />
                                        <label for="allDays" className='headtext'>All days</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row rows pt-5' >
                    <div className='col-lg-6 col6 creatBox'>
                        <div className='headtext'>Enter Item Price </div>
                        <div className='orngbox'>
                            <div className="">
                                <label htmlFor="validationCustom09" className="form-label texthead">Your Item Price *</label>
                                <input
                                    type="number"
                                    className="p-2 form-control"
                                    id="validationCustom09"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="py-3">
                                <div>
                                    <label htmlFor="validationCustom10" className="form-label texthead">
                                        Select Item tax *
                                    </label>
                                    <Select
                                        onChange={(selectedOption) => handleVATChange(selectedOption)}
                                        options={tax?.map((tax) => ({ value: tax, label: tax.name }))}
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className='d-flex gap-3 pt-2 d-flex'>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="Inclusive" style={{ width: "26px", height: "26px" }}
                                        name="Inclusive"
                                        checked={vatExclusive}
                                        onChange={(e) => handleVATSelection(e.target.checked)}
                                    />
                                    <label className="form-check-label checkItem center" htmlFor="Inclusive">
                                        VAT Inclusive
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col6'>
                        <div className='creatBox'>
                            <div className='headtext'>Select Item Modifiers </div>
                            <div className='orngbox'>
                                <div>
                                    <label htmlFor="validationCustom15" className="form-label">
                                        Select Item Modifier  *
                                    </label>
                                    <CreatableSelect
                                        isMulti
                                        options={modifier?.map((modifier) => ({ value: modifier, label: modifier.name }))}
                                        value={formData.modifiers}
                                        onChange={handleModifierChange}
                                        onCreateOption={null}
                                        placeholder="Select Modifier"
                                        className=''
                                        isClearable
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className='addbx mt-3'>
                                    {formData?.modifiers?.length === 0 ? (
                                        <div className='selected-addon p-5 mx-5'>
                                            <div className='px-5 mx-5'>
                                                <div className='center'>
                                                    <AddOn />
                                                </div>
                                                <div className='center'>
                                                    No selected modifiers
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        formData?.modifiers?.map((modifier, index) => (
                                            <div key={index} className='selected-addon  p-2'>
                                                <div className='d-flex gap-2 selectedadd'>
                                                    <div>{modifier.label}</div>
                                                    <div onClick={() => handleRemoveModifier(index)}>X</div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row rows pt-5' >
                    <div className='col-lg-6 col6 creatBox'>
                        <div className='headtext'>Add Item Variants </div>
                        <div className='orngbox'>
                            <div class="row">
                                <div class="col-md-4 col4 mb-3">
                                    <label for="validationCustom13" class="form-label texthead">Select Variant *</label>
                                    <Select
                                        onChange={handleAddVariants}
                                        options={variants?.map((category) => ({ value: category, label: category.name }))}
                                    />
                                    <div class="valid-feedback">Looks good!</div>
                                </div>
                                <div class="col-md-4 col4 mb-3">
                                    <label for="validationCustom14" class="form-label texthead">Add variant price *</label>
                                    <input
                                        type="number"
                                        class="p-2 form-control"
                                        id="validationCustom14"
                                        name="price"
                                        value={selectedVariant?.price}
                                        onChange={handlePriceChange}
                                        required
                                    />
                                    <div class="valid-feedback">Looks good!</div>
                                </div>
                                <div class="col-md-4 col4 center pt-1">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button class="addItem-btn" onClick={handleConfirm}>Save</button>
                                    </div>
                                </div>
                            </div>

                            <div className='form-control mt-4 table-responsive'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.variants.map((variant, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{variant.label}</td>
                                                <td>{Currency.currency.name}  {variant.price}</td>
                                                <td>
                                                    <div onClick={() => handleDeleteVariant(index)}><Delete /></div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>
                <div className='end pt-4'>
                    {location.state && location.state.item ?
                        <>
                            {formData?.imageUrl ?
                                <button className='addItem-btn' type="submit" onClick={() => handleUpdate()}>Update</button>
                                :
                                <button className='addItem-btn' type="submit" onClick={() => handleUpdateImg()}>Update</button>
                            }
                        </>
                        :
                        <button className='addItem-btn' type="submit" onClick={() => UploadImage()}>Save</button>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(AdminMaster);