import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import RequisitionGrid from './requisitionGrid';
import AuthorizationGrid from './authorization';
import MessageGrid from './messageGrid';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearRows } from '../../../Redux/actions/rowActions';
import { ClipLoader } from 'react-spinners';
import { getUserId, getName, getUserName, getCompany } from '../../../Redux/selecters';
import { useSelector } from 'react-redux';
import { FaAngleLeft } from "react-icons/fa6";
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
const PurchaseRequisition = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService2 = new ApiService2()
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [cost1, setCost1] = useState([]);
    const [cost2, setCost2] = useState([]);
    const [cost3, setCost3] = useState([]);
    const [cost4, setCost4] = useState([]);
    const [Location, setLocation] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [SupplierTab, setSupplierTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [usersDetails, setUser] = useState([]);
    const [Userdata, setUserdata] = useState();
    const [loader, setLoader] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const itemData = location.state;
    const userId = useSelector(getUserId);
    const userName = useSelector(getUserName);
    const Company = useSelector(getCompany);
    debugger
    const [ReqNo, setReqNo] = useState([]);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState([{ id: Date.now(), fileName: '', remarks: '' }]);
    const handleClose = () => setAttachmentModal(false);
    const handleOpen = () => setAttachmentModal(true);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const [formData, setFormData] = useState({
        recordId: "",
        requisitionNo: "",
        itemType: "",
        quatotionNo: "",
        remarks: "",
        requestor: "",
        requisitionDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        currencyCode: "",
        exchangeRate: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
    });
    useEffect(() => {
        getCurrency()
        getLocation()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        const selectedDepartment = department?.find(dep => dep.description === item?.department)
        if (selectedDepartment) {
            setSelectedDepartment({ label: selectedDepartment.description, value: selectedDepartment })
        }
        const selectedLocation = Location?.find(dep => dep.description === item?.approvalUser)
        if (selectedLocation) {
            setSelectedLocation({ label: selectedLocation.description, value: selectedLocation })
        }
        const costCenter1 = cost1?.find(dep => dep.description === item?.costCenter1)
        const costCenter2 = cost2?.find(dep => dep.description === item?.costCenter2)
        const costCenter3 = cost3?.find(dep => dep.description === item?.costCenter3)
        const costCenter4 = cost4?.find(dep => dep.description === item?.costCenter4)
        setFormData({
            recordId: item?.recordId,
            requisitionDate: item?.requisitionDate,
            deliveryDate: item?.deliveryDate,
            requisitionNo: item?.requisitionNo,
            itemType: item?.type,
            quatotionNo: item?.contractNo,
            remarks: item?.remarks,
            requestor: item?.requestor,
            currencyCode: item?.currencyCode,
            costCenter1: { label: costCenter1?.description, value: costCenter1 },
            costCenter2: { label: costCenter2?.description, value: costCenter2 },
            costCenter3: { label: costCenter3?.description, value: costCenter3 },
            costCenter4: { label: costCenter4?.description, value: costCenter4 },
            deliveryPoint: item?.deliveryPoint,
            contactPerson: item?.contactPerson,
            contatNo: item?.contatNo,
            taskCode: item?.projectCode,
            location: item?.location,
        });
    }, [location?.state, department, Location, cost1, cost2, cost3, cost4]);
    const getApprovalUser = async () => {
        try {
            const res = await apiService2.get('GET_USERS');
            const selecteduser = res?.data?.result?.find(dep => dep.name === userName)
            setUserdata(selecteduser)
            let param = `?Type=${"Purchase Requisition"}&Company=${selecteduser.company}&Department=IT`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER_LIST', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        if (itemData) {
            setReqNo(itemData.requisitionNo);
        } else {
            let docParam = `?TypeOfDoc=Req&DocId=Requisition`
            let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
            setReqNo(DocId?.data.result[0].docId);
        }
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        setFormData({ ...formData, exchangeRate: filteredDenominations.conversionRate });
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
    }
    const getLocation = async () => {
        let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
        setLocation(res?.data?.result)
        let DepRes = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
        setDepartment(DepRes?.data?.result)
        let cost1 = await apiService.get('GET_COST_ONE')
        setCost1(cost1?.data?.result)
        let cost2 = await apiService.get('GET_COST_TWO')
        setCost2(cost2?.data?.result)
        let cost3 = await apiService.get('GET_COST_THREE')
        setCost3(cost3?.data?.result)
        let cost4 = await apiService.get('GET_COST_FOUR')
        setCost4(cost4?.data?.result)
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')
    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const staticOptions = [];
    const itemType = [
        { value: 'Inventory', label: 'Inventory' },
        { value: 'Fixed Asset', label: 'Fixed Asset' },
        { value: 'Services', label: 'Services' },
    ];
    const totalAmount = requisitionData.reduce((sum, row) => sum + (row?.qty * row.baseAmount || 0), 0);
    const CreatePR = async () => {
        setLoader(true)
        if (!formData.location) {
            toast.warning("Enter location");
            setLoader(false)
            return;
        } else if (!selectedLocation) {
            toast.warning("Enter location");
            setLoader(false)
            return;
        }
        for (const item of requisitionData) {
            if (!item.itemDescription) {
                toast.warning(`Please Select Item`);
                setLoader(false);
                return;
            } else if (!item.qty) {
                toast.warning(`Please enter ${item.itemDescription} quantity`);
                setLoader(false);
                return;
            } else if (item.qty === "0") {
                toast.warning(`${item.itemDescription} quantity must be greater then 0`);
                setLoader(false);
                return;
            }
        }
        try {
            const prDetailsData = requisitionData?.map(item => ({
                requisitionNo: ReqNo.toString(),
                srno: parseInt(item.srno),
                itemCode: item?.itemNo.toString(),
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.qty),
                specification: item.specification,
                convValue: 1,
                unitPrice: parseInt(item.baseAmount),
                amount: parseInt(item.amount),
                baseAmount: parseInt(item.amount)
            }));
            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                type: "Inventory",
                requisitionNo: ReqNo,
                reqType: "Purchase Request",
                contractNo: formData?.quatotionNo,
                description: formData?.description,
                requestor: formData?.requestor,
                requisitionDate: currentDatetimeUTC,
                deliveryDate: formData.deliveryDate,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                // department: selectedDepartment?.value?.description,
                department: "IT",
                remarks: formData?.remarks,
                modifiedBy: userId,
                approvalUser: selectedLocation?.label,
                costCenter1: formData.costCenter1?.label,
                costCenter2: formData.costCenter2?.label,
                costCenter3: formData.costCenter3?.label,
                costCenter4: formData.costCenter4?.label,
                deliveryPoint: formData?.deliveryPoint,
                location: formData?.location,
                contactPerson: formData?.contactPerson,
                contatNo: formData?.contatNo,
                projectCode: formData?.taskCode,
                prDetails: prDetailsData,
                status: 0,
                amount: totalAmount,
                baseAmount: totalAmount,
            };
            const response = await apiService.post("CREATE_PR", userData);
            if (response?.data?.statusCode === 201 || response?.data?.statusCode === 200) {
                const docNumber = response?.data?.result?.requisitionNo
                const userData2 = {
                    docType: "Purchase Requisition",
                    docId: ReqNo ? ReqNo.toString() : ReqNo,
                    department: "IT",
                    company: Company?.selectedCompany?.companyNo,
                    userCode: userId,
                    userName: userName,
                };
                const docBody = {
                    docId: "Requisition",
                    typeOfDoc: "Req",
                };
                const formdata = new FormData();
                attachmentList.forEach((attachment) => {
                    if (attachment.file) {
                        formdata.append('Images', attachment.file);
                    }
                });
                const ImgResponse = await apiService.postMultiPart("UPLOAD_FILE", formdata);
                // const AttachmentData = {
                //     docType: "Purchase Requisition",
                //     docNo: ReqNo ? ReqNo.toString() : ReqNo,
                //     fileName: ImgResponse?.data.result,
                // };
                // const attachment = await apiService.post("CREATE_ATTACHMENT", AttachmentData);
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${ReqNo} Created successfully`);
                handleReset()
                navigate("/inventory/Requisition/Listing")
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const UpdatePR = async () => {
        try {
            setLoader(true)
            if (!formData.location) {
                toast.warning("Enter location");
                setLoader(false)
                return;
            }
            for (const item of requisitionData) {
                if (!item.itemDescription) {
                    toast.warning(`Please Select Item`);
                    setLoader(false);
                    return;
                } else if (!item.qty) {
                    toast.warning(`Please enter ${item.itemDescription} quantity`);
                    setLoader(false);
                    return;
                } else if (item.qty === "0") {
                    toast.warning(`${item.itemDescription} quantity must be greater then 0`);
                    setLoader(false);
                    return;
                }
            }
            const prDetailsData = requisitionData?.map((item, index) => ({
                requisitionNo: ReqNo,
                srno: index + 1,
                itemCode: item?.itemNo,
                itemDescription: item.itemDescription,
                specification: item.specification,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.qty),
                convValue: 1,
                unitPrice: parseInt(item.baseAmount),
                amount: parseInt(item.amount),
                baseAmount: parseInt(item.amount)
            }));

            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                requisitionNo: ReqNo,
                type: "Inventory",
                reqType: "Purchase Request",
                contractNo: formData?.quatotionNo,
                description: formData?.description,
                requestor: formData?.requestor,
                requisitionDate: currentDatetimeUTC,
                deliveryDate: formData.deliveryDate,
                currencyCode: formData?.currencyCode,
                exchangeRate: formData?.exchangeRate,
                department: selectedDepartment?.value?.description,
                remarks: formData?.remarks,
                costCenter1: formData.costCenter1?.label,
                costCenter2: formData.costCenter2?.label,
                costCenter3: formData.costCenter3?.label,
                costCenter4: formData.costCenter4?.label,
                deliveryPoint: formData?.deliveryPoint,
                location: formData?.location,
                contactPerson: formData?.contactPerson,
                contatNo: formData?.contatNo,
                projectCode: formData?.taskCode,
                prDetails: prDetailsData,
                modifiedBy: userId,
                baseAmount: totalAmount,
                amount: totalAmount,
                status: 0,
                approvalUser: selectedLocation?.label,
            };
            const response = await apiService.put("UPDATE_PR", userData);
            if (response?.data?.statusCode === 201) {
                toast.success(`${ReqNo} Update successfully`);
                const userData2 = {
                    docType: "Purchase Requisition",
                    docId: ReqNo,
                    status: 0,
                    remarks: formData?.remarks,
                };
                const response2 = await apiService.put("UPDATE_DOC_AUTHORIZATION_STATUS", userData2);
                handleReset()
                navigate("/inventory/Requisition/Listing")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'contatNo') && value.length > 16) {
            return;
        }
        if (name === 'requisitionDate' || name === 'deliveryDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleDepChange = (selectedOption) => {
        setSelectedDepartment(selectedOption)
    };
    const handleItemTypeChange = (selectedOption) => {
        setFormData({ ...formData, itemType: selectedOption });
    };
    const handlelocChange = (selectedOption) => {
        setSelectedLocation(selectedOption)
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handleCost1 = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    }
    const handleCost2 = (selectedOption) => {
        setFormData({ ...formData, costCenter2: selectedOption });
    }
    const handleCost3 = (selectedOption) => {
        setFormData({ ...formData, costCenter3: selectedOption });
    }
    const handleCost4 = (selectedOption) => {
        setFormData({ ...formData, costCenter4: selectedOption });
    }
    const handleReset = () => {
        setLoader(false)
        setFormData({
            itemType: "",
            quatotionNo: "",
            remarks: "",
            requestor: "",
            currencyCode: "",
            department: "",
            costCenter1: "",
            costCenter2: "",
            costCenter3: "",
            costCenter4: "",
            deliveryPoint: "",
            location: "",
            contactPerson: "",
            contatNo: "",
            taskCode: "",
        });
        dispatch(clearRows());
        setSelectedDepartment([])
        setSelectedLocation([])
    }
    const handleFileSelect = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setAttachmentList((prevList) => [
                ...prevList.map((attachment) =>
                    attachment.id === id ? { ...attachment, fileName: file.name, file: file } : attachment
                ),
                { id: Date.now(), fileName: '', remarks: '' },
            ]);
        }
    };
    const handleView = (file) => {
        if (file && file instanceof File) {
            setPreviewImage(URL.createObjectURL(file));
            setPreviewModal(true);
        } else {
            console.error("No valid file to preview");
        }
    };
    const handleDelete = (id) => {
        const updatedList = attachmentList.filter((attachment) => attachment.id !== id);
        setAttachmentList(updatedList);
    };
    return (
        <div className='p-4'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Purchase Requisition</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div className='col center'>
                        {itemData?.eye === true ? " " :
                            <>
                                {location.state ? (
                                    <>{loader === true ?
                                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                        <button className='mainbtn ' onClick={UpdatePR} type="submit">
                                            <FaSave className='center mt-1 mx-2' /> <div>Update</div>
                                        </button>
                                    }
                                    </>
                                ) : (
                                    <>{loader === true ?
                                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                        <button className='mainbtn ' onClick={CreatePR} type="submit">
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                        </button>
                                    }
                                    </>
                                )}
                            </>}
                    </div>
                    {itemData?.eye === true ? " " :
                        <button className='mainbtnRed' onClick={() => handleReset()}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    }
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Requisition Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ReqNo" className="form-label  pt-2 start">Requisition No</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={ReqNo}
                                    name="ReqNo"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryDate" className="form-label  pt-2 start">Delivery Date</label>
                                {itemData?.eye === true ?
                                    <input
                                        type='datetime' min={currentDatetimeUTC}
                                        className='form-Input w-70' disabled
                                        value={moment(formData?.deliveryDate || currentDatetimeUTC).format('DD-MMM-YYYY')}
                                    />
                                    :
                                    <input
                                        className='form-Input w-70'
                                        type='datetime-local' min={currentDatetimeUTC}
                                        value={formData.deliveryDate}
                                        name="deliveryDate"
                                        onChange={handleInputChange}
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryDate" className="form-label  pt-2 start">Location *</label>
                                {itemData?.eye === true ? (
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        isDisabled
                                        value={selectedLocation}
                                        className="w-70 p-0"
                                    />
                                ) : (
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        value={selectedLocation}
                                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        className="w-70 p-0"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="requisitionDate" className="form-label  pt-2 start">Date </label>
                                {itemData?.eye === true ?
                                    <input
                                        className='form-Input w-70' disabled
                                        type='datetime'
                                        value={moment(formData.requisitionDate).format('DD-MMM-YYYY')}
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        type='datetime-local' disabled
                                        value={formData.requisitionDate}
                                        name="requisitionDate"
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Delivery Point</label>
                                {itemData?.eye === true ?
                                    <input disabled
                                        className="form-Input w-70"
                                        value={formData.deliveryPoint}
                                    /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="subcatname"
                                        value={formData.deliveryPoint}
                                        onChange={handleInputChange}
                                        name="deliveryPoint"

                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Copy Request</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />}
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Currency</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled value={SelectedCurrency} /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        isSearchable={false}
                                        value={SelectedCurrency}
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="location" className="form-label  pt-2 start">Location *</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.location} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        name="location"
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Quotation No</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        options={staticOptions}
                                        onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                    />}
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="exchangeRate" className="form-label  pt-2 start">Exchange Rate</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.exchangeRate} className="form-Input w-70" /> :
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        id="exchangeRate"
                                        value={formData.exchangeRate}
                                        onChange={handleInputChange}
                                        name="exchangeRate"
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="contactPerson" className="form-label  pt-2 start">Contact Person</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.contactPerson} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="contactPerson"
                                        value={formData.contactPerson}
                                        onChange={handleInputChange}
                                        name="contactPerson"
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="requestor" className="form-label  pt-2 start">Requester</label>
                                {itemData?.eye === true ?
                                    <input disabled className="form-Input w-70" value={formData.requestor} /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="requestor"
                                        value={formData.requestor}
                                        onChange={handleInputChange}
                                        name="requestor"

                                    />}
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Item Type</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        value={formData.itemType}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        options={itemType}
                                        onChange={(selectedOption) => handleItemTypeChange(selectedOption)}
                                    />}
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="contatNo" className="form-label  pt-2 start">Contact No</label>
                                {itemData?.eye === true ?
                                    <input disabled className="form-Input w-70" value={formData.contatNo} /> :
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        id="contatNo"
                                        onWheel={(e) => e.target.blur()}
                                        value={formData.contatNo}
                                        onChange={handleInputChange}
                                        name="contatNo"
                                    />}
                            </div>
                            <div className="col d-flex justify-content-between">
                                <>
                                    <label htmlFor="selectedDepartment" className="form-label  pt-2 start">Department</label>
                                    {itemData?.eye === true ?
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                padding: 9
                                            })}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                    color: state.isFocused ? '#000 ' : '#000',
                                                    ':hover': {
                                                        backgroundColor: '#F5F5F5',
                                                        color: '#000 ',
                                                    },
                                                }),
                                            }}
                                            className="w-70 p-0" isDisabled value={selectedDepartment} /> :
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                padding: 9
                                            })}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                    color: state.isFocused ? '#000 ' : '#000',
                                                    ':hover': {
                                                        backgroundColor: '#F5F5F5',
                                                        color: '#000 ',
                                                    },
                                                }),
                                            }}
                                            className="w-70 p-0"
                                            value={selectedDepartment}
                                            options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                            onChange={(selectedOption) => handleDepChange(selectedOption)}
                                        />}
                                </>
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="department" className="form-label  pt-2 start">Task Code</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        defaultValue={formData.taskCode}
                                    // onChange={handleDepChange}
                                    // options={taskCode?.map((dep) => ({ value: dep, label: dep?.name }))}
                                    />}
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Remarks</label>
                                {itemData?.eye === true ?
                                    <input disabled
                                        className="form-Input w-70"
                                        value={formData.remarks}
                                    /> :
                                    <input
                                        name="remarks"
                                        type='text'
                                        className="form-Input w-70"
                                        id="subcatname"
                                        value={formData.remarks}
                                        onChange={handleInputChange}
                                    />}
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center" onClick={() => handleOpen()} >
                                {'Attachment'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setCostTab(!CostTab)}>
                    <div className='h6 pt-1'>Cost Center</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between">
                            <div className="col  d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property Group</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled value={formData.costCenter1} /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0"
                                        value={formData.costCenter1}
                                        onChange={handleCost1}
                                        options={cost1?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled value={formData.costCenter2} /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" value={formData.costCenter2}
                                        onChange={handleCost2}
                                        options={cost2?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Department</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled value={formData.costCenter3} /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" value={formData.costCenter3}
                                        onChange={handleCost3}
                                        options={cost3?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Outlet</label>
                                {itemData?.eye === true ?
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" isDisabled value={formData.costCenter4} /> :
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        className="w-70 p-0" value={formData.costCenter4}
                                        onChange={handleCost4}
                                        options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`cost-center-section`}>
                <div className="mt-3 cursor-pointer slide-header">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Requisition
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>
                </div>
            </div>
            <div className={`slide-content open`}>
                <div className={`grid-1 border-box2 p-3`}>
                    {gridVisibility[0] && (
                        <RequisitionGrid onRequisitionData={handleRequisitionData} itemData={itemData} selectedLocation={selectedLocation} />
                    )}
                    {gridVisibility[1] && (
                        <AuthorizationGrid
                            handleAuthorization={handleAuthorization}
                            usersDetails={usersDetails}
                            setUser={setUser}
                            itemData={itemData}
                        />
                    )}
                    {gridVisibility[2] && (
                        <div className="grid-3 border">
                            <MessageGrid handleMessage={handleMessage} />
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div>
                    <Modal show={attachmentModal} size="lg" onHide={handleClose} >
                        <Modal.Header closeButton>
                            <Modal.Title>Attachments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="docNp" className="form-label  pt-2 start">Document No</label>
                                <input disabled value={ReqNo} className="form-Input w-70" />
                            </div>
                            <div className="pt-2">
                                <table>
                                    <thead className="dynoTable">
                                        <tr>
                                            <th>File Name</th>
                                            <th>Remarks</th>
                                            <th>Upload</th>
                                            <th>View</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attachmentList?.map((attachment, index) => (
                                            <tr key={attachment.id}>
                                                <td className='m-0 p-0'><input className='p-0' type='text' disabled value={attachment.fileName} style={{ width: "320px" }} /></td>
                                                <td>
                                                    <input type="text" value={attachment.remarks}
                                                        onChange={(e) => {
                                                            const updatedList = attachmentList?.map((item) =>
                                                                item.id === attachment.id ? { ...item, remarks: e.target.value } : item
                                                            );
                                                            setAttachmentList(updatedList);
                                                        }}
                                                        style={{ width: '345px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <button className='mainbtn ' onClick={() => document.getElementById(`fileInput-${attachment.id}`).click()} type="submit"><div>Upload</div></button>
                                                    <input
                                                        type="file" accept="image/*"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileSelect(e, attachment.id)}
                                                        id={`fileInput-${attachment.id}`}
                                                    />
                                                </td>
                                                <td><button className='mainbtnRed ' onClick={() => handleView(attachment.file)} disabled={!attachment.fileName}>
                                                    <div>View</div></button></td>
                                                <td>
                                                    <button onClick={() => handleDelete(attachment.id)} disabled={!attachment.fileName} className='mainbtnSecondary'>
                                                        <div>Delete</div></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='mainbtn '>Attach</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={previewModal} size="lg" onHide={() => setPreviewModal(false)}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Image Preview</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {previewImage && <img src={previewImage} alt={previewImage} style={{ width: '100%', objectFit: 'cover' }} />}
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default PurchaseRequisition