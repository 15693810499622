import React, { useEffect, useState, Component, useCallback, useMemo, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Search from "../../../assets/images/search.png";
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import ApiService from '../../../services/apiService';
import Select from 'react-select';
import moment from 'moment/moment';
import { ReactComponent as List } from '../../../assets/images/svgIcons/list.svg';
import { AddToOrder, clearOrder } from '../../../../Redux/actions/orderEditAction';
import { useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { clearCart } from '../../../../Redux/actions/cartAction';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import { invokeBroadCastMessage } from '../../../services/signalRService';
import PasscodePopup from '../PassCode/passCodeModal';
import { AddComboToCart, clearComboCart } from '../../../../Redux/actions/comboAction';

const SaleHistory = () => {
    const [show, setShow] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedFilter, setSelectedFilter] = useState('4');
    const [selectedItems, setSelectedItems] = useState([]);


    const [status, setstatus] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [RefundModal, setRefundModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        remarks: "",
        name: "",
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false); // To manage authentication state
    const [showPasscodePopup, setShowPasscodePopup] = useState(false); // To manage modal visibility

    const handlePasscodeSubmit = (isVerified) => {
        if (isVerified) {
            setIsAuthenticated(true);
            setShowPasscodePopup(false);
            UpdateOrderData(); // Proceed with the update after successful verification
        }
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const gridRef = useRef();
    useEffect(() => {
        const handleOutsideClick = (event) => {
            const gridElement = document.querySelector('.ag-root-wrapper-body');
            if (gridElement && !gridElement.contains(event.target)) {
                setSelectedRowData(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [selectedRows]);
    const closeRefundModal = (e) => {
        setRefundModalOpen(false);
    }
    const openRefundModal = (e) => {
        setRefundModalOpen(true);
    }
    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        if (selectedRows.length === 1) {
            const selectedRowData = selectedRows[0];
            setSelectedRows(selectedRows[0]);
        }
    }, [])
    const initiateUpdateOrder = () => {
        if (!isAuthenticated) {
            closeRefundModal()
            setShowPasscodePopup(true); // Show the passcode popup if not authenticated
        } else {
            UpdateOrderData(); // Proceed directly if authenticated
        }
    };

    const UpdateOrderData = async () => {
        try {
            const itemNumber = selectedRows?.id.replace(/^ORD-/i, '');

            const dataReq = {
                id: parseInt(itemNumber),
                paymentStatus: 3,
                orderStatus: 7,
                amountRefunded: parseInt(selectedRows?.orderAmount),
                refundReason: formData?.remarks,
                loggedInUserId: userManagement.getUserRole() || 'Guest'
            };

            const apiService = new ApiService(); // Initialize your ApiService instance

            // Make API request to update order
            const res = await apiService.put("UPDATE_ORDER", dataReq);

            if (res) {
                toast.success("Order Refunded Successfully");
                invokeBroadCastMessage(`Order Refunded By Cashier. OrderNo#${itemNumber.toString()}`);
                setRefundModalOpen(false);
                window.location.reload(false); // Refresh the page
            } else {
                toast.warning("Something went wrong with updating your order. Please try again.");
            }
        } catch (error) {
            console.error("Error updating order:", error);
            toast.error("Failed to update order. Please try again later.");
        }
    };

    const mapPaymentType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Pending";
            case 1:
                return "Authorized";
            case 2:
                return "Paid";
            case 3:
                return "Refunded";
            case 4:
                return "Failed";
            default:
                return "Unknown Order Type";
        }
    };
    const mapOrderStatus = (orderStatus) => {
        switch (orderStatus) {
            case 0:
                return "New Order";
            case 1:
                return "Accepted";
            case 2:
                return "Preparing";
            case 3:
                return "Ready For Delivery";
            case 4:
                return "Ready For Pick up";
            case 5:
                return "Out For Delivery";
            case 6:
                return "Delivered";
            case 7:
                return "Cancelled";
            case 8:
                return "On-Hold";
            default:
                return "Unknown Order Status";
        }
    };
    const ThirdFilter = [
        { value: '0', label: 'Day' },
        { value: '1', label: 'Week' },
        { value: '2', label: 'Month' },
        { value: '3', label: 'Yearly' },
        { value: '4', label: 'All Time' },
    ];
    const ItemSourse = [
        { value: '0', label: 'New Order' },
        { value: '2', label: 'Preparing' },
        { value: '3', label: 'Ready For Delivery' },
        { value: '4', label: 'Ready For Pickup' },
        { value: '6', label: 'Completed' },
        { value: '7', label: 'Canceled' },
    ];
    useEffect(() => {
        GetOrders();
    }, [status]);
    const GetOrders = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?OrderStatus=${status}`;
            let res = await apiService.getApiParamater("GET_ORDERS", parameters);
            if (Array.isArray(res?.data?.result)) {
                const filteredOrders = res.data.result.filter(order => order?.orderStatus !== 8);
                filteredOrders.reverse();
                setOrderData(filteredOrders);
            } else {
                console.error("Invalid data format received from the API");
            }
        } catch (error) {
            console.error("Error fetching Orders:", error);
        }
    };
    const handleFilterChange = async (selectedFilter) => {
        setSelectedFilter(selectedFilter?.value);
    }
    const mapOrderType = (orderType) => {
        switch (orderType) {
            case 0:
                return "Dine-In";
            case 1:
                return "Take-Away";
            case 2:
                return "Delivery";
            case 3:
                return "Online Order";
            case 4:
                return "Drive through";
            case 5:
                return "Catering";
            case 6:
                return "Room Service";
            case 7:
                return "Pickup";
            case 8:
                return "Curbside Pickup";
            default:
                return "Unknown Order Type";
        }
    };
    const handleEditOrder = (data) => {
        dispatch(clearOrder())
        dispatch(clearCart())
        dispatch(clearComboCart())

        const orderItems = data?.orderItems;
        const orderItemsMeta = data?.orderItemsMeta;

        const orderItemsMetaMap = {};

        orderItemsMeta.forEach(orderItemMeta => {
            const menuItemId = orderItemMeta.menuItemId;
            if (!orderItemsMetaMap[menuItemId]) {
                orderItemsMetaMap[menuItemId] = [];
            }
            orderItemsMetaMap[menuItemId].push(orderItemMeta);
        });

        orderItems.forEach(orderItem => {
            const menuItemId = orderItem.itemId;
            if (orderItemsMetaMap[menuItemId]) {
                orderItem.orderItemsMeta = orderItemsMetaMap[menuItemId];

                orderItem.selectedModifier = [];
                orderItem.selectedAdOn = [];
                orderItem.selectedVariant = null;

                orderItemsMetaMap[menuItemId].forEach(metaItem => {
                    switch (metaItem.metaType) {
                        case 2:
                            orderItem.selectedAdOn.push(metaItem);
                            break;
                        case 3:
                            orderItem.selectedModifier.push(metaItem);
                            break;
                        case 4:
                            orderItem.selectedVariant = metaItem;
                            break;
                    }
                });
            }
        });
        orderItems?.map((item) => {
            const mergedData = {
                selectedAdOn: item?.selectedAdOn,
                selectedModifier: item?.selectedModifier,
                selectedVariant: item?.selectedVariant,
                specialInstructions: item?.kitchenInstructions,
                itemData: item,
                quantity: item?.quantity,
            };

            dispatch(AddToOrder(mergedData))

        })
        
        data?.orderCombos.map((data) => {
            let itemdata = ({
                name: data.comboName,
                description: data?.description,
                price: data?.comboPrice,
                tax: data?.tax,
                discount: data?.discount,
                active: data?.active,
                imageUrl: data?.imageUrl,
                quantity: data?.quantity,
                comboItems: data?.comboItems?.map(comboItem => ({
                    itemId: comboItem.itemId,
                    metaItemIds: [],
                })),
                id: data.comboId,
                loggedInUserId: orderItems?.loggedInUserId
            });
            dispatch((AddComboToCart(itemdata)))
            itemdata = ""
        })

    }
    const handleShow = (field, data) => {

        if (data.paymentStatus === 0) {
            handleEditOrder(data)
            
            navigate('/Cashier/UpdateOrder', { state: data });
            console.log("Proceed Data ", data)
        }
        else {
        dispatch(clearComboCart())

            navigate(`/Cashier/orderDetails/${field}`, { state: field });
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.isValid() ? date.format("YYYY-MM-DD") : null;
    }
    const mapOrderSource = (orderSource) => {
        switch (orderSource) {
            case 0:
                return "Website";
            case 1:
                return "Mobile App";
            case 2:
                return "Phone";
            case 3:
                return "In Person";
            case 4:
                return "Third Party Delivery";
            case 5:
                return "Cashier";
            case 6:
                return "Waiter";
            default:
                return "Unknown Order Source";
        }
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const columnDefs = [
        { headerName: 'Order Number', field: 'id', filter: true, floatingFilter: true, minWidth: 200 },
        {
            headerName: 'Order Date',
            field: "orderDate",
            filter: true,
            minWidth: 200,
            floatingFilter: true,
            filter: 'agDateColumnFilter',
            valueFormatter: dateFormatter, // Custom value formatter for date
            comparator: dateComparator, // Custom comparator for date filtering
        },
        {
            field: "orderType",
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapOrderType(params.value),
        },
        { headerName: 'Customer Name', field: 'customerName', filter: true, floatingFilter: true, minWidth: 200 },
        {
            headerName: 'Payment Status',
            field: "paymentStatus",
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapPaymentType(params.value),
            minWidth: 200
        },
        {
            field: "orderStatus",
            filter: true,
            floatingFilter: true,
            minWidth: 200,
            cellRenderer: (params) => mapOrderStatus(params.value),
        },
        {
            field: "orderSource",
            filter: true,
            floatingFilter: true,
            cellRenderer: (params) => mapOrderSource(params.value),
        },
        // { headerName: 'Order Taker', field: 'loggedInUserId', filter: true, floatingFilter: true, minWidth: 240 },
        { headerName: 'Amount', field: 'orderAmount', filter: true, floatingFilter: true, minWidth: 200 },
        {
            headerName: 'Action', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field, data) {
                    handleShow(field, data)
                },
            },
        },
    ]
    const handleStatusChange = (selectedOption) => {
        setstatus(selectedOption?.value);
    }
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    }
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);
    const onBtExcelExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel();
    }, []);
    const transformedOrders = orderData?.map(order => {
        return {
            ...order,
            id: `ORD-${order.id}`,
            orderAmount: Number(order.orderAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        };
    });
    const VoidItem = () => {
        handleEditOrder(selectedRows)
        navigate('/Cashier/UpdateOrder', { state: selectedRows });

    }
    return (
        <>
            <div className=' d-flex justify-content-between  m-5'>

                <div className='col-8 d-flex gap-3' >
                    <Select
                        defaultValue={status}
                        className='col-3'
                        onChange={handleStatusChange}
                        options={ItemSourse}
                    />
                </div>
                <div className='d-inline-flex gap-3 end col-4'>
                    <div className=''>
                        {selectedRows.id ?
                            <div className='d-inline-flex gap-3'>
                                {selectedRows?.paymentStatus === 3 || selectedRows?.paymentStatus === 0 ? " "
                                    :
                                    < div onClick={openRefundModal} className='addItem-btn'>
                                        Refund {selectedRows?.id}
                                    </div>
                                }
                                {selectedRows?.paymentStatus === 3 || selectedRows?.paymentStatus === 0 ? " "
                                    :

                                    <div onClick={VoidItem} className='addItem-btn'>
                                        Void
                                    </div>
                                }
                            </div>
                            : <></>
                        }
                    </div>
                    <div className='gap-2 d-flex'>
                        <button onClick={() => onBtExcelExport()} className='btn-border'>Excel</button>
                        <button onClick={onBtnCSVExport} className='btn-border'>CSV</button>
                    </div>
                </div>
            </div >
            <div className='px-5'>
        <div className='brdor  mt-3' style={{ }}>

                <div className="ag-theme-alpine" style={{ height: 700 }}>

                    <AgGridReact
                        ref={gridRef}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={columnDefs}
                        pagination={true}
                        rowData={transformedOrders}
                        onSelectionChanged={onSelectionChanged}
                        frameworkComponents={frameworkComponents}
                        getRowStyle={params => {
                            if (params.data === selectedRowData) {
                                return { background: 'lightblue' }; // Apply the style only to the selected row
                            }
                            return null; // Return null for all other rows
                        }}
                    />

                </div>
                </div>
            </div>
            {showPasscodePopup && <PasscodePopup onSubmit={handlePasscodeSubmit} />}

            <Modal size="md"
                show={RefundModal} onHide={closeRefundModal}
                onRequestClose={closeRefundModal}
                contentLabel='Refund Modal'
                style={customStyles} closeButton
            >
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Order Refund Reason </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <label htmlFor="validationCustom01" className="form-label">Refund Reason</label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='AddBtn' onClick={initiateUpdateOrder}>Save</button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        clicked(value, data);
    }

    render() {
        const { paymentStatus, orderStatus } = this.props.data;

        return (
            <button onClick={this.btnClickedHandler} className={`delbtn ${paymentStatus === 0 ? 'proceed-btn' : ''}`}>
                {orderStatus == 7 || !paymentStatus == 0 ? 'View' : 'Proceed'}
            </button>
        )
    }
}
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};
export default SaleHistory;
