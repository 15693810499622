import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useMemo, useRef, useState, Component } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ApiService from '../../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";

const CurrencyGrid = ({ data, addson, GetAddsOn, GetModifier, onEditCurrency }) => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState();
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const gridRef = useRef();
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = (field, action) => {
        
        if (action === 'edit') {
            const selectedRow = data.find(row => row.id === field);
            onEditCurrency(selectedRow);
        } else {
            setId(field);
            setShow(true);
        }
    };
    const handleEdit = (data) => {
        
        // navigate("admin/master/CurrencyManagement", { state: data });
        onEditCurrency(data);
    };
    const handleDelete = async (id) => {
        try {
            const apiService = new ApiService();
            let body = { id: parseInt(id) };

            if (data) {
                let res = await apiService.delete("DELETE_CURRENCY", body);
                if (res?.data?.statusCode === 204) {
                    setShow(false);
                    GetModifier();
                    toast.success(res?.data?.message);
                } else {
                    toast.warning(res?.data?.message);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const [colDefs, setColDefs] = useState([
        { field: "name", filter: true, floatingFilter: true, minWidth: 360 },
        { field: "code", filter: true, floatingFilter: true, minWidth: 360 },
        { field: "conversionRate", filter: true, floatingFilter: true, minWidth: 350 },
        { field: "default", filter: true, floatingFilter: true, minWidth: 350 },
        {
            headerName: 'Action',
            field: 'id',
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handleEdit: handleEdit,
                clicked: function (field, action) {
                    
                    handleShow(field, action);
                },
            },
        },
    ]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };

    const onCellClicked = useCallback((event) => {
        if (event.column.getColId() !== 'id') {
            const selectedRowData = event.data;
            setSelectedRows(selectedRowData);
            onEditCurrency(selectedRowData); // Pass the selected row data to the parent
        }
    }, [onEditCurrency]);


    return (
        <>
            <div className='brdor  mt-3' style={{}}>

                <div className="ag-theme-alpine mt-5" style={{ height: 400, backgroundColor: "#5B93FF" }}>
                    <AgGridReact
                        rowData={data || addson}
                        columnDefs={colDefs}
                        enableFilter={true}
                        floatingFilter={true}
                        frameworkComponents={frameworkComponents}
                        pagination={true}
                        ref={gridRef}
                        rowSelection={'single'}
                        onCellClicked={onCellClicked}
                    />
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleDelete(id)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler(action) {
        const { value, clicked } = this.props;
        
        clicked(value, action);
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        
        handleEdit(data);
    }
    render() {
        return (
            <div className='d-flex gap-4'>
                <div onClick={this.btnEditClickedHandler} className='center'><FaEdit size={25} /></div>
                {/* <button onClick={() => this.btnEditClickedHandler('edit')} className="editbtn">Edit</button> */}
                <button onClick={() => this.btnClickedHandler('delete')} className="delbtn">Delete</button>
            </div>
        );
    }
}

export default CurrencyGrid;
