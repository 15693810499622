import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Search from "../../../assets/images/search.png"
import { useNavigate } from 'react-router-dom'

const PayLater = () => {

    let navigate = useNavigate();

    const rowData = [
        { orderNum: 1, date: '2022-01-01', CustomerName: 'Saud Ahmad', amount: 1000, kot: 152, items: 2, action: "continue" },
        { orderNum: 2, date: '2022-01-02', CustomerName: 'Saad ', amount: 1200, kot: 153, items: 3, action: "continue" },
        // Add more rows as needed
    ];
    const columnDefs = [
        { headerName: 'Order Num', field: 'orderNum', filter: true, floatingFilter: true, minWidth: 250 },
        { headerName: 'Date', field: 'date', filter: true, floatingFilter: true, minWidth: 250 },
        { headerName: 'Customer Name', field: 'CustomerName', filter: true, floatingFilter: true, minWidth: 250 },
        { headerName: 'KOT', field: 'kot', filter: true, floatingFilter: true, minWidth: 250 },
        { headerName: 'Items', field: 'items', filter: true, floatingFilter: true, minWidth: 250 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingFilter: true, minWidth: 220 },
        { headerName: 'Action', field: 'action', filter: true, floatingFilter: true, minWidth: 204 },
    ];
    const handleViewDetails = (data) => {
        console.log('View Details:', data);
    };
    const handlehandoverform = () => {
        navigate('/Cashier/DayOpeningForm');
    };
    return (<>
        <div className='row d-flex justify-content-between  m-5'>
            <div className='col-lg-4 col-sm-4'>
                <div>
                    <img className='searchIcon ' search-icon src={Search} />
                    <input className='form-control' type='search' />
                </div>
            </div>
            <div className='col-lg-2 col-sm-4 d-inline-flex gap-3'>
                <div className='btn-border'>
                    PDF
                </div>
                <div className='btn-border'>
                    CSV
                </div>
            </div>
        </div>
        <div className='px-5'>
        <div className='brdor  mt-3' style={{ }}>

            <div className="ag-theme-alpine" style={{}}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    domLayout='autoHeight'
                    rowHeight={60}  // Adjust row height as needed
                    pagination={true}
                    suppressRowClickSelection={true}
                />
            </div>
</div>
        </div>
    </>
    );
};

export default PayLater;
