import { React, useState } from 'react';
import '../Menu/Menu Card/menuCard.css'
import { useNavigate } from 'react-router-dom';
import 'bootstrap'
import Pizza from "../../assets/images/P1.png"
import Modal from 'react-modal';
import AddtoCart from '../Menu/cartButton/cartButton';
import { connect } from "react-redux";
import ComboModal from './popup/comboModal';
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const ComboCard = ({ val, Currency }) => {

    let navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [CartValue, setCartValue] = useState("");

    const openModal = (e) => {
        setCartValue(e)
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const truncatedAddress = val?.name?.length > 20 ? val?.name.substring(0, 20) + '...' : val?.name;
    const truncatedDescription = val?.description?.length > 20 ? val?.description.substring(0, 50) + '...' : val?.description;
    return (
        <>
            <div className="menu-item-card my-5">
                <div className="menu-item-header p-0">
                    <div className="tagfont">
                        {val?.discount || "0"} % Off
                    </div>
                </div>
                <div className="menu-item-details justify-content-center IMG-BG">
                    <div><img src={val?.imageUrl === null || "" ? Pizza :
                        IMAGE_URL + val?.imageUrl} className='item-pic' /></div>
                </div>
                <div className="main-color p-lg-3">
                    <div className="ItemName" title={val?.name}>{truncatedAddress || "--"}</div>
                    <div className="item-des mt-1" title={val?.description}>{truncatedDescription || "--"}</div>
                    <div className="d-flex center gap-3 mt-1">
                        <div className="item-price ">
                            {Currency.currency.name}    {val?.price?.toLocaleString('en-US')}
                        </div>
                    </div>
                    <div className="row p-1 mt-3 center">
                        <AddtoCart openModal={openModal} val={val} />
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel='Cart Modal'
                value={CartValue}
            >
                <ComboModal CartValue={CartValue} closeModal={closeModal} />
            </Modal >
        </>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ComboCard);