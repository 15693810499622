import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import LocationMasterGrid from './grid';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { ClipLoader } from 'react-spinners';
const LocationMaster = () => {
    const apiService = new ApiService()
    const [cat, setCat] = useState([])
    const [rowData, setRowdata] = useState([])
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [load, setLoad] = useState(false);

    const [formData, setFormData] = useState({
        recordId: "",
        location: [],
        description: ""
    });

    useEffect(() => {
        getcategory()
        getLocation()
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            getLocation();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Location Master', 'edit');
    // const canCreateMaster = checkPermission('Location Master', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;

    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            setFormData({
                recordId: clickedRowData?.recordId || selectedRow?.recordId,
                description: clickedRowData?.description || selectedRow?.description,
            });
            const selectedPUM = cat?.find(p => p?.description === clickedRowData?.location || selectedRow?.location);
            if (selectedPUM) {
                setFormData(prevState => ({
                    ...prevState,
                    location: { label: selectedPUM.description, value: selectedPUM }
                }));
            }
        }
    };
    const getcategory = async (e) => {
        try {
            const res = await apiService.get('GET_WAREHOUSE_CAT_MASTER')
            setCat(res?.data?.result)
        } catch (error) {
            console.log(error)
        }

    }
    const getLocation = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            setRowdata(response?.data?.result)
        } catch (error) {
            console.log(error)
        }
    }
    const handleSave = async (e) => {
        setLoad(true)
        if (!formData.location || !formData.description) {
            if (!formData.location) {
                toast.warning("Enter Location Category")
            }
            if (!formData.description) {
                toast.warning("Enter Location")
            }
            setLoad(false)
            return;
        }
        try {
            let body = {
                location: formData?.location?.label,
                description: formData?.description,
                isActive: true
            }
            const res = await apiService.post('CREATE_WAREHOUSE_MASTER', body)
            if (res?.data?.statusCode == 200 || res?.data?.statusCode == 201) {
                toast.success("Location created successfully");
                handleClearFilters()
            } else {
                setLoad(false)
                toast.error(res?.data?.message);
            }
        } catch (error) {
            setLoad(false)
            console.log(error)
        }

    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleCatChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    };
    const updateFormData = async () => {
        try {
            setLoad(true)
            let body = {
                recordId: formData?.recordId,
                location: formData?.location?.label,
                description: formData?.description,
            }
            const res = await apiService.put(`UPDATE_WAREHOUSE_MASTER`, body);
            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                handleClearFilters()
            } else {
                console.error(res?.data?.message);
                setLoad(false)
            }
        } catch (error) {
            console.error('Error updating form data:', error);
            setLoad(false)
        }
    };
    const handleClearFilters = () => {
        getcategory()
        getLocation();
        setFormData({
            recordId: "",
            location: [],
            description: ""
        });
        setLoad(false)
        setSelectedRow(null)
    };
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Location Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className=''>
                        {selectedRow ? (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canEditMaster && (
                                        <button className='mainbtn ' onClick={updateFormData}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                    )}
                                </>
                            }
                            </>
                        ) : (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canCreateMaster === true ?
                                        <button className='mainbtn ' onClick={handleSave}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                        <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                        </button>
                                    }
                                </>
                            }
                            </>
                        )}
                    </div>
                    <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-3'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className="form-group row ">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Record ID</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                name="recordId"
                                value={formData.recordId}
                                onChange={handleInputChange}
                                required
                                disabled
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Location</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                disabled={canCreateMaster === true ? false : true}
                                id="validationCustom01"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Category</label>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                className="w-70 p-0"
                                value={formData.location}
                                onChange={handleCatChange}
                                options={cat?.map((vat) => ({ value: vat, label: vat?.description }))}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg pt-3'>
                <LocationMasterGrid rowData={rowData} getLocation={getLocation} canEditMaster={canEditMaster}
                    setSelectedRow={setSelectedRow} handleEdit={handleEdit} />
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getLocation} />
            </div>
        </div>
    )
}
export default LocationMaster