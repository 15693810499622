import React from 'react'
import { ReactComponent as Location } from '../../assets/images/svgIcons/Location.svg';
import "./costumer.css"
import { useLocation, useNavigate } from 'react-router';
const SearchNav = () => {
    const navigate = useNavigate()
    const thirdParty = () => {
        navigate('/customer/thirdparty');
    }
    const location = useLocation();

    // Check if the pathname is equal to '/customer/thirdparty'
    const isThirdPartyRoute = location.pathname === '/customer/thirdparty';
    return (
        <div className='d-flex justify-content-between inline'>
            <input type="search" class="form-control w-25 p-2" placeholder="Search" />
            {isThirdPartyRoute ? null :
                <div className='center'>
                    <div className='btn btn-primary' onClick={() => thirdParty()}>Third Party</div>
                </div>}
            <div className='d-flex justify-content-end pad-t flx-strt-mbl'>
                <div className='locbox'>
                    <h3 className='Delivr'>Delivering to</h3>
                    <h4 className='delivrloc'>Satellite Town D-Block</h4>
                </div>
                <div className='locationbox'>
                    <Location />
                </div>
            </div>
            
        </div>
    )
}

export default SearchNav