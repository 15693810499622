import { React, useState, useEffect } from 'react'
import ImageUpload from '../menus/addItems/imagUpload';
import "./master.css"
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import CategoryMasterCard from './categoryCard/categorycard';

const AddCategory = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        GetCategory()
    }, []);
    const [formData, setFormData] = useState({
        category: "",
        images: [],
        imageUrl: ""
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const CreateCategory = async () => {

        try {
            if (!formData?.images && !formData?.imageUrl) {
                toast.error("Upload Image");
            } else if (formData?.images) {
                try {
                    const apiService = new ApiService();
                    const formdata = new FormData();
                    for (const image of formData?.images) {
                        if (image.file) {
                            formdata.append('Images', image?.file);
                        }
                    }
                    const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                    if (response?.data?.statusCode === 201) {
                        setFormData({ ...formData, imageUrl: response?.data?.result });

                        handleSubmit(response?.data?.result)
                    }
                    else toast.error(response?.data?.msg)
                } catch (error) {
                    toast.error("Something went wrong with the image. Please try again!");
                }
            }
        } catch (error) {
            console.error('Error fetching category :', error);
        }
    };

    const handleSubmit = async (imgurl) => {
        try {
            let UserId = userManagement.getUserId();
            if (imgurl) {
                let body = {
                    name: formData?.category,
                    imageUrl: imgurl,
                    loggedInUserId: UserId,
                    active: true,
                }

                const apiService = new ApiService();
                let res = await apiService.post('CREATE_CATEGORY', body);
                if (res?.data?.statusCode == 201) {
                    toast?.success("Category Created Successfully")
                    setFormData({
                        category: "",
                        images: [],
                        imageUrl: ''
                    });
                    GetCategory();
                }
            }

        } catch (error) {
            console.error('Error fetching category :', error);
        }
    };
    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    }
    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    }
    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_CATEGORY');
            // const filteredcategory = res?.data?.result?.filter(item => item?.active === true);
            setCategories(res?.data?.result);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };
    return (
        <div className='p-5 container'>
            <div className='catlist'>
                <div className='py-3 row '>
                    <div className="col">
                        <label htmlFor="validationCustom01" className="form-label">Item Category Name </label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="category"
                            value={formData.category}
                            placeholder='Enter Item Category name'
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                <div className='row center py-3 '>
                    <div className='col-10 col6'><ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} /></div>
                    <div className='col-2 d-flex align-self-end col6  pt-3'><button className='addItem-btn' onClick={() => CreateCategory()}>Save</button></div>
                </div>
            </div>
            <div className='catlist mt-5'>
                <div className="row">
                    {categories?.map((item) => (
                        <CategoryMasterCard
                            key={item.id}
                            val={item}
                            GetCategory={GetCategory}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AddCategory