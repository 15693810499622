import { React, useState, useEffect } from 'react'
import "../master.css"
import { toast } from 'react-toastify';
import ApiService from '../../../../services/apiService';
import userManagement from '../../../../services/userManagement';
import { ReactComponent as Edit } from '../../../../../POS/assets/images/svgIcons/Edit.svg';
import { ReactComponent as Delete } from '../../../../../POS/assets/images/svgIcons/Delete.svg';
import { ClipLoader } from "react-spinners";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const KitchenMaster = () => {

    const [kitchen, setKitchen] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        GetKitchen()
    }, []);
    const [formData, setFormData] = useState({
        name: "",
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleClose = () => setShow(false);
    const handleShow = (val) => {

        setSelected(val)
        setShow(true)
    }

    const CreateKitchen = async () => {
        const UserId = userManagement.getUserId();
        setLoading(true)
        try {
            if (!formData?.name) toast.error("Please Enter Kitchen Name")
            else {
                let body = {
                    name: formData?.name,
                    loggedInUserId: UserId
                }
                const apiService = new ApiService();
                let res = await apiService.post('CREATE_KITCHEN', body);
                if (res?.data?.statusCode == 201) {
                    toast?.success("Kitchen Created Successfully")
                    setFormData({
                        name: ""
                    });
                    GetKitchen()
                    setLoading(false)
                }
            }
        } catch (error) {
            console.error('Error fetching Tax data:', error);
            setLoading(false)
        }
        setLoading(false)
    };
    const GetKitchen = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_KITCHEN');
            setKitchen(res.data.result);
        } catch (error) {
            console.error('Error fetching tax data:', error);
        }
    };
    const DeleteKitchen = async () => {

        const apiService = new ApiService();
        let body = {
            id: parseInt(selected?.id)
        }

        let res = await apiService.delete("DELETE_KITCHEN", { data: body });

        if (res?.data?.statusCode === 204 || res?.data?.statusCode ===200) {
            GetKitchen()
            setShow(false)
            toast.success(res?.data?.message);
        }
        else {
            toast.warning('Waiting for menu update');
        }
    }
    return (
        <div className='p-5 container' >
            <div className='catlist'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <label htmlFor="validationCustom01" className="form-label">
                            Kitchen name *
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                        <label className="form-label">
                            (Type area : exp bar , juice counter and cooking area )
                        </label>
                    </div>
                </div>
                <div className="pt-5 end">
                    {loading ? (
                        <div className="addItem-btn center">
                            <ClipLoader loading={loading} size={30} color="#fff" />
                        </div>
                    ) : (
                        <button className='addItem-btn' onClick={() => CreateKitchen()}>Save</button>
                    )}
                </div>
            </div>
            <div className='brderbx  p-5 my-5'>
                <div className='table-responsive'>
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {kitchen?.map((val) => (
                                <tr key={val.id}>
                                    <td>{val.name}</td>
                                    <td>
                                        <Delete onClick={() => handleShow(val)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => DeleteKitchen()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default KitchenMaster