import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import Select from 'react-select';
import ApprovalUserGrid from './grid';
import { toast } from 'react-toastify';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Pagination from '../pagination/pagination';

const ApprovalUser = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [Department, setDepartment] = useState([]);
    const [Active, setActive] = useState(false);
    const [AutoAprove, setAutoAprove] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [Users, serUsers] = useState([]);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const apiService = new ApiService()
    const apiService2 = new ApiService2()
    const [rowData, setRowData] = useState([]);
    const [formData, setFormData] = useState({
        DocType: [],
        UserNo: [],
        UserLevel: "",
        recordId: "",
        Signature: "",
        Department: [],
    })
    const DocType = [
        { value: 'Purchase Requisition', label: 'Purchase Requisition' },
        { value: 'Price Comparison', label: 'Price Comparison' },
        { value: 'Store Requisition', label: 'Store Requisition' },
        // { value: 'Transfer Requisition', label: 'Transfer Requisition' },
        // { value: 'Transfer', label: 'Transfer' },
        { value: 'PO', label: 'PO' },
        { value: 'I Return', label: 'I Return' },
        { value: 'PR', label: 'Purchase Return' },
        { value: 'Quotation', label: 'Quotation' },
        { value: 'GRN', label: 'GRN' },
        { value: 'Invoice', label: 'Invoice' },
        // { value: 'ADJ', label: 'ADJ' },
        { value: 'Issue', label: 'Item Issue' },
        // { value: 'Stock Adjustment', label: 'Stock Adjustment' },
        // { value: 'Opening Stock', label: 'Opening Stock' },
        // { value: 'Assets Transfer', label: 'Assets Transfer' },
        // { value: 'Assets Clearance', label: 'Assets Clearance' },
        // { value: 'Assets Transfer Request', label: 'Assets Transfer Request' },
    ]
    useEffect(() => {
        if (pageSize) {
            fetchApprovalData();
        }
    }, [pageSize]);
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        fetchApprovalData();
    }, []);
    useEffect(() => {
        const item = location.state;
        const selectedUser = Users.find(user => user.name === item?.userNo)
        const selectedDepartment = Department.find(dep => dep.description === item?.department)
        const selectedDocType = DocType.find(doc => doc.value === item?.docType)
        setFormData({
            recordId: item?.rcid || "",
            UserLevel: item?.userLevel || "",
            UserNo: { label: selectedUser?.name, value: selectedUser },
            Department: { label: selectedDepartment?.description, value: selectedDepartment },
            Signature: item?.sigFilePath,
            DocType: { label: selectedDocType?.label, value: selectedDocType?.label }
        });
        setActive(item?.active === 1 ? true : false)
        setAutoAprove(item?.isAutoApprove === 1 ? true : false)
    }, [location?.state]);

    const handleActiveSelection = (e) => {
        setActive(e);
    }
    const handleAutoApprove = (e) => {
        setAutoAprove(e);
    }
    const fetchData = async () => {
        const department = await apiService.get("GET_DEPARTMENT_MASTER");
        setDepartment(department?.data?.result);
        const Users = await apiService2.get("GET_USERS");
        serUsers(Users?.data?.result);
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const fetchApprovalData = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', `?${params.toString()}`);
            setPagination(response.data.paginationData)
            const approvals = response.data.result?.filter(dep => dep.active === 1)
            setRowData(approvals);
        } catch (error) {
            console.error("Error fetching packing data:", error);
        }
    }
    const handleUser = async () => {
        try {
            let body = {
                docType: formData?.DocType?.value,
                userNo: formData?.UserNo?.label,
                userLevel: formData?.UserLevel,
                department: formData?.Department?.label,
                active: Active === true ? 1 : 0 || 1,
                sigFilePath: formData?.Signature,
                isAutoApprove: AutoAprove === true ? 1 : 0,
                createdBy: "string",
                stockTypeName: "string"
            }

            const response = await apiService.post('CREATE_APPROVAL_USER', body);
            if (response?.data?.statusCode === 201) {
                toast.success(response.data.message)
                setFormData({
                    DocType: [],
                    UserNo: [],
                    UserLevel: "",
                    Signature: "",
                    Department: [],
                });
                fetchApprovalData()
            } else {
                toast.warning(response.data.message)
            }

        } catch (error) {
            toast.warning("Something may be wrong try again later!")
        }



    }
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                rcid: formData?.recordId,
                docType: formData?.DocType?.value,
                userNo: formData?.UserNo?.label,
                userLevel: formData?.UserLevel,
                department: formData?.Department?.label,
                active: Active === true ? 1 : 0 || 1,
                sigFilePath: formData?.Signature,
                isAutoApprove: AutoAprove === true ? 1 : 0,
                createdBy: "",
                stockTypeName: "",
                modifiedBy: "",
                poAmount: 0,
            }
            const res = await apiService.put(`UPDATE_APPROVAL_USER`, body);

            if (res?.data?.statusCode === 201) {
                toast.success(res.data.message)
                setFormData({
                    DocType: [],
                    UserNo: [],
                    UserLevel: "",
                    Signature: "",
                    Department: [],
                });
                window.history.replaceState({}, '')
                fetchApprovalData()
            } else {
                toast.warning(res.data.message)
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    }
    const handleDocTypeChange = (selectedOption) => {
        setFormData({ ...formData, DocType: selectedOption });
    }
    const handleDepartmentChange = (selectedOption) => {
        setFormData({ ...formData, Department: selectedOption });
    }
    const handleUsersDrop = (selectedOption) => {
        setFormData({ ...formData, UserNo: selectedOption });
    }
    const handleBackClick = () => {
        window.history.back();
    };
    const handleReset = () => {
        setFormData({
            DocType: [],
            UserNo: [],
            UserLevel: "",
            recordId: "",
            Signature: "",
            Department: [],
        });
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Approval User</h5>
                <div className='d-flex gap-3'>
                    {location.state ? (
                        <button className='mainbtn ' onClick={updateFormData}><FaSave className='center mt-1 mx-2' /><div>Update</div></button>
                    ) : (
                        <button className='mainbtn ' type="submit" onClick={() => handleUser()}><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    )}
                    <button className='mainbtnRed' onClick={handleReset}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Approval Users Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Doc Type</label>
                                <Select
                                    value={formData?.DocType}
                                    options={DocType?.map((doc) => ({ value: doc?.value, label: doc?.label }))}
                                    onChange={handleDocTypeChange}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User No</label>
                                <Select
                                    value={formData.UserNo}
                                    onChange={handleUsersDrop}
                                    options={Users?.map((sup) => ({ value: sup, label: sup?.name }))}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Level</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="UserLevel"
                                    value={formData?.UserLevel}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Department</label>
                                <Select
                                    value={formData.Department}
                                    onChange={handleDepartmentChange}
                                    options={Department?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 d-flex justify-content-between ">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">Signature</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="Signature"
                                    value={formData?.Signature}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4 d-flex justify-content-between gap-3">
                                <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="Active" style={{ width: "20px", height: "20px" }}
                                        name="Active"
                                        checked={Active}
                                        onChange={(e) => handleActiveSelection(e.target.checked)}
                                    />
                                    <label className='px-2'>Active</label>
                                </div>
                                <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="AutoAprove" style={{ width: "20px", height: "20px" }}
                                        name="AutoAprove"
                                        checked={AutoAprove}
                                        onChange={(e) => handleAutoApprove(e.target.checked)}
                                    />
                                    <label className='px-2'>Auto Aprove</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Approval User List</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 ${SupplierTab ? 'slide-in' : 'slide-out'}`}>
                        <ApprovalUserGrid rowData={rowData} fetchApprovalData={fetchApprovalData} />
                        <Pagination pagination={pagination} getItems={fetchApprovalData} pageSize={pageSize} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalUser;
