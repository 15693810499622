import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg';
import ApiServicePOS from '../../../POS/services/apiService';
import Select from 'react-select';

const UserList = () => {
    const [rowData, setRowData] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [totalpages, setTotalPages] = useState();
    const apiServicePos = new ApiServicePOS();
    const [Roles, setRoles] = useState([]);
    const [formData, setFormData] = useState({
        userRole: [],
        userName: "",
        userId: "",
    });
    useEffect(() => {
        getRoles();
    }, []);
    const getRoles = async () => {
        try {
            const res = await apiServicePos.get('GET_ROLE');
            setRoles(res.data.result);
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };
    const [colDefs, setColDefs] = useState([
        { headerName: "User Name", field: "name", filter: true, floatingFilter: false },
        // { headerName: "Email", field: "email", filter: true, floatingFilter: false },
        // { headerName: "Employee ID", field: "employeeID", filter: true, floatingFilter: false },
        { headerName: "Role", field: "roleName", filter: true, floatingFilter: false },
        { headerName: "Mobile No", field: "mobileNo", filter: true, floatingFilter: false },
        // { headerName: "Designation", field: "designation", filter: true, floatingFilter: false },
        { headerName: "Department", field: "department", filter: true, floatingFilter: false },
        { headerName: "Company", field: "company", filter: true, floatingFilter: false },
        { headerName: "Branch", field: "branchName", filter: true, floatingFilter: false },
        { headerName: "Status", field: "status", filter: true, floatingFilter: false },
        // {
        //     headerName: "Action", field: "id", filter: true, floatingfilter: true,
        //     cellRenderer: BtnCellRenderer,
        //     cellRendererParams: {
        //         clicked: function (field) {
        //             // handleShow(field)
        //         },
        //     },
        // },
    ])
    useEffect(() => {
        fetchSupplierData();
    }, []);
    useEffect(() => {
        if (pageSize) {
            fetchSupplierData();
        }
        if (formData) {
            fetchSupplierData();
        }
    }, [pageSize, formData]);
    const fetchSupplierData = async (pageChange) => {
        try {
            const params = new URLSearchParams();
            let newPageNo = pagination?.currentPage;
            if (pageChange === "next") {
                newPageNo += 1;
            } else if (pageChange === "prev") {
                newPageNo -= 1;
            }
            params.append('PageNo', newPageNo || 1);
            params.append('UserName', formData.userName || "");
            params.append('Role', formData.userRole.label || "");
            params.append('Id', formData.userId || "");
            const res = await apiServicePos.getApiParamater('GET_USERS', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            const totalPages = Math.ceil(res.data.paginationData.totalRecords / res.data.paginationData.pageSize);
            setTotalPages(totalPages)
        } catch (error) {
            console.error("Error fetching Supplier data:", error);
        }
    };
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = Math.min(totalpages, 4);
        let startPage = Math.max(pagination?.currentPage - Math.floor(totalPageNumbers / 2), 1);
        let endPage = startPage + totalPageNumbers - 1;

        if (endPage > totalpages) {
            endPage = totalpages;
            startPage = endPage - totalPageNumbers + 1;
            if (startPage < 1) {
                startPage = 1;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers?.push(
                <div key={i} className={`${pagination?.currentPage === i ? "pActive" : "pagen"}`}>
                    {i}
                </div>
            );
        }
        return pageNumbers;
    }
    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
    };
    const handleRoleChange = (selectedOption) => {
        setFormData({ ...formData, userRole: selectedOption });
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div className=''>
            <div className='pageBodr  mt-3 p-2'>
                <div className='row d-flex justify-content-between'>
                    <div className="col">
                        <label htmlFor="validationCustom03" className="form-label">UserName</label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            name="userName"
                            value={formData?.userName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="validationCustom03" className="form-label">User ID</label>
                        <input
                            type="number" onWheel={(e) => e.target.blur()}
                            className="form-control"
                            id="validationCustom03"
                            name="userId"
                            value={formData?.userId}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="validationCustom01" className="form-label">User Role</label>
                        <Select
                            // value={formData?.userRole}
                            onChange={(selectedOption) => handleRoleChange(selectedOption)}
                            options={Roles?.map((role) => ({ value: role, label: role?.name }))}
                        />
                    </div>
                </div>
            </div>
            <div className='brdor '>
                <div className="ag-theme-quartz" style={{ height: 625, backgroundColor: "#5B93FF" }}>
                    <AgGridReact
getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                        rowData={rowData}
                        columnDefs={colDefs}
                        enableFilter={true}
                        // floatingFilter={true}
                        rowSelection="multiple"
                        pagination={false}
                        frameworkComponents={frameworkComponents}
                        suppressRowClickSelection={true}
                    />
                </div>
            </div>
            <div className='paginationbrdr'>
                <div className='row'>
                    <div className='col-6'></div>
                    <div className='col-6 d-flex gap-5'>
                        <div className='d-flex'>
                            {pagination?.currentPage === 1 ?
                                <div><button className='prevBtnDisable'>{"<< Previous"}</button></div> :
                                <div><button className='prevBtn' onClick={() => fetchSupplierData("prev")} >{"<< Previous"}</button></div>
                            }
                            <div className='d-flex pageno'>
                                {renderPageNumbers()}
                            </div>
                            {pagination?.currentPage === totalpages ?
                                <div><button className='prevBtnDisable' >{"Next >>"}</button></div>
                                :
                                <div><button className='prevBtn' onClick={() => fetchSupplierData("next")} >{"Next >>"}</button></div>
                            }
                        </div>
                        <div className="gap-4 d-flex ">
                            <div className='center'>Page Size</div>
                            <div className='center'>
                                <select
                                    className="form-select pagclr"
                                    aria-label="Default select example"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <div className='d-flex gap-3'>
                {/* <div><Edit /></div> */}
                {/* <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={this.props}
                        // onChange={(e) => btnClickedHandler(val, e.target.checked)}
                        onClick={this.btnClickedHandler}
                    />
                </div> */}
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default UserList;