import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ItemlistGrid from '../issueItems/itemsGrid';
import IssueAuthorization from '../issueItems/issueAuthorization';
import IssueMessageGrid from '../issueItems/IssueMessage';
import DirectPurchaseGrid from '../DirectPurchase/directPurchaseGrid';
import StockAdjGrid from './stockAdjustmentGrid';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';

const StockAdjView = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [locationList, setLocation] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [userData, setUserdata] = useState([]);
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const itemData = location.state;
    const [POTab, setPOTab] = useState(true);
    const [loader, setLoader] = useState(false);

    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const [formData, setFormData] = useState({
        remarks: "",
        requisitionDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        currencyCode: "",
        exchangeRate: "",
        department: '',
        costCenter1: '',
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        location: [],
        taskCode: "",
        adjNo: '',
        adjReason: '',
    });
    useEffect(() => {
        getCurrency()
        getUser()
        getLocation()
        getDepartment()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        const selectedDepartment = department?.find(dep => dep?.description === item?.department)
        const selectedLocation = locationList?.find(dep => dep?.description === item?.warehouse)
        setFormData({
            remarks: item?.remarks,
            requisitionDate: formatDateToDatetimeLocal(item?.adjDate) || currentDatetimeUTC,
            deliveryDate: formatDateToDatetimeLocal(item?.todayDate) || currentDatetimeUTC,
            costCenter1: item?.costCenter1, 
            costCenter2: item?.costCenter2,
            costCenter3: item?.costCenter3,
            costCenter4: item?.costCenter4,
            adjNo: item?.adjNo,
            adjReason: { label: item?.adjReason, value: item?.adjReason },
            department: { label: selectedDepartment?.description, value: selectedDepartment },
            location: { label: selectedLocation?.description, value: selectedLocation }
        });
    }, [location?.state, department, locationList]);
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=Requisition`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result); // Only set the first record
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        // setFormData({
        //     exchangeRate: filteredDenominations?.conversionRate
        // })
        setFormData({ ...formData, exchangeRate: filteredDenominations?.conversionRate });
    }
    const getLocation = async () => {
        let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
        setLocation(res?.data?.result)
    }
    const getDepartment = async () => {
        let res = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
        setDepartment(res?.data?.result)
    }
    const getUser = async () => {
        try {
            const token = localStorage.getItem('Token');
            let userId = userManagement.getUserId(token)
            let Id = `?id=${userId}`
            const apiService2 = new ApiService2()
            let res = await apiService2.getApiParamater('GET_USERS', Id)
            setUserdata(res?.data?.result)
        } catch (error) {
            console.log(error)
        }

    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility?.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    }
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    }
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    }
    const staticOptions = [
        { name: 'Wastage', label: 'Wastage' }
    ]
    const CreatePR = async () => {
        try {
            setLoader(true);
            if (!formData?.location.label) {
                toast.warning("Select location");
                setLoader(false);
                return;
            } else if (!formData?.adjReason.label) {
                toast.warning("Select Adjustment Reason");
                setLoader(false);
                return;
            } else if (!formData?.remarks) {
                toast.warning("Enter Remarks");
                setLoader(false);
                return;
            }
            for (const item of requisitionData) {
                if (!item.itemDescription) {
                    toast.warning(`Please Select Item`);
                    setLoader(false);
                    return;
                } else if (!item.qty) {
                    toast.warning(`Please enter ${item.itemDescription} quantity`);
                    setLoader(false);
                    return;
                } else if (item.stock === "0") {
                    toast.warning(`${item.itemDescription} not available in this location`);
                    setLoader(false);
                    return;
                }
            }
            const currentDatetimeUTC = new Date().toISOString();
            const apiService = new ApiService()
            const totalAmount = requisitionData?.reduce((sum, row) => sum + (row.amount || 0), 0);

            const GRNDetailsData = requisitionData?.map((item, index) => ({
                adjNo: formData.adjNo,
                srno: index + 1,
                itemCode: item?.itemNo,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.baseQty),
                specification: item.specification,
                convValue: parseInt(item?.baseQty),
                unitPrice: parseInt(item.baseAmount),
                amount: parseInt(item.amount),
                baseAmount: parseInt(item.amount),
                convToInvUOM: parseInt(item.baseQty),
                importedQty: 0,
                prodDate: currentDatetimeUTC,
                expDate: item.expDate,
                isActive: true,
                remarks: item.remarks
            }));

            const userData = {
                costCenter4: formData?.department.label,
                adjDetails: GRNDetailsData,
                location: formData?.location.label,
                adjNo: formData.adjNo,
                adjDate: currentDatetimeUTC,
                adjReason: formData?.adjReason.label,
                warehouse: formData?.location.label,
                currencyCode: formData?.currencyCode,
                exchangeRate: 1,
                amount: totalAmount,
                baseAmount: totalAmount,
                status: 0,
                approvalDate: formData?.deliveryDate,
                remarks: formData?.remarks,
                modifiedBy: "",
            };
            const response = await apiService.post("CREATE_ADJUSTMENT", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "ADJ",
                    docId: formData.adjNo,
                    department: "IT",
                    company: "PI",
                };
                const docBody = {
                    docId: "ADJ",
                    typeOfDoc: "ADJ",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${formData.adjNo} Created successfully`);
                setLoader(false);
                navigate("/inventory/Stock/Adjustment")
            }
        } catch (error) {
            setLoader(false);
            console.error('Error fetching items:', error);
        }
    }
    const UpdatePR = async () => {

    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleDepChange = (selectedOption) => {
        setFormData({ ...formData, department: selectedOption });
    }
    const handleAdjReason = (selectedOption) => {
        setFormData({ ...formData, adjReason: selectedOption });
    }
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    }
    const handleBackClick = () => {
        window.history.back();
    }
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Stock Adjustment</h5>
                <div className='d-flex gap-3'>
                    {itemData?.eye === true ? " " :
                        <>
                            {location.state ? (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={UpdatePR}>
                                        <FaSave className='center mt-1 mx-2' /><div>Update</div>
                                    </button>
                                }</>
                            ) : (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' type="submit" onClick={CreatePR}>
                                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                                    </button>
                                }</>)}
                        </>}
                    {itemData?.eye === true ? " " : <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>}
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Adjustment Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="recordId" className="form-label  pt-2 start">Adjustment No</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    value={formData.adjNo}
                                    name="recordId"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property Group</label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }} value={SelectedCurrency} />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Adjustment Date </label>
                                <input
                                    className='form-Input w-70'
                                    type='date' disabled
                                    defaultValue={new Date().toISOString().substr(0, 10)}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Property Group</label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }} isDisabled />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Adjustment Reason</label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }} isDisabled
                                    value={formData?.adjReason}
                                    onChange={(selectedOption) => handleAdjReason(selectedOption)}
                                    options={staticOptions?.map((res) => ({ value: res, label: res?.name }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="subcatname" className="form-label  pt-2 start">Department</label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }} isDisabled
                                    value={formData?.department}
                                    onChange={(selectedOption) => handleDepChange(selectedOption)}
                                    options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Location </label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })} isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    value={formData?.location}
                                    onChange={(selectedOption) => handlelocChange(selectedOption)}
                                    options={locationList?.map((loc) => ({ value: loc, label: loc?.description }))}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Outlet </label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }} isDisabled
                                //  options={staticOptions}
                                // onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="remarks" className="form-label  pt-2 start">Remarks</label>
                                <input
                                    name="remarks"
                                    type='text'
                                    className="form-Input w-70"
                                    id="remarks" disabled
                                    value={formData.remarks}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className=' col-lg-2 brderbox p-2 center'><a href='' disabled>Attachment</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 ">
                {gridVisibility[0] && (
                    <StockAdjGrid onRequisitionData={handleRequisitionData} itemData={itemData} selectedLocation={formData.location} />

                )}
                {gridVisibility[1] && (
                    <IssueAuthorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <IssueMessageGrid handleMessage={handleMessage} />
                    </div>
                )}

            </div>
        </div>
    )
}

export default StockAdjView