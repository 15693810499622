import React, { useEffect, useState, useRef } from 'react';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import DRNGrid from './DRNGrid';
import InvItemsGrid from './itemGrid';
import { useLocation, useNavigate } from 'react-router-dom';
import InvApiService from '../../../INVENTORY/services/apiService';
import ApiService from '../../services/apiServices';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
const CreateCustomerInv = () => {
    const [loader, setLoader] = useState(false);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [POTab, setPOTab] = useState(true);
    const [DRNTab, setDRNTab] = useState(false);
    const [CostTab, setCostTab] = useState(false);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [ItemsData, setItemsData] = useState([]);
    const [DrnList, setDrnList] = useState([]);
    const [SoList, setSoList] = useState([]);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [activeTab, setActiveTab] = useState(0);
    const [invNo, setInvNo] = useState();
    const InvapiService = new InvApiService();
    const apiService = new ApiService();
    const location = useLocation();
    let navigate = useNavigate();
    const itemData = location.state;

    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    useEffect(() => {
        getDocId()
    }, [])
    const getDocId = async () => {
        try {
            let param = `?isActive=true`
            if (itemData?.dnInvoiceNo) {
                setInvNo(itemData?.dnInvoiceNo)
            } else {
                let docParam = `?TypeOfDoc=DNINV&DocId=DN Invoice`
                let DocId = await InvapiService.getApiParamater('GET_DOC_ID', docParam)
                setInvNo(DocId?.data.result[0].docId);
            }
            let res = await InvapiService.getApiParamater('GET_CURRENCY_MASTER', param)
            let soParam = `?Status=1`
            let SOList = await apiService.getApiParamater('GET_SALE', soParam)
            setSoList(SOList.data.result)
            const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
            if (filteredDenominations) {
                setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
            }
            setFormData({ exchangeRate: filteredDenominations?.conversionRate })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const [formData, setFormData] = useState({
        sOrderNo: "",
        SOType: "",
        DocumentDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        InvoiceDate: getCurrentLocalDatetime(),
        DueDate: getCurrentLocalDatetime(),
        TaxInvoice: "",
        Narration: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        exchangeRate: "",
        CostInvoiceNo: "",
        customerId: "",
        customerName: "",
        OrderType: [],
        paymentTerm: [],
        selectedSale: [],
    });
    const handleBackClick = () => {
        window.history.back();
    };
    const handleItemData = (data) => {
        setItemsData(data);
    };
    const handleDRNData = (data) => {
        setDrnList(data);
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'deliveryDate' || name === 'DocumentDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleSaleChange = async (selectedOption) => {
        let param = `?SOrderNo=${selectedOption?.label}`
        let res = await apiService.getApiParamater('GET_SALE', param)
        let selectedSaleOrder = res?.data?.result[0]
        const saleorderDate = selectedSaleOrder?.deliveryDate ? formatDateToDatetimeLocal(selectedSaleOrder?.deliveryDate) : getCurrentLocalDatetime();
        setFormData({
            ...formData,
            selectedSale: selectedOption?.value,
            sOrderNo:selectedOption?.value?.sOrderNo,
            deliveryDate: saleorderDate,
            SOType: selectedSaleOrder?.soType,
            customerId: selectedSaleOrder?.vendorId,
            paymentTerm: { label: selectedSaleOrder?.terms, value: selectedSaleOrder?.terms },
            customerName: { label: selectedSaleOrder?.vendorName, value: selectedSaleOrder?.vendorName },
            costCenter1: { label: selectedSaleOrder?.costCenter1, value: selectedSaleOrder?.costCenter1 },
            costCenter2: { label: selectedSaleOrder?.costCenter2, value: selectedSaleOrder?.costCenter2 },
            costCenter3: { label: selectedSaleOrder?.costCenter3, value: selectedSaleOrder?.costCenter3 },
            costCenter4: { label: selectedSaleOrder?.costCenter4, value: selectedSaleOrder?.costCenter4 },
        });
    }

    const CreateDRN = async () => {
        setLoader(true)
        if (ItemsData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        const totalAmount = ItemsData.reduce((sum, row) => sum + (row?.amount), 0)
        const vatAmount = ItemsData.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
        const grossAmount = parseFloat(totalAmount) - parseFloat(vatAmount || 0);
        try {
            const currentDatetimeUTC = new Date().toISOString();
            const DRNDetailsData = ItemsData.map((item, index) => {
                const grossAmount = parseFloat(item.amount) - parseFloat(item.vatAmount || 0);
                
                return {
                    dnInvoiceRegNo: invNo,
                    extra: 0,
                    baseExtra: 0,
                    dnAccountCode: '',
                    dnAccountName: '',
                    invoiceAccountCode: '',
                    invoiceAccountName: '',
                    cgsAccountCode: '',
                    cgsAccountName: '',
                    discount: 0,
                    baseDiscount: 0,
                    vatAccountCode: '',

                    dnNo: item.dnNo,
                    vatPerc: item?.vat,
                    vatDesc: item?.vatDec,
                    vatAmount: item.vatAmount,
                    vatUnitPrice: item.vatAmount / parseInt(item.qty),
                    vatBaseAmount: item.vatAmount,
                    srno: index + 1,
                    itemCode: item.ItemNo || item.recordId,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: parseInt(item.qty),
                    specification: item.specification,
                    unitPrice: parseFloat(item.unitPrice),
                    amount: parseFloat(item.amount),
                    baseAmount: parseFloat(item.amount),
                    budgetCode: '',
                    extraQty: 0,
                    batchNo: '',
                    inventoryAccountCode: item.inventoryAccountCode,
                    inventoryAccountName: item.inventoryAccountName,
                    vatCode: item?.vat,
                    grossAmount: parseFloat(grossAmount),
                    grossBaseAmount: parseFloat(grossAmount),
                }
            });
            
            const userData = {
                dnInvoiceRegNo: formData.invoiceNo,
                dnInvoiceNo: invNo,
                dnInvoiceDate: formData.InvoiceDate,
                dnInvoiceRef: formData?.TaxInvoice,
                docDate: currentDatetimeUTC,
                attention: "",
                fax: "",
                quotationRef: "",
                contractNo: formData.Narration,
                deliveryPoint: "",
                contactPerson: "",
                state: "",
                aprrovalUser: '',
                modifiedBy: "",
                diffAmount: totalAmount,
                dnNo: invNo,
                batchNo: '',
                invType: "DNINV",
                diffAcCode: '',
                costCenter5: formData?.CostInvoiceNo,
                dueDate: formData.DueDate || currentDatetimeUTC,
                isDeleted: true,

                invoiceNo: formData.invoiceNo,
                sOrderNo: formData?.selectedSale?.sOrderNo,
                location: formData.location?.label,
                approvalUser: '',
                approvalDate: currentDatetimeUTC,
                amount: parseFloat(totalAmount),
                baseAmount: parseFloat(totalAmount),
                grossAmount: parseFloat(grossAmount),
                grossBaseAmount: parseFloat(grossAmount),
                vatAmount: parseFloat(vatAmount),
                isEditable: true,
                currencyCode: SelectedCurrency?.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                department: formData?.Department,
                // status: 0,
                status: 1,
                remarks: formData?.remarks,
                costCenter1: formData?.costCenter1?.label,
                costCenter2: formData?.costCenter2?.label,
                costCenter3: formData?.costCenter3?.label,
                costCenter4: formData?.costCenter4?.label,
                isActive: true,
                invoiceRegDetailDtos: DRNDetailsData,
                vendorId: formData.customerId,
                vendorName: formData.customerName?.label,
                delivery_Date: formData.deliveryDate || currentDatetimeUTC,
                deliveryNoteDate: formData.deliveryDate || currentDatetimeUTC,
                terms: formData.paymentTerm?.label,
                modifiedOn: currentDatetimeUTC,
            };
            
            const response = await apiService.post("CREATE_INV", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "SO",
                    docId: invNo,
                    department: "IT",
                    // company: Userdata?.company || "Qfinity",
                };
                const AttachmentData = {
                    docType: "Purchase Requisition",
                    docNo: invNo ? invNo.toString() : invNo,
                    fileName: "IT",
                };
                const docBody = {
                    docId: "DN Invoice",
                    typeOfDoc: "DNINV",
                };

                // const attachment = await apiService.post("CREATE_ATTACHMENT", AttachmentData);
                // const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await InvapiService.put("UPDATE_DOC_ID", docBody);
                setLoader(false)
                toast.success(`${invNo} Created successfully`);
                navigate("/Sales/Customer/Invoice")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PO:", error);
        }
    }


    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Customer Invoice</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' onClick={CreateDRN} type="submit"><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    }
                    <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Invoice Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceNO" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    className="form-Input w-70"
                                    value={invNo}
                                    disabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="deliveryDate" className="form-label  pt-1 start">Date </label>
                                <input className='form-Input w-70'
                                    value={formData.DocumentDate}
                                    id="DocumentDate"
                                    type='datetime-local' min={currentDatetimeUTC}
                                    onChange={handleInputChange}
                                    name="DocumentDate"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="SOType" className="form-label  pt-1 start">SO Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="SOType"
                                    name="SOType"
                                    value={formData.SOType}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Invoice Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="InvoiceDate" min={currentDatetimeUTC}
                                    name="InvoiceDate"
                                    value={formData.InvoiceDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Customer Name</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerName}
                                    cacheOptions
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PaymentTerm" className="form-label  pt-1 start">Payment Term</label>
                                <Select
                                    className="w-70 p-0"
                                    value={formData?.paymentTerm}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Sale Order No *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    onChange={(selectedOption) => handleSaleChange(selectedOption)}
                                    options={SoList?.map((sup) => ({ value: sup, label: sup?.sOrderNo }))}
                                    className="w-70 p-0"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="CostInvoiceNo" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="CostInvoiceNo"
                                    name="CostInvoiceNo"
                                    value={formData.CostInvoiceNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="DueDate" className="form-label  pt-1 start">Due Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="DueDate" min={currentDatetimeUTC}
                                    name="DueDate"
                                    value={formData.DueDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="TaxInvoice" className="form-label  pt-1 start">Tax Invoice No ( KRA )</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="TaxInvoice"
                                    name="TaxInvoice"
                                    value={formData.TaxInvoice}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Currency" className="form-label  pt-1 start">Currency</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    value={SelectedCurrency}
                                    isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    value={formData.exchangeRate}
                                    id="exchangeRate"
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="Narration" className="form-label  pt-1 start">Narration</label>
                                <textarea
                                    type="text"
                                    className="form-Input w-70"
                                    id="Narration"
                                    name="Narration"
                                    value={formData.Narration}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setDRNTab(!DRNTab)}>
                    <div className='h6 pt-1'>Dn Details</div>
                </div>
                <div className={`slide-content ${DRNTab ? 'open' : 'closed'}`}>
                    <div className="border-box2">
                        <div className="grid-1">
                            <DRNGrid formData={formData} handleDRNData={handleDRNData} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label me-2">Property Group</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter1}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label me-2">Property</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter2}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label me-2">Department</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter3}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label me-2">Outlet</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter4}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label me-2">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 mb-3">
                {gridVisibility[0] && (
                    <InvItemsGrid DrnList={DrnList} handleItemData={handleItemData} />
                )}
            </div>
        </div>
    );
};
export default CreateCustomerInv; 