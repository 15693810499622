import React, { useState } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, NavLink, useLocation } from "react-router-dom";
import YourLogo from "../../Assets/images/Pro_Logo.png";
import { MdOutlineInventory2 } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { GiSellCard } from "react-icons/gi";
import { FaUncharted } from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";
import "../../Assets/styles/style.css"
import "./sideBar.css"
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineStock } from "react-icons/ai";
import { LuFileCog } from "react-icons/lu";
import { useSelector } from "react-redux";
import { getCompany } from "../../../Redux/selecters";

const InventorySideBar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [showDetailsSegment, setDetailsSegment] = useState(false);
  // const [showMasterSegment, setMasterSegment] = useState(false);
  const [showMasterSegment, setMasterSegment] = useState(false);
  const [showTransectionSegment, setTransectionSegment] = useState(false);
  const [showReportSegment, setReportSegment] = useState(false);
  const [showSystem, setSystem] = useState(false);
  const userMaster = location.pathname.startsWith('/Inventory/UserMaster/');
  const userRole = location.pathname.startsWith('/inventory/Master/UserRole');
  const manageRole = location.pathname.startsWith('/inventory/UserRole/manage');
  const companies = useSelector(getCompany);
  
  // master
  const itemMasters = location.pathname === '/inventory/Master/Item' || location.pathname === '/Inventory/Master/Item/Create';
  const catMasters = location.pathname.startsWith('/inventory/Master/category');
  const subCatMasters = location.pathname.startsWith('/inventory/Master/SubCategory');
  const UomMasters = location.pathname.startsWith('/Inventory/Master/UnitMeasurement');
  const DepMasters = location.pathname.startsWith('/inventory/Master/Department');
  const StockMasters = location.pathname.startsWith('/inventory/Master/Stock');
  const packingMasters = location.pathname.startsWith('/inventory/Master/Packing');
  const companyMasters = location.pathname.startsWith('/inventory/Master/Company');
  const branchMaster = location.pathname.startsWith('/inventory/Master/Branch');
  const currencyMaster = location.pathname.startsWith('/inventory/Master/Currency');
  const paymentMaster = location.pathname.startsWith('/inventory/Master/Payment');
  const bankMaster = location.pathname.startsWith('/inventory/Master/Bank');
  const supplierMaster = location.pathname.startsWith('/inventory/Master/supplier_Type');
  const locationMaster = location.pathname.startsWith('/inventory/Master/LocationMaster');
  const locationCategoryMaster = location.pathname.startsWith('/Inventory/Master/Location/CategoryMaster');
  // transection
  const PrMaster = location.pathname === '/inventory/Requisition/Listing' || location.pathname === '/Inventory/Requisition/PurchaseRequisition';
  const PcMaster = location.pathname === '/inventory/PriceComparison/List' || location.pathname === '/Inventory/PriceComparison/price';
  const PoMaster = location.pathname === '/inventory/Purchase/Order' || location.pathname === '/inventory/Purchase/Create';
  const PReturn = location.pathname === '/inventory/PurchaseReturn' || location.pathname === '/inventory/PurchaseReturn/Create';
  const DirectPurchase = location.pathname === '/inventory/DirectPurchase' || location.pathname === '/inventory/DirectPurchase/Create';
  const Grn = location.pathname === '/inventory/GRN/Listing' || location.pathname === '/inventory/GRN/Create';
  const invoice = location.pathname === '/inventory/invoice/List' || location.pathname === '/inventory/invoice/Create';
  const approval = location.pathname === ('/inventory/Approval');
  const approvalUser = location.pathname.startsWith('/inventory/ApprovalUser');
  const stockTake = location.pathname.startsWith('/inventory/StockTake');
  const posting = location.pathname.startsWith('/inventory/Posting');
  const userStore = location.pathname === '/inventory/UserStore/List' || location.pathname === '/inventory/UserStore/Create';
  const issueItem = location.pathname === '/inventory/Issue/List' || location.pathname === '/inventory/Issue/Create';
  const stockAdjustment = location.pathname === '/inventory/Stock/Adjustment' || location.pathname === '/inventory/Stock/Adjustment/create';
  const IssueReceived = location.pathname === '/inventory/Issue/Received' || location.pathname === '/inventory/Issue/Received/Create';
  const issuereturn = location.pathname === '/inventory/Issue/Return' || location.pathname === '/inventory/Issue/Return/Create';
  const issueDirect = location.pathname.startsWith('/inventory/Issue/Direct');
  const itemReport = location.pathname.startsWith('/inventory/Reports/Items');
  const RequsitionReport = location.pathname.startsWith('/inventory/Reports/PurchaseRequisition');
  const POreport = location.pathname.startsWith('/inventory/Reports/PurchaseOrder');
  const GRNRep = location.pathname.startsWith('/inventory/Reports/grn');
  const PRRep = location.pathname.startsWith('/inventory/Reports/PurchaseReturn');
  const InvRep = location.pathname.startsWith('/inventory/Reports/Invoice');
  const StockRep = location.pathname.startsWith('/inventory/Reports/storeTransection');
  const StockVarRep = location.pathname.startsWith('/inventory/Reports/stockVarience');

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <ProSidebar collapsed={collapsed}>
        {collapsed ? (
          <a href="/Inventory" className="navbar-brand start">
            <img
              src={companies?.selectedCompany?.logo || YourLogo}
              alt="Logo"
              style={{ height: "40px", paddingTop: "8px", paddingBottom: "8px" }}
            />
          </a>
        ) : (
          <a href="/Inventory" className="navbar-brand start">
            <img
              src={companies?.selectedCompany?.logo || YourLogo}
              alt="Logo"
              style={{ height: "35px" }}
            />
            <span className="logoName" style={{ marginLeft: "8px" }}>
              {companies?.selectedCompany?.logo
                ?.split('/')
                .pop()
                ?.split('.')
                .slice(0, -1)
                .join('.')
                ?.charAt(0)
                .toUpperCase() +
                companies?.selectedCompany?.logo
                  ?.split('/')
                  .pop()
                  ?.split('.')
                  .slice(0, -1)
                  .join('.')
                  ?.slice(1)
                  .toLowerCase() }
            </span>
          </a>
        )}
        <Menu className="mt-2">
          <MenuItem
            icon={<LuLayoutDashboard size={23} />}
            active={location.pathname === "/Inventory/Dashboard"}
          >
            <NavLink to="/Inventory/Dashboard">
              <div>Dashboard</div>
            </NavLink>
          </MenuItem>
          {/* System Menu */}
          {/* <SubMenu className="mt-2"
            title="System"
            icon={<RiUserSettingsLine size={22} />}
            open={showSystem}
            onOpenChange={() => setSystem(!showSystem)}
          >
            <MenuItem><Link to="/inventory/UserRole/manage">
              <div className={`submenu ${manageRole ? 'subactive' : ''}`}>- Manage Role</div></Link></MenuItem>
            <MenuItem><Link to="/inventory/Master/UserRole" className="subactive">
              <div className={`submenu ${userRole ? 'subactive' : ''}`}>- User Role</div></Link></MenuItem>
            <MenuItem><Link to="/Inventory/UserMaster/list" className="subactive">
              <div className={`submenu ${userMaster ? 'subactive' : ''}`}>- User Master</div></Link></MenuItem>
          </SubMenu> */}
          {/* Procurement Menu */}
          {/* <SubMenu className="mt-2"
            title="Procurement"
            icon={<GiSellCard size={22} />}
            open={showDetailsSegment}
            onOpenChange={() => setDetailsSegment(!showDetailsSegment)}
          > */}
          
          <SubMenu
            title="Masters"
            icon={<LuFileCog size={22} />}
            open={showMasterSegment}
            onOpenChange={() => setMasterSegment(!showMasterSegment)}
          >
            <MenuItem><NavLink to="/inventory/Master/Item" className="mt-2"><div className={`submenu ${itemMasters ? 'subactive' : ''}`}>- Item Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Inventory/Master/UnitMeasurement" className="mt-2"><div className={`submenu ${UomMasters ? 'subactive' : ''}`}>-  UOM Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/category" className="mt-2"><div className={`submenu ${catMasters ? 'subactive' : ''}`}>- Category</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/SubCategory"><div className={`submenu ${subCatMasters ? 'subactive' : ''}`}>- SubCategory</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Department"><div className={`submenu ${DepMasters ? 'subactive' : ''}`}> - Department</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Stock"><div className={`submenu ${StockMasters ? 'subactive' : ''}`}>- Stock Type</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Packing"><div className={`submenu ${packingMasters ? 'subactive' : ''}`}> - Packing Type</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Company"><div className={`submenu ${companyMasters ? 'subactive' : ''}`}> - Company Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Branch"><div className={`submenu ${branchMaster ? 'subactive' : ''}`}>- Branch Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Currency"><div className={`submenu ${currencyMaster ? 'subactive' : ''}`}>- Currency Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Payment"><div className={`submenu ${paymentMaster ? 'subactive' : ''}`}>- Payment Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/Bank"><div className={`submenu ${bankMaster ? 'subactive' : ''}`}>- Bank Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/supplier_Type"><div className={`submenu ${supplierMaster ? 'subactive' : ''}`}>- Supplier Type Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Master/LocationMaster"><div className={`submenu ${locationMaster ? 'subactive' : ''}`}>- Location Master</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Inventory/Master/Location/CategoryMaster"><div className={`submenu ${locationCategoryMaster ? 'subactive' : ''}`}>- Location Category Master</div></NavLink></MenuItem>
          </SubMenu>
          <SubMenu className="mt-2"
            title="Transaction"
            icon={<MdOutlineInventory2 size={22} />}
            open={showTransectionSegment}
            onOpenChange={() => setTransectionSegment(!showTransectionSegment)}
          >
            {/* <MenuItem><NavLink to="/Inventory/Supplier_Master/list"><div className={`submenu ${supplier ? 'subactive' : ''}`}>- Supplier</div></NavLink></MenuItem> */}
            <MenuItem><NavLink to="/inventory/Requisition/Listing"><div className={`submenu ${PrMaster ? 'subactive' : ''}`}>- Purchase Requisition</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/PriceComparison/List"><div className={`submenu ${PcMaster ? 'subactive' : ''}`}>- Price Comparison</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Purchase/Order"><div className={`submenu ${PoMaster ? 'subactive' : ''}`}>- Purchase Order</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/GRN/Listing"><div className={`submenu ${Grn ? 'subactive' : ''}`}>- GRN</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/invoice/List"><div className={`submenu ${invoice ? 'subactive' : ''}`}>- Invoice</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/PurchaseReturn"><div className={`submenu ${PReturn ? 'subactive' : ''}`}>- Purchase Return</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/DirectPurchase"><div className={`submenu ${DirectPurchase ? 'subactive' : ''}`}>- Direct Purchase</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Approval"><div className={`submenu ${approval ? 'subactive' : ''}`}>- Approval</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/ApprovalUser"><div className={`submenu ${approvalUser ? 'subactive' : ''}`}>- Approval User</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/StockTake"><div className={`submenu ${stockTake ? 'subactive' : ''}`}>- Stock Take</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Posting"><div className={`submenu ${posting ? 'subactive' : ''}`}>- Posting</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/UserStore/List"><div className={`submenu ${userStore ? 'subactive' : ''}`}>- User Store Request</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Issue/List"><div className={`submenu ${issueItem ? 'subactive' : ''}`}>- Item Issue</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Stock/Adjustment"><div className={`submenu ${stockAdjustment ? 'subactive' : ''}`}>- Stock Adjustment</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Issue/Direct"><div className={`submenu ${issueDirect ? 'subactive' : ''}`}>- Issue Inventory Direct</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Issue/Return"><div className={`submenu ${issuereturn ? 'subactive' : ''}`}>- Issue Return</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Issue/Received"><div className={`submenu ${IssueReceived ? 'subactive' : ''}`}>- Issue Received</div></NavLink></MenuItem>
          </SubMenu>
          {/* </SubMenu> */}
          {/* Reports Menu */}
          <SubMenu className="mt-2"
            title="Reports"
            icon={<FaUncharted size={22} />}
            open={showReportSegment}
            onOpenChange={() => setReportSegment(!showReportSegment)}
          >
            <MenuItem><NavLink to="/inventory/Reports/Items"><div className={`submenu ${itemReport ? 'subactive' : ''}`}>- Items</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Reports/PurchaseOrder"><div className={`submenu ${POreport ? 'subactive' : ''}`}>- Purchase Order</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Reports/PurchaseRequisition"><div className={`submenu ${RequsitionReport ? 'subactive' : ''}`}>- Requisition</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Reports/grn"><div className={`submenu ${GRNRep ? 'subactive' : ''}`}>- GRN</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Reports/PurchaseReturn"><div className={`submenu ${PRRep ? 'subactive' : ''}`}>- Purchase Return</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Reports/Invoice"><div className={`submenu ${InvRep ? 'subactive' : ''}`}>- Invoice</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Reports/StockTransection"><div className={`submenu ${StockRep ? 'subactive' : ''}`}>- Stock Transection Report</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/inventory/Reports/stockVarience"><div className={`submenu ${StockVarRep ? 'subactive' : ''}`}>- Stock Varience Report</div></NavLink></MenuItem>
          </SubMenu>
          {/* <MenuItem
            icon={<AiOutlineStock size={23} />}
            active={location.pathname === "/Sales/SaleOrder"}
          >
            <NavLink to="/Sales/SaleOrder">
              <div>Sales Trading</div>
            </NavLink>
          </MenuItem> */}
        </Menu>
        <div
          onClick={handleToggle}
          className="sidebar-toggle-btn"
          style={{ left: collapsed ? '100px' : '300px' }}
        >
          <RxHamburgerMenu size={24} />
        </div>
      </ProSidebar>
    </div>
  );
};

export default InventorySideBar;
