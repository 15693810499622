import React, { useState } from "react";
import Select from "react-select";
import { Procurement, Reports, System } from "./data";
import "./rights.css"

const AccessPage = () => {
    const [expandedNodes, setExpandedNodes] = useState({});
    const [permissions, setPermissions] = useState({});

    const modules = {
        Procurement,
        System,
        Reports,
    };

    const toggleNode = (node) => {
        setExpandedNodes((prevState) => ({
            ...prevState,
            [node]: !prevState[node],
        }));
    };
    const updateChildPermissions = (items, permission, isChecked, moduleName, updatedPermissions) => {
        items.forEach((item) => {
            updatedPermissions[moduleName] = {
                ...updatedPermissions[moduleName],
                [item.label]: {
                    ...updatedPermissions[moduleName]?.[item.label],
                    [permission.toLowerCase()]: isChecked,
                },
            };
            if (item.value) {
                updateChildPermissions(item.value, permission, isChecked, moduleName, updatedPermissions);
            }
        });
    };

    const handlePermissionChange = (moduleName, permission, isMainModule) => {
        const isChecked = !permissions[moduleName]?.[permission];
        setPermissions((prevState) => {
            const updatedPermissions = {
                ...prevState,
                [moduleName]: {
                    ...prevState[moduleName],
                    [permission]: isChecked,
                },
            };

            if (isMainModule) {
                updateChildPermissions(modules[moduleName], permission, isChecked, moduleName, updatedPermissions);
            }

            return updatedPermissions;
        });
    };

    const handleSubModulePermissionChange = (moduleName, submoduleLabel, permission) => {
        const isChecked = !permissions[moduleName]?.[submoduleLabel]?.[permission.toLowerCase()];
        setPermissions((prevState) => {
            const updatedState = {
                ...prevState,
                [moduleName]: {
                    ...prevState[moduleName],
                    [submoduleLabel]: {
                        ...prevState[moduleName]?.[submoduleLabel],
                        [permission.toLowerCase()]: isChecked,
                    },
                },
            };
            const submodule = modules[moduleName]?.find((mod) => mod.label === submoduleLabel);
            if (submodule?.value) {
                submodule.value.forEach((subSubmodule) => {
                    updatedState[moduleName][subSubmodule.label] = {
                        ...updatedState[moduleName][subSubmodule.label],
                        [permission.toLowerCase()]: isChecked,
                    };
                });
            }

            return updatedState;
        });
    };

    const renderTree = (data, parentKey = "") => {
        return data.map((item) => {
            const currentKey = parentKey ? `${parentKey}-${item.label}` : item.label;
            const isExpanded = expandedNodes[currentKey];
            const hasChildren = item.value && item.value.length > 0;

            return (
                <div key={currentKey} className="tree-item">
                    <div className="tree-node">
                        <div className="w-25" onClick={() => toggleNode(currentKey)}>
                            {hasChildren && (
                                <span className="tree-icon">
                                    {isExpanded ? "▼" : "▶"}
                                </span>
                            )}
                            <span className="tree-label">{item.label}</span>
                        </div>
                        <div className="tree-checkbox-group w-75 d-flex justify-content-between">
                            {["Enable", "Add", "Edit", "Delete", "Query"].map((permission) => (
                                <div key={permission}>
                                    <input
                                        type="checkbox"
                                        className="tree-checkbox"
                                        checked={!!permissions[parentKey]?.[item.label]?.[permission.toLowerCase()]}
                                        onChange={() => handleSubModulePermissionChange(parentKey, item.label, permission)}
                                    />
                                    <label>{permission}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {hasChildren && isExpanded && (
                        <div className="tree-children">
                            {renderTree(item.value, currentKey)}
                        </div>
                    )}
                </div>
            );
        });
    };

    return (
        <div className="access-page-container">
            <h2>Access Rights Management</h2>
            <div className="tree-container">
                {Object.keys(modules).map((moduleKey) => (
                    <div key={moduleKey} className="module-tree">
                        <div className="tree-node">
                            <div className="module-title w-25" onClick={() => toggleNode(moduleKey)}>
                                <span className="tree-icon">
                                    {expandedNodes[moduleKey] ? "▼" : "▶"}
                                </span>
                                <span>{moduleKey}</span>
                            </div>
                            <div className="tree-checkbox-group w-75 d-flex justify-content-between">
                                {["Enable", "Add", "Edit", "Delete", "Query"].map((permission) => (
                                    <div key={permission}>
                                        <input
                                            type="checkbox"
                                            className="tree-checkbox"
                                            checked={!!permissions[moduleKey]?.[permission]}
                                            onChange={() => handlePermissionChange(moduleKey, permission, true)}
                                        />
                                        <label>{permission}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {expandedNodes[moduleKey] && (
                            <div className="tree-children">
                                {renderTree(modules[moduleKey], moduleKey)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AccessPage;