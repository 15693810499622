
import React from 'react';
import ApiService from '../../../../services/apiService';

const Modifiertable = ({ val }) => {
    return (
        <div className='table-responsive'>
            <table class="table " >
                <thead>
                    <tr>
                        <th scope="col">Modifiers</th>
                        <th scope="col">Price</th>
                    </tr>
                </thead>
                <tbody className=''>
                    {val?.map((val, key) => (
                        <tr>
                            <td>{val?.metaItemName}</td>
                            <td>
                                {val?.metaItemPrice?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) || "0"} </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Modifiertable;
