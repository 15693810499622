import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg';
import { ReactComponent as Print } from '../../Assets/svg/print.svg';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Pagination from '../pagination/pagination';

const StockTake = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [Location, setLocation] = useState([]);
    const userName = useSelector(getName);
    const todayDate = new Date().toISOString().substr(0, 10);
    const staticOptions = [];
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [formData, setFormData] = useState({
        category: [{ label: 'Select Category', value: '0' }],
        wareHouse: [{ label: 'Select WareHouse', value: '0' }],
        date: todayDate,
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const apiService = new ApiService();

    useEffect(() => {
        getItems();
        if (pageSize) {
            getItems();
        }
    }, [formData, pageSize]);

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const apiService = new ApiService();
                let param = `?isActive=true`
                const categoryResponse = await apiService.getApiParamater("GET_CATEGORY", param);
                setCategoryOptions(categoryResponse?.data?.result);
                let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
                setLocation(res?.data?.result)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);

    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('Category', formData.category.value.catCode);
            params.append('Warehouse', formData.wareHouse.label);
            const res = await apiService.getApiParamater('GET_STOCK_TAKE', `?${params.toString()}`);
            setPagination(res.data.paginationData);
            if (res.status === 400) {
                setRowData([]);
                setLoading(false);
            } else {
                setRowData(res?.data || []);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
            setRowData([]);
            setLoading(false);
        }
    }
    const Comparison = [
        { headerName: 'Item No', field: 'itemNo', filter: true, floatingfilter: true, minWidth: 110 },
        { headerName: 'Description', field: 'itemDescription', filter: true, floatingfilter: true, minWidth: 110 },
        { headerName: 'UOM', field: 'unitMeasurement', filter: true, floatingfilter: true, minWidth: 110 },
        { headerName: 'Actual Qty', field: 'qty', filter: true, floatingfilter: true, minWidth: 110 },
        { headerName: 'Availability QTY', field: 'location', editable: false, filter: true, floatingfilter: true, minWidth: 110 },
        { headerName: 'Variance', field: 'variance', filter: true, floatingfilter: true, minWidth: 110, editable: true },
        { headerName: 'Unit Price', field: 'unitPrice', filter: true, floatingfilter: true, minWidth: 110 },
        { headerName: 'Variance Amount', field: 'varianceAmount', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Remarks', field: 'comments', filter: true, floatingfilter: true, minWidth: 110 },
    ];
    const handleCatChange = (selectedOption) => {
        setFormData({ ...formData, category: selectedOption });
    }
    const handleWareHouseFilter = (selectedOption) => {
        setFormData({ ...formData, wareHouse: selectedOption });
    }
    const handleAvailQty = async (event) => {
        const editedRow = rowData.find(row => row.itemNo === event.data.itemNo);
        if (editedRow) {
            editedRow[event.colDef.field] = event.value;
            editedRow.variance = editedRow.qty - event.value;
            editedRow.varianceAmount = editedRow.variance * editedRow.unitPrice;
            setRowData(prevData => prevData.map(row =>
                row.itemNo === editedRow.itemNo ? { ...editedRow } : row
            ));
            const currentDatetimeUTC = new Date().toISOString();
            setSelectedItems(prevItems => {
                const updatedItems = [...prevItems];
                const existingItemIndex = updatedItems.findIndex(item => item.rcNo === editedRow.rcNo);
                if (existingItemIndex !== -1) {
                    updatedItems[existingItemIndex] = { ...updatedItems[existingItemIndex], ...editedRow };
                } else {
                    const existingItem = prevItems.find(item => item.rcNo === editedRow.rcNo);
                    updatedItems.push({ ...existingItem, ...editedRow });
                }
                return updatedItems;
            });
            
            const requestBody = {
                tranDate: formData.date,
                modifiedBy: userName,
                mode: "new",
                itemStockTypes: rowData.map(row => ({
                    recordId: 0,
                    itemNo: row.itemNo,
                    description: row.itemDescription,
                    location: formData.wareHouse.label,
                    uom: row.unitMeasurement,
                    actualQuantity: row.qty,
                    quantity: parseInt(row.location),
                    variance: row.variance,
                    remarks: row.comments,

                    takenBy: "",
                    takenDate: currentDatetimeUTC,
                    checked: false,
                    checkedBy: "",
                    checkedDate: currentDatetimeUTC,
                    checkedRemarks: "",
                    approved: false,
                    approvedBy: "",
                    approvedDate: currentDatetimeUTC,
                    approvedRemarks: "",
                    status: "0",
                    exp1: "",
                    exp2: "",
                    exp3: "",
                    exp4: "",
                    exp5: ""
                }))
            };
            
            try {
                const response = await apiService.post("UPDATE_STOCK_TAKE", requestBody);
                window.location.reload()
                window.history.replaceState({}, '')
                
            } catch (error) {
                console.error("Error updating stock take:", error);
            }
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div className='px-4 pb-5'>
            <div className='justify-content-between d-flex py-3'>
                <div className='col'><h5 className='mainInvHead'>Stock Take</h5></div>
                <div className='col'>
                    <Select
                        value={formData?.category}
                        onChange={(selectedOption) => handleCatChange(selectedOption)}
                        options={categoryOptions?.map((CatOpt) => ({ value: CatOpt, label: CatOpt?.catName }))}
                        className="w-70 p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                    />
                </div>
                <div className='col'>
                    <input
                        type='date'
                        className="form-Input w-70"
                        id="validationCustom02" name='date'
                        onChange={handleInputChange}
                        value={formData.date}
                    />
                </div>
                <div className='col'>
                    <Select
                        value={formData?.wareHouse}
                        onChange={(selectedOption) => handleWareHouseFilter(selectedOption)}
                        options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                        className="w-70 p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                    />
                </div>
                <div className='col'>
                    <Select
                        value={staticOptions}
                        options={staticOptions}
                        className="w-70 p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                    />
                </div>
            </div>
            <div className='brderbox'>
                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        pagination={false}
                        suppressExcelExport={true}
                        onCellValueChanged={handleAvailQty}
                    />
                </div>
            </div>
            <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <div className='d-flex gap-3'>
                <div><Print /></div>
                <div><Edit /></div>
            </div>
        )
    }
}
export default StockTake; 