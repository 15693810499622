import {ADD_TO_ORDER,CLEAR_ORDER,DOWNGRADE_ORDER_ITEM_QUANTITY,REMOVE_FROM_ORDER,UPDATE_ORDER_ITEM_DISCOUNT,UPDATE_ORDER_ITEM_QUANTITY,UPDATE_ORDER_ITEM} from "./types"

export const AddToOrder = (item) => ({
  type: ADD_TO_ORDER,
  payload: item,
});

export const RemoveFromOrder = (itemId) => ({
  type: REMOVE_FROM_ORDER,
  payload: itemId,
});

export const updateOrderItemQuantity = (index,itemId, quantity) => ({
  type: UPDATE_ORDER_ITEM_QUANTITY,
  payload: {index, itemId, quantity },
});
export const updateOrderItem = (index, itemId, voidItem) => ({
  type: UPDATE_ORDER_ITEM,
  payload: { index, itemId, voidItem },
});

export const downgradeOrderItemQuantity = (index,itemId, quantity) => ({
  type: DOWNGRADE_ORDER_ITEM_QUANTITY,
  payload: { index,itemId, quantity },
});
export const clearOrder = () => ({
  type: CLEAR_ORDER,
});
export const updateOrderDiscount = (index, itemId, discount, selectedPromotionDetails, discountType,discountId,promotionId) => {
  return {
    type: UPDATE_ORDER_ITEM_DISCOUNT,
    payload: { index, itemId, discount, selectedPromotionDetails, discountType,discountId, promotionId },
  };
};