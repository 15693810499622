import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ApiService from '../../services/apiServices';

const DRNGrid = ({ handleDRNData, formData, itemData }) => {
    const apiService = new ApiService();
    const todayDate = new Date().toISOString().substr(0, 10);
    const [rows, setRows] = useState([{
        poNo: '',
        grnNo: '',
        invoiceNo: '',
        refNo: '',
        grnDate: '',
        currency: '',
        amount: '',
        checked: '',
        invoiceCheck: ''
    }]);
    useEffect(() => {
        getGrn();
    }, [formData?.selectedSale?.sOrderNo, itemData]);
    const getGrn = async () => {
        try {
            let param = `?SOrderNo=${formData?.sOrderNo}`
            const grnres = await apiService.getApiParamater('GET_SO_DRN', param);
            if (itemData?.eye === true) {
                const formattedRows = grnres.data.result?.map(detail => {
                    return {
                        recordId: detail.dnNo,
                        sOrderNo: detail.sOrderNo,
                        dnNo: detail?.dnNo,
                        invoiceNo: detail.invoiceNo,
                        refNo: detail?.invoiceRef,
                        grnDate: detail.delivery_Date,
                        currency: detail.currencyCode,
                        amount: detail?.amount,
                        selected: detail.selected,
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows || []);
                }
            } else {
                const formattedRows = grnres.data.result?.map(detail => {
                    return {
                        recordId: detail.dnNo,
                        sOrderNo: detail.sOrderNo,
                        dnNo: detail?.dnNo,
                        invoiceNo: detail.invoiceNo,
                        refNo: detail?.invoiceRef,
                        grnDate: detail.delivery_Date,
                        currency: detail.currencyCode,
                        amount: detail?.amount,
                        selected: detail.selected,
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows || []);
                }
            }
        } catch (error) {
            console.error('Error fetching Grn:', error);
        }
    };

    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const handleCheckboxChange = (index, checked, row) => {
        const updatedRows = rows.map((r, i) => ({
            ...r,
            selected: i === index ? (checked ? 1 : 0) : r.selected
        }));
        setRows(updatedRows);
        handleDRNData(updatedRows);
    };
    return (
        <div>
            <div className='table-container mb-5' style={{ overflowX: 'auto', height: "fit-content" }}>
                <table>
                    <thead className='dynoTable'>
                        <tr>
                            <th></th>
                            <th>Dn No</th>
                            <th>SO no</th>
                            <th>Invoice No</th>
                            <th>Ref NO</th>
                            <th>DN Date</th>
                            <th>Currency</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) && rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 text-center  p-1' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                    <input className='form-check-input' checked={row.selected === 1} disabled={itemData?.eye}
                                        onChange={(e) => handleCheckboxChange(index, e.target.checked, row)}
                                        type="checkbox" style={{ width: "26.81px", height: "26.81px" }} />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.dnNo ? row.dnNo : " "} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.sOrderNo ? row.sOrderNo : " "} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.invoiceNo} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.refNo} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={moment(row.grnDate || todayDate).format('ll')} style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.currency} disabled style={{ width: "220px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.amount} style={{ width: "220px" }} /></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default DRNGrid;
