import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Pages/header/header';
import InventorySideLayout from "./sideLayout"
const MainInventoryLayout = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const isMainLayoutActive = location.pathname === "/Inventory/";
        if (isMainLayoutActive) {
            document.body.classList.add('body-with-sidebar');
        } else {
            document.body.classList.remove('body-with-sidebar');
        }
    }, [location.pathname]);

    return (
        <InventorySideLayout>
            <Header />
            <div className='mainbdy'>{children}</div>
        </InventorySideLayout>
    );
};

export default MainInventoryLayout;
