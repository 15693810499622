import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import "../report.css"
import { FaCalendarAlt } from "react-icons/fa";
import GridReport from '../Grid/grid';
import ApiService from '../../../../services/apiService';

const SaleDetail = ({ Currency }) => {
    let navigate = useNavigate();
    const [selectedFilter, setSelectedFilter] = useState('4');
    const [data, setData] = useState([]);

    useEffect(() => {
        getSumData()
    }, [selectedFilter]);
    const handleFilterChange = async (selectedFilter) => {
        setSelectedFilter(selectedFilter?.value);
        console.log('Selected Option:', selectedFilter);
        await getSumData();
    };
    const getSumData = async () => {
        try {
            const apiService = new ApiService();
            let paramaters = `?GetBy=${selectedFilter}&PageNo=${1}&PageSize=${20}`;
            
            let res = await apiService.getApiParamater("GET_SALE_REPORTS", paramaters);
            setData(res?.data?.result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const ThirdFilter = [
        { value: '0', label: 'Day' },
        { value: '1', label: 'Week' },
        { value: '2', label: 'Month' },
        { value: '3', label: 'Year' },
        { value: '4', label: 'All Time' },
    ];
    const handlechart = () => {
        navigate('/admin/Reports/3rdParty/chart')
    }
    return (
        <div className='p-lg-5'>
            <div className='px-lg-5'>
                <div className='d-flex justify-content-between' >
                    <div className='d-flex justify-content-between gap-4' >
                        <div>
                            <input className='form-control' type='search' placeholder='Search' />
                        </div>
                    </div>
                    <div className='d-flex mbl-center gap-3'>
                        <div className='d-flex'>
                            <nav className="nav nav-pills weekfilter ">
                                {ThirdFilter.map((filter) => (
                                    <button
                                        key={filter.value}
                                        className={`nav-link ${selectedFilter === filter.value ? 'activecls' : ''}`}
                                        onClick={() => handleFilterChange(filter)}
                                    >
                                        {filter.label}
                                    </button>
                                ))}
                            </nav>
                        </div>
                        <div className='custom center gap-2'>Custom <FaCalendarAlt /></div>
                    </div>
                </div>
                <div className='pt-5'>
                    <GridReport data={data}/>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(SaleDetail);