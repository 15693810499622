import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLayout from './sideBarLayout';
import AdminHeader from '../header/adminHeader';

const MainLayout = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Determine if the MainLayout is active
        const isMainLayoutActive = location.pathname === "/menu" || location.pathname === "/orders" || location.pathname === "/Home" || location.pathname === "/ordersDND"
            || location.pathname === "/additems";

        // Apply or remove the body-with-sidebar class
        if (isMainLayoutActive) {
            document.body.classList.add('body-with-sidebar');
        } else {
            document.body.classList.remove('body-with-sidebar');
        }
    }, [location.pathname]);

    return (
        <SidebarLayout>
            <AdminHeader />
            <div className='pl-5 pt-5 pr-5'>
                <div className='pt-5'>
                    {children}
                </div>
            </div>
        </SidebarLayout>
    );
};

export default MainLayout;
