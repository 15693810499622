export const setRows = (rows) => ({
    type: 'SET_ROWS',
    payload: rows,
});

export const addRow = (row) => ({
    type: 'ADD_ROW',
    payload: row,
});

export const removeRow = () => ({
    type: 'REMOVE_ROW',
});
export const removeSingleRow = (index) => ({
    type: 'REMOVE_ROW_INDEX',
    payload: index
});
export const updateRow = (index, row) => ({
    type: 'UPDATE_ROW',
    payload: { index, row }
});
export const clearRows = () => ({
    type: 'CLEAR_ROWS',
});
