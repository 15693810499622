import React, { useEffect, useState } from "react";
import './setting.css';
import Sidebox from "./SideBox";
import { handleCurrency } from "../../../../Redux/actions/currency";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import ApiService from "../../../services/apiService";
import { toast } from "react-toastify";

const CurrencySetting = () => {
    const dispatch = useDispatch();
    const [Currencies, setCurrency] = useState([]);
    const [SelectedCurrency, setSelectedCurrency] = useState(null);

    useEffect(() => {
        GetCurrency();
    }, []);

    // Function to fetch the currencies
    const GetCurrency = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CURRENCY');
            setCurrency(res?.data?.result);
            const defaultCurrency = res?.data?.result?.find(currency => currency?.default === true);
            if (defaultCurrency) {
                setSelectedCurrency({ label: defaultCurrency.code, value: defaultCurrency });
            }
        } catch (error) {
            console.error('Error fetching currency data:', error);
            toast.error('Error fetching currency data');
        }
    };

    // Function to handle currency selection
    const handle_Currency = (selectedOption) => {
        dispatch(handleCurrency({ price: selectedOption.value.conversionRate, name: selectedOption.label }));
        setSelectedCurrency(selectedOption);
    };

    // Function to handle the toggle switch change
    const handleSwitchChange = async (currency, newActiveStatus) => {
        try {
            await handleUpdateCurrency(currency, newActiveStatus);
        } catch (error) {
            console.error('Error updating currency:', error);
        }
    };

    // Function to update the currency status
    const handleUpdateCurrency = async (currency, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const currencyData = {
                id: currency?.id,
                loggedInUserId: currency?.loggedInUserId,
                code: currency?.code,
                name: currency?.name,
                conversionRate: currency?.conversionRate,
                default: newActiveStatus,
                active: newActiveStatus,
                currencyDenomination: currency?.currencyDenomination
            };
            const res = await apiService.put(`UPDATE_CURRENCY`, currencyData);
            if (res?.data?.statusCode === 200) {
                toast.success("Currency updated successfully!");
                GetCurrency();  // Refresh the currencies to reflect changes
            } else {
                toast.error("Failed to update Currency. Please try again!");
            }
        } catch (error) {
            console.error('Error updating Currency:', error);
        }
    };

    return (
        <div className='container'>
            <div className='pading-space pt-5'>
                <div className="pt-5 pb-5">
                    <div className="row">
                        <div className="col-3 pt-5"><Sidebox /></div>
                        <div className="col-9 px-5">
                            <div className="pb-4"><span className="settingtext">Select Currency</span></div>
                            <div className='row'>
                                <div className='col-6'>
                                    <Select
                                        isSearchable={false}
                                        value={SelectedCurrency}
                                        className="Currency-dropdown mbl-dropdown"
                                        options={Currencies.map(currency => ({ value: currency, label: currency?.code }))}
                                        onChange={handle_Currency}
                                    />
                                </div>
                                <div className="col-3 center">
                                    <div className="d-flex text-center gap-3">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheck${SelectedCurrency?.value?.id}`}
                                                checked={SelectedCurrency?.value?.default}
                                                onChange={(e) => handleSwitchChange(SelectedCurrency?.value, e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Uncomment if needed */}
                            {/* <div className='row pt-5'>
                                <div className="col-5">
                                    <button className='addItem-btn'>Save</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;

export default connect(mapStatetoProps)(CurrencySetting);
