import React, { useState, useEffect } from 'react';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';

const ItemlistGrid = ({ onRequisitionData, itemData, formData, SelectedIssue }) => {
    
    const apiService = new ApiService();
    const [rows, setRows] = useState([{
        requisitionNo: '',
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        reqQty: '',
        uom: '',
        issueQty: '',
        qty: '',
        balQty: '',
        amount: '',
        invQty: '',
        convRate: '',
        stock: '',
        unitPrice: '',
        cgsAcc: '',
        cgsAccName: '',
        expDate: '',
    }]);
    const [itms, setItems] = useState([])
    const totalAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVatAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    useEffect(() => {
        getItems();
    }, [formData]);
    const getItems = async () => {
        try {
            
            let param = `?RequisitionNo=${formData.requisitionNo.label || formData.requisitionNo}`
            const itmsresponse = await apiService.get('GET_ITEM');
            setItems(itmsresponse.data.result)
            
            if (itemData) {
                if (itemData.docno) {
                    const formattedRowsPromises = SelectedIssue?.issueDetails.map(async (detail, index) => {
                        // const response = await apiService.getApiParamater('GET_REQ_ITEM_ISSUE', param);
                        const selecteditmstock = await apiService.getApiParamater('GET_ITEM', `?isActive=true&Warehouse=${formData?.fromLocation?.label}&itemNo=${detail?.itemCode}`);
                        const selectedItem = itmsresponse?.data?.result?.find(req => req.itemNo === detail.itemCode);
                        // const selectedReqItem = response?.data?.find(req => req.itemCode === detail.itemCode);
                        let item = selecteditmstock?.data.result?.[0]
                        const getGLAccounts = await apiService.getApiParamater("GET_ITEM_GL", `?ItemNo=${detail.itemCode}`);
                        let glAccount = getGLAccounts?.data?.result[0]
                        return {
                            ItemNo: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            specification: detail.specification,
                            // reqQty: selectedReqItem?.reqQty,
                            reqQty: item?.reqQty,
                            uom: detail.uom,
                            issueQty: detail.qty || 0,
                            // balQty: selectedReqItem?.balQty,
                            balQty: item?.balQty || 0,
                            amount: detail.unitPrice * detail.qty || 0,
                            // invQty: selectedItem.convToInvUOM * detail.qty,
                            invQty: 1 * detail.qty,
                            convRate: selectedItem.convToInvUOM,
                            stock: item?.stock || 0,
                            unitPrice: detail.unitPrice || 0,
                            qty: item?.qty,
                            // qty: selectedReqItem?.qty,
                            cgsAcc : glAccount?.cgsAccountCode,
                            cgsAccName : glAccount?.cgsAccountName,
                            expDate: detail.expDate,
                        };
                    }).filter(row => row !== null);
                    const formattedRows = await Promise.all(formattedRowsPromises);
                    if (formattedRows.length > 0) {
                        setRows(formattedRows);
                        onRequisitionData(formattedRows);
                    }
                } else {
                    const formattedRowsPromises = itemData?.issueDetails.map(async (detail, index) => {
                        // const response = await apiService.getApiParamater('GET_REQ_ITEM_ISSUE', param);
                        
                        const selecteditmstock = await apiService.getApiParamater('GET_ITEM', `?isActive=true&Warehouse=${formData?.fromLocation?.label}&itemNo=${detail?.itemCode}`);
                        const selectedItem = itmsresponse?.data?.result?.find(req => req.itemNo === detail.itemCode);
                        // const selectedReqItem = response?.data?.find(req => req.itemCode === detail.itemCode);
                        let item = selecteditmstock?.data.result?.[0]
                        
                        return {
                            ItemNo: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            specification: detail.specification,
                            // reqQty: selectedReqItem?.reqQty,
                            reqQty: item?.reqQty,
                            uom: detail.uom,
                            issueQty: detail.qty || 0,
                            // balQty: selectedReqItem?.balQty,
                            balQty: item?.balQty || 0,
                            amount: detail.unitPrice * detail.qty || 0,
                            // invQty: selectedItem.convToInvUOM * detail.qty,
                            invQty: 1 * detail.qty,
                            convRate: selectedItem.convToInvUOM,
                            stock: item?.stock || 0,
                            unitPrice: detail.unitPrice || 0,
                            qty: item?.qty,
                            // qty: selectedReqItem?.qty,
                            cgsAcc: item.acCode,
                            cgsAccName: item.acName,
                            expDate: detail.expDate,
                        };
                    }).filter(row => row !== null);
                    const formattedRows = await Promise.all(formattedRowsPromises);
                    if (formattedRows.length > 0) {
                        setRows(formattedRows);
                        onRequisitionData(formattedRows);
                    }
                }
            } else {
                const response = await apiService.getApiParamater('GET_REQ_ITEM_ISSUE', param);
                
                const formattedRowsPromises = response.data.result.map(async (detail, index) => {
                    const selecteditmstock = await apiService.getApiParamater('GET_ITEM', `?isActive=true&Warehouse=${formData?.fromLocation?.label}&itemNo=${detail?.itemCode}`);
                    
                    const selectedItem = itmsresponse?.data?.result?.find(req => req.itemNo === detail.itemCode);
                    let item = selecteditmstock?.data.result?.[0]
                    return {
                        srno: index + 1,
                        ItemNo: detail.itemCode,
                        itemDescription: detail.itemDescription,
                        specification: detail.specification,
                        reqQty: detail.reqQty,
                        uom: detail.uom,
                        issueQty: detail.issueQty || 0,
                        balQty: detail.balQty,
                        amount: detail.amount || 0,
                        // invQty: selectedItem.convToInvUOM * detail.qty,
                        invQty: 0,
                        convRate: selectedItem.convToInvUOM,
                        stock: item?.stock || 0,
                        unitPrice: item.avgPrice || 0,
                        qty: detail.qty,
                        cgsAcc: item.acCode,
                        cgsAccName: item.acName,
                        expDate: detail.expDate,
                    };
                }).filter(row => row !== null);
                const formattedRows = await Promise.all(formattedRowsPromises);
                if (formattedRows.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            }

        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    const handleQtyChange = (index, value) => {
        const newQty = parseInt(value) || '';
        const balQty = rows[index].balQty;
        const stock = rows[index].stock;
        const unitPrice = rows[index].unitPrice;
        const invQty = rows[index].invQty;
        const convRate = rows[index].convRate;
        if (newQty > stock) {
            toast.warning('Enter a quantity less than or equal to the Stock.');
            return
        } else if (newQty > balQty) {
            toast.warning('Enter a quantity less than or equal to the balance quantity.');
            return
        } else {
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[index].issueQty = newQty;
                newRows[index].amount = newQty * unitPrice;
                // newRows[index].invQty = newQty * convRate || invQty;
                newRows[index].invQty = newQty * 1 || 0;
                return newRows;
            });
        }
    };
    const handleDateChange = (index, expDate) => {
        const updatedRows = [...rows];
        updatedRows[index].expDate = expDate;
        const updatedSelectedRows = rows.map((row, i) =>
            i === index ? { ...row, expDate: expDate } : row
        );
        setRows(updatedSelectedRows);
        onRequisitionData(updatedSelectedRows);
    };
    return (
        <div>
            <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Req QTY</th>
                            <th>Issue QTY</th>
                            <th>Bal QTY</th>
                            <th>Inventory UOM</th>
                            <th>Inventory Qty</th>
                            <th>Stock</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                            <th>CGS Acc </th>
                            <th>CGS Acc Name </th>
                            <th>exp Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={index + 1} disabled style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.ItemNo} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.reqQty || 0} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'>
                                    <input
                                        className='p-2' style={{ width: "100px" }}
                                        type="number" min={0} disabled={itemData?.eye === true ? true : false}
                                        onWheel={(e) => e.target.blur()}
                                        value={row.issueQty}
                                        max={row.issueQty}
                                        onChange={(e) => handleQtyChange(index, e.target.value)}
                                    />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' value={row.balQty} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.uom} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' value={row.invQty} disabled style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.stock || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.unitPrice || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.amount || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.cgsAcc || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.cgsAccName || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type='date' value={row?.expDate} disabled={itemData?.eye === true ? true : false}
                                    onChange={(e) => handleDateChange(index, e.target.value)} style={{ width: "130px" }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>---</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVatAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{totalAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default ItemlistGrid;

