import {
    ADD_COMBO_TO_CART,
    REMOVE_COMBO_FROM_CART,
    UPDATE_COMBO_QUANTITY,
    DOWNGRADE_COMBO_QUANTITY,
    CLEAR_COMBO_CART
} from '../actions/types';

const initialState = {
    items: [],
};

const ComboReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_COMBO_TO_CART:
            const newIndex = state.items.length;
            const newItem = { ...action.payload, index: newIndex };
            return {
                ...state,
                items: [...state.items, newItem],
            };

        case REMOVE_COMBO_FROM_CART:
            
            return {
                ...state,
                items: state?.items.filter(
                    (item, i) => item?.id !== action.payload.itemId || i !== action.payload.index
                ),
            };

        case UPDATE_COMBO_QUANTITY:
            return {
                ...state,
                items: state.items.map((item, index) => {
                    if (index === action.payload.index) {
                        return {
                            ...item,
                            quantity: item.quantity + 1,
                        };
                    }
                    return item;
                }),
            };

        case DOWNGRADE_COMBO_QUANTITY:
            return {
                ...state,
                items: state.items.map((item, index) => {
                    if (index === action.payload.index) {
                        return {
                            ...item,
                            quantity: item.quantity - 1,
                        };
                    }
                    return item;
                }),
            };

        case CLEAR_COMBO_CART:
            return {
                ...state,
                items: [],
            };

        default:
            return state;
    }
};

export default ComboReducer;