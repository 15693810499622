// reducers/index.js
import { LOGIN, USER_LOGOUT, USER_ROLE } from '../actions/types';

const initialState = {
  user: {},
  role: null, 
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, user: action.payload };
    case USER_ROLE:
      return { ...state, role: action.payload };
    case USER_LOGOUT:
      return { user:null};
    default:
      return state;
  }
};


export default userReducer