import React, { useState } from 'react';
import Cake from '../../assets/images/CakeImg.png';
import '../itemCard.css';
import { useNavigate } from 'react-router';
import "../categoryCard/category.css"


const Calculator = ({ handleInput }) => {
    const handleClick = (value) => {
        handleInput(value);
    };

    return (
        <>
            <div className='row'>
                <div className='col-lg-3 calbx caltxt' onClick={() => handleClick('1')}>1</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('2')}>2</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('3')}>3</div>
                <div className='col-lg-3 calbx2 caltxt' onClick={() => handleClick('500')}>500</div>
            </div>
            <div className='row'>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('4')}>4</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('5')}>5</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('6')}>6</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('100')}>100</div>
            </div>
            <div className='row'>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('7')}>7</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('8')}>8</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('9')}>9</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('50')}>50</div>
            </div>
            <div className='row'>
                <div className='col-lg-3 calbx4 caltxt' onClick={() => handleClick('C')}>C</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('0')}>0</div>
                <div className='col-lg-3 calbx1 caltxt' onClick={() => handleClick('.')}>.</div>
                <div className='col-lg-3 calbx3 caltxt' onClick={() => handleClick('00')}>00</div>
            </div>
        </>
    );
};

export default Calculator;