export const setCompanies = (company) => ({
    type: 'SET_COMPANIES',
    payload: company,
});

export const addCompany = (company) => ({
    type: 'ADD_COMPANY',
    payload: company,
});

export const removeCompany = () => ({
    type: 'REMOVE_COMPANY',
});
export const removeSingleCompany = (index) => ({
    type: 'REMOVE_COMPANY_INDEX',
    payload: index
});
export const updateCompany = (index, company) => ({
    type: 'UPDATE_COMPANY',
    payload: { index, company }
});
export const clearCompanies = () => ({
    type: 'CLEAR_COMPANIES',
});
export const updateSelectedCompany = (company) => ({
    type: 'UPDATE_SELECTED_COMPANY',
    payload: company,
});