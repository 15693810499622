import React, { useState, useEffect } from 'react';

const AuthorizationPurchaseReturn = ({ handleAuthorization, usersDetails, setUser, itemData }) => {
    const handleRemarksChange = (userId, value) => {
        const updatedUsers = usersDetails.map(user =>
            user.rcid === userId ? { ...user, remarks: value } : user
        );
        setUser(updatedUsers);
        const updatedUser = updatedUsers.find(user => user.rcid === userId);
        handleAuthorization(updatedUser);
    };
    function getStatusButton(status) {
        if (status === 0 || status === null) {
            return (<input className="p-2 w-100" value={"Pending"} readOnly />);
        }
        else if (status === 1 || status === null) {
            return (<input value={"Aproved"} className="p-2 w-100" readOnly />);
        }
        else if (status === 2 || status === null) {
            return (<input value={"Hold"} className="p-2 w-100" readOnly />);
        }
        else if (status === 3 || status === null) {
            return (<input value={"Rejected"} className="p-2 w-100" readOnly />);
        } else {
            return (<input className="p-2 w-100" value={"Pending"} readOnly />);
        }
    }
    return (
        <div className='table-container mb-5 p-2' style={{ overflowX: 'auto' }}>
            <table className='w-100'>
                <thead className='dynoTable' >
                    <tr>
                        <th>Sr No</th>
                        <th>User Code</th>
                        <th>User Name</th>
                        <th>Approval Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {usersDetails?.map((user, index) => (
                        <tr key={user.rcid}>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={index + 1} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userCode} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.userNo} disabled /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.createdOn || "---"} disabled /></td>
                            {itemData?.eye === true ?
                                <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={user?.remarks} disabled /></td>
                                :
                                <td className='m-0 p-0'>
                                    <input
                                        type="text"
                                        className='p-2 w-100'
                                        value={user?.remarks || ''}
                                        disabled
                                        onChange={(e) => handleRemarksChange(user.rcid, e.target.value)}
                                    />
                                </td>}
                            <td className='m-0 p-0'><input className='p-2 w-100' value={"Pending"} disabled /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AuthorizationPurchaseReturn;
