import { React, useState, useEffect } from 'react'
import Profile from "../assets/images/profileImg.png"
import Logo from "../assets/images/Bingo.png"
import BlueLogo from '../assets/images/blueCoco.png';
import QLogo from "../assets/images/Qfinitylogos.png"
import "./kitchenheader.css"
import { useDispatch } from 'react-redux'
import { logout } from '../../Redux/actions'
import { useNavigate } from 'react-router-dom'
import YourLogo from "../../INVENTORY/Assets/images/yourlogo.png";
const CookHeader = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleOut = () => {
        console.log("clicked")
        dispatch(logout())
        localStorage.clear()
        navigate('/')
        window.location.reload(false);
    }
    
    return (
        <nav className="navbar-expand-lg bg">
            <div class="container-fluid px-lg-5">
                <div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div class="collapse navbar-collapse d-flex justify-content-between" id="navbarSupportedContent">
                    <div>
                        {/* <img src={Logo} className='' /> */}
                        {/* <img src={BlueLogo} className='' /> */}
                        {/* <img src={QLogo} className='' /> */}
                        <img src={YourLogo} className='' style={{height:"30px"}}/>
                    </div>
                    <div className='d-flex justify-content-end end'>
                        <div onClick={handleOut} className='profilebox'>
                            Log Out                        </div>
                        <div className='KDCprofileimgbox '>
                            <img src={Profile} alt='profile' />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default CookHeader