export const setComparison = (rows) => ({
    type: 'SET_COMPARISON',
    payload: rows,
});

export const addComparison = (row) => ({
    type: 'ADD_COMPARISON',
    payload: row,
});

export const removeComparison = () => ({
    type: 'REMOVE_COMPARISON',
});
export const removeSingleComparison = (index) => ({
    type: 'REMOVE_COMPARISON_INDEX',
    payload: index
});
export const updateComparison = (index, row) => ({
    type: 'UPDATE_COMPARISON',
    payload: { index, row }
});
export const clearComparison = () => ({
    type: 'CLEAR_COMPARISON',
});
