import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import "./employee.css"
import ImageUpload from '../menus/addItems/imagUpload';
import Select from 'react-select';
import userManagement from '../../../services/userManagement';
import { shiftEnums } from '../../../../Enums/config';


const AddEmployee = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [Restaurants, setRestaurant] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedShift, setSelectedShift] = useState(null);
    const [Roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState([])

    const [formData, setFormData] = useState({
        id: '',
        name: "",
        idNumber: "",
        gender: "",
        dateOfBirth: "",
        dateOfJoining: "",
        City: "",
        roleId: "",
        branchId: "",
        restaurantId: "",
        State: "Active",
        shiftNumber: "",
        JobTitle: "",
        LicenseNumber: "",
        address: "",
        phoneNumber: "",
        email: "",
        shiftClosingTime: "",
        shiftOpeningTime: "",
    });

    useEffect(() => {
        if (location?.state && location?.state?.val) {
            const { val } = location.state;

            setFormData({
                id: val?.id || "",
                name: val?.name || "",
                idNumber: val?.idNumber || "",
                gender: val?.gender || "",
                dateOfBirth: moment(val?.dateOfBirth).format("YYYY-MM-DD") || "" || "",
                dateOfJoining: moment(val?.dateOfJoining).format("YYYY-MM-DD") || "",
                City: val?.city || "",
                State: val?.state || "Active",
                Shift: val?.shift || 1,
                JobTitle: val?.jobTitle || "",
                BranchId: val?.branchId || "",
                LicenseNumber: val?.LicenseNumber || "",
                address: val?.address || "",
                phoneNumber: val?.contactNumber || "",
                email: val?.email || "",
            });
        }
        getRoles()
        getRestaurant()

    }, [location.state]);
    const getRoles = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_ROLE');
            setRoles(res.data.result);

        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };
    const getRestaurant = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_RESTAURANT');
            const filter = res.data.result.filter(res => res.isActive==true);
            setRestaurant(filter);

        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // if (name === 'shiftOpeningTime' || name === 'shiftClosingTime') {
        //     const timeParts = value.split(':');
        //     const hours = parseInt(timeParts[0], 10);
        //     const minutes = parseInt(timeParts[1], 10);

        //     setFormData({
        //         ...formData,
        //         [name]: { hours, minutes }
        //     });
        // } else
        if (name === 'dateOfBirth') {
            const inputDate = new Date(value);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (inputDate > today) {
                toast.error("Please Enter a valid Date");
                return;
            }

            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption);
        setFormData({ ...formData, roleId: selectedOption?.value?.id });

    };
    const handleAddCostumer = async (e) => {
        e.preventDefault();
        try {
            let token = localStorage.getItem('Token')
            let userID = userManagement.getUserId(token)
            if (formData?.shiftNumber == undefined || formData?.shiftNumber == null) {
                toast.error('Please Choose shift ')
            }
            const contoTic = (time) => {
                const { hours, minutes } = time;
                const open = new Date();
                open.setHours(hours);
                open.setMinutes(minutes);
                open.setSeconds(0);
                open.setMilliseconds(0);

                return (open.getTime())

            }
            const shiftOpeningFormatted = moment(formData?.shiftOpeningTime, 'HH:mm').format('HH:mm:ss.SSSSSSS');
            const shiftClosingFormatted = moment(formData?.shiftClosingTime, 'HH:mm').format('HH:mm:ss.SSSSSSS');

            const shift = {
                shiftOpening: shiftOpeningFormatted,
                shiftClosing: shiftClosingFormatted
            };

            const body = {
                employee_Name: formData.name,
                department: formData.JobTitle,
                exp1: formData.idNumber,
                phoneNumber: formData.phoneNumber,
                exp3: formData.address,
                restaurantId: formData?.restaurantId,
                roleId: formData?.roleId,
                branchId: formData?.branchId,
                email: formData.email,
                city: formData.City,
                createdBy: userID,
                dob: moment.utc(formData?.dateOfBirth).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                joiningDate: moment.utc(formData?.dateOfJoining).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                shiftClosingTime: shiftOpeningFormatted,
                shiftOpeningTime: shiftClosingFormatted,
                shiftNumber: formData?.shiftNumber

            }
            const apiService = new ApiService();
            let response = await apiService.post("ADD_EMPLOYEE", body);

            if (response.data.statusCode === 201) {
                toast.success(response.data.message);
                navigate(`/admin/employee`);

            } else {
                toast.warning(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleUpdateCostumer = async (e) => {
        e.preventDefault();
        try {
            const body = {
                employee_Name: formData.name,
                department: formData.JobTitle,
                exp1: formData.idNumber,
                exp2: formData.phoneNumber,
                exp3: formData.address,
                restaurantId: formData?.restaurantId,
                roleId: formData?.roleId,
                branchId: formData?.branchId,

            }
            const apiService = new ApiService();
            let response = await apiService.post("UPDATE_EMPLOYEE", body);

            if (response.data.statusCode === 204) {
                toast.success('Employee Updated successfully');
                navigate(`/admin/employee`);

            } else {
                toast.warning('Waiting for menu update');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const Gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];
    const handleGenderChange = (selectedOption) => {
        setFormData({ ...formData, gender: selectedOption?.value });
    }
    const getBranches = async (restaurantId) => {
        try {
            const apiService = new ApiService();
            let param = `?restaurantId=${restaurantId}`
            let res = await apiService.getApiParamater("GET_BRANCH", param);
            const filter = res.data.result.filter(res => res.isActive==true);
            setBranches(filter);
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    };
    const handleRestaurantChange = (selectedOption) => {

        setSelectedRestaurant(selectedOption);
        getBranches(selectedOption?.value?.id);
        setFormData({ ...formData, restaurantId: selectedOption?.value?.id });

    };
    const handleBranchChange = (selectedOption) => {
        setSelectedBranch(selectedOption);
        setFormData({ ...formData, branchId: selectedOption?.value?.id });

    };
    const handleShiftChange = (selectedOption) => {
        
        setSelectedShift(selectedOption);
        setFormData({ ...formData, shiftNumber: selectedOption?.value });

    };
    return (
        <div className='pb-lg-5 px-lg-5 container'>
            <form>
                <div className='py-lg-5'>
                    <div className="form-group row justify-content-between">
                        <div className="col-md-5 col-lg-5">
                            <label htmlFor="validationCustom01" className="form-label">Name</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom01"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="validationCustom02" className="form-label">Email</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom02"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>

                    <div className="form-group row mt-3 justify-content-between">
                        <div className="col-md-5 col-lg-5">
                            <label htmlFor="validationCustom03" className="form-label">Address</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom03"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="validationCustom04" className="form-label">Gender</label>
                            <Select
                                defaultValue={formData.gender}
                                onChange={handleGenderChange}
                                options={Gender}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                    </div>
                    <div className="form-group row mt-3 justify-content-between">
                        <div className="col-md-5">
                            <label htmlFor="validationCustom13" className="form-label">License Number</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom13"
                                name="LicenseNumber"
                                value={formData.LicenseNumber}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="validationCustom04" className="form-label">Role</label>
                            <Select
                                defaultValue={formData.role}
                                onChange={handleRoleChange}
                                options={Roles?.map((role) => ({ value: role, label: role.name }))}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                    </div>

                    <div className='form-group row mt-3 justify-content-between'>
                        <div className="col-md-5 col-lg-5">
                            <label htmlFor="validationCustom05" className="form-label">Contact Number</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom05"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="validationCustom08" className="form-label">City</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom08"
                                name="City"
                                value={formData.City}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                    <div className="form-group row mt-3 justify-content-between">
                        <div className="col-md-5 col-lg-5">
                            <label htmlFor="restaurantSelect" className="form-label">Select Restaurant</label>
                            <Select
                                id="restaurantSelect"
                                value={selectedRestaurant}
                                onChange={handleRestaurantChange}
                                options={Restaurants?.map(restaurant => ({ value: restaurant, label: restaurant.name }))}
                            />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="branchSelect" className="form-label">Select Branch</label>
                            <Select
                                id="branchSelect"
                                value={branches ? selectedBranch : ''}
                                onChange={handleBranchChange}
                                options={branches?.map(branch => ({ value: branch, label: branch.branchName }))}
                            />
                        </div>
                    </div>

                    <div className="form-group row mt-3 justify-content-between">
                        <div className="col-md-5 col-lg-5">
                            <label htmlFor="validationCustom06" className="form-label">ID Number</label>
                            <input
                                type="number"
                                className="p-2 form-control"
                                id="validationCustom06"
                                name="idNumber"
                                value={formData.idNumber}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="validationCustom07" className="form-label">Date of Birth</label>
                            <input
                                type="date"
                                placeholder="dd-mm-yyyy"
                                className="p-2 form-control"
                                id="validationCustom07"
                                name="dateOfBirth"
                                value={formData.dateOfBirth}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>

                    <div className="form-group row mt-3 justify-content-between">
                        <div className="col-md-5">
                            <label htmlFor="validationCustom09" className="form-label">Date of Joining</label>
                            <input
                                type="date"
                                placeholder="dd-mm-yyyy"
                                className="p-2 form-control"
                                id="validationCustom09"
                                name="dateOfJoining"
                                value={formData.dateOfJoining}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="validationCustom11" className="form-label">Shift</label>
                            <Select
                                id="Shift"
                                value={shiftEnums ? selectedShift : ''}
                                onChange={handleShiftChange}
                                options={shiftEnums?.map(shift => ({ value: shift.type, label: shift.value }))}
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                    <div className="form-group row mt-3 justify-content-between">
                        <div className="col-md-5">
                            <label htmlFor="validationCustom09" className="form-label">Shift Opening Time</label>
                            <input
                                type='time'
                                placeholder="HH:MM"
                                className="p-2 form-control"
                                id="validationCustom09"
                                name="shiftOpeningTime"
                                value={formData?.shiftOpeningTime}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="validationCustom09" className="form-label">Shift Closing Time</label>
                            <input
                                type='time'
                                placeholder="HH:MM"
                                className="p-2 form-control"
                                id="validationCustom09"
                                name="shiftClosingTime"
                                value={formData.shiftClosingTime}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                    <div className="form-group row mt-3 justify-content-between">
                        <div className="col-md-5">
                            <label htmlFor="validationCustom12" className="form-label">Job Title</label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom12"
                                name="JobTitle"
                                value={formData.JobTitle}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    {location?.state?.val?.name ?
                        <div className='end'>
                            <button className='addItem-btn' type="submit" onClick={handleUpdateCostumer}>Update Employee</button>
                        </div> :
                        <div className='end'>
                            <button className='addItem-btn' type="submit" onClick={handleAddCostumer}>Save</button>
                        </div>
                    }
                </div>
            </form>
        </div>
    );
};


export default AddEmployee
