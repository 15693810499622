import React, { useState } from 'react';
import Cake from '../assets/images/CakeImg.png';
import './itemCard.css';
import Modal from 'react-modal';
import CartModal from '../components/cartPopup';
import { useNavigate } from 'react-router';
import { connect } from "react-redux";

const CashierHistory = ({ val, Currency }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    let navigate = useNavigate();

    const singleItem = (e) => {
        navigate(`/admin/order/${e?.id}/${e?.customerName}`, { state: e });
    }
    return (
        <div className='p-2 mb-4 card'>
            <div className='table-responsive'>
                <table class="table" >
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody >
                        {val?.map((val, index) => {
                            return (
                                <tr key={index}>
                                    <td
                                    // onClick={() => singleItem(val)}
                                    >#  {val?.id}</td>
                                    <td>{val?.customerName}</td>
                                    <td >{Currency.currency.name}   {val?.orderAmount}</td>
                                    <td>
                                        <div className={val?.orderStatus === 'New Order' ? 'status-btn' : val?.orderStatus === 'On Delivery' ? 'status-delivery' : 'status-delivered'}>
                                            {val?.orderStatus}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(CashierHistory);