import React from "react";
import { Link, useLocation } from 'react-router-dom';
import './setting.css'
import './sideBox.css'
import { FiSettings, FiUser, FiLink2 } from "react-icons/fi"
import { MdNotificationsNone } from "react-icons/md"
import { GrCurrency } from "react-icons/gr";
import { LuUser2 } from "react-icons/lu";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
const Sidebox = () => {
    const location = useLocation();

    const isActive = (route) => {
        return location.pathname === route;
    }
    return (
        <ProSidebar className="set-sidebar">
            <Menu iconShape="square" className=""  >
                <MenuItem className={`dashitems sidebar-text ${isActive("/admin/Setting/Profile") ? "sideactive" : ""}`}
                    component={<Link to="/admin/Setting/Profile" />}>
                    <LuUser2 />  Profile
                    <Link to="/admin/Setting/Profile" />
                </MenuItem>
                <MenuItem className={`dashitems sidebar-text ${isActive("/admin/Setting/Notification") ? "sideactive" : ""}`}
                    component={<Link to="/admin/Setting/Notification" />}>
                    <MdNotificationsNone />  Notifications
                </MenuItem>
                <MenuItem className={`dashitems sidebar-text ${isActive("/admin/Setting/Currency") ? "sideactive" : ""}`}
                    component={<Link to="/admin/Setting/Currency" />}>
                    <GrCurrency />  Currency
                </MenuItem>
            </Menu>
        </ProSidebar>
    );
}
export default Sidebox; 