import React, { useState } from "react";
import './userMaster.css';
import { useLocation, useParams } from 'react-router-dom';
import NavBox from "./navBox";
import CreateUser from "./createUser";
import UserAccess from "./userAccess";
import UserGrid from "./usersGrid";

const UserMaster = () => {
    const { id } = useParams();
    const [userID, setUserID] = useState();
    const { state } = useLocation();
    
    const handleUserId = (id) => {
        setUserID(id);
    };


    return (
        <div className="p-3 container">
            <NavBox />
            <div className="row px-lg-5">
                {(() => {
                    switch (id) {
                        case "create":
                            return <CreateUser state={state} onUserId={handleUserId} />;
                        case "access":
                            return <UserAccess state={state} userId={userID || state?.userId}  />;
                        case "users":
                            return <UserGrid  />;
                        default:
                            return <UserGrid />;
                    }
                })()}
            </div>
        </div>
    );
};

export default UserMaster;
