import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import ApiService from '../../services/apiService';
import Select from 'react-select';
import "./reports.css"
import Modal from 'react-bootstrap/Modal';
import { TfiEmail } from "react-icons/tfi";
import { HiOutlinePrinter } from "react-icons/hi2";
import { PiDownloadSimple } from "react-icons/pi";
import { toast } from 'react-toastify';
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { jsPDF } from 'jspdf';
import { BsFileEarmarkPdf, BsFiletypeCsv } from "react-icons/bs";
import { FaFileCircleExclamation } from "react-icons/fa6";
import { RiRefreshLine } from "react-icons/ri";
import 'jspdf-autotable';
import Pagination from '../pagination/pagination';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useCurrency } from '../../Layouts/currencyContext';

const StoreVarience = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [fromDate, setFromDate] = useState("13-jun-23");
    const [Location, setLocation] = useState([]);
    const [Variancer, setVariancer] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const apiService = new ApiService();
    const onOpenModal = () => setEmailModal(true);
    const onCloseModal = () => setEmailModal(false);
    const currentDatetimeUTC = new Date().toISOString();
    const { currencyPrecision } = useCurrency();
    const gridRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [formData, setFormData] = useState({
        startdate: "13-jun-23",
        endDate: "13-jun-25",
        itemNo: { label: 'Select ItemNo', value: 'All' },
        ItemDescription: { label: 'Select Item', value: 'All' },
        category: { label: 'Select Category', value: 'All' },
        location: { label: 'Select Location', value: 'All' },
    });
    const handleClearFilters = () => {
        setFormData({
            location: { label: 'Select Location', value: 'All' },
        });
        setFromDate("")
        setVariancer(false)
    };
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                let param = `?isActive=true`
                let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
                setLocation(res?.data?.result)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
        getItems()
    }, []);
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            if (formData.location?.value.description) {
                params.append('Location', formData.location?.value.description || '');
            }
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('FromDate', fromDate || "13-jun-2025");
            const res = await apiService.getApiParamater('GET_STOCK_VARIENCE_REPORT', `?${params.toString()}`);
            setRowData(res?.data?.result?.reverse() || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const Comparison = [
        { headerName: 'Item Code', field: 'itemCode', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Item Description', field: 'itemDescription', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Sub Category', field: 'trans_No', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Location', field: 'unitType', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Actual Stock', field: 'unitPrice', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Available Stock', field: 'amount', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Unit Price', field: 'amount', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
        { headerName: 'Remarks', field: 'amount', filter: true, floatingfilter: true, suppressMovable: true, width: 200 },
    ];
    const onBtnCSVExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'PurchaseOrderReport.csv'
        });
    }, []);
    const onBtPDFExport = useCallback(() => {
        const doc = new jsPDF();
        const rowData = [];
        const columnHeaders = [];
        const comparisonFields = [
            'recordId',
            'requisitionNo',
            'docDate',
            'vendorName',
            'terms',
            'status',
            'deliveryDate',
            'deliveryStatus',
            'itemCode',
            'itemDescription',
            'uom',
            'qty',
            'unitPrice',
        ];

        const translateStatus = (status) => {
            switch (status) {
                case 1:
                    return 'Approved';
                case 0:
                    return 'Pending';
                case 2:
                    return 'Hold';
                case 3:
                    return 'Rejected';
                default:
                    return status;
            }
        };

        comparisonFields.forEach(field => {
            const header = Comparison.find(col => col.field === field).headerName;
            columnHeaders.push(header);
        });

        gridRef.current.api.forEachNode((node) => {
            const row = [];
            comparisonFields.forEach(field => {
                let value = node.data[field];
                if (field === 'status') {
                    value = translateStatus(value);
                }
                row.push(value);
            });
            rowData.push(row);
        });

        const poppinsFont = 'data:font/ttf;base64,AAEAAAARAQAABAAw7HA...';
        doc.addFileToVFS("Poppins-Regular.ttf", poppinsFont);
        doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
        doc.setFont("Poppins");
        doc.setFontSize(18);
        doc.setTextColor(40, 40, 40);
        doc.text("Purchase Order Report", 72, 12);
        doc.text("__________________________", 60, 15);
        doc.autoTable({
            head: [columnHeaders],
            body: rowData,
            styles: {
                fontSize: 5,
            },
            headStyles: {
                fillColor: [209, 126, 6],
                textColor: [255, 255, 255],
                fontSize: 5,
            },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 15 },
                2: { cellWidth: 15 },
                3: { cellWidth: 15 },
                4: { cellWidth: 15 },
                5: { cellWidth: 15 },
                6: { cellWidth: 15 },
                7: { cellWidth: 20 },
                8: { cellWidth: 15 },
                9: { cellWidth: 15 },
                10: { cellWidth: 10 },
                11: { cellWidth: 10 },
                12: { cellWidth: 10 },
            },
            startY: 20
        });

        doc.save('PurchaseOrderReport.pdf');
    }, []);
    const handleFromDate = (e) => {
        setFromDate(e.target.value);
    };
    const handleVariancer = (e) => {
        setVariancer(e);
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const SendEmail = async () => {
        try {
            if (!formData.email) {
                toast.warning("Enter User Email to send Report")
                return;
            }
            const requestBody = {
                // toEmail: "shahzaibawan864@gmail.com",
                toEmail: formData.email,
                subject: "Item Report",
                body: `
                <div className='printhead2 mb-5 px-4'>
                    <table>
                        <thead className='dynoTable' style='background-color: #5B93FF; color: #fff;'>
                            <tr>
                                <th>Item Code</th>
                                <th>Description</th>
                                <th>Trans Type</th>
                                <th>Trans Date</th>
                                <th>Trans No</th>
                                <th>Unit Type</th>
                                <th>Unit Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${rowData?.map((row, index) => `
                                <tr key=${index}>
                                    <td className='m-0 p-0'><input style='width: 100px;' className='p-2' type='text' value='${row.itemCode}' disabled /></td>
                                    <td className='m-0 p-0'><input style='width: 110px;' className='p-2' disabled value='${row.itemDescription}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.trans_Type}' /></td>
                                    <td className='m-0 p-0'><input style='width: 140px;' className='p-2' disabled value='${row.trans_Date}' /></td>
                                    <td className='m-0 p-0'><input style='width: 80px;' className='p-2' disabled value='${row.trans_No}' /></td>
                                    <td className='m-0 p-0'><input style='width: 150px;' className='p-2' disabled value='${row.unitType}' /></td>
                                    <td className='m-0 p-0'><input style='width: 120px;' className='p-2' disabled value='${row.unitPrice}' /></td>
                                    <td className='m-0 p-0'><input className='p-2 w-100' type='text' value='${row.amount?.toFixed(currencyPrecision) || 0}' disabled /></td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </div>`
            }
            const apiService = new ApiService();
            const response = await apiService.post("SEND_EMAIL", requestBody);
            if (response.status === 200) {
                onCloseModal()
                toast.success("Email send successfully")
                handleClearFilters()
                getItems()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <div className='p-4'>
            <div className='gridBg m-0 row' style={{ borderRadius: "16px 16px 16px 16px" }}>
                <div className='col-8'>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <input
                                type='date'
                                className="form-Input w-100"
                                value={fromDate}
                                name="fromDate"
                                onChange={handleFromDate}
                                placeholder='From  Date '
                            />
                        </div>
                        <div className='col-4'>
                            <Select
                                className="p-0"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    padding: 9
                                })}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                        color: state.isFocused ? '#000 ' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000 ',
                                        },
                                    }),
                                }}
                                value={formData?.location}
                                onChange={(selectedOption) => handlelocChange(selectedOption)}
                                options={Location?.map((loc) => ({ value: loc, label: loc?.description }))}
                            />
                        </div>
                        <div className='col gap-3 mt-1 end'>
                            <input className="form-check-input"
                                type="checkbox"
                                id="dateFilter" style={{ width: "20px", height: "20px" }}
                                name="dateFilter"
                                checked={Variancer}
                                onChange={(e) => handleVariancer(e.target.checked)} />
                            <label className=''>Store Variancer</label>
                        </div>
                    </div>
                </div>
                <div className='col-1'></div>
                <div className='col-3 '>
                    <div onClick={() => getItems()} className='mainbtnGry flex-end gap-2'>
                        <FaFileCircleExclamation color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Search</div>
                    </div>
                    <div onClick={() => handleClearFilters()} className='mainbtnRed flex-end mt-2 gap-2'>
                        <RiRefreshLine color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Reset</div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between p-2 gridBg mt-2' style={{ borderRadius: "8px 8px 0px 0px " }}>
                <div className='mainInvHead center' >Stock Take Variance Report</div>
                <div className='d-flex gap-3 end'>
                    <div className='mainbtnGry flex-end gap-2'>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                <PiDownloadSimple size={25} />
                            </DropdownToggle>
                            <DropdownMenu className='mt-3'>
                                <DropdownItem onClick={onBtnCSVExport} className='gap-3 d-flex center'><BsFiletypeCsv /><div>Export CSV</div></DropdownItem>
                                {/* <DropdownItem onClick={onBtExcelExport} className='gap-3 d-flex center'><LiaFileExcel/><div>Export Excel</div></DropdownItem> */}
                                <DropdownItem onClick={onBtPDFExport} className='gap-3 d-flex center'><BsFileEarmarkPdf /><div>Export PDF</div></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='mainbtnRed flex-end '><HiOutlinePrinter size={25} /></div>
                    <div className='mainbtn flex-end gap-1' onClick={onOpenModal}>
                        <div className='center'><TfiEmail size={25} /></div>
                        <Modal size="md"
                            show={emailModal} onHide={() => onCloseModal()}
                            onRequestClose={() => onCloseModal()}
                            contentLabel='Email Modal'
                            style={customStyles} closeButton
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className='center'>Send Email</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <input
                                        type='email'
                                        className="form-Input w-100"
                                        id="validationCustom02"
                                        value={formData.email}
                                        name="email"
                                        onChange={handleInputChange}
                                        placeholder='Enter Email'
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='end p-3'>
                                <button className='mainbtnRed' onClick={SendEmail} >Save</button>
                            </Modal.Footer>
                        </Modal >
                    </div>
                </div>
            </div>
            <div className='gridshad'>
                <div className="ag-theme-quartz" style={{ height: 550 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        enableFilter={true}
                        floatingFilter={true}
                        rowSelection={'single'}
                        columnDefs={Comparison}
                        suppressExcelExport={true}
                        ref={gridRef}
                    />
                </div>
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
            </div>
            <div class="row p-0 m-0" style={{ background: '#fff' }}>
                <div class="col-4 border p-3"></div>
                <div class="col-2 border p-3">Main Store</div>
                <div class="col-2 border p-3">As : 27/08/2024</div>
                <div class="col-4 border p-3"></div>
            </div>
            <div className='p-5 row'>
                <div className=' row d-flex justify-content-between'>
                    <div className='col-2'>
                        <div className='itmKIns center'>Admin</div>
                        <hr className='' />
                        <div className='itmKIns center'>Created By</div>
                    </div>
                    <div className='col-2'>
                        <div className='itmKIns center'>Admin</div>
                        <hr className='' />
                        <div className='itmKIns center'>Checked By</div>
                    </div>
                    <div className='col-2'>
                        <div className='itmKIns center'>Admin</div>
                        <hr className='' />
                        <div className='itmKIns center'>Approved By</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StoreVarience;
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};