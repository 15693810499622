import React, { useState } from "react";
import './setting.css'
import { Button, Input } from "reactstrap";
import Sidebox from "./SideBox";

const Notification = () => {

    const [open, setOpen] = useState(false);
    
    return (
        <div className='container'>
            <div className='pading-space pt-5'>
                <div className="pt-5 pb-4">
                    <div className="row ">
                        <div className="col-3 pt-5">
                            <Sidebox />
                        </div>
                        <div className="col-9 px-5">
                            <div className="pb-5 " ><span className="settingtext">Notifications Settings</span> </div>
                            <div className=" row ">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                                    <label class="form-check-label " for="flexCheckDefault"><h5 className="notificationhead">Chat Notification</h5></label>
                                    <div><span className="notificationtxt">When someone messaged you</span></div>
                                </div>
                                <div class="form-check pt-4">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                                    <label class="form-check-label " for="flexCheckDefault"><h5 className="notificationhead">Orders Notifications</h5></label>
                                    <div><span className="notificationtxt">When someone purchased one of your items</span></div>
                                </div>
                                <div class="form-check pt-4">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
                                    <label class="form-check-label " for="flexCheckDefault">
                                        <h5 className="notificationhead">Customers Notifications</h5>
                                    </label>
                                    {/* <div><span className="notificationtxt">When someone purchased one of your items</span></div> */}
                                </div>
                                <div className='row pt-5'>
                                    <div className="col-5">
                                        <Button className='addItem-btn '>Save</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </div>
    );
}
export default Notification;