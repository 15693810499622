import { React, useState, useEffect } from 'react'
import ImageUpload from '../menus/addItems/imagUpload';
import "./master.css"
import ApiService from '../../../services/apiService';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ReactComponent as Delete } from '../../../assets/images/svgIcons/Delete.svg';

const TaxManagment = () => {
    const [taxData, setTax] = useState([]);
    const [vat, setVat] = useState(false);

    useEffect(() => {
        GetTax()
    }, []);
    const [formData, setFormData] = useState({
        name: "",
        percentage: "",
        excise: "",
        sales: "",
        category: "",
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const GetTax = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_TAX');
            setTax(res?.data?.result);
        } catch (error) {
            console.error('Error fetching paymentMethod data:', error);
        }
    }
    const CreateTax = async () => {
        const UserId = userManagement.getUserId();
        if (!formData?.name) { toast.warning("Please Enter Tax Name") }
        else if (!formData?.percentage) { toast.warning("Please Enter Tax percentage") }
        else {
            try {
                let body = {
                    name: formData?.name,
                    taxPercentage: parseInt(formData?.percentage),
                    vat: vat,
                    excise: parseInt(formData?.excise),
                    // excise: true,
                    // salesTaxRate: true,
                    salesTax: formData?.sales || null,
                    category: formData?.category,
                    active: true,
                    loggedInUserId: UserId
                }
                
                const apiService = new ApiService();
                let res = await apiService.post('CREATE_TAX', body);
                
                if (res?.data?.statusCode == 201) {
                    toast?.success("Tax Created Successfully")
                    GetTax()
                    setFormData({
                        name: "",
                        percentage: "",
                        vat: "",
                        excise: "",
                        sales: "",
                        category: "",
                    });
                } else {
                    toast?.success("Tax Created Successfully")
                }
            } catch (error) {
                console.error('Error fetching Tax data:', error);
            }
        }

    };
    const handleUpdateGTax = async (tax, newActiveStatus) => {
        try {
            const apiService = new ApiService();
            const catData = {
                id: tax?.id,
                name: tax?.name,
                loggedInUserId: tax?.loggedInUserId,
                active: newActiveStatus,
            };
            const res = await apiService.put(`UPDATE_TAX`, catData);
            if (res?.data?.statusCode === 200) {
                toast.success(`Tax ${tax?.name} updated successfully!`);
                GetTax();
            } else {
                toast.error("Failed to update Tax. Please try again!");
            }
        } catch (error) {
            console.error('Error updating Tax:', error);
        }
    }
    const handleSwitchChange = async (tax, newActiveStatus) => {
        try {
            await handleUpdateGTax(tax, newActiveStatus);
        } catch (error) {
            console.error('Error updating Tax:', error);
        }
    }
    return (
        <div className='p-5 container '>
            < div className='catlist'>

                <div className='row d-flex justify-content-between'>
                    <div className='col-lg-5'>
                        <label htmlFor="validationCustom01" className="form-label">
                            Tax Name *
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                        <label className="form-label">
                            ( will be showed on receipt )
                        </label>
                    </div>
                    <div className='col-lg-6 d-flex gap-3 mt-5'>
                        <div className="form-check ">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name="vat"
                                checked={vat}
                                onChange={(e) => setVat(e.target.checked)}
                                style={{ width: '1.5rem', height: '1.5rem' }}
                            />
                        </div>
                        <div className='w-100'>
                            <label htmlFor="validationCustom02" className="form-label">
                                VAT
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row py-3 d-flex justify-content-between'>
                    <div className='col-lg-5'>
                        <label htmlFor="validationCustom03" className="form-label">
                            Category
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            name="category"
                            value={formData.category}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className='col-lg-6 d-flex gap-3'>
                        {/* <div className="form-check center pt-4">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="excise"
                            checked={formData.excise}
                            onChange={(e) => setFormData({ ...formData, excise: e.target.checked })}
                            style={{ width: '1.5rem', height: '1.5rem' }}
                        />
                    </div> */}
                        <div className='w-100'>
                            <label htmlFor="validationCustom02" className="form-label">
                                Excise Duty
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="validationCustom02"
                                name="excise"
                                value={formData.excise}
                                onChange={handleInputChange}
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row d-flex justify-content-between'>
                    <div className='col-lg-5'>
                        <label htmlFor="validationCustom02" className="form-label">
                            Tax Percentage *
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            name="percentage"
                            value={formData.percentage}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                        <label className="form-label">
                            ( Enter number only without % sign )
                        </label>
                    </div>
                    <div className='col-lg-6 d-flex gap-3 mt-5'>
                        <div className="form-check ">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name="sales"
                                checked={formData.sales}
                                onChange={(e) => setFormData({ ...formData, sales: e.target.checked })}
                                style={{ width: '1.5rem', height: '1.5rem' }}
                            />
                        </div>
                        <div className='w-100'>
                            <label htmlFor="validationCustom02" className="form-label">
                                Sales Tax
                            </label>
                        </div>
                        {/* <div className='w-100'>
                        <label htmlFor="validationCustom03" className="form-label">
                            Sales tax
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            name="sales"
                            value={formData.sales}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div> */}
                    </div>
                </div>
                <div className='row'>
                    {/* <div className='col-6'>
                    <div className='d-flex gap-3 pt-2'>
                        <input type="radio" id="Zero" name="fav_language" value="Zero" />
                        <label for="Zero">Zero %</label>
                        <input type="radio" id="Exempt" name="fav_language" value="Exempt" />
                        <label for="Exempt">VAT Exempt</label>
                    </div>
                </div> */}
                    <div className="Col-6">
                        <div className="pt-5 end">
                            <button className='addItem-btn' onClick={() => CreateTax()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='catlist mt-5 table-responsive'>
                <table className='table table-striped mt-3'>
                    <thead>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Variant Name</th>
                            <th scope='col'>Tax Percentage</th>
                            <th scope='col'>Category</th>
                            <th scope='col'>Excise Duty</th>
                            <th scope='col'>Sales tax</th>
                            <th scope='col'>VAT</th>
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {taxData?.length === 0 ? (
                            <tr>
                                <td colSpan="3" className="text-center">No variants found</td>
                            </tr>
                        ) : (
                            taxData?.map((tax, index) => (
                                <tr key={index}>
                                    <th scope='row'>{index + 1}</th>
                                    <td>{tax.name}</td>
                                    <td>{tax.taxPercentage + "%" || "--"}</td>
                                    <td>{tax.category || "--"}</td>
                                    <td>{tax.excise || "--"}</td>
                                    {/* <td><div className="form-check center">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={tax?.excise}
                                            checked={tax.excise}
                                            disabled
                                            style={{ width: '1.5rem', height: '1.5rem' }}
                                        />
                                    </div></td> */}
                                    <td><div className="form-check center">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={tax?.salesTax}
                                            checked={tax.salesTax}
                                            disabled
                                            style={{ width: '1.5rem', height: '1.5rem' }}
                                        />
                                    </div></td>
                                    <td><div className="form-check center">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={tax?.vat}
                                            checked={tax.vat}
                                            disabled
                                            style={{ width: '1.5rem', height: '1.5rem' }}
                                        />
                                    </div></td>
                                    <td>
                                        <div className="d-flex text-center">
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheck${tax.id}`}
                                                    checked={tax.active}
                                                    onChange={(e) => handleSwitchChange(tax, e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TaxManagment