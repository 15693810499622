import React, { useEffect, useState, Component, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { HiPencil } from "react-icons/hi2";
import { FaEye } from "react-icons/fa";
import ApiService from '../../services/apiService';
import Button from 'react-bootstrap/Button';
import { TbBallpenOff } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { MdOutlineEditOff } from "react-icons/md";
import { toast } from 'react-toastify';

const BankGrid = ({ fetchRoleData, rowData, canEditMaster, setSelectedRow, handleEdit }) => {

    useEffect(() => {
        fetchRoleData();
    }, []);
    const [ColDefs, setColDefs] = useState([
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: "Bank Code", field: "descriptionBI", filter: true, floatingfilter: true, minWidth: 320 },
        { headerName: "Bank Name", field: "description", filter: true, floatingfilter: true, minWidth: 320 },
        { headerName: "Country", field: "country", filter: true, floatingfilter: true, minWidth: 320 },
        { headerName: "City", field: "city", filter: true, floatingfilter: true, minWidth: 320 },
        {
            headerName: "Action", field: "recordId", filter: true, floatingfilter: true, minWidth: 320,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, exp2) {
                    handleToggle(field, exp2);
                },
            },
        },
    ])
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: id,
                exp2: isActive ? "1" : "0"
            }
            const res = await apiService.put(`UPDATE_BANK`, body);
            if (res?.data?.statusCode === 201) {
                toast.success('Updated successfully');
                fetchRoleData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'descriptionBI') {
            setSelectedRow(null);
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const handleToggle = (recordId, newExp9Value) => {
        updateActiveStatus(recordId, newExp9Value === "1");
    };
    return (
        <div >
            <div className='gridshad'>
                <div className="ag-theme-quartz" style={{ height: 500 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                    rowSelection={'single'}
                    columnDefs={ColDefs}
                        onCellClicked={onCellClicked}
                        onSelectionChanged={onSelectionChanged}
                    />
                </div>
            </div>
        </div>
    )
}
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const exp2Value = data.exp2 === "1" ? "0" : "1";
        clicked(value, exp2Value);
    }

    render() {
        const { isActive } = this.props.data; // Extract isActive from rowData
        const { canEditMaster } = this.props;
        const { exp2 } = this.props.data;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={exp2 === "1"}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={exp2 === "1"}
                        />
                    </div>
                }
            </div>
        )
    }
}
export default BankGrid;