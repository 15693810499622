import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import moment from 'moment';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

const ApprovalGrid = ({ fetchApprovalData, rowData, docType }) => {

    const handleStatusChange = async (rcNo, status, remarks = "") => {
        const currentDocType = localStorage.getItem('selectedOptionLabel') || docType;
        
        try {
            const apiService = new ApiService();
            const currentDatetimeUTC = new Date().toISOString();
            let body = {
                rcNo: rcNo,
                status: status,
                remarks: remarks,
                approvalDate: currentDatetimeUTC,
            };
            
            const response = await apiService.put('UPDATE_DOC_AUTHORIZATION', body);
            
            if (response?.data?.statusCode === 201) {
                const res = await apiService.get('GET_DOC_AUTHORIZATION');
                const selectedItem = res.data.result.find(data => data.rcNo === rcNo);

                if (currentDocType === "Quotation") {
                    const apiService = new ApiService();
                    const res = await apiService.get('GET_PC');
                    const DocNo = Number(selectedItem?.docNo);
                    const selectItem = res.data.result.find(data => data.recordId === DocNo);
                    const selectedItems = res.data.result.filter(data => data.requisitionNo === selectItem.requisitionNo);
                    for (const item of selectedItems) {
                        let PrBody = {
                            quoteNo: item?.quoteNo,
                            status: status,
                            recordId: item?.recordId,
                        };
                        const response = await apiService.put('UPDATE_PC', PrBody);
                        if (response.data.statusCode === 201) {
                            fetchApprovalData();
                        } else {
                            console.error('Failed to update item:', item);
                        }
                    }
                } else if (currentDocType === "Requisition") {
                    let PrBody = {
                        recordId: selectedItem?.docNo,
                        status: status,
                    };
                    const response = await apiService.put('UPDATE_PR_STATUS', PrBody);
                    if (response.data.statusCode === 200) {
                        fetchApprovalData();
                    }
                } else if (currentDocType === "Price Comparison") {
                    let documentnum = parseInt(selectedItem?.docNo);
                    let PrBody = {
                        rfqid: documentnum,
                        status: status,
                    };
                    const response = await apiService.put('UPDATE_QUOTATION', PrBody);
                    if (response.data.statusCode === 201) {
                        fetchApprovalData();
                    }
                } else {
                    let PrBody = {
                        recordId: selectedItem?.docNo,
                        status: status,
                    };
                    const response = await apiService.put('UPDATE_PR_STATUS', PrBody);
                    if (response.data.statusCode === 200) {
                        fetchApprovalData();
                    }
                }
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "Doc Type", field: "docType", filter: true, floatingfilter: true, minWidth: 200 },
        {
            headerName: "Doc No", field: "docNo", filter: true, floatingfilter: true, minWidth: 219,
            valueGetter: (params) => {
                const { docType, docNo } = params.data;
                let prefix;
                switch (docType) {
                    case "Requisition":
                        prefix = "Req-24-";
                        break;
                    case "Price Comparison":
                        prefix = "PC-24-";
                        break;
                    case "Quotation":
                        prefix = "Qut-24-";
                        break;
                    default:
                        prefix = "";
                }
                return `${prefix}${docNo.toString().padStart(4, '0')}`;
            }
        },
        {
            headerName: "Date", field: "approvalDate", filter: true, floatingfilter: true, minWidth: 219,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: "User", field: "userName", filter: true, floatingFilter: false },
        {
            headerName: "Status", field: "id", filter: true, floatingfilter: true, minWidth: 300,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handleStatusChange: handleStatusChange
            },
        },
        { headerName: "Cost Center 1", field: "costcenter1", filter: true, floatingFilter: false },
        { headerName: "Cost Center 2", field: "costCenter2", filter: true, floatingFilter: false },
        { headerName: "Cost Center 3", field: "costCentre3", filter: true, floatingFilter: false },
        { headerName: "User Level", field: "userLevel", filter: true, floatingFilter: false },
        { headerName: "Department", field: "location", filter: true, floatingFilter: false },
        { headerName: "Action", field: "isActive", filter: true, floatingFilter: false },
    ]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };

    return (
        <div>
            <div className="ag-theme-quartz" style={{ height: 700, backgroundColor: "#5B93FF" }}>
                <AgGridReact
getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={{
                        filter: true,
                        floatingfilter: true,
                        minWidth: 100,
                    }}
                    frameworkComponents={frameworkComponents}
                    suppressRowClickSelection={true}
                    pagination={true}
                />
            </div>
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        const { rcNo, status } = props.data; // Access rcNo and status from props.data
        this.state = {
            rcNo: rcNo,
            pending: status === 0,
            approved: status === 1,
            hold: status === 2,
            rejected: status === 3,
            showModal: false, // State to manage modal visibility
            remarks: '' // State to store remarks
        };
    }

    handleCheckboxChange = async (event) => {
        const { id, checked } = event.target;
        const { handleStatusChange } = this.props;
        const { rcNo } = this.state;

        this.setState({ [id]: checked });
        if (id === "pending" && checked) {
            await handleStatusChange(rcNo, 0);
        }
        if (id === "approved" && checked) {
            await handleStatusChange(rcNo, 1);
        }
        if (id === "hold" && checked) {
            await handleStatusChange(rcNo, 2);
        }
        if (id === "rejected" && checked) {
            this.setState({ showModal: true }); // Show modal when reject is checked
        }
    };

    handleSave = async () => {
        const { rcNo, remarks } = this.state;
        const { handleStatusChange } = this.props;
        const remarksToSend = remarks.trim() !== '' ? remarks : null;
        await handleStatusChange(rcNo, 3, remarksToSend);
        this.setState({ showModal: false, remarks: '' });
    };

    handleModalClose = () => {
        this.setState({ showModal: false, rejected: false, remarks: '' });
    };

    handleRemarksChange = (event) => {
        this.setState({ remarks: event.target.value });
    };
    render() {
        return (
            <div className='d-flex gap-3'>
                <div className="form-check gap-2 center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        style={{ width: "26.81px", height: "26.81px", border: "0.5px solid #5B93FF" }}
                        id="approved"
                        checked={this.state.approved}
                        onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="approved">Approve</label>
                </div>
                <div className="form-check gap-2 center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        style={{ width: "26.81px", height: "26.81px", border: "0.5px solid #5B93FF" }}
                        id="hold"
                        checked={this.state.hold}
                        onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="hold">Hold</label>
                </div>
                <div className='gap-2 center'>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        style={{ width: "26.81px", height: "26.81px", border: "0.5px solid #5B93FF" }}
                        id="rejected"
                        checked={this.state.rejected}
                        onChange={this.handleCheckboxChange}
                    />
                    <label className="form-check-label center" htmlFor="rejected">Reject</label>
                </div>

                <Modal size="md"
                    show={this.state.showModal} onHide={this.handleModalClose}
                    onRequestClose={this.handleModalClose}
                    contentLabel='Receipt Modal'
                    style={customStyles} closeButton
                >
                    <Modal.Header closeButton>
                        <Modal.Title className='center'>Add Remarks</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label htmlFor="validationCustom02" className="form-label">Remarks</label>
                        <input
                            type='text'
                            placeholder='Remarks'
                            value={this.state.remarks}
                            onChange={this.handleRemarksChange}
                            className="form-control"
                            id="validationCustom02"
                        />
                    </Modal.Body>
                    <Modal.Footer className='end p-3'>
                        <button className='AddBtn' onClick={this.handleSave}>Save</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ApprovalGrid;
const customStyles = {
    content: {
        width: '30%',
        height: '75%',
        top: '50%',
        left: '50%',
        right: '50%',
        transform: 'translate(-50%, -50%)',
    },
};