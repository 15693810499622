import React, { useEffect, useState } from 'react';
import '../itemCard.css'
import "../receipt.css"
import "../bucket.css"
import { useNavigate } from 'react-router';
import { ReactComponent as BackArrow } from '../../../assets/images/svgIcons/LeftArrow.svg';
import ApiService from '../../../services/apiService';
import { useSelector } from 'react-redux';
import { getUserId, getUserName } from '../../../../Redux/selecters';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { CashEnums } from '../../../../Enums/config';
import { generateUniqueVoucherNumber } from '../../../services/globalService';

const PayOutForm = ({ val, addToCart }) => {
    let navigate = useNavigate();
    const [Currencies, setCurrency] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [noteQuantities, setNoteQuantities] = useState({});
    const [fillQuantities, setFillQuantities] = useState({});

    const [loading, setLoading] = useState(false);
    let currency = "EUR"
    const userId = useSelector(getUserId);
    const userName = useSelector(getUserName);
    useEffect(() => {
        GetCash()
        GetCurrency()
    }, []);

    useEffect(() => {
        const initialQuantities = {};
        separateAndCountValues(Currencies, currency)?.forEach(item => {
            initialQuantities[item.value] = item.count;
        });
        setQuantities(initialQuantities);
    }, [Currencies, currency]);

    const [formData, setFormData] = useState({
        user: "",
        toUser: "",
        voucherid: "",
        date: "",
        handOverBranch: "",
        id: "",
        shift: "",
        note: "",
    });
    const GetCash = async () => {
        try {
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0];
            const apiService = new ApiService();
            let param = `?UserId=${userId}&Type=${3}`;
            const res = await apiService.getApiParamater('GET_CASH', param);
            let response = res?.data?.result;
            const filteredResponse = response?.filter(item => {
                const openingDateWithoutTime = item?.openingDate ? item.openingDate.split('T')[0] : null;
                return openingDateWithoutTime === formattedDate;
            });
        } catch (error) {
            console.error(error);
        }
    };
  
    const totalAmount = Object.keys(quantities).reduce((total, value) => {
        return total + (value * quantities[value]);
    }, 0);
    
    const totalCashAmount = Object.keys(noteQuantities).reduce((total, value) => {
        return total + (value * noteQuantities[value]);
    }, 0);
    
    const totalFills = Object.keys(fillQuantities).reduce((total, value) => {
        return total + (value * fillQuantities[value]);
    }, 0);
    
    const grandTotalAmount = ((parseFloat(formData?.digitalAmount || 0) +(totalAmount + totalCashAmount )+ (totalFills / 100)).toFixed(2)); 
    const PayOut = async () => {
        try {
            const now = new Date();
            const currentTime = `${now.getUTCHours().toString().padStart(2, '0')}:${now.getUTCMinutes().toString().padStart(2, '0')}:${now.getUTCSeconds().toString().padStart(2, '0')}`;
        
            const cashMeta = separateAndCountValues(Currencies, currency)?.map(item => {
                const coinQuantity = quantities[item.value] || 0;
                const noteQuantity = noteQuantities[item.value] || 0;
                const fillQuantity = fillQuantities[item.value] || 0;
                const totalQuantity = coinQuantity + noteQuantity + (fillQuantity); 
            
                if (totalQuantity > 0) {
                    const type = coinQuantity > 0 ? 0 : (noteQuantity > 0 ? 1 : 2);

                    return {
                        currencyDenominationId: item.currencyDenominationId,
                        quantity: totalQuantity,
                        type:type
                    };
                }
                return null; 
            }).filter(item => item !== null); // Filter out null items
            
            const allQuantitiesZero = cashMeta.every(item => item.quantity === 0);
            const currentDatetimeUTC = new Date().toISOString();
            if (allQuantitiesZero) {
                toast.error("Please Enter Currency Denominations");
            } else if (!formData?.note) {
                toast.warning("Please Enter Remarks")
            } else {
                const datareq = {
                    userId: userId,
                    date: currentDatetimeUTC,
                    amount: parseFloat(grandTotalAmount),
                    remarks: formData?.note || "",
                    type: CashEnums.PayOut,
                    loggedInUserId: userId,
                    cashMeta: cashMeta,
                    time:currentTime,
                voucherNo: generateUniqueVoucherNumber()

                }
                const apiService = new ApiService();
                let res = await apiService.post("CREATE_CASH", datareq);
                if (res?.data?.statusCode === 201) {
                    toast?.success("Pay Out Successfully");
                    navigate('/cashier/home');
                }
            }
        } catch (error) {
            console.error('Error Creating Paying Out:', error);
        }
    }
    
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const GetCurrency = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CURRENCY');
            setCurrency(res?.data?.result);
        } catch (error) {
            console.error('Error fetching currency data:', error);
            toast.error('Error fetching currency data');
        }
    }
    const separateAndCountValues = (denominations) => {
        const filteredDenominations = denominations?.find(currency => currency.default === true)
        return filteredDenominations?.currencyDenomination?.map(denomination => {
            const count = denomination?.count || 0;
            return {
                currencyDenominationId: denomination?.currencyDenominationId,
                value: denomination?.value,
                count: count,
                type: denomination?.type
            };
        });

    };
    const incrementQuantity = (value, type) => {
        if (type === 0) { // Coin
            setQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };

    const decrementQuantity = (value, type) => {
        if (type === 0) { // Coin
            setQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };
    const incrementFillQuantity = (value, type) => {
        if (type === 2) { // Coin
            setFillQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };

    const decrementFillQuantity = (value, type) => {
        if (type === 2) { // Coin
            setFillQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };

    const incrementNoteQuantity = (value, type) => {
        if (type === 1) { // Note
            setNoteQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };

    const decrementNoteQuantity = (value, type) => {
        if (type === 1) { // Note
            setNoteQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };
    function getCurrentDate() {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        const yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return yyyy + '-' + mm + '-' + dd;
    }
    const handleBackClick = () => {
        navigate(-1);
    }
    const cashDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 1);
    const coinDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 0);
    const fillDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 2);

    return (
        <div className='p-5'>
            <div className='row'>
                <div className='col-5'><button className='AddBtn ' onClick={() => handleBackClick()}><BackArrow />  Back</button></div>
            </div>
            <div className='row pt-4' >
                <div className='col-lg-4 col-sm-4'>
                    <form>
                        <div className='pb-lg-5'>
                            <div className="form-group row">
                                <div className="col-md-10 col-lg-10">
                                    <label htmlFor="validationCustom01" className="form-label">User</label>
                                    <input
                                        type="text"
                                        className="p-2 form-control"
                                        id="validationCustom01"
                                        name="user"
                                        value={userName}
                                        disabled
                                        required
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                {/* <div className="col-md-5">
                                        <label htmlFor="validationCustom02" className="form-label">To User</label>
                                        <Select
                                            className=''
                                            onChange={handleToUserChange}
                                            options={ToUser}
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div> */}
                            </div>
                            <div className="form-group row mt-3">
                                <div className="col-md-10">
                                    <label htmlFor="validationCustom04" className="form-label">Date</label>
                                    <input
                                        type="date"
                                        className="p-2 form-control"
                                        id="validationCustom04"
                                        name="date"
                                        value={getCurrentDate()}
                                        required
                                        onChange={handleInputChange}
                                        disabled
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-10">
                                    <label htmlFor="validationCustom08" className="form-label">Total</label>
                                    <input
                                        type="text"
                                        className="p-2 form-control"
                                        id="validationCustom08"
                                        name="amount"
                                        value={grandTotalAmount}
                                        required
                                        disabled
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row mt-3 justify-content-between">
                                <div className="col-md-10">
                                    <label htmlFor="validationCustom12" className="form-label">Remarks</label>
                                    <textarea
                                        type=""
                                        className="p-2 form-control"
                                        id="validationCustom12"
                                        name="note"
                                        value={formData.note}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <div className='col-10 mt-5'>
                                {loading ? (
                                    <div className="addItem-btn center">
                                        <ClipLoader loading={loading} size={30} color="#fff" />
                                    </div>
                                ) : (
                                    <div className='end'>
                                        <button className='addItem-btn' type="button" onClick={() => PayOut()}>Save</button>
                                    </div>
                                )}
                            </div>
                        </div>

                    </form>
                </div>
                {cashDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Cash Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {cashDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementNoteQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{noteQuantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementNoteQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (noteQuantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    {coinDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Coin Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {coinDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{quantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (quantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    {fillDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Fill Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {fillDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementFillQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{fillQuantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementFillQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (fillQuantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
            </div>
        </div>
    );
};

export default PayOutForm;
