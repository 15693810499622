import React, { createContext, useContext, useState, useEffect } from 'react';
import apiServiceInv from '../services/apiService'; 

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currencyPrecision, setCurrencyPrecision] = useState(2); // Default to 2 initially

  const handleCurrencies = async () => {
    try {
      const res = await apiServiceInv.get('GET_CURRENCY_PER');
      const currencyPrecision = res?.data?.data?.defaultCurrencyPrecision || 2;
      setCurrencyPrecision(currencyPrecision);
    } catch (error) {
      console.error('Failed to fetch currency precision', error);
    }
  };

  useEffect(() => {
    handleCurrencies();
  }, []);

  return (
    <CurrencyContext.Provider value={{ currencyPrecision, setCurrencyPrecision }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => useContext(CurrencyContext);