import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ItemlistGrid from '../issueItems/itemsGrid';
import IssueAuthorization from '../issueItems/issueAuthorization';
import IssueMessageGrid from '../issueItems/IssueMessage';
import IssueReturnItem from './issueReturnItems';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";

const CreateIssueReturn = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [Locationlist, setLocation] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [userData, setUserdata] = useState([]);
    const [issueList, setIssueList] = useState([]);
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const [itemNo, setItemNo] = useState();
    const [loader, setLoader] = useState(false);
    const userName = useSelector(getName);
    const itemData = location.state;
    const [POTab, setPOTab] = useState(true);
    const todayDate = new Date().toISOString().substr(0, 10);
    const [formData, setFormData] = useState({
        recordId: "",
        issueNo: "",
        itemType: "",
        quatotionNo: "",
        remarks: "",
        requestor: "",
        issueDate: todayDate,
        deliveryDate: todayDate,
        currencyCode: "",
        exchangeRate: "",
        department: [],
        costCenter1: [],
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        issuedBy: "",
        taskCode: "",
        subcatname: "",
    });

    useEffect(() => {
        getCurrency()
        getUser()
        getLocation()
        getDepartment()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        setFormData({
            recordId: item?.recordId,
            requisitionDate: item?.requisitionDate || currentDatetimeUTC,
            deliveryDate: item?.deliveryDate ? item.deliveryDate.substr(0, 10) : todayDate,
            requisitionNo: item?.requisitionNo,
            itemType: item?.type,
            quatotionNo: item?.contractNo,
            remarks: item?.remarks,
            requestor: item?.requestor,
            currencyCode: item?.currencyCode,
            costCenter2: item?.costCenter2,
            costCenter3: item?.costCenter3,
            costCenter4: item?.costCenter4,
            deliveryPoint: item?.deliveryPoint,
            contactPerson: item?.contactPerson,
            contatNo: item?.contatNo,
            taskCode: item?.projectCode,
            location: item?.location,
        });
        const selectedDepartment = department?.find(dep => dep.description === item?.department)
        if (selectedDepartment) {
            setFormData(prevState => ({
                ...prevState,
                department: { label: selectedDepartment.description, value: selectedDepartment }
            }));
        }
        const selectedLocation = Locationlist?.find(dep => dep.location === item?.costCenter1)
        if (selectedLocation) {
            setFormData(prevState => ({
                ...prevState,
                costCenter1: { label: selectedLocation.location, value: selectedLocation }
            }));
        }
    }, [location?.state, department, Location]);
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=Requisition`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result); // Only set the first record
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        if (itemData) {
            setItemNo(itemData.requisitionNo);
        } else {
            let docParam = `?TypeOfDoc=IR&DocId=IR`
            let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
            setItemNo(DocId?.data.result[0].docId);
        }
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        setFormData({
            exchangeRate: filteredDenominations?.conversionRate
        })
        setFormData({ ...formData, exchangeRate: filteredDenominations?.conversionRate });
    }
    const getLocation = async () => {
        let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
        const response = await apiService.get('GET_ITEM_ISSUE');
        let responseSort = response?.data?.result.reverse()
        setIssueList(responseSort)
        setLocation(res?.data?.result)
    }
    const getDepartment = async () => {
        let res = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
        setDepartment(res?.data?.result)
    }
    const getUser = async () => {
        try {
            const token = localStorage.getItem('Token');
            let userId = userManagement.getUserId(token)
            let Id = `?id=${userId}`
            const apiService2 = new ApiService2()
            let res = await apiService2.getApiParamater('GET_USERS', Id)
            setUserdata(res?.data?.result)
        } catch (error) {
            console.log(error)
        }

    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const staticOptions = [];
    const totalAmount = requisitionData.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const CreatePR = async () => {
        setLoader(true)
        
        if (!formData.location) {
            toast.warning("Select location");
            setLoader(false)
            return;
        }
        if (!formData.remarks) {
            toast.warning("Select remarks");
            setLoader(false)
            return;
        }
        if (requisitionData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        try {
            
            const issueDetailDtos = requisitionData?.map(item => ({
                issueRNo: itemNo,
                isrno: parseInt(item.srno),
                srno: parseInt(item.srno),
                itemCode: item?.ItemNo,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: item.qty,
                specification: item.specification,
                unitPrice: parseFloat(item.unitPrice),
                amount: parseFloat(item.convRate * item.unitPrice),
                baseAmount: parseFloat(item.convRate * item.unitPrice),
                issueNo: formData.issueNo.label,
                cgsAccountCode: item?.cgsAcc,
                cgsAccountName: item?.cgsAccName,
            }));
            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                irDate: currentDatetimeUTC,
                issueRNo: itemNo,
                approvalUser: userName,
                issueNo: formData.issueNo.label,
                issueDate: formData.issueDate,
                // department: formData.department?.label,
                department: 'IT',
                issuedBy: userName,
                amount: totalAmount,
                baseAmount: totalAmount,
                issueReturnDetailsDtos: issueDetailDtos,
                divisionCode: formData?.issueNo?.value?.divisionCode,
                divisionName: formData?.issueNo?.value?.divisionCode,
                warehouse: formData?.location.label,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency.value.conversionRate,
                status: 1,
                approvalDate: currentDatetimeUTC,
                remarks: formData.remarks,
            };
            
            const response = await apiService.post("CREATE_ISSUE_RETURN", userData);
            if (response?.data?.statusCode === 201) {
                const docNumber = response?.data?.result?.issueNo
                const userData2 = {
                    docType: "IReturn",
                    docId: docNumber ? docNumber.toString() : itemNo,
                    department: "IT",
                    company: "Qfinity",
                };
                const docBody = {
                    docId: "IR",
                    typeOfDoc: "IR",
                };
                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await apiService.put("UPDATE_DOC_ID", docBody);
                toast.success(`${itemNo} Created successfully`);
                setLoader(false)
                setFormData({
                    itemType: "",
                    quatotionNo: "",
                    remarks: "",
                    requestor: "",
                    requisitionDate: "",
                    deliveryDate: "",
                    currencyCode: "",
                    department: "",
                    costCenter1: "",
                    costCenter2: "",
                    costCenter3: "",
                    costCenter4: "",
                    deliveryPoint: "",
                    location: "",
                    contactPerson: "",
                    contatNo: "",
                    taskCode: "",
                });
                navigate("/inventory/Issue/Return")
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const UpdatePR = async () => {

    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const handleDepChange = (selectedOption) => {
        setFormData({ ...formData, department: selectedOption });
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    };
    const handleIssueNoChange = (selectedOption) => {
        
        const selectedLocation = Locationlist?.find(dep => dep.description === selectedOption?.value.warehouse);
        const selectedDepartment = department?.find(dep => dep.description === selectedOption?.value.department);
        const utcDate = new Date(selectedOption?.value.issueDate);
        const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        setFormData({
            ...formData,
            issueNo: selectedOption,
            issuedBy: selectedOption?.value.issuedBy,
            issueDate: localDate,
            location: selectedLocation ? { label: selectedLocation.description, value: selectedLocation } : null,
            department: selectedDepartment ? { label: selectedDepartment.description, value: selectedDepartment } : null,
        });
    };
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='d-flex justify-content-between col mainInvHead'>Issue Return</h5>
                <div className='d-flex gap-3'>
                    {itemData?.eye === true ? " " :
                        <>
                            {location.state ? (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={UpdatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Update</div>
                                    </button>
                                }
                                </>
                            ) : (
                                <>{loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <button className='mainbtn ' onClick={CreatePR} type="submit">
                                        <FaSave className='center mt-1 mx-2' /><div>Save</div>
                                    </button>
                                }
                                </>
                            )}</>
                    }
                    {itemData?.eye === true ? " " : <button className='mainbtnRed' ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>}
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Issue Return Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="itemNo" className="form-label  pt-2 start">Stock Return No</label>
                                <input
                                    className="form-Input w-70"
                                    readOnly
                                    value={itemNo}
                                    name="itemNo"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Issue No</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} value={formData.issueNo}
                                        options={issueList?.map((dep) => ({ value: dep, label: dep?.issueNo }))}
                                        onChange={(selectedOption) => handleIssueNoChange(selectedOption)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Issue Item Date </label>
                                {itemData?.eye === true ?
                                    <input
                                        className='form-Input w-70'
                                        type='date' readOnly value={formData.issueDate}
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        type='date' readOnly
                                        value={formData.issueDate}
                                        name="issueDate"
                                        onChange={handleInputChange}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Currency</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled value={SelectedCurrency} /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                        isSearchable={false}
                                        value={SelectedCurrency}
                                        onChange={(e) => handlecurChange(e)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="exchangeRate" className="form-label  pt-2 start">Exchange Rate</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.exchangeRate} className="form-Input w-70" /> :
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        disabled
                                        onWheel={(e) => e.target.blur()}
                                        id="exchangeRate"
                                        value={formData.exchangeRate}
                                        onChange={handleInputChange}
                                        name="exchangeRate"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="catcode" className="form-label  pt-2 start">Date </label>
                                {itemData?.eye === true ?
                                    <input
                                        className='form-Input w-70'
                                        type='date' disabled value={formData.deliveryDate}
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        type='date' value={formData.deliveryDate}
                                        name="deliveryDate"
                                        onChange={handleInputChange}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="department" className="form-label  pt-2 start">Department</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.department} className="form-Input w-70" /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} value={formData.department}
                                        options={department?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        onChange={(selectedOption) => handleDepChange(selectedOption)}
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="issuedBy" className="form-label  pt-2 start">Issued By</label>
                                {itemData?.eye === true ?
                                    <input disabled value={formData.issuedBy} className="form-Input w-70" /> :
                                    <input
                                        type="text"
                                        className="form-Input w-70"
                                        id="issuedBy"
                                        value={formData.issuedBy}
                                        onChange={handleInputChange}
                                        name="issuedBy"
                                    />
                                }
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Ware House</label>
                                {itemData?.eye === true ?
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} isDisabled /> :
                                    <Select
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }} value={formData.location}
                                        options={Locationlist?.map((loc) => ({ value: loc, label: loc?.description }))}
                                        onChange={(selectedOption) => handlelocChange(selectedOption)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row ">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="remarks" className="form-label  pt-2 start">Remarks</label>
                                {itemData?.eye === true ?
                                    <input disabled
                                        className="form-Input w-70"
                                        value={formData.remarks}
                                    /> :
                                    <input
                                        name="remarks"
                                        type='text'
                                        className="form-Input w-70"
                                        id="remarks"
                                        value={formData.remarks}
                                        onChange={handleInputChange}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <IssueReturnItem onRequisitionData={handleRequisitionData} itemData={itemData} formData={formData} />
                )}
                {gridVisibility[1] && (
                    <IssueAuthorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <IssueMessageGrid handleMessage={handleMessage} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default CreateIssueReturn