import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiService from '../../../services/apiService';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select from 'react-select';
import userManagement from '../../../services/userManagement';
import { TableStatus } from '../../../../Enums/config';

const NewReservation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tables, setTables] = useState([]);
    const [selectedArea, setSelectedArea] = useState();
    const [employeeData, setEmployeeData] = useState({});
    const [restaurantArea, setRestaurantArea] = useState([]);
    const currentDatetime = new Date().toISOString();

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        selectedTable: '',
        mobile: '',
        reservedDateFrom: '',
        reservedDateTo: ''
    });

    useEffect(() => {
        if (location.state?.reservation) {
            const { reservation } = location.state;
            setFormData({
                id: reservation.id,
                name: reservation.reservedByName,
                selectedTable: { value: reservation, label: reservation?.name },
                mobile: reservation.reservedByPhone,
                reservedDateFrom: moment(reservation.reservedDateFrom).format('YYYY-MM-DDTHH:mm'),
                reservedDateTo: moment(reservation.reservedDateTo).format('YYYY-MM-DDTHH:mm')
            });
            setSelectedArea(reservation.area);
        }
        GetEmployee();
    }, [location.state]);

    useEffect(() => {
        if (selectedArea) {
            GetTable();
        }
    }, [selectedArea]);

    const GetTable = async (id) => {
        try {
            setTables([]);
            const apiService = new ApiService();
            const parameters = `?Area=${id}`;
            const res = await apiService.getApiParamater('GET_TABLE', parameters);
            let updateresponse = res?.data?.result.filter(area => area.status === TableStatus.Available)
            
            setTables(updateresponse);
        } catch (error) {
            console.error(error)
        }

    };

    const GetEmployee = async () => {
        try {
            const apiService = new ApiService();
            const token = localStorage.getItem('Token');
            const UserId = userManagement.getUserId(token);
            const parameters = `?UserId=${UserId}`;
            const res = await apiService.getApiParamater('GET_EMPLOYEE', parameters);
            setEmployeeData(res?.data?.result[0]);
            fetchKitchenArea(res?.data?.result[0].restaurantId);
        } catch (error) {
            console.log(error)
        }

    };

    const fetchKitchenArea = async (restaurantId) => {
        try {
            const apiService = new ApiService();
            const parameters = `?ResturentId=${restaurantId}`;
            const response = await apiService.getApiParamater('GET_RESTAURANT_AREA', parameters);
            const updatedKitchen = response?.data?.result;
            setRestaurantArea(updatedKitchen);
            setSelectedArea(updatedKitchen.find(area => area.id === selectedArea?.id));
        } catch (error) {
            console.error('Error fetching areas:', error);
        }
    };

    const handleAreaChange = (selectedFilter) => {
        setSelectedArea(selectedFilter?.value);
        GetTable(selectedFilter?.value?.id)
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleTableChange = (selectedOption) => {
        setFormData({ ...formData, selectedTable: selectedOption });
    };

    const handleUpdateTable = async () => {
        try {
            const datareq = {
                id: formData.id || formData.selectedTable.value.id,
                loggedInUserId: formData.selectedTable.value.loggedInUserId,
                name: formData.selectedTable.value.name,
                seatCapacity: formData.selectedTable.value.seatCapacity,
                topPosition: formData.selectedTable.value.topPosition,
                leftPosition: formData.selectedTable.value.leftPosition,
                shape: formData.selectedTable.value.shape,
                area: formData.selectedTable.value.area,
                chirsFilledCount: formData.selectedTable.value.chirsFilledCount,
                status: TableStatus.Reserved,
                active: formData.selectedTable.value.active,
                reservedByName: formData.name,
                reservedByPhone: formData.mobile,
                reservedDateTo: formData.reservedDateTo,
                reservedDateFrom: moment(formData.reservedDateFrom).format('YYYY-MM-DDTHH:mm')
            };
            
            const apiService = new ApiService();
            const res = await apiService.put('UPDATE_TABLE', datareq);

            if (res?.data?.statusCode === 200) {
                toast.success('Table reserved successfully');
                navigate('/Cashier/Reserved');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='container'>
            <div className='px-4'>
                <div className='form-group row mt-3 justify-content-between'>
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="name"
                            onChange={handleInputChange}
                            name="name"
                            value={formData.name}
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="mobile" className="form-label">Mobile No</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="mobile"
                            name="mobile"
                            onChange={handleInputChange}
                            value={formData.mobile}
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                </div>


                <div className='form-group row mt-3 justify-content-between'>

                    <div className="col-md-5">
                        <label htmlFor="area" className="form-label">Select Area</label>
                        <Select
                            onChange={handleAreaChange}
                            options={restaurantArea?.map((sup) => ({ value: sup, label: sup?.name }))}
                        />
                    </div>
                    <div className="col-md-5 col-lg-5">
                        <label htmlFor="table" className="form-label">Select Table</label>
                        <Select
                            defaultValue={formData.selectedTable}
                            onChange={handleTableChange}
                            options={tables?.map(tab => ({ value: tab, label: tab.name }))}
                        />
                    </div>

                </div>
                <div className='form-group row mt-3 justify-content-between'>

                    <div className="col-md-5">
                        <label htmlFor="reservedDateFrom" className="form-label">Schedule From</label>
                        <input
                            type="datetime-local"
                            className="p-2 form-control"
                            id="reservedDateFrom"
                            name="reservedDateFrom"
                            min={currentDatetime.slice(0, -8)}
                            onChange={handleInputChange}
                            value={formData.reservedDateFrom}
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="reservedDateTo" className="form-label">Schedule TO</label>
                        <input
                            type="datetime-local"
                            className="p-2 form-control"
                            id="reservedDateTo"
                            name="reservedDateTo"
                            min={currentDatetime.slice(0, -8)}
                            onChange={handleInputChange}
                            value={formData.reservedDateTo}
                            required
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                </div>

            </div>
            <div className='mt-3 px-4'>
                {location?.state?.reservation ?
                    <button className='saveButton' onClick={handleUpdateTable}>
                        Update
                    </button>
                    :
                    <button className='saveButton' onClick={handleUpdateTable}>
                        Save
                    </button>
                }
            </div>
        </div>
    );
}

export default NewReservation;
