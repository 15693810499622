import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import ApiService2 from '../../../POS/services/apiService';
import { toast } from 'react-toastify';
import userManagement from '../../../POS/services/userManagement';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ItemlistGrid from '../issueItems/itemsGrid';
import IssueAuthorization from '../issueItems/issueAuthorization';
import IssueMessageGrid from '../issueItems/IssueMessage';
import DirectItems from './itemGrid';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';

const UpdateIssueDirect = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const apiService = new ApiService();
    const [messageData, setMessageData] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [department, setDepartment] = useState([]);
    const [Locationlist, setLocation] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [usersDetails, setUser] = useState([]);
    const [userData, setUserdata] = useState([]);
    const [loader, setLoader] = useState(false);
    const [itemNo, setItemNo] = useState();
    let navigate = useNavigate();
    const currentDatetimeUTC = new Date().toISOString();
    const location = useLocation();
    const [SelectedIssue, setSelectedIssue] = useState();
    const userName = useSelector(getName);
    const itemData = location.state;
    const todayDate = new Date().toISOString().substr(0, 10);
    const [formData, setFormData] = useState({
        recordId: "",
        requisitionNo: "",
        itemType: "",
        description: "",
        remarks: "",
        requestor: "",
        requisitionDate: currentDatetimeUTC,
        date: todayDate,
        currencyCode: "",
        exchangeRate: "",
        department: [],
        costCenter1: [],
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        deliveryPoint: "",
        fromLocation: [],
        toLocation: [],
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        subcatname: "",
    });

    useEffect(() => {
        getCurrency()
        getUser()
        getLocation()
        getDepartment()
        getApprovalUser()
    }, [])
    useEffect(() => {
        const item = location.state;
        
        if (item.docno) {
            const fetchData = async (item) => {
                const apiService = new ApiService();
                const res = await apiService.get('GET_ITEM_ISSUE');
                const selectedIssue = res.data.result?.find(itm => itm?.issueNo === item.docno)
                const selectedDepartment = department?.find(dep => dep.description === selectedIssue?.department)
                const fromLocation = Locationlist?.find(dep => dep?.description === selectedIssue?.warehouse)
                const toLocation = Locationlist?.find(dep => dep?.description === selectedIssue?.divisionCode)
                setSelectedIssue(selectedIssue)
                setFormData({
                    recordId: selectedIssue?.issueNo,
                    date: selectedIssue?.issueDate || currentDatetimeUTC,
                    deliveryDate: selectedIssue?.deliveryDate ? selectedIssue.deliveryDate.substr(0, 10) : todayDate,
                    description: selectedIssue?.description,
                    refNo: selectedIssue?.issueRefNo,
                    remarks: selectedIssue?.remarks,
                    fromLocation: { label: fromLocation?.description, value: fromLocation },
                    toLocation: { label: toLocation?.description, value: toLocation },
                    department: { label: selectedDepartment?.description, value: selectedDepartment }
                });
            }
            fetchData(item)
        } else {
            const selectedDepartment = department?.find(dep => dep.description === item?.department)
            const fromLocation = Locationlist?.find(dep => dep?.description === item?.warehouse)
            const toLocation = Locationlist?.find(dep => dep?.description === item?.divisionCode)
            setFormData({
                recordId: item?.issueNo,
                date: item?.issueDate || currentDatetimeUTC,
                deliveryDate: item?.deliveryDate ? item.deliveryDate.substr(0, 10) : todayDate,
                description: item?.description,
                refNo: item?.issueRefNo,
                remarks: item?.remarks,
                fromLocation: { label: fromLocation?.description, value: fromLocation },
                toLocation: { label: toLocation?.description, value: toLocation },
                department: { label: selectedDepartment?.description, value: selectedDepartment }
            });
        }
        setItemNo(item?.issueNo || item?.docno)
    }, [location?.state, department, Locationlist]);
    const getApprovalUser = async () => {
        try {
            let param = `?DocType=Requisition`;
            const response = await apiService.getApiParamater('GET_APPROVAL_USER', param);
            if (itemData) {
                setItemNo(itemData.requisitionNo);
            } else {
                let docParam = `?TypeOfDoc=STI&DocId=Issue`
                let DocId = await apiService.getApiParamater('GET_DOC_ID', docParam)
                setItemNo(DocId?.data.result[0].docId);
            }
            if (response?.data?.result && response.data.result.length > 0) {
                setUser(response.data.result); // Only set the first record
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    let param = `?isActive=true`
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        // setFormData({
        //     exchangeRate: filteredDenominations?.conversionRate
        // })
        setFormData({ ...formData, exchangeRate: filteredDenominations?.conversionRate });
    }
    const getLocation = async () => {
        let res = await apiService.getApiParamater('GET_WAREHOUSE_MASTER', param)
        setLocation(res?.data?.result)
    }
    const getDepartment = async () => {
        let res = await apiService.getApiParamater('GET_DEPARTMENT_MASTER', param)
        setDepartment(res?.data?.result)
    }
    const getUser = async () => {
        try {
            const token = localStorage.getItem('Token');
            let userId = userManagement.getUserId(token)
            let Id = `?id=${userId}`
            const apiService2 = new ApiService2()
            let res = await apiService2.getApiParamater('GET_USERS', Id)
            setUserdata(res?.data?.result)
        } catch (error) {
            console.log(error)
        }

    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const staticOptions = [];
    const totalAmount = requisitionData.reduce((sum, row) => sum + (row?.qty * row.unitPrice || 0), 0);

    const UpdateIssue = async () => {
        setLoader(true)
        if (!formData.fromLocation) {
            toast.warning("Enter from location");
            setLoader(false)
            return;
        }
        if (requisitionData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        if (!formData.toLocation) {
            toast.warning("Enter To location");
            setLoader(false)
            return;
        }
        for (const item of requisitionData) {
            if (!item.qty) {
                toast.warning(`Please enter ${item.itemDescription} quantity`);
                setLoader(false);
                return;
            }
        }
        try {
            const issueDetailDtos = requisitionData?.map(item => ({
                srno: parseInt(item.srno),
                itemCode: item?.itemNo,
                itemDescription: item?.itemDescription,
                uom: item?.uom,
                qty: parseInt(item?.qty),
                specification: item?.specification,
                convValue: parseFloat(item?.baseQty),
                unitPrice: parseFloat(item?.unitPrice),
                amount: parseFloat(item?.amount),
                baseAmount: parseFloat(item?.amount),
                issueNo: itemNo,
                rsrno: parseInt(item.srno),
                itemDetailId: 0,
                received: 0,
                stockSrNo: 0,
                cgsAccountCode: "string",
                cgsAccountName: "string",
                prodDate: moment(formData.date).format(),
                expDate: moment(item.expDate).format(),
            }));
            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                issueNo: itemNo,
                issueDate: moment(formData.date).format(),
                description: formData.description,
                // department: formData.department.label,
                department: "IT",
                issuedBy: userName,
                warehouse: formData.fromLocation.label,
                issueRefNo: formData.refNo,
                currencyCode: SelectedCurrency.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                amount: totalAmount,
                baseAmount: totalAmount,
                status: 0,
                approvalDate: currentDatetimeUTC,
                remarks: formData.remarks,
                costCenter1: "",
                costCenter2: "",
                costCenter3: "",
                costCenter4: "",
                divisionCode: formData.toLocation.label,
                divisionName: formData.toLocation.label,
                issueType: "GENERAL",
                issueDetailDtos: issueDetailDtos,
                modifiedBy: userName,
            };
            const response = await apiService.put("UPDATE_ITEM_ISSUE", userData);
            if (response?.data?.statusCode === 201) {
                toast.success(`${itemNo} Updated successfully`);
                setLoader(false)
                setFormData({
                    itemType: "",
                    quatotionNo: "",
                    remarks: "",
                    requestor: "",
                    requisitionDate: "",
                    deliveryDate: "",
                    currencyCode: "",
                    department: "",
                    costCenter1: "",
                    costCenter2: "",
                    costCenter3: "",
                    costCenter4: "",
                    deliveryPoint: "",
                    location: "",
                    contactPerson: "",
                    contatNo: "",
                    taskCode: "",
                });
                navigate("/inventory/Issue/Direct")
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const handleDepToChange = (selectedOption) => {
        setFormData({ ...formData, department: selectedOption });
    };
    const handlelocChange = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    };
    const handlelocFromChange = (selectedOption) => {
        setFormData({ ...formData, fromLocation: selectedOption });
    };
    const handlelocToChange = (selectedOption) => {
        setFormData({ ...formData, toLocation: selectedOption });
    };
    return (
        <div className='p-5'>
            <div className='h4'>
                Issue Inventory Direct
            </div>
            <div className='brderbox p-lg-3 mb-5'>
                <div className="form-group justify-content-between row pb-3">
                    <div className="col">
                        <label htmlFor="recordId" className="form-label">Issue Note No</label>
                        <input
                            className="form-control"
                            disabled
                            value={itemNo}
                            name="itemNo"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="subcatname" className="form-label">Department</label>
                        <Select value={formData.department} isDisabled={itemData.eye === true ? true : false}
                            options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                            onChange={(selectedOption) => handleDepToChange(selectedOption)}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Property Group</label>
                        <Select isDisabled={itemData.eye === true ? true : false}
                            options={staticOptions}
                            onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="catcode" className="form-label">Date </label>
                        <input
                            className='form-control'
                            type='date'
                            disabled={itemData.eye === true ? true : false}
                            value={formData.date}
                            defaultValue={new Date().toISOString().substr(0, 10)}
                            name="date"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="description" className="form-label">Description</label>
                        <input
                            type="text"
                            className="form-control"
                            disabled={itemData.eye === true ? true : false}
                            id="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            name="description"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="subcatname" className="form-label">Property</label>
                        {itemData?.eye === true ?
                            <Select isDisabled value={formData.costCenter1} /> :
                            <Select
                                value={formData?.costCenter1}
                                onChange={(selectedOption) => handlelocChange(selectedOption)}
                                options={Locationlist?.map((loc) => ({ value: loc, label: loc?.location }))}
                            />}
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Currency</label>
                        <Select isDisabled={itemData.eye === true ? true : false}
                            isSearchable={false}
                            value={SelectedCurrency}
                            className="Currency-dropdown mbl-dropdown"
                            onChange={(e) => handlecurChange(e)}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Issued By</label>
                        <Select isDisabled={itemData.eye === true ? true : false}
                            value={{ label: userName, value: userName }}
                            onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Cost Centre 3</label>
                        <Select isDisabled={itemData.eye === true ? true : false}
                            options={staticOptions}
                            onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                        />
                    </div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col">
                        <label htmlFor="exchangeRate" className="form-label">Exchange Rate</label>
                        <input
                            type="number"
                            className="form-control"
                            disabled
                            onWheel={(e) => e.target.blur()}
                            id="exchangeRate"
                            value={formData.exchangeRate}
                            onChange={handleInputChange}
                            name="exchangeRate"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="contactPerson" className="form-label">Ref No</label>
                        <input
                            type="text"
                            className="form-control"
                            disabled={itemData.eye === true ? true : false}
                            id="contactPerson"
                            value={formData.contactPerson}
                            onChange={handleInputChange}
                            name="contactPerson"
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="inventoryAccountCode" className="form-label">Outlets</label>
                        <Select
                            isDisabled={itemData.eye === true ? true : false}
                            options={staticOptions}
                            onChange={(selectedOption) => handleInputChange('inventoryAccountCode', selectedOption)}
                        />
                    </div>
                </div>
                <div className="form-group row pb-3">
                    <div className="col-4">
                        <label htmlFor="inventoryAccountCode" className="form-label">From Location </label>
                        <Select
                            isDisabled={itemData.eye === true ? true : false}
                            value={formData.fromLocation}
                            options={Locationlist?.map((loc) => ({ value: loc, label: loc?.description }))}
                            onChange={(selectedOption) => handlelocFromChange(selectedOption)}
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="remarks" className="form-label">Remarks</label>
                        <input
                            name="remarks"
                            type='text'
                            className="form-control"
                            disabled={itemData.eye === true ? true : false}
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleInputChange}
                        />
                    </div>
                    {itemData?.eye === true ?
                        <div className=' col-lg-2 mt-4 brderbox p-3'>
                            <a href='' disabled>
                                Attachment
                            </a>
                        </div> :
                        <div className=' col-lg-2 mt-4 brderbox p-3'>
                            <a href=''>
                                Attachment
                            </a>
                        </div>}
                    <div className='col-2'></div>
                </div>
                <div className="form-group row justify-content-between pb-3">
                    <div className="col-4">
                        <label htmlFor="inventoryAccountCode" className="form-label"> To Location</label>
                        <Select
                            value={formData.toLocation}
                            isDisabled={itemData.eye === true ? true : false}
                            options={Locationlist?.map((loc) => ({ value: loc, label: loc?.description }))}
                            onChange={(selectedOption) => handlelocToChange(selectedOption)}
                        />
                    </div>
                </div>
            </div>
            <div className="topnav2 maon-BG ">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Requisition
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <DirectItems onRequisitionData={handleRequisitionData} itemData={itemData} formData={formData} />
                )}
                {gridVisibility[1] && (
                    <IssueAuthorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                        formData={formData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <IssueMessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {itemData?.eye === true ? " " :
                    <div className='d-flex justify-content-end pt-4'>
                        {loader === true ?
                            <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                            <button className='mainbtn ' onClick={UpdateIssue} type="submit">Update</button>
                        }
                    </div>}
            </div>
        </div>
    )
}

export default UpdateIssueDirect