// OrderSection.js
import React from 'react';
import { useDrop } from 'react-dnd';
import "../order.css"
const Section = ({ status, onDrop, children }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'ORDER',
    drop: (item) => onDrop(item.id, status),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
 
  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className='section-contain'>
      <div className={`status-${status === 'NewOrder' ? 'Pre' : status === 'Ready' ? 'ready' : status === 'Cancel' ? 'cancel' : status === 'Delivered' ? 'done'  : 'contain' }`}>
        {status}
      </div>
      <div className='pt-4 DnDOvrflow'>
        {children}
      </div>
    </div>
  );
};

export default Section;
