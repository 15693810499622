import { React, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as List } from '../../../assets/images/svgIcons/list.svg';
import { ReactComponent as Next } from '../../../assets/images/svgIcons/pagination_right.svg';
import { ReactComponent as Previous } from '../../../assets/images/svgIcons/pagination_left.svg';
import "./order.css"
import ApiService from '../../../services/apiService';
import TelericGrid from './telericGrid/telericGrid';
import Select from 'react-select';

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setstatus] = useState('');


  useEffect(() => {
    GetOrders();
  }, [searchTerm, status]);

  let navigate = useNavigate();
  const ItemSourse = [
    { value: '0', label: 'New Order' },
    { value: '2', label: 'Preparing' },
    { value: '3', label: 'Ready For Delivery' },
    { value: '4', label: 'Ready For Pickup' },
    { value: '6', label: 'Completed' },
    { value: '7', label: 'Canceled' },
  ];
  const handleDND = () => {
    navigate('/admin/ordersDND');
  };

  const singleItem = (e) => {
    navigate(`/admin/order/${e?.id}/${e?.customerName}`, { state: e });
  };

  const GetOrders = async () => {
    try {
      const apiService = new ApiService();
      let parameters = `?OrderStatus=${status}`;
      let res = await apiService.getApiParamater("GET_ORDERS", parameters);
      if (Array.isArray(res?.data?.result)) {
        res.data.result.reverse();
        setOrders(res.data.result);
      } else {
        console.error("Invalid data format received from the API");
      }
    } catch (error) {
      console.error("Error fetching Orders:", error);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleStatusChange = (selectedOption) => {
    setstatus(selectedOption?.value);
  }
  return (
    <div className="container-fluid m-0 p-0 px-lg-5">
      <div className='d-flex gap-4 pt-5'>
        {/* <input
          type='Search'
          className='form-control  w-25'
          id='validationCustom01'
          placeholder='Search Orders By Customer'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        /> */}
        <Select
          defaultValue={status}
          className='w-25'
          onChange={handleStatusChange}
          options={ItemSourse}
        />
        <List className='filter' onClick={handleDND} />
      </div>
      <div className='pt-lg-5 p-0 m-0'>
        <TelericGrid orders={orders} />
      </div>
    </div>
  )
}

export default Order