import React, { useState, useEffect } from "react";
import "./sideBar.css";
import Logo from "../../../assets/images/yourlogo.png";
import BlueLogo from '../../../assets/images/blueCoco.png';
import BingoLogo from "../../../assets/images/Bingo.png";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Dashboard } from '../../../assets/images/svgIcons/Dashboard.svg';
import { ReactComponent as DashboardInactive } from '../../../assets/images/svgIcons/DashboardinActive.svg';
import { ReactComponent as Menu } from '../../../assets/images/svgIcons/menu.svg';
import { ReactComponent as Menuinactive } from '../../../assets/images/svgIcons/menu-inactive.svg';
import { ReactComponent as Order } from '../../../assets/images/svgIcons/order.svg';
import { ReactComponent as OrderInactive } from '../../../assets/images/svgIcons/order-inactive.svg';
import { ReactComponent as Costumer } from '../../../assets/images/svgIcons/costumer.svg';
import { ReactComponent as Employee } from '../../../assets/images/svgIcons/employee.svg';
import { ReactComponent as EmployeeActive } from '../../../assets/images/svgIcons/Employee-Inactive.svg';
import { ReactComponent as Reports } from '../../../assets/images/svgIcons/reports.svg';
import { ReactComponent as Inventory } from '../../../assets/images/svgIcons/inventory.svg';
import { ReactComponent as Maintenance } from '../../../assets/images/svgIcons/Maintenance.svg';
import { ReactComponent as Payment } from '../../../assets/images/svgIcons/payment.svg';
import { ReactComponent as Wastage } from '../../../assets/images/svgIcons/wastage.svg';
import { ReactComponent as WearHouse } from '../../../assets/images/svgIcons/wearHouse.svg';
import { ReactComponent as RestaurantActive } from '../../../assets/images/svgIcons/Restaurants_Active.svg';
import { ReactComponent as RestaurantInActive } from '../../../assets/images/svgIcons/Restaurants-InActive.svg';
import { ReactComponent as MasterActive } from '../../../assets/images/svgIcons/MasterActive.svg';
import { ReactComponent as MAsterInActive } from '../../../assets/images/svgIcons/Master.svg';
import { ReactComponent as ItemMaster } from '../../../assets/images/svgIcons/ItemMaster.svg';
import { ReactComponent as UserRole } from '../../../assets/images/svgIcons/UserRole.svg';


import { Link } from "react-router-dom";
import { FaCog, FaPlus, FaTh, FaLayerGroup, FaPuzzlePiece, FaMoneyBillWave, FaPizzaSlice, FaFileUpload, FaUserClock, FaUsersCog } from 'react-icons/fa';
import { BsCurrencyExchange } from "react-icons/bs";
import { FaKitchenSet } from "react-icons/fa6";
import { MdOutlineTableBar, MdOutlinePayments } from "react-icons/md";
import { TbSpeakerphone } from "react-icons/tb";
import { CiDiscount1 } from "react-icons/ci";
import userManagement from "../../../services/userManagement"
import { FiUser } from "react-icons/fi";
import ReceiptModal from "../reports/xReport";
const SideBar = () => {

    const location = useLocation();
    const [showMasterSubMenu, setShowMasterSubMenu] = useState(false);
    const [showUserMasterSubMenu, setShowUserMasterSubMenu] = useState(false);
    const [showItemMasterSubMenu, setShowItemMasterSubMenu] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [totalSale, setTotalSale] = useState();
    const [SaleDetails, setSaleDetails] = useState();
    const [OrdersSale, setOrdersSale] = useState();
    const [OrdersHistory, setOrdersHistory] = useState();
    const [SaleReport, setSaleReport] = useState();
    const [TaxReport, setTaxReport] = useState();

    const handleLinkClick = (e) => {
        e.preventDefault(); // Prevent the default navigation behavior
        setIsModalVisible(true); // Open the modal
    };
    let token = localStorage.getItem("Token")
    let Permission = userManagement?.getUserCrud(token)
    useEffect(() => {
        let token = localStorage.getItem("Token")
        let Permission = userManagement?.getUserCrud(token)
        const isTotalSaleEnable = Permission?.includes("Total Sale.add") && Permission.includes("Total Sale.query");
        setTotalSale(!isTotalSaleEnable)
        const saleDetails = Permission?.includes("Sale Details.add") && Permission.includes("Sale Details.query");
        setSaleDetails(!saleDetails)
        const OrdersSales = Permission?.includes("Orders Sale.add") && Permission.includes("Orders Sale.query");
        setOrdersSale(!OrdersSales)
        const OrderHistory = Permission?.includes("Order History.add") && Permission.includes("Order History.query");
        setOrdersHistory(!OrderHistory)
        const saleReport = Permission?.includes("Sale Report.add") && Permission.includes("Sale Report.query");
        setSaleReport(!saleReport)
    }, []);
    return (
        <nav class="navbar navbar-expand-md  fixed-left ">
            <a href="/home" class="navbar-brand center" className="logo"><img style={{ height: "36px" }} src={Logo} alt="Logo" /></a>
            {/* <a href="/home" class="navbar-brand center" className="logo"><img src={BingoLogo} alt="Logo"  /></a> */}
            {/* <a href="/home" class="navbar-brand center" className="logo"><img src={BlueLogo} alt="Logo"  style={{ width: "110px" }}/></a> */}
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
                aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation"
            // onClick={toggleMobileMenu}
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            {/* {isSidebarEnabled && isMobileMenuOpen && ( */}
            <div class="collapse navbar-collapse ovrflo" id="navbarsExampleDefault">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <NavLink to="/home" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/home" ? (
                                <>
                                    <Dashboard size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt" >Home</h6>
                                    </a>

                                </>
                            ) : (
                                <>
                                    <DashboardInactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Home</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/menu" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/menu" ? (
                                <>
                                    <Menuinactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Menus</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Menu size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Menus</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/orders" className="nav-link align-middle" activeClassName="active">

                            {location.pathname === "/admin/orders" ? (
                                <>
                                    <OrderInactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Orders</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Order size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Orders</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <NavLink to="/admin/Payment" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/Payment" ? (
                                <>
                                    <Menuinactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Payment</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Payment size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Payment</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/CustomerList" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/CustomerList" ? (
                                <>
                                    <Menuinactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Customer</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Costumer size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Customer</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/employee" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/employee" ? (
                                <>
                                    <EmployeeActive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Employees</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Employee size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Employees</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    {/* <li class="nav-item">
                        <NavLink to="/costumer/wastage" className="nav-link align-middle" activeClassName="active">
                            <Wastage size={25} className="" />
                            <a className="nav-link align-middle">
                                <h6 className="d-none d-sm-inline inactivetxt">Wastage</h6>
                            </a>
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/Maintenance" className="nav-link align-middle" activeClassName="active">
                            <Maintenance size={25} className="" />
                            <a className="nav-link align-middle">
                                <h6 className="d-none d-sm-inline inactivetxt">Maintenance</h6>
                            </a>
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/costumer/Warehouse" className="nav-link align-middle" activeClassName="active">
                            <WearHouse size={25} className="" />
                            <a className="nav-link align-middle">
                                <h6 className="d-none d-sm-inline inactivetxt">Warehouse</h6>
                            </a>
                        </NavLink>
                    </li> */}
                 
                    <li class="nav-item">
                        <NavLink to="/admin/Restaurant" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/Restaurant" ? (
                                <>
                                    <RestaurantActive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Restaurants</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <RestaurantInActive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Restaurants</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
              
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/master/") && !showMasterSubMenu ? "active" : ""}`}>
                            {location.pathname.startsWith("/admin/master/") ? (
                                <>
                                    <MasterActive size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">Master</h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <MAsterInActive size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">Master</h6>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="dropdown-content-ex">
                            <Link to="/admin/master/CurrencyManagement" className="p-2"><FaMoneyBillWave /> Currency Management</Link>
                            <Link to="/admin/master/TaxManagement" className="p-2"><BsCurrencyExchange /> Tax Management</Link>
                            <Link to="/admin/master/paymentMethod" className="p-2"><MdOutlinePayments /> Payment Master</Link>
                            <Link to="/admin/master/Table" className="p-2"><MdOutlineTableBar /> Table Master</Link>
                            <Link to="/admin/master/area" className="p-2"><MdOutlineTableBar /> Table Area Master</Link>
                            <Link to="/admin/master/Kitchen" className="p-2"><FaKitchenSet /> Kitchen Master</Link>
                            <Link to="/admin/master/Discount" className="p-2"><CiDiscount1 /> Discount Master</Link>
                            <Link to="/admin/master/Promotion" className="p-2"><TbSpeakerphone /> Promotion Master</Link>
                        </div>
                    </li>
                    {/* <li class="nav-item">
                        <NavLink to="/admin/UserManagement/userMaster/UserRole" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin//UserRole" ? (
                                <>
                                    <Menuinactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">User Role</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Costumer size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">User Role</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li> */}
                          <li className="nav-item dropdown">
                        <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/Reports/home/") && !showMasterSubMenu ? "active" : ""}`}>
                            {location.pathname.startsWith("/admin/Reports/home/") ? (
                                <>
                                    <Reports size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">Reports & Analytics</h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Reports size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">Reports & Analytics</h6>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="dropdown-content-ab">
                            {totalSale &&
                                <Link to="/admin/Reports/Grid" className="p-2"><FaPizzaSlice />Total Sale </Link>

                            }
                            <Link to="/admin/Reports/Grid" className="p-2" onClick={handleLinkClick}>
                                <FaPizzaSlice /> X Report
                            </Link>

                     
                            {/* {SaleDetails &&
                                <Link to="/admin/Reports/SaleDetails" className="p-2"><FaPlus />Total Sale Details </Link>
                            } */}
                            {OrdersSale &&
                                <Link to="/admin/Reports/Order" className="p-2"><FaCog />Total Orders Sale </Link>
                            }
                            {OrdersHistory &&
                                <Link to="/admin/Reports/OrderHistory" className="p-2"><FaTh />Orders History</Link>
                            }
                            {/* {SaleReport &&
                                <Link to="/admin/Reports/SaleReport" className="p-2"><FaLayerGroup />Item sale Report</Link>
                            } */}
                            {/* <Link to="/admin/Reports/3rdParty" className="p-2"><FaPuzzlePiece />Third Party </Link> */}
                            {/* {TaxReport &&
                                <Link to="/admin/Reports/Tax" className="p-2"><FaMoneyBillWave />Tax Report</Link>} */}
                            {/* <Link to="/admin/Reports/Delivery" className="p-2"><BsCurrencyExchange />Delivery Report</Link> */}
                            {/* <Link to="/admin/Reports/Cash" className="p-2"><FaFileUpload />Cash In Out Report</Link> */}
                            {/* <Link to="/admin/Reports/Table" className="p-2"><MdOutlineTableBar />Table Sale Report</Link> */}
                        </div>
                    </li>
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/UserManagement/") && !showUserMasterSubMenu ? "active" : ""}`}>
                            {location.pathname.startsWith("/admin/UserManagement/") ? (
                                <>
                                    <UserRole size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">User Management</h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <UserRole size={25} className=" " />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">User Management</h6>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="dropdown-content-ex">
                            <Link to="/admin/master/userMaster/users" state={{ userId: 'users' }} className="p-2">
                                <FaUsersCog /> User Master
                            </Link>
                            <Link to="/admin/UserManagement/userMaster/UserClaim" className="p-2"><FaUserClock /> User Claim Master</Link>
                            <Link to="/admin/UserManagement/userMaster/UserRole" className="p-2"><FaUserClock /> User Role</Link>
                        </div>
                    </li>

                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/itemMaster/") && !showMasterSubMenu ? "active" : ""}`}>
                            {location.pathname.startsWith("/admin/itemMaster/") ? (
                                <>
                                    <ItemMaster size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">Item Master</h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <ItemMaster active size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">Item Master</h6>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="dropdown-content-ex">
                            <Link to="/admin/itemMaster/Combos" className="p-2"><FaPizzaSlice /> Combo Master</Link>
                            <Link to="/admin/itemMaster/addsOns" className="p-2"><FaPlus /> AddOn Master</Link>
                            <Link to="/admin/itemMaster/modifier" className="p-2"><FaCog /> Modifier Master</Link>
                            <Link to="/admin/itemMaster/addCategory" className="p-2"><FaTh /> Category Master</Link>
                            <Link to="/admin/itemMaster/subCategory" className="p-2"><FaLayerGroup /> SubCategory Master</Link>
                            <Link to="/admin/itemMaster/UploadFile" className="p-2"><FaFileUpload /> Menu File Master</Link>
                            <Link to="/admin/itemMaster/Variants" className="p-2"><FaFileUpload /> Variant Master</Link>
                            <Link to="/admin/itemMaster/CounterMaster" className="p-2"><FaFileUpload /> Counter Master</Link>


                        </div>
                    </li>

                </ul>
            </div>
            {/* )} */}
            {isModalVisible &&
            <ReceiptModal
                                isVisible={isModalVisible}
                                onClose={() => setIsModalVisible(false)}
                            />
            }

        </nav>

    );

}
export default SideBar