import { React, useState, useEffect } from 'react'
import "../../../../Customer/Menu/NavBar/navBar.css"
import "../menuItemcard.css"
import { useNavigate } from 'react-router';
import AddItem from './addItems';
import CreateAddOn from './createAddOn';
import CreateModifiers from './createModifiers';
import CreateCombo from './createCombo';
import AdminHeader from '../../header/adminHeader';

const ItemNavBar = () => {

    const navigate = useNavigate();
    const [AddItemSegment, setAddItemSegment] = useState(true);
    const [AddOnSegment, setAddOnSegment] = useState(false);
    const [ModifiersSegment, setModifiersSegment] = useState(false);
    const [CombosSegment, setCombosSegment] = useState(false);
    const [DealsSegment, setDealsSegment] = useState(false);
    const handleSegmentClick = (segment) => {
        // Reset all segments to false
        setAddItemSegment(false);
        setAddOnSegment(false);
        setModifiersSegment(false);
        setCombosSegment(false);
        setDealsSegment(false);

        // Set the clicked segment to true
        switch (segment) {
            case 'AddItem':
                setAddItemSegment(true);
                break;
            case 'AddOn':
                setAddOnSegment(true);
                break;
            case 'Modifiers':
                setModifiersSegment(true);
                break;
            case 'Combos':
                setCombosSegment(true);
                break;
            case 'Deals':
                setDealsSegment(true);
                break;
            default:
                break;
        }
    };
    return (
        <div className="">
            <div className="p-lg-5">
                        {/* <nav className='maoin-BG overflow mx-5'>
                            <div className="nav nav-pills d-flex justify-content-between" id="nav-tab" role="tablist">
                                <a className={`nav-item nav-link ${AddItemSegment ? 'active' : ''}`} onClick={() => handleSegmentClick('AddItem')}>
                                    Add item
                                </a>
                                <a className={`nav-item nav-link ${AddOnSegment ? 'active' : ''}`} onClick={() => handleSegmentClick('AddOn')}>
                                    Add-On
                                </a>
                                <a className={`nav-item nav-link ${ModifiersSegment ? 'active' : ''}`} onClick={() => handleSegmentClick('Modifiers')}>
                                    Add Modifiers
                                </a>
                                <a className={`nav-item nav-link ${CombosSegment ? 'active' : ''}`} onClick={() => handleSegmentClick('Combos')}>
                                    Make Combos
                                </a>
                                <a className={`nav-item nav-link ${DealsSegment ? 'active' : ''}`} onClick={() => handleSegmentClick('Deals')}>
                                    Make Deals
                                </a>
                            </div>
                        </nav> */}
                        <div className="tab-content" id="nav-tabContent">
                            {AddItemSegment && <div className='py-lg-5'><AddItem /></div>}
                            {AddOnSegment && <div><CreateAddOn /></div>}
                            {ModifiersSegment && <div><CreateModifiers /></div>}
                            {CombosSegment && <div><CreateCombo /></div>}
                            {DealsSegment && <div><CreateCombo /></div>}
                        </div>
                    </div>
        
        </div>
    )
}

export default ItemNavBar