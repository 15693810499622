import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import ItemGrid from './itemList';
import Authorization from './authorization';
import MessageGrid from '../purchaseRequisition/messageGrid';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import ApiService2 from '../../../POS/services/apiService';
import "../../Assets/styles/addmenu.css"
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ClipLoader } from 'react-spinners';
import moment from 'moment';

const UpdatePurchaseOrder = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemsData, setRequisitionData] = useState([]);
    const [suppliers, setSupplier] = useState([]);
    const [payments, setPayments] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const [isDiscountPercentage, setIsDiscountPercentage] = useState(true);
    const [usersDetails, setUser] = useState([]);
    const [Supplier, setSupplierData] = useState([]);
    const [poNumber, setPONumber] = useState([]);
    const [SupplierID, setSupplierID] = useState([]);
    const [SupplierContact, setSupplierContact] = useState();
    const [Payment, setPayment] = useState([]);
    const apiService2 = new ApiService2()
    const location = useLocation();
    const navigate = useNavigate();
    const [cost1, setCost1] = useState([]);
    const userName = useSelector(getName);
    const [cost2, setCost2] = useState([]);
    const [cost3, setCost3] = useState([]);
    const [Userdata, setUserdata] = useState();
    const [loader, setLoader] = useState(false);
    const [cost4, setCost4] = useState([]);
    const itemData = location.state;
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        fetchPayment()
        fetchSuplier()
        getDepartment()
        getCurrency()
        getApprovalUser()
    }, []);
    useEffect(() => {
        const item = location.state;
        const selectedpaymentTerm = payments?.find(payment => payment?.description === item?.terms);
        if (selectedpaymentTerm) {
            setPayment({ label: selectedpaymentTerm?.description, value: selectedpaymentTerm })
        }
        const selectedSuplier = suppliers?.find(sup => sup?.supplierName === item?.vendorName);
        if (selectedSuplier) {
            setSupplierData({ label: selectedSuplier?.supplierName, value: selectedSuplier })
            setSupplierID({ label: selectedSuplier?.record_Id, value: selectedSuplier })
            setSupplierContact(selectedSuplier?.phoneNo1)
        }
        const formattedPODate = item?.docDate ? formatDateToDatetimeLocal(item?.docDate) : getCurrentLocalDatetime();
        const formattedDelivDate = item.deliveryDate ? formatDateToDatetimeLocal(item.deliveryDate) : getCurrentLocalDatetime();
        const costCenter1 = cost1?.find(dep => dep.description === item?.costCenter1)
        const costCenter2 = cost2?.find(dep => dep.description === item?.costCenter2)
        const costCenter3 = cost3?.find(dep => dep.description === item?.costCenter3)
        const costCenter4 = cost4?.find(dep => dep.description === item?.costCenter4)
        setFormData({
            PayOrderNo: item?.pOrderNo,
            Remarks: item.remarks,
            DocumentDate: formattedPODate,
            deliveryDate: formattedDelivDate,
            deliveryPoint: item.deliveryPoint,
            location: item.location,
            contactPerson: item.contactPerson,
            requisitionNo: item.requisitionNo,
            Department: item.department,
            Description: item.description,
            QuotationReq: item.quotationRef,
            costCenter1: { label: costCenter1?.description, value: costCenter1 },
            costCenter2: { label: costCenter2?.description, value: costCenter2 },
            costCenter3: { label: costCenter3?.description, value: costCenter3 },
            costCenter4: { label: costCenter4?.description, value: costCenter4 },
        });
        
        setPONumber(item?.pOrderNo)
        setIsVatExcluded(item.costCenter5 === "excluded" ? true : false)
    }, [payments, suppliers, location.state, cost1, cost2, cost3, cost4]);
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        Remarks: "",
        DocumentDate: "",
        SupplierContact: "",
        deliveryDate: "",
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        requisitionNo: "",
        RequisitionType: "",
        ContactNo: "",
        currencyCode: "",
        QuotationReq: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        exchangeRate: "",
        Description: "",
        BuyerName: "",
        vendorId: "",
        vendorName: "",
        OrderType: "",
        paymentTerm: [],
        SupplierDep: [],
    });
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    const getApprovalUser = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService2.get('GET_USERS');
            const selecteduser = res?.data?.result?.find(dep => dep.name === userName)
            let approvalParam = `?Type=${"PO"}&Company=${selecteduser.company}&Department=IT`;
            const Approvalresponse = await apiService.getApiParamater('GET_APPROVAL_USER_LIST', approvalParam);
            setUserdata(selecteduser)
            setUser(Approvalresponse.data.result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')
    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    };
    const handlecurChange = (selectedOption) => {
        setFormData({ ...formData, currencyCode: selectedOption?.value?.code });
    };
    const orderType = [
        { value: 'Local Purchase', label: 'Local Purchase' },
        { value: 'Import Purchase', label: 'Import Purchase' },
        { value: 'Fixed Assist Purchase', label: 'Fixed Assist Purchase' },
        { value: 'Non Inventory Purchase', label: 'Non Inventory Purchase' },
        { value: 'Hire Purchase', label: 'Hire Purchase' },
        { value: 'Internal Pay Order', label: 'Internal Pay Order' },
        { value: 'Open Pay Order', label: 'Open Pay Order' },
    ];
    let param = `?isActive=true`
    const fetchPayment = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_EPAYMENT');
            // const activeData = response?.data?.result.filter(res => res.isActive === true);
            setPayments(response?.data?.result);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const getCurrency = async () => {
        const apiService = new ApiService()
        let res = await apiService.getApiParamater('GET_CURRENCY_MASTER', param)
        setCurrency(res?.data?.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        setFormData({
            exchangeRate: filteredDenominations?.conversionRate
        })
    }
    const fetchSuplier = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_SUPPLIER');
            // const activeData = response?.data?.result.filter(res => res.exp4 === "1");
            setSupplier(response?.data?.result);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const getDepartment = async () => {
        try {
            const apiService = new ApiService();
            let cost1 = await apiService.get('GET_COST_ONE')
            let cost2 = await apiService.get('GET_COST_TWO')
            let cost3 = await apiService.get('GET_COST_THREE')
            let cost4 = await apiService.get('GET_COST_FOUR')
            setCost1(cost1?.data?.result)
            setCost2(cost2?.data?.result)
            setCost3(cost3?.data?.result)
            setCost4(cost4?.data?.result)
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handlePayChange = (selectedOption) => {
        setPayment(selectedOption)
    };
    const handleSupplierChange = (selectedOption) => {
        setSupplierData({ label: selectedOption?.label, value: selectedOption })
        setSupplierID({ label: selectedOption?.value?.record_Id, value: selectedOption })
        setSupplierContact(selectedOption?.value?.phoneNo1)
        setPayment({ label: selectedOption?.value?.paymentTerm, value: selectedOption })
    }
    const handleDepChange = (selectedOption) => {
        setFormData({ ...formData, SupplierDep: selectedOption });
    };
    const UpdatePO = async () => {
        setLoader(true)
        if (ItemsData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        const totalAmount = ItemsData.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
        try {
            const apiService = new ApiService()
            const prDetailsData = ItemsData.map((item, index) => ({
                vatPerc: item?.vat,
                vatAmount: parseFloat(item.vatAmount),
                itemCode: item.ItemNo || item.recordId,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                baseUOM: item?.uom,
                baseQty: parseInt(item.baseQty),
                specification: item.specification,
                convValue: parseInt(item?.baseQty),
                unitPrice: parseFloat(item.baseAmount),
                amount: parseFloat(item.amount),
                baseAmount: parseFloat(item.amount),
                pOrderNo: poNumber,
                status: 0,
                vatDesc: item?.vatDec,
                vatUnitPrice: item.vatAmount / item.qty,
                vatBaseAmount: item.vatAmount,
                srno: index + 1,
                rsrno: index + 1,
                discount: parseFloat(item.discount),
                discountPerc: isDiscountPercentage === true ? 1 : 0,
                cancelledQty: 0,
                returnQty: 0,
                deliveryDate: formData.deliveryDate,
                requisitionNo: formData?.requisitionNo || poNumber,
            }));
            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                // docId: formData.RequisitionType,
                docId: "Inventory",
                currencyCode: SelectedCurrency?.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                // modifiedBy: formData.BuyerName,
                modifiedBy: userName,
                poType: "PO",
                costCenter5: isVatExcluded === true ? "excluded" : "Included",
                amount: parseFloat(totalAmount),
                baseAmount: parseFloat(totalAmount),
                quotationRef: formData?.QuotationReq,
                pOrderNo: itemData.pOrderNo,
                terms: Payment.label,
                vendorId: SupplierID?.label.toString(),
                vendorName: Supplier?.label,
                docDate: currentDatetimeUTC,
                attention: SupplierContact,
                location: formData?.location,
                department: formData?.Department,
                deliveryPoint: formData?.deliveryPoint,
                contactPerson: formData?.contactPerson,
                status: ItemsData?.status,
                description: formData?.Description,
                remarks: formData?.Remarks,
                costCenter1: formData?.costCenter1.label,
                costCenter2: formData?.costCenter2.label,
                costCenter3: formData?.costCenter3.label,
                costCenter4: formData?.costCenter4.label,
                costCenter5: isVatExcluded === true ? "excluded" : "Included",
                isActive: true,
                poDetails: prDetailsData,
                requisitionNo: formData?.requisitionNo || poNumber,
                deliveryDate: formData.deliveryDate,
            };
            const response = await apiService.put("UPDATE_PO", userData);
            if (response?.data?.statusCode === 201 || 200) {
                toast.success(`${formData.PayOrderNo} Updated successfully`);
                setLoader(false)
                navigate("/inventory/Purchase/Order")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PR:", error);
        }
    };
    const handleVatChange = () => {
        setIsVatExcluded(!isVatExcluded);
    };
    const handlePyorderChange = (selectedOption) => {
        setFormData({ ...formData, OrderType: selectedOption });
    };
    const handleDiscountChange = () => {
        setIsDiscountPercentage(!isDiscountPercentage);
    };
    const handleCost1 = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    }
    const handleCost2 = (selectedOption) => {
        setFormData({ ...formData, costCenter2: selectedOption });
    }
    const handleCost3 = (selectedOption) => {
        setFormData({ ...formData, costCenter3: selectedOption });
    }
    const handleCost4 = (selectedOption) => {
        setFormData({ ...formData, costCenter4: selectedOption });
    }
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Purchase Order</h5>
                <div className='d-flex gap-3'>
                    {itemData?.eye === false ?
                        <>
                            {loader === true ?
                                <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                <button className='mainbtn ' type="submit" onClick={UpdatePO}><FaSave className='center mt-1 mx-2' /><div>Update</div></button>
                            }
                        </> : ""
                    }
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Purchase Order Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group justify-content-between row pb-3">
                            <div className="col">
                                <label htmlFor="InvoiceNO" className="form-label">Purchase Order No</label>
                                <input
                                    className="form-Input w-70"
                                    value={poNumber}
                                    name="poNumber"
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="DeliveryDate" className="form-label">Delivery Date</label>
                                {itemData?.eye === true ?
                                    <input
                                        type="datetime" disabled
                                        className="form-Input w-70"
                                        id="DeliveryDate"
                                        value={moment(formData.deliveryDate).format('DD-MMM-YYYY')}
                                        name="deliveryDate"
                                    /> :
                                    <input
                                        type="datetime-local"
                                        className="form-Input w-70"
                                        id="DeliveryDate"
                                        value={formData.deliveryDate}
                                        onChange={handleInputChange}
                                        name="deliveryDate"
                                    />
                                }
                            </div>
                            <div className="col d-flex align-items-center">
                                <label htmlFor="Currency" className="form-label">Currency </label>
                                <Select
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    isSearchable={false}
                                    value={SelectedCurrency} isDisabled={itemData?.eye === true ? true : false}
                                    onChange={(e) => handlecurChange(e)}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="DocumentDate" className="form-label">Document Date</label>
                                {itemData?.eye === true ?
                                    <input
                                        type="datetime" disabled
                                        className="form-Input w-70"
                                        id="DocumentDate"
                                        value={moment(formData.DocumentDate).format('DD-MMM-YYYY')}
                                        name="DocumentDate"
                                    /> :
                                    <input
                                        className='form-Input w-70'
                                        id="DocumentDate"
                                        value={formData.DocumentDate}
                                        type='datetime-local' disabled={itemData?.eye === true ? true : false}
                                        name="DocumentDate"
                                        onChange={handleInputChange}
                                    />}
                            </div>
                            <div className="col">
                                <label htmlFor="deliveryPoint" className="form-label">Delivery Point</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="deliveryPoint" disabled={itemData.eye === true ? true : false}
                                    value={formData?.deliveryPoint}
                                    onChange={handleInputChange}
                                    name="deliveryPoint"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="ExchangeRate" className="form-label">Exchange Rate </label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="requisitionNo" className="form-label">Requisition No</label>
                                <input
                                    className="form-Input w-70" name='requisitionNo'
                                    id="requisitionNo" disabled={itemData.eye === true ? true : false}
                                    value={formData.requisitionNo}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="location" className="form-label">Location</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="location" disabled={itemData.eye === true ? true : false}
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    name="location"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Description" className="form-label">Description</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="Description" disabled={itemData.eye === true ? true : false}
                                    value={formData.Description}
                                    onChange={handleInputChange}
                                    name="Description"
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col">
                                <label htmlFor="RequisitionType" className="form-label">Requisition Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="RequisitionType" disabled={itemData.eye === true ? true : false}
                                    value={formData.RequisitionType}
                                    onChange={handleInputChange}
                                    name="RequisitionType"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="contactPerson" className="form-label">Contact Person</label>
                                <input
                                    type="text" disabled={itemData.eye === true ? true : false}
                                    className="form-Input w-70"
                                    id="contactPerson"
                                    value={formData.contactPerson}
                                    onChange={handleInputChange}
                                    name="contactPerson"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Remarks" className="form-label">Remarks</label>
                                <input
                                    type="text" disabled={itemData.eye === true ? true : false}
                                    className="form-Input w-70"
                                    id="Remarks"
                                    value={formData.Remarks}
                                    onChange={handleInputChange}
                                    name="Remarks"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col">
                                <label htmlFor="QuotationReq" className="form-label">Quotation Req</label>
                                <input disabled
                                    className="form-Input w-70"
                                    id="QuotationReq"
                                    value={formData.QuotationReq}
                                    name="QuotationReq"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="ContactNo" className="form-label">Contact No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="ContactNo" disabled={itemData.eye === true ? true : false}
                                    value={formData.ContactNo}
                                    onChange={handleInputChange}
                                    name="ContactNo"
                                />
                            </div>
                            <div className="col d-flex align-items-center">
                                <label htmlFor="Description" className="form-label">Pay OrderType</label>
                                <Select
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })} isDisabled={itemData.eye === true ? true : false}
                                    options={orderType} value={formData.OrderType}
                                    onChange={(selectedOption) => handlePyorderChange(selectedOption)}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4">
                                <label htmlFor="BuyerName" className="form-label">Buyer Name</label>
                                <input
                                    type="text" disabled={itemData.eye === true ? true : false}
                                    className="form-Input w-70"
                                    id="BuyerName"
                                    value={formData.BuyerName}
                                    onChange={handleInputChange}
                                    name="BuyerName"
                                />
                            </div>
                            <div className='col-1 px-5'></div>
                            <div className='col-lg-2 brderbox center'><a href='' disabled>Attachment</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Supplier Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex align-items-center">
                                <label htmlFor="inventoryAccountCode" className="form-label">Vendor ID</label>
                                {itemData?.pOrderNo === itemData?.requisitionNo ?
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={SupplierID}
                                        options={suppliers?.map((sup) => ({ value: sup, label: sup?.record_Id }))}
                                        onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    />
                                    :
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        isDisabled={itemData?.vendorName ? true : false}
                                        value={SupplierID}
                                        options={suppliers?.map((sup) => ({ value: sup, label: sup?.record_Id }))}
                                        onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    />
                                }
                            </div>
                            <div className="col d-flex align-items-center">
                                <label htmlFor="inventoryAccountCode" className="form-label">Vendor Name</label>
                                {itemData?.pOrderNo === itemData?.requisitionNo ?
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={Supplier}
                                        options={suppliers?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                                        onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    />
                                    :
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={Supplier}
                                        isDisabled={itemData?.vendorName ? true : false}
                                        options={suppliers?.map((sup) => ({ value: sup, label: sup?.supplierName }))}
                                        onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                    />
                                }
                            </div>
                            <div className="col d-flex align-items-center">
                                <label htmlFor="inventoryAccountCode" className="form-label">Payment terms</label>
                                <Select
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })}
                                    value={Payment} isDisabled={itemData.eye === true ? true : false}
                                    onChange={(selectedOption) => handlePayChange(selectedOption)}
                                    options={payments?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label">Supplier Dept</label>
                                <Select
                                    className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        padding: 9
                                    })} isDisabled={itemData.eye === true ? true : false}
                                    value={formData.SupplierDep}
                                    // options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                    onChange={(selectedOption) => handleDepChange(selectedOption)}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label">Supplier Contact</label>
                                <input disabled={itemData.eye === true ? true : false}
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="SupplierContact"
                                    value={SupplierContact}
                                    onChange={handleInputChange}
                                    name="SupplierContact"
                                />
                            </div>
                            <div className="col-4 pt-1 d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input disabled={itemData.eye === true ? true : false}
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isVatExcluded}
                                            onChange={handleVatChange}
                                        />
                                        <label className="form-check-label mx-2 mt-1">Vat Excluded</label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input disabled={itemData.eye === true ? true : false}
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isDiscountPercentage}
                                            onChange={handleDiscountChange}
                                        />
                                        <label className="form-check-label mx-2 mt-1">Discount in %</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex align-items-center">
                                <label htmlFor="inventoryAccountCode" className="form-label">Property Group</label>
                                {itemData?.pOrderNo === itemData?.requisitionNo ?
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter1}
                                        onChange={handleCost1}
                                        options={cost1?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    /> :
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter1} isDisabled={itemData?.vendorName ? true : false}
                                        onChange={handleCost1}
                                        options={cost1?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                            <div className="col d-flex align-items-center">
                                <label htmlFor="inventoryAccountCode" className="form-label">Property</label>
                                {itemData?.pOrderNo === itemData?.requisitionNo ?
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter2}
                                        onChange={handleCost2}
                                        options={cost2?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    /> :
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter2}
                                        onChange={handleCost2}
                                        isDisabled={itemData?.vendorName ? true : false}
                                        options={cost2?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                            <div className="col d-flex align-items-center">
                                <label htmlFor="inventoryAccountCode" className="form-label">Department</label>
                                {itemData?.pOrderNo === itemData?.requisitionNo ?
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter3}
                                        onChange={handleCost3}
                                        options={cost3?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    /> :
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter3}
                                        onChange={handleCost3}
                                        isDisabled={itemData?.vendorName ? true : false}
                                        options={cost3?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex align-items-center">
                                <label htmlFor="inventoryAccountCode" className="form-label">Outlet</label>
                                {itemData?.pOrderNo === itemData?.requisitionNo ?
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter4}
                                        onChange={handleCost4}
                                        options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    /> :
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.costCenter4}
                                        onChange={handleCost4}
                                        isDisabled={itemData?.vendorName ? true : false}
                                        options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                            <div className="col-4 d-flex align-items-center">
                                <label htmlFor="CostCentre5" className="form-label">Cost Centre 5</label>
                                {itemData?.pOrderNo === itemData?.requisitionNo ?
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.CostCentre5}
                                        // onChange={handleCost4}
                                        options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    /> :
                                    <Select
                                        className="flex-grow-1"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            padding: 9
                                        })}
                                        value={formData.CostCentre5}
                                        // onChange={handleCost4}
                                        isDisabled={itemData?.vendorName ? true : false}
                                        options={cost4?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    />
                                }
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Recommendation
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid-1 brderbox2 p-4 mb-3">
                {gridVisibility[0] && (
                    <ItemGrid onRequisitionData={handleRequisitionData} itemData={itemData} selectedCheck={isVatExcluded} handleVatChange={handleVatChange} />
                )}
                {gridVisibility[1] && (
                    <Authorization
                        handleAuthorization={handleAuthorization}
                        usersDetails={usersDetails}
                        setUser={setUser}
                        itemData={itemData}
                    />
                )}
                {gridVisibility[2] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}
                {gridVisibility[3] && (
                    <div className="grid-3 border">
                        <MessageGrid handleMessage={handleMessage} />
                    </div>
                )}

            </div>
        </div >

    )
}

export default UpdatePurchaseOrder