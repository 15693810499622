import React from 'react'
import ChefIcon from "../../../../assets/images/chefIcon.png";
import { useNavigate } from 'react-router-dom';

function AddItembox() {
    let navigate = useNavigate();
  const handleAddItem = () => {
    navigate('/admin/master/Create');
  }
    return (
        <div className='linear-box justify-content-center p-2'>
            <div className='absolute'>
                <img src={ChefIcon} className='img-cartoon'/>
            </div>
            <div className='pl-3 pr-3 pt-3 pt-1 justify-content-center row'>
                <div className='   w-75 addItems-btn p-2' onClick={handleAddItem}>
                    + Add items
                </div>
            </div>

        </div>)
}

export default AddItembox