import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "../../pages/cashierMenu.css"
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import userManagement from '../../../services/userManagement';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserId, getUserName } from '../../../../Redux/selecters';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/actions';
import { CashEnums } from '../../../../Enums/config';
import {generateUniqueVoucherNumber} from "../.../../../../services/globalService"
const DayCloseForm = ({ Currency }) => {

    let navigate = useNavigate();
    const location = useLocation();
    const [Currencies, setCurrency] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [noteQuantities, setNoteQuantities] = useState({});
    const [fillQuantities, setFillQuantities] = useState({});
    const [userIds, setUserId] = useState('');
    let currency = Currency.currency.name
    const userId = useSelector(getUserId);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const userName = useSelector(getUserName);
    const [Restaurants, setRestaurant] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [emp, setEmp] = useState(null);

    const Shift = [
        { value: '1', label: 'Morning Shift' },
        { value: '2', label: 'Evening Shift' },
        { value: '3', label: 'Night Shift' },
    ];

    useEffect(() => {
        GetCurrency()
        getEmployee()
        
    }, []);

    useEffect(() => {
        const initialQuantities = {};
        separateAndCountValues(Currencies, currency)?.forEach(item => {
            initialQuantities[item.value] = item.count;
        });
        setQuantities(initialQuantities);
    }, [Currencies, currency]);
    const getEmployee = async () => {
        try {
            const apiService = new ApiService();
            let token = localStorage.getItem('Token')
            let param = `?UserId=${userId}`;
            const res = await apiService.getApiParamater('GET_EMPLOYEE', param);
            
            setEmp(res?.data?.result[0]);
        } catch (error) {
            console.error('Error fetching Employe:', error);
        }
    };
    const [formData, setFormData] = useState({
        userName: "",
        date: "",
        amount: "",
        counter: { value: '', label: '' },
        shift: "",
        note: "",
        branchId: "",
        restaurent: ""
    });
    const totalAmount = Object.keys(quantities).reduce((total, value) => {
        return total + (value * quantities[value]);
    }, 0);
    
    const totalCashAmount = Object.keys(noteQuantities).reduce((total, value) => {
        return total + (value * noteQuantities[value]);
    }, 0);
    
    const totalFills = Object.keys(fillQuantities).reduce((total, value) => {
        return total + (value * fillQuantities[value]);
    }, 0);
    
    const grandTotalAmount = ((parseFloat(formData?.digitalAmount || 0) +(totalAmount + totalCashAmount )+ (totalFills / 100)).toFixed(2)); 

    const CreateDayClose = async () => {
        try {
            const cashMeta = separateAndCountValues(Currencies, currency)?.map(item => {
                const coinQuantity = quantities[item.value] || 0;
                const noteQuantity = noteQuantities[item.value] || 0;
                const fillQuantity = fillQuantities[item.value] || 0;
                const totalQuantity = coinQuantity + noteQuantity + (fillQuantity);

                if (totalQuantity > 0) {
                    const type = coinQuantity > 0 ? 0 : (noteQuantity > 0 ? 1 : 2);

                    return {
                        currencyDenominationId: item.currencyDenominationId,
                        quantity: totalQuantity,
                        type: type
                    };
                }
                return null;
            }).filter(item => item !== null); // Filter out null items

            const allQuantitiesZero = cashMeta.every(item => item.quantity === 0);
            const currentDatetime = new Date().toISOString();
            const body = {
                userId: userId,
                counterId: formData?.counter.label,
                restaurantId: emp.restaurantId,
                branchId: emp.branchId,
                date: currentDatetime,
                amount: parseFloat(grandTotalAmount),
                remarks: formData?.note || "",
                type: CashEnums.DayClose,
                loggedInUserId: userId,
                cashMeta: cashMeta,
                voucherNo: generateUniqueVoucherNumber()
            };
            const apiService = new ApiService();
            let res = await apiService.post("CREATE_CASH", body);
            if (res?.data?.statusCode === 201) {
                CreateShiftClose()
            }
            else{
                toast.error(res.data.message)
            }
        } catch (error) {
            console.error('Error fetching Cash data:', error);
        }
    };
    const CreateShiftClose = async () => {

        try {
            const cashMeta = separateAndCountValues(Currencies, currency)?.map(item => ({
                currencyDenominationId: item.currencyDenominationId,
                quantity: quantities[item.value] || 0
            }));
            const allQuantitiesZero = cashMeta.every(item => item.quantity === 0);
            const currentDatetime = new Date().toISOString();

            if (allQuantitiesZero) {
                toast.error("Please Enter Currency Denominations");
            } else if (!formData?.branchId) {
                toast.error("Please Enter Branch");
            } else if (!formData?.counter) {
                toast.error("Please Enter Counter Number");
            } else {
                const datareq = {
                    userId: userId,
                    counterId: parseInt(formData?.counter),
                    restaurantId: parseInt(formData?.restaurent),
                    branchId: parseInt(formData?.branchId),
                    amount: parseFloat(grandTotalAmount),
                    remarks: formData?.note || "",
                    type: CashEnums.ShiftClose,
                    loggedInUserId: userId,
                    cashMeta: cashMeta,
                    date: currentDatetime,
                voucherNo: generateUniqueVoucherNumber()

                }
                const apiService = new ApiService();
                let res = await apiService.post("CREATE_CASH", datareq);
                if (res?.data?.statusCode === 201) {
                    dispatch(logout())
                    navigate('/Login')
        localStorage.clear()
                    window.location.reload(false);
                } setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error fetching Tax data:', error);
        }
    };
    const GetCurrency = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CURRENCY');
            setCurrency(res?.data?.result);
        } catch (error) {
            console.error('Error fetching currency data:', error);
            toast.error('Error fetching currency data');
        }
    };
    const separateAndCountValues = (denominations) => {
        const filteredDenominations = denominations?.find(currency => currency.default === true)
        return filteredDenominations?.currencyDenomination?.map(denomination => {
            const count = denomination?.count || 0;
            return {
                currencyDenominationId: denomination?.currencyDenominationId,
                value: denomination?.value,
                count: count,
                type: denomination?.type
            };
        });

    };
    const incrementQuantity = (value, type) => {
        if (type === 0) { // Coin
            setQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };

    const decrementQuantity = (value, type) => {
        if (type === 0) { // Coin
            setQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };
    const incrementFillQuantity = (value, type) => {
        if (type === 2) { // Coin
            setFillQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };

    const decrementFillQuantity = (value, type) => {
        if (type === 2) { // Coin
            setFillQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };

    const incrementNoteQuantity = (value, type) => {
        if (type === 1) { // Note
            setNoteQuantities(prevState => ({
                ...prevState,
                [value]: (prevState[value] || 0) + 1
            }));
        }
    };

    const decrementNoteQuantity = (value, type) => {
        if (type === 1) { // Note
            setNoteQuantities(prevState => ({
                ...prevState,
                [value]: Math.max((prevState[value] || 0) - 1, 0)
            }));
        }
    };

    const handleShiftChange = (selectedOption) => {
        setFormData({ ...formData, shift: selectedOption?.value });
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    function getCurrentDate() {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        const yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        return yyyy + '-' + mm + '-' + dd;
    }
    const handleCounterChange = (selectedOption) => {
        
        setFormData({ ...formData, counter: selectedOption });
    };
    const cashDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 1);
    const coinDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 0);
    const fillDenominations = separateAndCountValues(Currencies, currency)?.filter(item => item.type === 2);

    return (
        <div className='p-lg-5'>
            <div className='p-lg-5'>
                <div className='row'>
                    <div className='col-lg-4 col-sm-4'>
                        <form>
                            <div className='pb-lg-5'>
                                <div className="form-group row justify-content-between">
                                    <div className="col-md-6 col-lg-6">
                                        <label htmlFor="validationCustom01" className="form-label">User</label>
                                        <input
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom01"
                                            name="user"
                                            value={userName}
                                            disabled
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label">Shift</label>
                                        <Select
                                            className=''
                                            onChange={handleShiftChange}
                                            options={Shift}
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row mt-3 justify-content-between">
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom08" className="form-label">Amount</label>
                                        <input
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom08"
                                            name="amount"
                                            value={grandTotalAmount}
                                            required
                                            disabled
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom04" className="form-label">Date</label>
                                        <input
                                            type="date"
                                            className="p-2 form-control"
                                            id="validationCustom04"
                                            name="date"
                                            value={getCurrentDate()}
                                            required
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group row mt-3 justify-content-between'>
                                    <div className="col-md-6 col-lg-6">
                                        <label htmlFor="restaurantSelect" className="form-label">Restaurant</label>
                                        <input
                                            className="p-2 form-control"
                                            id="validationCustom08"
                                            name="restaurant"
                                            value={emp?.restaurantName}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="branchSelect" className="form-label">Branch</label>
                                        <input
                                            className="p-2 form-control"
                                            id="validationCustom08"
                                            name="branch"
                                            value={emp?.branchName}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="form-group row mt-3 justify-content-between">
                                <div className="col-md-6">
                                        <label htmlFor="validationCustom11" className="form-label">Counter</label>
                                        <Select
                                            type="number"
                                            id="validationCustom08"
                                            name="counter"
                                            value={formData.counter}
                                            placeholder="Select Counter"
                                            onChange={(selectedOption) => handleCounterChange(selectedOption)} // Pass the entire object to the function
                                            options={emp?.counters?.map(count => ({ value: count?.counterId, label: count.counterName }))}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <label htmlFor="validationCustom06" className="form-label">Note</label>
                                        <textarea
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom06"
                                            name="note"
                                            value={formData.note}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='end'>
                                    <div className='addItem-btn' onClick={() => CreateDayClose()}>Save</div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {cashDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Cash Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {cashDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementNoteQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{noteQuantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementNoteQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (noteQuantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    {coinDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Coin Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {coinDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{quantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (quantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                    {fillDenominations?.length === 0 ? " " :
                        <div className='col'>
                            <div className=''>
                                <div>
                                    Fill Denominations
                                </div>
                                <div className='borderMain mt-3 p-4'>
                                    {fillDenominations?.map((item, index) => (
                                        <div className='row p-2 my-3 innerBorder' key={index}>
                                            <div className='col-3'>{item.value} X</div>
                                            <div className='col-6 d-flex justify-content-between'>
                                                <div className="vr"></div>
                                                <div className="quantity-control d-flex justify-content-around center">
                                                    <div onClick={() => decrementFillQuantity(item.value, item?.type)} className="addbtn center">
                                                        -
                                                    </div>
                                                    <div className="center px-2">{fillQuantities[item.value] || 0}</div>
                                                    <div onClick={() => incrementFillQuantity(item.value, item?.type)} className="addbtn center">
                                                        +
                                                    </div>
                                                </div>
                                                <div className="vr"></div>
                                            </div>
                                            <div className='col-3'>{item.value * (fillQuantities[item.value] || 0)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(DayCloseForm);