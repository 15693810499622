import { ADD_ADDON, REMOVE_ADDON, UPDATE_ADDON_QUANTITY } from '../actions/types';

const initialState = {
  addons: [] // Array to hold the list of addons
};

const addOnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ADDON:
      // Check if the addon already exists
      const existingAddonIndex = state.addons.findIndex(addon => addon.id === action.payload.id);

      if (existingAddonIndex > -1) {
        // If addon exists, update its quantity
        const updatedAddons = [...state.addons];
        updatedAddons[existingAddonIndex] = {
          ...updatedAddons[existingAddonIndex],
          quantity: updatedAddons[existingAddonIndex].quantity + 1
        };
        return { ...state, addons: updatedAddons };
      } else {
        // If addon does not exist, add it to the list
        return { ...state, addons: [...state.addons, { ...action.payload, quantity: 1 }] };
      }

    case REMOVE_ADDON:
      // Remove addon by ID
      return { ...state, addons: state.addons.filter(addon => addon.id !== action.payload) };

    case UPDATE_ADDON_QUANTITY:
      // Update quantity for a specific addon
      const { id, quantity } = action.payload;
      const addonsWithUpdatedQuantity = state.addons.map(addon =>
        addon.id === id ? { ...addon, quantity } : addon
      );
      return { ...state, addons: addonsWithUpdatedQuantity };

    default:
      return state;
  }
};

export default addOnsReducer;
