import React, { useEffect, useState } from 'react';
import Sider from '../../../assets/images/KDS.png';
import './auth.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../../services/apiService'; // Import the ApiService class
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from 'jwt-decode';
import CashierRoutes from '../../../Cashier/CashierRouter';
import { connection, createHubConnection, invokeLogin, isSignalRConnected } from '../../../services/signalRService';
import userManagement from '../../../services/userManagement'
import { useDispatch, useSelector } from 'react-redux';
import { saveLogin, setUserRole } from '../../../../Redux/actions';
import YourLogo from "../../../../INVENTORY/Assets/images/yourlogo.png";

const KDSLogin = () => {
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        emailOrPhone: '',
        password: '',
    });
    const user = useSelector((state) => state)
    const dispatch = useDispatch()
    const location = useLocation();
    const { state } = location;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    useEffect(() => {
        const handleReceiveMessage = (user, message) => {
            // Handle received message
            console.log(`Received message from ${user}: ${message}`);
        };

        const handleReceiveAck = (user, message) => {
            // Handle received acknowledgment
            console.log(`Received acknowledgment from ${user}: ${message}`);
        };

        const handleReceiveRejection = (user, message) => {
            // Handle received rejection
            console.log(`Received rejection from ${user}: ${message}`);
        };

        // Initialize SignalR connection
        const hubConnection = createHubConnection(
            handleReceiveMessage,
            handleReceiveAck,
            handleReceiveRejection
        );

        return () => {
            hubConnection.stop();
        };
    }, []);
    const handleLogin = async () => {


        const apiService = new ApiService();
        let body = {
            email: formData.emailOrPhone,
            password: formData.password,
        };


        try {
            // Call the login API
            const response = await apiService.post('USER_LOGIN', body);

            if (response && response.status === 200) {
                // Update the token in local storage

                dispatch(saveLogin(response?.data?.result))

                //  await apiService.setToken(response?.data?.result?.token);
                // apiService.setRefreshToken(response?.data?.result?.refreshToken);

                const userRole = userManagement.getUserRole(response?.data?.result?.token);
                dispatch(setUserRole(userRole))

                toast.success('Login successful!', {
                    position: 'top-right',
                    autoClose: 3000,
                });

                const userId = userManagement.getUserId();

                if (userId) {
                    // Call the SignalR login method
                    invokeLogin(userId);

                }
                if (userRole == 'Admin' && response?.data?.result?.token) {

                    navigate('/home');
                    window.location.reload(false);


                } else if (userRole == 'Cashier') {

                    navigate('/Cashier/ShiftOpen');
                    window.location.reload(false);

                }
                else if (userRole == 'Customer') {

                    navigate('/customer/menu', { state: { state } });
                    window.location.reload(false);

                }
                else if (userRole === 'Kitchen') {
                    navigate('/Kitchen/Orders', { state: { state } });
                    window.location.reload(false);
                }
            } else if (response && response.status === 400) {
                for (const error of response.data.errors) {
                    toast.error(error, {
                        position: 'top-right',
                        autoClose: 5000,
                    });
                }
            } else {
                toast.error(`Login failed: ${response ? response.data.message : 'Unknown error'}`, {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Unauthorized error
                toast.error('Unauthorized. Please check your credentials.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            } else {
                // Other errors
                toast.error('Login failed', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        }
    };
    return (
        <div className="container-fluid display-flex body m-0 p-0">
            <div className="row">
                <div className="col-lg-6 m-0 col-sm-12">
                    <img src={Sider} className="sider_login" alt="Login Sider" />
                </div>
                <div className="col-lg-6 m-0 p-5 col-sm-12">
                    <div className="row">
                        {/* <img src={BlueLogo} className="logo_size" alt="Logo" /> */}
                        {/* <img src={Logo} className="logo_size" alt="Logo" /> */}
                        {/* <img src={QLogo} className="logo_size" alt="Logo" /> */}
                        <img src={YourLogo} className="logo_size" alt="Logo" />
                    </div>
                    <div className="p-lg-5 p-sm-0 p-md-0">
                        {/* <div className="login_head p-3">Welcome Back to Qfinity</div> */}

                        <form className="">
                            <div className="form-group p-3">
                                <label className="pb-2 labels" htmlFor="emailOrPhone">
                                    Email or phone number
                                </label>
                                <input
                                    type="text"
                                    className="form-control p-2"
                                    id="emailOrPhone"
                                    name="emailOrPhone"
                                    value={formData.emailOrPhone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group p-3">
                                <label className="pb-2 labels" htmlFor="password">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    className="form-control p-2"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                        </form>

                        <div className="align_center mt-5">
                            <div className="form-group p-3 d-flex justify-content-center mt-3 align_center">
                                <button
                                    onClick={handleLogin}
                                    className="btn Button_Normal center p-2"
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KDSLogin;
