import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { HiPencil } from "react-icons/hi2";
import { MdOutlineEditOff } from "react-icons/md";

const CategoryGrid = ({ rowData, fetchStockData, canEditMaster, setSelectedRow, handleEdit }) => {

    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 45 },
        { headerName: 'Record ID', field: 'id', filter: true, floatingfilter: true, minWidth: 550 },
        { headerName: 'Category', field: 'description', filter: true, floatingfilter: true, minWidth: 550 },
        {
            headerName: 'Status', field: 'id', cellRenderer: BtnCellRenderer, minWidth: 460,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                id: id,
                isActive: isActive ? "1" : "0"
            }
            const res = await apiService.put(`UPDATE_WAREHOUSE_CAT_MASTER`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                fetchStockData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'id') {
            setSelectedRow(null);
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const handleToggle = (recordId, newExp9Value) => {
        updateActiveStatus(recordId, newExp9Value === "1");
    };
    return (
        <div className='brdor' style={{ height: 550 }}>
            <div className="ag-theme-quartz" style={{ height: 550 }}>
                <AgGridReact
                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onCellClicked={onCellClicked}
                    onSelectionChanged={onSelectionChanged}
                />
            </div>
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const exp2Value = data.exp2 === "1" ? "0" : "1";
        clicked(value, exp2Value);
    }
    render() {
        const { isActive } = this.props.data;
        const { canEditMaster } = this.props;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
        )
    }
}
export default CategoryGrid;
