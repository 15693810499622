import React, { useEffect, useState } from "react";
import "./itemdetail.css"
import ImageSlider from "./imageSlider";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ApiService from "../../../../services/apiService";
import Modifiertable from "./modifiertable";

const ItemDetails = ({ Currency }) => {

    const { state } = useLocation();
    const val = state

    const [modifiers, setModifiers] = useState([]);
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [varients, setVarients] = useState([]);
    useEffect(() => {
        if (val) {
            const modifiersArray = val?.menuItemMeta.filter(item => item.metaType === 3);
            const varientsArray = val?.menuItemMeta.filter(item => item.metaType === 4);
            setModifiers(modifiersArray);
            setVarients(varientsArray);
        }
        GetCategory()
        GetSubCategory()
    }, [val]);

    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Id=${val?.categoryId}`
            let res = await apiService.getApiParamater('GET_CATEGORY', parameters);
            const filteredcategory = res.data.result.filter(item => item?.active === true);
            setCategory(filteredcategory[0]);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };
    const GetSubCategory = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Id=${val?.subCategoryId}`
            let res = await apiService.getApiParamater('GET_SUBCATEGORY', parameters);
            setSubCategory(res?.data?.result?.[0]);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };
    return (
        <div className='p-5'>
            <div className='row'>
                <div className='col-lg-6 '>
                    <div className='headtes'>Item Information</div>
                    <div className="headtx pt-4">Item Name </div>
                    <div className="valtx pt-3">{val?.name}</div>
                    <div class="separator">
                        <hr />
                    </div>
                    <div className="headtx pt-3">Category</div>
                    <div className="valtx pt-3">{category?.name}</div>
                    <div class="separator">
                        <hr />
                    </div>
                    <div className="headtx pt-3">Sub Category</div>
                    <div className="valtx pt-3">{subCategory?.name}</div>
                    <div class="separator">
                        <hr />
                    </div>
                    <div className="headtx pt-3">Item Description</div>
                    <div className="valtx pt-3">{val?.description}</div>
                    <div class="separator">
                        <hr />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='headtes center'>Item Image</div>
                    <div className='orangebx mt-4'>
                        <ImageSlider val={val} />
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className='col-lg-6 '>
                    <div className='headtes'>Price and tax information </div>
                    <div className="headtx pt-4">Price</div>
                    <div className="valtx pt-3">{val?.price?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }) || "0"} {Currency.currency.name}</div>
                    <div class="separator">
                        <hr />
                    </div>
                    <div className="headtx pt-3">Tax</div>
                    <div className="valtx pt-3">{val?.tax} %</div>
                    <div class="separator">
                        <hr />
                    </div>
                </div>
                <div className="col-lg-6 orangebox">
                    {/* <AddOnTbl val={addsOn} /> */}
                    <Modifiertable val={modifiers} />
                </div>
            </div>
            <div className="row mt-5">
                <div className='col-lg-6 '>
                    <div className='table-responsive'>
                        <table class="table " >
                            <thead>
                                <tr>
                                    <th scope="col">Variants</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {varients?.map((val, key) => (
                                    <tr>
                                        <td>{val?.metaItemName}</td>
                                        <td>{val?.variantPrice?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }) || "0"} </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div >
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(ItemDetails);