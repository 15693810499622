import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import Pagination from '../pagination/pagination';
import { HiPencil } from "react-icons/hi2";
import { toast } from 'react-toastify';

const MeasurementGrid = ({ refreshKey, fetchMeasurementData, canEditItemMaster, setSelectedRow, handleEdit }) => {

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    useEffect(() => {
        getItems();
    }, [refreshKey]);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);

    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);

            const res = await apiService.getApiParamater('GET_EUNIT_MEASUREMENT', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const updateActiveStatus = async (id, isActive, data) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: data?.recordId,
                exp2: isActive
            }
            const res = await apiService.put(`UPDATE_EUNIT_MEASUREMENT`, body);
            if (res?.status === 200) {
                toast.success('Status Updated successfully')
                fetchMeasurementData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'UOM Number', field: 'recordId', filter: true, floatingfilter: true, editable: false, minWidth: 430 },
        { headerName: 'Code', field: 'type', filter: true, floatingfilter: true, editable: false, minWidth: 450 },
        { headerName: 'UOM', field: 'description', filter: true, floatingfilter: true, minWidth: 420, editable: false },
        {
            headerName: 'Active', field: 'exp2', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEdit: canEditItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, exp2, data) {
                    updateActiveStatus(field, exp2, data);
                },
            },
        }
    ];
    const onCellClicked = (event) => {
        if (event.colDef.field === 'recordId') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    return (
        <div className=''>
            {loading ? (
                <div className='center'>
                    <PropagateLoader color="#6E5199" size={15} />
                </div>
            ) : (
                <>
                    <div className='gridshad' style={{ height: 600 }}>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                rowSelection={'single'}
                                onSelectionChanged={onSelectionChanged}
                                onCellClicked={onCellClicked}
                            />
                        </div>
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </>
            )}
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.exp2 === "false" ? "true" : "false";
        clicked(value, newActiveStatus, data);
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }
    render() {
        const { exp2 } = this.props.data;
        const { canEditItemMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {/* {canEditItemMaster && (
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={20} /></div>
                )} */}
                <div className="form-check form-switch center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={exp2 === "true"}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
        )
    }
}
export default MeasurementGrid;
