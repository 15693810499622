import React, { useState } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, NavLink, useLocation } from "react-router-dom";
import YourLogo from "../../Assets/Images/Pro_Logo.png";
import "../../Assets/Style/style.css"
import "./sidebar.css"
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineStock } from "react-icons/ai";
import { GiSellCard } from "react-icons/gi";

const SalesSideBar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [showSalesSegment, setSalesSegment] = useState(false);
  const saleOrder = location.pathname.startsWith('/Sales/SaleOrder');
  const Quotation = location.pathname.startsWith('/Sales/Quotation');
  const DRN = location.pathname.startsWith('/Sales/DRN');
  const CustomerInv = location.pathname.startsWith('/Sales/Customer/Invoice');
  const salesReturn = location.pathname.startsWith('/Sales/return');

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <ProSidebar collapsed={collapsed}>
        {collapsed ?
          <a href="/Sales" className="navbar-brand start"><img src={YourLogo} alt="Logo" style={{ height: "35px", paddingTop: "8px", paddingBottom: "8px" }} /></a> :
          <a href="/Sales" className="navbar-brand start"><img src={YourLogo} alt="Logo" style={{ height: "30px" }} /></a>
        }
        <Menu className="mt-2">
          <MenuItem
            icon={<AiOutlineStock size={23} />}
            active={location.pathname === "/Sales/SaleOrder"}
          >
            <NavLink to="/Sales/Quotation">
              <div>Quotation</div>
            </NavLink>
          </MenuItem>
          <SubMenu className="mt-2"
            title="Sales Trading"
            icon={<AiOutlineStock size={22} />}
            open={showSalesSegment}
            onOpenChange={() => setSalesSegment(!showSalesSegment)}
          >
            {/* <MenuItem><NavLink to="/Sales/Quotation"><div className={`submenu ${Quotation ? 'subactive' : ''}`}>- Quotation</div></NavLink></MenuItem> */}
            <MenuItem><NavLink to="/Sales/SaleOrder"><div className={`submenu ${saleOrder ? 'subactive' : ''}`}>- Sales  Order</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Sales/DRN"><div className={`submenu ${DRN ? 'subactive' : ''}`}>- Delivery Note</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Sales/Customer/Invoice"><div className={`submenu ${CustomerInv ? 'subactive' : ''}`}>- Invoice</div></NavLink></MenuItem>
            <MenuItem><NavLink to="/Sales/Return"><div className={`submenu ${salesReturn ? 'subactive' : ''}`}>- Sales Return</div></NavLink></MenuItem>
          </SubMenu>
          <MenuItem
            title="Procurement"
            icon={<GiSellCard size={22} />}
            active={location.pathname === "/Inventory/Dashboard"}
          >
            <NavLink to="/Inventory/Dashboard">
              <div>Procurement</div>
            </NavLink>
          </MenuItem>
        </Menu>
        <div
          onClick={handleToggle}
          className="sidebar-toggle-btn"
          style={{ left: collapsed ? '100px' : '300px' }}
        >
          <RxHamburgerMenu size={24} />
        </div>
      </ProSidebar>
    </div>
  );
};

export default SalesSideBar;
