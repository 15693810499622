import React from "react";
import { useLocation, useParams } from 'react-router-dom';
import SupplierGrid from "./supplierList";
import CreateSupplier from "./createSupplier";
import GlAccount from "./glAccount";
import Additional from "./additionalContact";
import BankDetail from "./bankDetail";
import Attachment from "./attactment";
import SupplierNavBox from "./supplierNav";

const SupplierMaster = () => {
    const { id } = useParams();
    const { state } = useLocation();

    let componentToRender;

    switch (id) {
        // case "list":
        //     componentToRender = <SupplierGrid state={state} />;
        //     break;
        case "create":
            componentToRender = <CreateSupplier state={state} />;
            break;
        case "account":
            componentToRender = <GlAccount state={state} />;
            break;
        case "additional":
            componentToRender = <Additional state={state} />;
            break;
        case "bank":
            componentToRender = <BankDetail state={state} />;
            break;
        case "attachment":
            componentToRender = <Attachment state={state} />;
            break;
        default:
            componentToRender = <CreateSupplier state={state} />;
            break;
    }

    return (
        <div className="p-3">
            <div className="row px-lg-5">
                <SupplierNavBox id={id} state={state} />
                {componentToRender}
            </div>
        </div>
    );
};

export default SupplierMaster;
