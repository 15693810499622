import { ReactComponent as Edit } from '../../Assets/svg/edit.svg'
import { ReactComponent as Add } from '../../Assets/svg/Add.svg'
import React, { useState, useEffect, Component } from 'react'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import ApiService from '../../services/apiService'
import { AiOutlinePrinter } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'
import { TbBallpenOff } from "react-icons/tb"
import 'ag-grid-community/styles/ag-grid.css'
import { AgGridReact } from 'ag-grid-react'
import { FaRegEye } from "react-icons/fa6"
import moment from 'moment'
import { GrEdit } from "react-icons/gr";
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination'
const PurchaseOrderGrid = () => {
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [Quots, setPCRowData] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);

    const [pcPagination, setPCPagination] = useState();
    let navigate = useNavigate();
    useEffect(() => {
        getItems();
        getPO()
    }, []);
    useEffect(() => {
        if (pageSize) {
            getItems();
            getPO()
        }
    }, [pageSize]);
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY") || " ";
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_PO', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            const sortedData = res?.data?.result.slice().sort((a, b) => {
                const idA = parseInt(a.pOrderNo.split('-')[2]);
                const idB = parseInt(b.pOrderNo.split('-')[2]);
                return idB - idA;
            });
            setRowData(sortedData || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const getPO = async (newPageNo = pcPagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('Status', 1);
            const res = await apiService.getApiParamater('GET_PC', `?${params.toString()}`);
            setPCPagination(res.data.paginationData)
            const data = res?.data?.result || [];
            const modifiedData = data?.map((item, index) => ({
                ...item,
                requisitionNo: item?.requisitionNo
            }));
            setPCRowData(modifiedData.reverse());
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const [formData, setFormData] = useState([]);
    useEffect(() => {
        const transformData = (data, rowData) => {
            const transformedData = [];
            const groupedData = data.reduce((acc, item) => {
                item.priceComparisonDetails.forEach(detail => {
                    for (let i = 1; i <= 6; i++) {
                        const supplier = detail[`supp${i}`];
                        const selected = detail[`selected${i}`];
                        if (supplier && selected === 1) {
                            const requisitionNo = item.requisitionNo;
                            const pcNo = item.pcNo;
                            const vendorKey = `${requisitionNo}-${supplier.trim()}`;
                            if (!acc[vendorKey]) {
                                acc[vendorKey] = {
                                    vendorName: supplier.trim(),
                                    requisitionNo: requisitionNo,
                                    pcNo: pcNo,
                                    paymentTerms: detail[`paymentTerms${i}`] || '',
                                    items: []
                                };
                            }
                            if (!acc[vendorKey].items.some(existingItem => existingItem.itemCode === detail.itemCode)) {
                                acc[vendorKey].items.push({
                                    itemCode: detail.itemCode,
                                    itemDescription: detail.itemDescription,
                                    qty: detail.qty,
                                    price: detail[`price${i}`] || 0,
                                    amount: (detail[`price${i}`] || 0) * detail.qty,
                                    ref: detail[`ref${i}`] || '',
                                    remarks: detail[`remarks${i}`] || '',
                                    uom: detail.uom
                                });
                            }
                        }
                    }
                });
                return acc;
            }, {});
            for (const key in groupedData) {
                transformedData.push(groupedData[key]);
            }
            console.log("Transformed Data:", transformedData);
            console.log("Row Data:", rowData);
            const filteredData = transformedData.filter(item => {
                return !rowData.some(row => {
                    const match = row.vendorName === item.vendorName && row.requisitionNo === item.requisitionNo && row.quotationRef === item.pcNo;
                    if (match) {
                        console.log("Match found:", { item, row });
                    }
                    return match;
                });
            });
            console.log("Filtered Data:", filteredData);
            return filteredData.reverse();
        };

        const data = transformData(Quots, rowData);
        setFormData(data);
    }, [Quots, rowData]);
    const handlePO = (data) => {
        navigate("/Inventory/Purchase/Create", { state: data })
    }
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/inventory/Purchase/Order/${selectedRow?.recordId}`, { state: selectedRow })
        }
    }
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    let eye = true
                    const updatedData = { ...clickedRowData, eye: eye };
                    navigate(`/inventory/Purchase/${updatedData.recordId}`, { state: updatedData })
                } else {
                    navigate(`/inventory/Purchase/${updatedData.recordId}`, { state: updatedData })
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    let eye = true
                    const updatedData = { ...selectedRow, eye: eye };
                    navigate(`/inventory/Purchase/${updatedData.recordId}`, { state: updatedData })
                } else {
                    navigate(`/inventory/Purchase/${updatedData.recordId}`, { state: updatedData })
                }
            }
        }
    };
    const handleEye = () => {
        if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate(`/inventory/Purchase/${selectedRow.recordId}`, { state: updatedData });
        };
    };
    const Comparison = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Po No', field: 'pOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        {
            headerName: 'Req No', field: 'requisitionNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
        },
        {
            headerName: 'Date', field: 'docDate', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Supplier Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        { headerName: 'Delivery Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Approval date', field: 'approvalDate', filter: true, floatingfilter: true, suppressMovable: true, width: 160,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        // {
        //     headerName: 'Active', field: 'id', cellRenderer: PrinterBtn,
        //     cellRendererParams: {
        //         handlePrint: handlePrint,
        //         handleEye: handleEye,
        //         handleEdit: handleEdit,
        //         clicked: function (field, isActive) {
        //             // updateActiveStatus(field, isActive);
        //         },
        //     },suppressMovable: true , width: 180
        // }
    ];
    const Requisition = [
        { headerName: 'Req No', field: 'requisitionNo', filter: true, floatingfilter: true, suppressMovable: true, width: 335 },
        {
            headerName: 'Date', field: 'docDate', filter: true, floatingfilter: true, suppressMovable: true, width: 330,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Supplier ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 350 },
        { headerName: 'Supplier Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 350 },
        {
            headerName: 'Active', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handlePO: handlePO,
                clicked: function (field, isActive) {
                    // updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handlenew = () => {
        navigate('/inventory/Purchase/Create')
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'pOrderNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className=' justify-content-between d-flex align-items-center pb-3'>
                        <h4 className='col mainInvHead'>
                            Pending Purchase Order
                        </h4>
                        <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                            <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div>
                        </button>
                    </div>
                    <div className='gridshad'>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={formData}
                                enableFilter={true}
                                floatingFilter={true}
                                rowSelection={'single'}
                                columnDefs={Requisition}
                                frameworkComponents={{ frameworkComponents }}
                            />
                        </div>
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pcPagination} getItems={getPO} />
                    </div>
                </>
            )}
            <div>
                <div className='justify-content-between d-flex  pt-5'>
                    <h5 className='col mainInvHead'>Purchase Order</h5>
                    <div className='d-flex justify-content-end gap-3'>
                        <div
                            onClick={handleEye}
                            className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                            style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                        >
                            <FaRegEye color='#fff' className='center mt-1 mx-2' />
                            <div className='center'>View</div>
                        </div>
                        <div
                            onClick={selectedRow && selectedRow.status === 1 ? null : handleEdit}
                            className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status === 1 ? 'disabled' : ''
                                }`}
                            style={{ cursor: selectedRow && selectedRow.status === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            <GrEdit color='#fff' className='center mt-1 mx-2' />
                            <div className='center'>Edit</div>
                        </div>
                        <div
                            onClick={handlePrint}
                            className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                            style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                        >
                            <AiOutlinePrinter color='#fff' className='center mt-1 mx-2' />
                            <div className='center'>Print</div>
                        </div>
                    </div>
                </div>
                <div className='gridshad mt-3'>
                    <div className="ag-theme-quartz" style={{ height: 600 }}>
                        <AgGridReact
                            getRowStyle={(params) => {
                                if (params.node.rowIndex % 2 === 0) {
                                    return { background: '#F0EDF5' };
                                } else {
                                    return { background: '#fff' };
                                }
                            }}
                            rowData={rowData}
                            enableFilter={true}
                            floatingFilter={true}
                            columnDefs={Comparison}
                            onCellClicked={onCellClicked}
                            onSelectionChanged={onSelectionChanged}
                        />
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </div>
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handlePO } = this.props;
        handlePO(data);
    }
    render() {
        return (
            <div>
                <button onClick={this.btnEditClickedHandler} className="gridbtn p-0">Create PO</button>
            </div>
        )
    }
}
class PrinterBtn extends Component {
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEyeClickedHandler = this.btnEyeClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
        this.btnPrintClickedHandler = this.btnPrintClickedHandler.bind(this);
    }
    btnEyeClickedHandler() {
        const { data, handleEye } = this.props;
        handleEye(data);
    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }
    btnPrintClickedHandler() {
        const { data, handlePrint } = this.props;
        handlePrint(data);
    }
    render() {
        return (
            <div className='d-flex gap-3'>
                {/* <div><Link /></div> */}
                {this.props.data?.status === 1 ?
                    <div><TbBallpenOff size={28} /></div>
                    :
                    <div onClick={this.btnEditClickedHandler}><Edit /></div>
                }
                <div>
                    <FaRegEye onClick={this.btnEyeClickedHandler} size={30} />
                </div>
                <div>
                    <AiOutlinePrinter onClick={this.btnPrintClickedHandler} size={30} />
                </div>
            </div>
        )
    }
}
export default PurchaseOrderGrid; 