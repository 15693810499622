import React, { useState, useEffect, useCallback } from 'react';
import '../../../../../Cashier/components/Table/table.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TableBox from "../arrangment/ractangleTable"
import TableContainer from './container';
import ApiService from '../../../../../services/apiService';
import userManagement from '../../../../../services/userManagement';

const FloorPlan = ({ Currency, tablesData, GetTable, area }) => {
    
    const [instance, setInstance] = useState();
    const [tables, setTables] = useState([]);
    useEffect(() => {
        setTables(tablesData);
    }, [tablesData]);
    const moveBox = useCallback((id, left, top, val) => {
        setTables((prevState) => {
            const newState = [...prevState];
            newState.forEach((box, index) => {
                if (box.id === id) {
                    newState[index].leftPosition = left;
                    newState[index].topPosition = top;
                }
            });
            return newState;
        });
        handleDrop(id, left, top, val);
    }, []);

    const handleDrop = async (id, left, top, val) => {
        const UserId = userManagement.getUserId();
        const body = {
            name: val?.name,
            seatCapacity: val?.seatCapacity,
            position: val?.position,
            area: val?.area,
            id: id,
            loggedInUserId: UserId,
            topPosition: top,
            leftPosition: left,
        };
        const apiService = new ApiService();
        const response = await apiService.put('UPDATE_TABLE', body);
        GetTable();
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <TableContainer moveBox={moveBox} setInstance={setInstance} GetTable={GetTable} area={area}>
                {tables?.map((box) => (
                    <TableBox
                        key={box.id}
                        instance={instance}
                        id={box.id}
                        left={box.leftPosition}
                        top={box.topPosition}
                        name={box?.name}
                        val={box}
                    />
                ))}
            </TableContainer>
        </DndProvider>
    );
};

export default FloorPlan;
