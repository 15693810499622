import React from 'react';
import cooking from "../../assets/images/cooking.png"
import { useNavigate } from 'react-router-dom';

const WaitPage = ({ }) => {
    let navigate = useNavigate();

    const handlecontinue = () => {
        navigate('/customer/menu');
    }
    return (
        <section class="p-5" >
            <div class="container p-5 ">
                <div class="container p-5 ">
                    <div class="container p-5 ">
                        <div className='center'>
                            <img src={cooking} className='waitimg' />
                        </div>
                        <div className='center headTxt py-5'>
                            Order In Progress
                        </div>
                        <div className='center'>
                            <div className='col-lg-2 py-3'>
                                <div className='subbtn center' onClick={() => handlecontinue()}>Continue</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WaitPage;
