import React, { useState, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { HiPencil } from "react-icons/hi2";
import Pagination from '../pagination/pagination';
import { PropagateLoader } from 'react-spinners';
import { toast } from 'react-toastify';
 
const CategoryGridMaster = ({ rowData, fetchCategoryData, canEditItemMaster, pagination, loading, setSelectedRow, handleEdit }) => {
    let navigate = useNavigate();
    const gridRef = useRef();
    const [pageSize, setPageSize] = useState(20);
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true,suppressMovable: true , width: 50 },
        { headerName: 'Code', field: 'catCode', filter: true, floatingfilter: true,suppressMovable: true , width: 200 },
        { headerName: 'Category Name', field: 'catName', filter: true, floatingfilter: true,suppressMovable: true , width: 200 },
        { headerName: 'Inventory ACC ', field: 'inventoryAccountCode', filter: true, floatingfilter: true,suppressMovable: true , width: 160 },
        { headerName: 'GRN ACC ', field: 'grnAccountCode', filter: true, floatingfilter: true,suppressMovable: true , width: 160 },
        { headerName: 'Work in progress ', field: 'workInProgressAccountCode', filter: true, floatingfilter: true,suppressMovable: true , width: 160 },
        { headerName: 'CGS ACC', field: 'cgsAccountCode', filter: true, floatingfilter: true,suppressMovable: true , width: 160 },
        { headerName: 'Invoice ACC', field: 'invoiceAccountCode', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        { headerName: 'Wastage ACC', field: 'wastageAccountCode', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        { headerName: 'Issue ACC', field: 'issueAccountCode', filter: true, floatingfilter: true,suppressMovable: true , width: 150 },
        {
            headerName: 'Actions', field: 'recordId', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                canEditItemMaster: canEditItemMaster,
                handleEdit: handleEdit,
                clicked: function (field, active, recordId) {
                    updateActiveStatus(field, active, recordId);
                },
            },suppressMovable: true , width: 150
        }
    ];
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const updateActiveStatus = async (id, active, data) => {
        
        try {
            const apiService = new ApiService();
            let body = {
                catCode: data?.catCode,
                recordId: data?.recordId,
                active: active
            };
            
            const res = await apiService.put(`UPDATE_CATEGORY`, body);
            if (res?.data?.statusCode === 201) {
                toast.success('Status Updated successfully')
                fetchCategoryData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'catCode') {
            setSelectedRow(null);
            const clickedRowData = event.data;
            setSelectedRow(null)
            handleEdit(clickedRowData)
        }
    };
    return (
        <div >
            {loading ? (
                <div className='center'>
                    <PropagateLoader color="#6E5199" size={15} />
                </div>
            ) : (
                <>
                    <div className='gridshad'>
                        <div className="ag-theme-quartz" style={{ height: 500 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                ref={gridRef}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                onCellClicked={onCellClicked}
                                onSelectionChanged={onSelectionChanged}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.active === 1 ? 0 : 1;
        clicked(value, newActiveStatus, data);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }

    render() {
        const { active } = this.props.data;
        const { canEditItemMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {/* {canEditItemMaster && (
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={25} /></div>
                )} */}
                {canEditItemMaster && (
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={active === 1}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                )}
            </div>
        )
    }
}
export default CategoryGridMaster;
