import React, { useEffect, useState } from "react";
import "./sideBar.css";
import BlueLogo from '../../../POS/assets/images/blueCoco.png';
import BingoLogo from "../../assets/images/Bingo.png";
import Logo from "../../assets/images/yourlogo.png";
import SubMenu from "../components/subMenu";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Food } from '../../assets/images/svgIcons/Food.svg';
import { ReactComponent as FoodInactive } from '../../assets/images/svgIcons/Food_InActive.svg';
import { ReactComponent as Drinkactive } from '../../assets/images/svgIcons/Drink_Active.svg';
import { ReactComponent as CompleteOrder } from '../../assets/images/svgIcons/CompleteOrder.svg';
import { ReactComponent as PlaceOrderIcon } from '../../assets/images/svgIcons/PlaceOrderIcon.svg';
import { ReactComponent as RunningOrder } from '../../assets/images/svgIcons/RunningOrder.svg';
import { ReactComponent as DayOpening } from '../../assets/images/svgIcons/DayOpening.svg';
import { ReactComponent as ShiftOpening } from '../../assets/images/svgIcons/ShiftOpening.svg';
import { ReactComponent as Handover } from '../../assets/images/svgIcons/handover.svg';
import { ReactComponent as PayIn } from '../../assets/images/svgIcons/PayInIcon.svg';
import { ReactComponent as TableActive } from '../../assets/images/svgIcons/table-Active.svg';
import { ReactComponent as TableInActive } from '../../assets/images/svgIcons/Table-Inactive.svg';
import { ReactComponent as PayOut } from '../../assets/images/svgIcons/PayoutIcon.svg';
import { ReactComponent as Delivery } from '../../assets/images/svgIcons/DeliveryIcon.svg';
import { ReactComponent as DeliveryB } from '../../assets/images/svgIcons/DeliveryIconB.svg';


import { ReactComponent as Dispatch } from '../../assets/images/svgIcons/Dispatch.svg';
import { useDispatch } from "react-redux";
import { logout } from "../../../Redux/actions";
import { BiLogOut } from "react-icons/bi"
import ApiService from "../../services/apiService";
import userManagement from "../../services/userManagement";
import ReceiptModal from "../components/Reports/xReport";
import { FaPizzaSlice } from "react-icons/fa";

const SideBar = ({ id }) => {
    let token = localStorage.getItem("Token")
    let Permission = userManagement?.getUserCrud(token)
    const [activeLink, setActiveLink] = useState(null);
    const [categories, setCategories] = useState([]);
    const [TableEnable, setTableEnable] = useState();
    const [waiterPermission, setWaiterPermission] = useState();
    const [role, setRole] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);



    const location = useLocation();
    useEffect(() => {
        let token = localStorage.getItem("Token")
        let Permission = userManagement?.getUserCrud(token)
        let role = userManagement?.getUserRole(token)
        setRole(role)

        const isTableEnable = Permission?.includes("Table Managment.add") && Permission.includes("Table Managment.query");
        const waiterPermission = Permission?.includes("Table Managment.add") && Permission.includes("Table Managment.query");

        setWaiterPermission(!waiterPermission)

        setTableEnable(!isTableEnable)
        GetCategory();
    }, []);
    const handleLinkClick = (e) => {
        e.preventDefault(); // Prevent the default navigation behavior
        setIsModalVisible(true); // Open the modal
    };
    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_CATEGORY');
            const filteredcategory = res?.data?.result?.filter(item => item?.active === true);
            setCategories(filteredcategory);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };
    return (
        <nav class="navbar navbar-expand-md  fixed-left ">
            {/* <a href="/home" class="navbar-brand center" className="logo"><img src={BlueLogo} alt="Logo"  style={{ width: "110px" }}/></a> */}
            {/* <a href="/cashier/home" class="navbar-brand center" className="logo"><img src={BingoLogo} alt="Logo" /></a> */}
            <a href="/cashier/home" class="navbar-brand center" className="logo"><img style={{ height: "36px" }} src={Logo} alt="Logo" /></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
                aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse ovrflo" id="navbarsExampleDefault">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <NavLink to="/cashier/home" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/cashier/home" ? (
                                <>
                                    <Food size={23} width={23} className="" />
                                    <a className="nav-link align-middle ">
                                        <h6 className="d-none d-sm-inline  activetxt" >Menu</h6>
                                    </a>

                                </>
                            ) : (
                                <>
                                    <FoodInactive size={23} width={23} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Menu</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/Cashier/SaleHistory" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/Cashier/SaleHistory" ? (
                                <>
                                    <CompleteOrder size={10} width={23} className="" />
                                    <a className="nav-link align-middle p-3">
                                        <h6 className="d-none d-sm-inline  activetxt">Orders</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <CompleteOrder size={10} width={23} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Orders</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    {/* <li class="nav-item">
                        <NavLink to="/" className="nav-link align-middle" activeClassName="active">

                            {location.pathname === "/" ? (
                                <>
                                    <PlaceOrderIcon size={25} width={23}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Place Order</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <PlaceOrderIcon size={25} width={23}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Place Order</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li> */}
                </ul>
                {role === 'Cashier' &&

                    <ul class="navbar-nav">
                        {/* <li class="nav-item">
                        <NavLink to="/Cashier/WaiterOrders" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/Cashier/WaiterOrders" ? (
                                <>
                                    <RunningOrder size={25} width={23}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Running orders</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <RunningOrder size={25} width={23}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Running orders</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li> */}
                        {/* <li class="nav-item">
                        <NavLink to="/Cashier/DayOpening" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/Cashier/DayOpening" ? (
                                <>
                                    <DayOpening size={25} width={23}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Day Opening</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <DayOpening size={25} width={23}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Day Opening</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li> */}

                        <li class="nav-item">
                            <NavLink to="/Cashier/OpenCashList" className="nav-link align-middle" activeClassName="active">
                                {location.pathname === "/Cashier/OpenCashList" ? (
                                    <>
                                        <ShiftOpening size={30} width={30} className="" />
                                        <a className="nav-link align-middle">
                                            <h6 className="d-none d-sm-inline  activetxt">Cash Opening</h6>
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <ShiftOpening size={30} width={30} className="" />
                                        <a className="nav-link align-middle">
                                            <h6 className="d-none d-sm-inline  inactivetxt">Cash Opening</h6>
                                        </a>
                                    </>
                                )}
                            </NavLink>
                        </li>
                    </ul>

                }

                <ul class="navbar-nav">
                    {role === 'Cashier' &&
                        <>
                            <li class="nav-item">
                                <NavLink to="/Cashier/HandOvered" className="nav-link align-middle" activeClassName="active">
                                    {location.pathname === "/Cashier/HandOvered" ? (
                                        <>
                                            <Handover size={25} width={30} className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  activetxt">Cash Closing</h6>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <Handover size={25} width={30} className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  inactivetxt">Cash Closing</h6>
                                            </a>
                                        </>
                                    )}
                                </NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink to="/Cashier/PayIn" className="nav-link align-middle" activeClassName="active">
                                    {location.pathname === "/Cashier/PayIn" ? (
                                        <>
                                            <PayIn size={25} width={30} className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  activetxt">Pay In</h6>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <PayIn size={25} width={30} className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  inactivetxt">Pay In</h6>
                                            </a>
                                        </>
                                    )}
                                </NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink to="/Cashier/PayOut" className="nav-link align-middle" activeClassName="active">
                                    {location.pathname === "/Cashier/PayOut" ? (
                                        <>
                                            <PayOut size={25} width={30} className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  activetxt">Pay Out</h6>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <PayOut size={25} width={30} className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  inactivetxt">Pay Out</h6>
                                            </a>
                                        </>
                                    )}
                                </NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink to="/Cashier/Deliveries" className="nav-link align-middle" activeClassName="active">
                                    {location.pathname === "/Cashier/Deliveries" ? (
                                        <>
                                            <Delivery size={25} width={30} className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  activetxt">Delivery</h6>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <DeliveryB size={25} width={30}  className="" />
                                            <a className="nav-link align-middle">
                                                <h6 className="d-none d-sm-inline  inactivetxt">Delivery</h6>
                                            </a>
                                        </>
                                    )}
                                </NavLink>
                            </li>
                        </>
                    }
                    <NavLink to="/Cashier/XReport" onClick={handleLinkClick} className="nav-link align-middle" activeClassName="active">

                        <h6 className="p-2">
                            <FaPizzaSlice /> X Report
                        </h6>
                    </NavLink>
                    {TableEnable ?
                        <li class="nav-item">
                            <NavLink to="/Cashier/Tables" className="nav-link align-middle" activeClassName="active">
                                {(location.pathname === "/Cashier/Tables") ?

                                    <>
                                        <TableActive className="" />
                                        <a className="nav-link align-middle">
                                            <h6 className="d-none d-sm-inline activetxt">Table</h6>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <TableInActive className="" />
                                        <a className="nav-link align-middle">
                                            <h6 className="d-none d-sm-inline  inactivetxt">Table</h6>
                                        </a>
                                    </>
                                }
                            </NavLink>
                        </li>
                        : ""
                    }

                    {/* <li class="nav-item">
                        <NavLink to="/Cashier/Dispute" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/Cashier/Dispute" ? (
                                <>
                                    <Dispatch size={25} width={30}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Dispatch</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Dispatch size={25} width={30}  className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Dispatch</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li> */}
                </ul>
            </div>
            {isModalVisible &&
                <ReceiptModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                />
            }

        </nav>
    );
};

export default SideBar;
