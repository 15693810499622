import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState, Component, useCallback } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ApiService from '../../../../services/apiService';
import moment from 'moment';

const PromotionGrid = ({ promotion, GetPromotion }) => {

    const [show, setShow] = useState(false);
    const [VoucherShow, setVoucherShow] = useState(false);
    const [id, setId] = useState();
    const handleClose = () => setShow(false);
    const handleVoucherClose = () => setVoucherShow(false);
    const handleVoucherShow = () => setVoucherShow(true);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleShow = (field) => {
        setId(field)
        setShow(true);
    }
    const handleDelete = async (id) => {
        const apiService = new ApiService();
        let body = {
            id: parseInt(id)
        }
        let res = await apiService.delete("DELETE_PROMOTION", { data: body });

        if (res?.data?.statusCode === 200) {
            setShow(false)
            GetPromotion()
            toast.success("Promotion Successfully Deleted");
        }
        else {
            toast.warning('Waiting for update');
        }

    };
    const mapCategoryType = (category) => {
        switch (category) {
            case "1":
                return "Voucher";
            case "2":
                return "Promotion";
            default:
                return "Unknown Promotion Type";
        }
    };
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const [colDefs, setColDefs] = useState([
        { headerName: 'Name', field: "name", filter: true, floatingFilter: true, minWidth: 200 },
        { headerName: 'Promo Code', field: "promoCode", filter: true, floatingFilter: true, minWidth: 200 },
        { headerName: 'Discount Percentage', field: "discountPercentage", filter: true, floatingFilter: true, minWidth: 200 },
        { headerName: 'Amount', field: "discountAmount", filter: true, floatingFilter: true, minWidth: 200 },
        {
            headerName: 'From', field: "fromDate", filter: true, floatingFilter: true, minWidth: 200,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        {
            headerName: 'Expire', field: "toDate", filter: true, floatingFilter: true, minWidth: 200,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        {
            headerName: 'Category', field: "category", filter: true, floatingFilter: true, minWidth: 200,
            cellRenderer: (params) => mapCategoryType(params?.value),
        },
        {
            headerName: 'Action',
            field: 'id', minWidth: 238,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    handleShow(field)
                },
            },

        },
    ]);

    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const onCellClicked = useCallback((event) => {
        if (event.column.getColId() !== 'id') {
            const selectedRowData = event.data;
            setSelectedRows(selectedRowData?.posPromotionDetails);
            handleVoucherShow()
        }
    }, []);
    return (
        <div className='catlist mt-5 table-responsive'>
            <div className="ag-theme-alpine" style={{ height: 550, backgroundColor: "#5B93FF" }}>
                <AgGridReact
                    rowData={promotion}
                    columnDefs={colDefs}
                    enableFilter={true}
                    floatingFilter={true}
                    frameworkComponents={frameworkComponents}
                    pagination={true}
                    suppressRowClickSelection={true}
                    rowSelection={'single'}
                    onCellClicked={onCellClicked}
                />
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() => handleDelete(id)}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={VoucherShow} onHide={handleVoucherClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Promotion Vouchers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table table-striped mt-3'>
                        <thead>
                            <tr>
                                <th scope='col'>#</th>
                                <th scope='col'>Variant Code</th>
                                <th scope='col'>Validity Days</th>
                                <th scope='col'>Use Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedRows?.map((pro, index) => (
                                <tr key={index}>
                                    <th scope='row'>{index + 1}</th>
                                    <td>{pro?.vouchorNo}</td>
                                    <td>{pro?.validityDays + " - Days" || "--"}</td>
                                    <td>{pro?.useCount || "--"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <button onClick={this.btnClickedHandler} className="delbtn">Delete</button>
        )
    }
}
export default PromotionGrid;
