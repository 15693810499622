import React, { useState } from 'react';
import './itemCard.css'
import "./receipt.css"
import "./bucket.css"
import { useNavigate } from 'react-router';
import { ReactComponent as BackArrow } from './../../assets/images/svgIcons/LeftArrow.svg';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import Select from 'react-select';

const CustomerCreatePOpup = ({ orderType, GetCostumer, setAddCustomer }) => {
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: '',
        name: "",
        idNumber: "",
        city: "",
        gender: "",
        address: "",
        address01: "",
        phoneNumber: "",
        email: "",
        deliveryCharges:'',
        rider:''
    });
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const Gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];
    const handleAddCostumer = async () => {
        
        try {
            if (!formData?.name) { toast?.warning("Enter Customer Name") }
            else {
                const body = {
                    customerCategoryId: 1,
                    address1: formData?.address,
                    address2: formData?.address01,
                    status: true,
                    name: formData.name,
                    identityCardNumber: formData.idNumber,
                    mobileNumber: formData?.phoneNumber,
                    area: formData?.city,
                    gender: formData.gender,
                    phoneNo1: formData?.phoneNumber,
                    email: formData?.email,
                    enableLoyalty: true,
                }
                const apiService = new ApiService();
                let response = await apiService.post("ADD_COSTUMER", body);
                
                if (response.data.statusCode === 201) {
                    toast.success('Customer created successfully');
                    
                    setAddCustomer(false)
                    GetCostumer()
                } else if (response?.data?.statusCode === 400) {
                    toast.warning(response?.data?.message);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleGenderChange = (selectedOption) => {
        setFormData({ ...formData, gender: selectedOption?.value });
    }
    const handleRiderChange = (selectedOption) => {
        setFormData({ ...formData, rider: selectedOption?.value });
    }
    return (
        <Modal.Body>
            <div className=''>
                {/* name, Phone */}
                    <h5 className='p-1'>Add New Customer</h5>
                <div className="form-group row py-4">
                    <div className="col-md-6 ">
                        <label htmlFor="validationCustom01" className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label">Phone</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom02"
                            name="phoneNumber"
                            value={formData?.phoneNumber}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                {/* email, city */}
                <div className="form-group row ">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom03" className="form-label">Email</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom03"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom04" className="form-label">City</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom04"
                            name="city"
                            value={formData?.city}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                {/* town, Id */}
                <div className="form-group row py-4">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom09" className="form-label">
                            Gender
                        </label>
                        <Select
                            defaultValue={formData.gender}
                            onChange={handleGenderChange}
                            options={Gender}
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom06" className="form-label">ID Number</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom06"
                            name="idNumber"
                            value={formData?.idNumber}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                {/* address 01, address 02 */}
                <div className="form-group row ">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom07" className="form-label">Address 1</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom07"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom08" className="form-label">Address 2</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom08"
                            name="address01"
                            value={formData?.address01}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                </div>
                {
                    orderType == 'Delivery' ?
                <div className="form-group row py-4">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom06" className="form-label">Delivery Charges</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom06"
                            name="deliveryCharges"
                            value={formData?.deliveryCharges}
                            onChange={handleInputChange}
                            required
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom09" className="form-label">
                            Rider
                        </label>
                        <Select
                            defaultValue={formData.rider}
                            onChange={handleRiderChange}
                            options={Gender}
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                </div>
                :""
                }
                
            </div>
            <div className='row mt-4'>
                <div className='end '>
                    <button className='addItem-btn text-center' type="submit" onClick={() => handleAddCostumer()}>Save</button>
                </div>
            </div>
        </Modal.Body>
    );
};

export default CustomerCreatePOpup;
