import React, { useState, useEffect, useRef, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { IoAddSharp } from "react-icons/io5";
import { clearRows } from '../../../Redux/actions/rowActions';
import { useDispatch } from 'react-redux';
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { hasPermission } from "../../services/roleService"
import { FaRegEye } from "react-icons/fa6";
import { AiOutlinePrinter } from "react-icons/ai";

const StockAdjustmentList = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [permissions, setPermissions] = useState([]);
    let navigate = useNavigate();
    const gridRef = useRef();
    let dispatch = useDispatch()
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
    }, [pageSize]);
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditItemMaster = checkPermission('Purchase Requisition', 'edit');
    // const canCreateItemMaster = checkPermission('Purchase Requisition', 'create');
    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
                    navigate(`/inventory/Stock/Adjustment/${updatedData?.adjNo}`, { state: updatedData });
                } else {
                    navigate("/inventory/UserStore/Create", { state: clickedRowData });
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
                    navigate(`/inventory/Stock/Adjustment/${updatedData?.adjNo}`, { state: updatedData });
                } else {
                    navigate("/inventory/UserStore/Create", { state: selectedRow });
                }
            }
        }
    };
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/Inventory/Requisition/${selectedRow?.requisitionNo}`, { state: selectedRow })
        }
    }
    const handleEye = () => {
        if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate(`/inventory/Stock/Adjustment/${updatedData?.adjNo}`, { state: updatedData });
        }
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_ADJUSTMENT', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            let responseSort = res?.data?.result.reverse()
            const modifiedData = responseSort?.map((item, index) => ({
                ...item,
                recordId: `Adj-24-${item?.recordId?.toString().padStart(4, '0')}`
            }));
            const reversedData = modifiedData?.reverse();
            setRowData(reversedData || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            // Handle error (e.g., show error message to user)
            setLoading(false); // Set loading to false even in case of error
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Adj No', field: 'adjNo', filter: true, floatingFilter: false, width: 140 },
        { headerName: 'Adj Reason', field: 'adjReason', filter: true, floatingFilter: false , width: 140},
        {
            headerName: "Date", field: "adjDate", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Location', field: 'warehouse', filter: true, floatingFilter: false , width: 140},
        { headerName: 'Property Group', field: 'costCenter1', filter: true, floatingFilter: false , width: 160},
        { headerName: 'Property', field: 'costCenter1', filter: true, floatingFilter: false, width: 140 },
        { headerName: 'Department', field: 'costCenter1', filter: true, floatingFilter: false , width: 160},
        { headerName: 'Outlet', field: 'costCenter1', filter: true, floatingFilter: false , width: 140},
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingFilter: false, width: 160 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 3 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }, width: 160
        },
    ];
    const handlenew = () => {
        dispatch(clearRows());
        navigate('/inventory/Stock/Adjustment/create')
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'adjNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    return (
        <div className='p-4'>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='justify-content-between d-flex align-items-center'>
                        <h4 className='mainInvHead'>
                            Stock Adjustment List
                        </h4>
                        <div className='d-flex justify-content-end gap-2'>
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1' />
                                <div className='center'>View</div>
                            </div>
                            <div
                                onClick={selectedRow && selectedRow.status === 1 ? handleEdit : null}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status !== 1 ? 'disabled' : ''
                                    }`}
                                style={{
                                    cursor: selectedRow && selectedRow.status === 1 ? 'pointer' : 'not-allowed',
                                }}
                            >
                                <GrEdit color='#fff' className='center mt-1 ' />
                                <div className='center'>Edit</div>
                            </div>
                            <div
                                onClick={handlePrint}
                                className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <AiOutlinePrinter color='#fff' className='center mt-1 ' />
                                <div className='center'>Print</div>
                            </div>
                            <div className='col center'>
                                {canCreateItemMaster === true ?
                                    <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                        <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div>
                                    </button>
                                    :
                                    <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                        <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div></button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='brdor mt-3'>
                        <div className="ag-theme-quartz" style={{ height: 650 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                onCellClicked={onCellClicked}
                                onSelectionChanged={onSelectionChanged}
                            />
                        </div>
                    </div>
                    <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </>
            )}
        </div>
    );
};

export default StockAdjustmentList;
