import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
import { IoAddSharp } from "react-icons/io5";

const SupplierGrid = () => {
    const [rowData, setRowData] = useState();
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    let navigate = useNavigate();
    const handleEdit = (data) => {
        navigate("/Inventory/Supplier_Master/create", { state: data })
    }
    const [colDefs, setColDefs] = useState([
        { headerName: "Supplier ID", field: "record_Id", filter: true, floatingfilter: true,suppressMovable: true , width: 120 },
        { headerName: "Supplier Type", field: "supplierType", filter: true, floatingfilter: true,suppressMovable: true , width: 140 },
        { headerName: "Name", field: "supplierName", filter: true, floatingfilter: true,suppressMovable: true , width: 140 },
        { headerName: "Phone No", field: "phoneNo1", filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        { headerName: "VAT Reg No", field: "vatRegNo", filter: true, floatingfilter: true,suppressMovable: true , width: 140 },
        { headerName: "Contact Person", field: "accountCode", filter: true, floatingfilter: true,suppressMovable: true , width: 140 },
        { headerName: "C P Number", field: "accountCode", filter: true, floatingfilter: true,suppressMovable: true , width: 130 },
        { headerName: "Credit Limit", field: "creditLimit", filter: true, floatingfilter: true,suppressMovable: true , width: 140 },
        { headerName: "Credit days", field: "creditDays", filter: true, floatingfilter: true,suppressMovable: true , width: 120 },
        { headerName: "Acc Code", field: "accountCode", filter: true, floatingfilter: true,suppressMovable: true , width: 110 },
        { headerName: "Acc Name", field: "accountName", filter: true, floatingfilter: true,suppressMovable: true , width: 110 },
        {
            headerName: "Action", field: "exp4", filter: true, floatingfilter: true,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handleEdit: handleEdit,
                clicked: function (field, record_Id, supplier_ID) {
                    updateActiveStatus(field, record_Id, supplier_ID);
                },
            },suppressMovable: true , width: 120
        },
    ])
    useEffect(() => {
        fetchSupplierData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchSupplierData();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Currency Master', 'edit');
    // const canCreateMaster = checkPermission('Currency Master', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;
    const updateActiveStatus = async (exp4, record_Id, supplier_ID) => {
        try {
            const apiService = new ApiService();
            let body = {
                record_Id: record_Id,
                exp4: exp4,
                supplier_ID: supplier_ID
            };
            const res = await apiService.put(`UPDATE_SUPPLIER`, body);
            if (res?.data?.statusCode === 201) {
                console.log('Updated successfully');
                fetchSupplierData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const fetchSupplierData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUPPLIER', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            const stockData = response?.data?.result;
            setRowData(stockData.reverse());
        } catch (error) {
            console.error("Error fetching Supplier data:", error);
        }
    };
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handlenew = () => {
        navigate('/Inventory/Supplier_Master/create');
    };
    return (
        <div className='p-4'>
            <div className=' justify-content-between d-flex align-items-center pb-3'>
                <h4 className='col mainInvHead'>
                    Supplier Listing
                </h4>
                <div onClick={handlenew} className='mainbtn  flex-end  gap-3'>
                    <IoAddSharp color='#fff' className='center mt-1 mx-2' />
                    <div>Add New</div>
                </div>
            </div>
            <div className='gridBg' style={{ borderRadius: "0px 0px 0 0" }}>
                <div className="ag-theme-quartz" style={{ height: 625, backgroundColor: "#5B93FF" }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        columnDefs={colDefs}
                        enableFilter={true}
                        floatingFilter={true}
                        frameworkComponents={frameworkComponents}
                        suppressRowClickSelection={true}
                    />
                </div>
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchSupplierData} />
            </div>
        </div>
    )
}
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);

    }
    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data);
    }
    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.exp4 === "1" ? "0" : "1";
        clicked(newActiveStatus, data.record_Id, data.supplier_ID);
    }

    render() {
        const { exp4 } = this.props.data;
        return (
            <div className='d-flex gap-3'>
                <div onClick={this.btnEditClickedHandler}><Edit size={25} /></div>
                <div className="form-check form-switch center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={exp4 === "1"}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default SupplierGrid;