import React, { useState, useEffect } from 'react';

const MessageGrid = ({ message }) => {
    const [rows, setRows] = useState(message || []);

    useEffect(() => {
        if (!message || message.length === 0) {
            addRow(); // Add a row initially if no data provided
        }
    }, [message]);

    const handleInputChange = (index, fieldName, value) => {
        const updatedRows = [...rows];
        updatedRows[index][fieldName] = value;
        setRows(updatedRows);

        // Add a new row if the last row is filled
        if (index === rows.length - 1 && value !== '') {
            addRow();
        }
    };

    const addRow = () => {
        const newRow = {
            SrNo: '',
            MessageFrom: '',
            Message: '',
            MessageTo: '',
            Date: '',
        };
        setRows([...rows, newRow]);
    };

    return (
        <div className='table-container mb-5 p-2' style={{ overflowX: 'auto' }}>
            <table className='w-100'>
                <thead className='dynoTable' >
                    <tr>
                        <th>Sr No</th>
                        <th>Message From</th>
                        <th>Message</th>
                        <th>Message To</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={row.SrNo} onChange={e => handleInputChange(index, 'SrNo', e.target.value)} /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={row.MessageFrom} onChange={e => handleInputChange(index, 'MessageFrom', e.target.value)} /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={row.Message} onChange={e => handleInputChange(index, 'Message', e.target.value)} /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={row.MessageTo} onChange={e => handleInputChange(index, 'MessageTo', e.target.value)} /></td>
                            <td className='m-0 p-0'><input type="text" className='p-2 w-100' value={row.Date} onChange={e => handleInputChange(index, 'Date', e.target.value)} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MessageGrid;
