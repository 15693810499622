import React, { useState, useEffect } from 'react';
import "../../../../Customer/Menu/NavBar/navBar.css";
import "../../menus/menuItemcard.css";
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import DiscountGrid from './grid';
import userManagement from "../../../../services/userManagement";
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../../Redux/selecters';

const CreateDiscount = () => {
    const [discount, setDiscount] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    let UserId = useSelector(getUserId);

    useEffect(() => {
        GetDiscount();
    }, [searchTerm]);

    const [formData, setFormData] = useState({
        name: '',
        price: '',
        vat: '',
        discount: '',
    });

    const Type = [
        { value: '0', label: 'Automatic ' },
        { value: '1', label: 'Store Coupons' },
        { value: '2', label: 'Percent Off' },
        { value: '3', label: 'Dollar  Off' },
    ];

    // const handleClear = () => {
    //     setFormData({
    //         ...formData,
    //         addOn: {
    //             name: '',
    //             price: '',
    //             vat: '',
    //             discount: '',
    //         },
    //     });
    // };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCreate = async () => {
        if (!formData?.name) { toast.warning("Enter Discount Name") }
        else if (!formData?.price) { toast.warning("Enter Amount  for Discount") }
        else if (!formData?.discount) { toast.warning("Enter Discount Percentage") }
        else {
            try {

                const datareq = {
                    loggedInUserId: UserId,
                    name: formData.name,
                    amount: parseInt(formData.price),
                    percentage: parseInt(formData?.discount),
                    type: parseInt(formData?.vat),
                    active: true
                };

                if (formData.name && formData.price) {
                    const apiService = new ApiService();
                    let res = await apiService.post("CREATE_DISCOUNT", datareq);

                    if (res?.data?.statusCode === 201) {
                        toast.success("Discount Created Successfully");
                        GetDiscount();
                        setFormData({
                            name: '',
                            price: '',
                            vat: '',
                            discount: '',
                        })
                    } else {
                        toast.warning('Waiting for Response');
                    }
                } else {
                    toast.error("Fill in all the fields to create Promotion");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleTypeChange = (selectedOption) => {
        setFormData({ ...formData, vat: selectedOption?.value });
    }

    const GetDiscount = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_DISCOUNT', parameters);

            setDiscount(res?.data.result);
        } catch (error) {
            console.error('Error fetching AddsOn data:', error);
        }
    };

    return (
        <div className="p-lg-5 container">
            <div className="row catlist">
                <div className="form-group row mt-2 justify-content-between align-content-center">
                    <div className="col-md-5">
                        <label htmlFor="validationCustomName" className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            placeholder="Enter Name"
                            id="validationCustomName"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="gap-2 d-flex justify-content-center">
                            <div>
                                <label htmlFor="validationCustomPrice" className="form-label">Amount</label>
                                <input
                                    type="number"
                                    placeholder="Price"
                                    className=" form-control"
                                    id="validationCustomPrice"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="validationCustomVAT" className="form-label">
                                    Type
                                </label>
                                <Select
                                    defaultValue={formData.vat}
                                    onChange={handleTypeChange}
                                    options={Type}
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div>
                                <label htmlFor="validationCustomDiscount" className="form-label">Discount %</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="validationCustomDiscount"
                                    name="discount"
                                    value={formData?.discount}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-end mt-4">
                    {/* <div
                        className="col-lg-2 col-md-2 addMoreBtn p-3 mt-4 mb-3 mx-3"
                        onClick={handleClear}
                    >
                        Clear
                    </div> */}
                    <div
                        className="col-lg-2 col-md-2 addItem-btn p-3 mt-4 mb-3 mx-3"
                        onClick={handleCreate}
                    >
                        Save
                    </div>
                </div>
            </div>

            <div className="pt-4">
                <div>
                    <DiscountGrid discount={discount} GetDiscount={GetDiscount} />
                </div>
            </div>
        </div>
    );
};

export default CreateDiscount;
