import React from 'react';
import { connect } from "react-redux";

const StockCard = ({ val, Currency }) => {

    return (
        <div className='p-2'>
            <div className='d-flex justify-content-between row'>
                <div className='col-lg-10'>
                    {val?.orderStatus === 0 ?
                        <div class="progress">
                            <div class="progress-bar " role="progressbar" aria-valuenow="10"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: `10%` }}>
                                New Order
                            </div>
                        </div>
                        : ''
                    }
                    {val?.orderStatus === 1 ?
                        <div class="progress">
                            <div class="progress-bar bg-info" role="progressbar" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: `25%` }}>
                                Accepted
                            </div>
                        </div>
                        : ''
                    }
                    {val?.orderStatus === 2 ?
                        <div class="progress">
                            <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="50"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: `50%` }}>
                                Cooking
                            </div>
                        </div> : ''
                    }
                    {val?.orderStatus === 4 ?
                        <div class="progress">
                            <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: `75%` }}>
                                Ready For Delivery
                            </div>
                        </div>
                        : ''
                    }
                    {val?.orderStatus === 5 ?
                        <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="100"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: `100%` }}>
                                Delivered
                            </div>
                        </div> : ''
                    }
                    {val?.orderStatus === 10 ?
                        <div class="progress">
                            <div class="progress-bar bg-dark" role="progressbar" aria-valuenow="100"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: `100%` }}>
                                Canceled
                            </div>
                        </div> : ''
                    }
                    {val?.orderStatus === 8 ?
                        <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="10"
                                aria-valuemin="0" aria-valuemax="100" style={{ width: `10%` }}>
                                On Hold
                            </div>
                        </div> : ''
                    }
                </div>
                <div className='col-lg-2 text-start'>
                    <div>{Currency.currency.name}  {val?.orderAmount}</div>
                </div>
            </div>
        </div>
    );
};

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(StockCard);
