export const Procurement = [{
    label: "Master", value: [
        { label: "Item Master" },
        { label: "UOM Master" },
        { label: "Category Master" },
        { label: "SubCategory Master" },
        { label: "Department Master" },
        { label: "Stock Master" },
        { label: "Packing Master" },
        { label: "Company Master" },
        { label: "Currency Master" },
        { label: "Payment Master" },
        { label: "Bank Master" },
        { label: "Supplier Type Master" },
        { label: "Location Master" },
        { label: "Location Category Master" },
        { label: "Branch Master" }]
},
{
    label: "Transection", value: [
        { label: "Purchase Requisition" },
        { label: "Quotation" },
        { label: "Price Comparison" },
        { label: "Purchase Order" },
        { label: "GRN" },
        { label: "Direct Purchase" },
        { label: "Purchase Return" },
        { label: "Invoice" },
        { label: "Issue Item" },
        { label: "User Store Request" },
        { label: "Direct Issue" },
        { label: "Issue Received" },
        { label: "Issue Return" },
        { label: "Supplier" },
    ]
}
];

export const Reports = [
    { label: "Items Report" },
    { label: "Purchase Req Report" },
    { label: "Purchase Order Report" },
    { label: "GRN Report" },
    { label: "Purchase Return Report" },
    { label: "Invoice Report" },
    { label: "Store Transection Report" },
];

// Yaha sa nechy wala use karna opr nah @Ali Hashim
export const masters = [
    { label: "Item Master" },
    { label: "UOM Master" },
    { label: "Category Master" },
    { label: "SubCategory Master" },
    { label: "Department Master" },
    { label: "Stock Master" },
    { label: "Packing Master" },
    { label: "Company Master" },
    { label: "Currency Master" },
    { label: "Payment Master" },
    { label: "Bank Master" },
    { label: "Supplier Type Master" },
    { label: "Location Master" },
    { label: "Location Category Master" },
    { label: "Branch Master" },
    { label: "Supplier" }
]
export const Purchase = [
    { label: "Purchase Requisition" },
    { label: "Quotation" },
    { label: "Price Comparison" },
    { label: "Purchase Order" },
    { label: "GRN" },
    { label: "Direct Purchase" },
    { label: "Purchase Return" },
    { label: "Invoice" },
    { label: "Posting" },
];

export const Issue = [
    { label: "Issue Item" },
    { label: "User Store Request" },
    { label: "Direct Issue" },
    { label: "Issue Received" },
    { label: "Issue Return" },
]
export const System = [
    { label: "Manage Role" },
    { label: "User Role" },
    { label: "User Master" },
];
