import React, { useState, useEffect, Component, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { HiPencil } from "react-icons/hi2";
import { FaEye } from "react-icons/fa";
import { MdOutlineEditOff } from "react-icons/md";

const PackingGrid = ({ rowData, fetchPackingData, canEditMaster, setSelectedRow, handleEdit }) => {
    const gridRef = useRef();
    let navigate = useNavigate();

    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Code', field: 'type', filter: true, floatingfilter: true, editable: false, minWidth: 550 },
        { headerName: 'Packing Type Name', field: 'description', filter: true, floatingfilter: true, minWidth: 550, editable: false },
        {
            headerName: 'Status', field: 'exp2', cellRenderer: BtnCellRenderer, minWidth: 460,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, exp2, data) {
                    updateActiveStatus(field, exp2, data);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive, data) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordId: data?.recordId,
                exp2: isActive
            }
            const res = await apiService.put(`UPDATE_EPACKING`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                fetchPackingData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'type') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    return (
        <div className='gridshad'>
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    rowData={rowData}
                    ref={gridRef}
                    enableFilter={true}
                    floatingFilter={true}
                    columnDefs={columnDefs}
                    onSelectionChanged={onSelectionChanged}
                    onCellClicked={onCellClicked}
                />
            </div>
        </div>
    );
};

class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.exp2 === "false" ? "true" : "false";
        clicked(value, newActiveStatus, data);
    }

    render() {
        const { exp2 } = this.props.data;
        const { canEditMaster } = this.props;

        return (
            <div className='d-flex gap-3'>
                {/* {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                } */}
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={exp2 === "true"}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={exp2 === "true"}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default PackingGrid;
