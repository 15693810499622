import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Edit } from '../../Assets/svg/edit.svg';
import Select from 'react-select';
import FileUpload from '../imageUpload/fileUpload';

const Attactment = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    let navigate = useNavigate();

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_SUPPLIER');
            setRowData(response?.data?.result);
        } catch (error) {
        }
    }

    const columnDefs = [
        { headerName: 'Account Name', field: 'accountName', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Bank Name', field: 'cP1ContactNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Branch', field: 'cP1ContactNo2', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Swift Code', field: 'phoneNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'IBAN', field: 'phoneNo2', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Country', field: 'mobileNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'City', field: 'faxNo1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Contact No', field: 'email1', filter: true, floatingfilter: true, minWidth: 150 },
        { headerName: 'Category', field: 'email2', filter: true, floatingfilter: true, minWidth: 150 },
        {
            headerName: 'Action', field: 'supplier_ID', filter: true, floatingfilter: true, minWidth: 150,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {

                    // handleShow(field)
                },
            },
        },

    ];
    const [formData, setFormData] = useState({
        Description: "",
        fileName: "",
        Branch: "",
        ContactNo1: "",
        Country: "",
        ContactPerson: "",
        Category: "",
        ContactNo: "",
        MobileNo: "",
        IBAN: ""
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    return (
        <div className='px-5 pt-3'>
            <div className='brderbox p-lg-5'>
                <div className="form-group row  pb-3">
                    <div className="col-md-4 col-lg-6">
                        <label htmlFor="validationCustom01" className="form-label">Description</label>
                        <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            name="Description"
                            value={formData?.Description}
                            onChange={handleInputChange}
                        />
                        <div className="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div className=" col-md-4 col-lg-6">
                        <label htmlFor="validationCustom01" className="form-label">File Name</label>
                        <input
                            type="number" onWheel={(e) => e.target.blur()}
                            className="form-control"
                            id="validationCustom01"
                            name="fileName"
                            value={formData?.fileName}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="col mt-3">
                    <FileUpload />
                </div>
                <div className="form-group  center">
                    <div className="col-2 start">
                        <label htmlFor="validationCustom01" className="form-label"></label>
                        <div className='center mt-2'>
                            <button className='mainbtn '>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ag-theme-quartz mt-5" style={{ height: 700 }}>
                <AgGridReact
getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                    rowData={rowData}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection={'single'}
                    columnDefs={columnDefs}
                    pagination={false}
                    suppressExcelExport={true}
                    rowHeight={60}
                    domLayout='autoHeight'
                    suppressRowClickSelection={true}
                    frameworkComponents={frameworkComponents}
                />
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }

    render() {
        return (
            <div className='d-flex gap-3'>
                <div><Edit /></div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck`}
                        checked={this.props}
                        // onChange={(e) => btnClickedHandler(val, e.target.checked)}
                        onClick={this.btnClickedHandler}
                    />
                </div>
            </div>
            // <button onClick={this.btnClickedHandler} className="gridbtn">Delete</button>
        )
    }
}
export default Attactment;
