import {
    ADD_RIGHT,
    CLEAR_RIGHT,
} from '../actions/types';

const initialState = {
    userDetails: [],
};

const rightReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_RIGHT:
            const newIndex = state.userDetails.length;
            
            const newItem = { ...action.payload, index: newIndex };
            
            return {
                ...state,
                userDetails: [...state.userDetails, newItem],
            };

        case CLEAR_RIGHT:
            return {
                ...state,
                userDetails: [],
            };

        default:
            return state;
    }
};

export default rightReducer;