import Button from 'react-bootstrap/Button';
import { React, useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import ApiService from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Next } from '../../../assets/images/svgIcons/pagination_right.svg';
import { ReactComponent as Previous } from '../../../assets/images/svgIcons/pagination_left.svg';
import "./customer.css"
import CostumerGrid from './CustomerGrid/grid';
const CostumerList = () => {

    const [show, setShow] = useState(false);
    const [CostValue, setDelCost] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1); // Add this line for pageNumber
    const [data, setData] = useState([]); // Add this line for data state

    let navigate = useNavigate();
    const handleClose = () => setShow(false);

    const handlePagination = (newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const handleShow = (e) => {
        setDelCost(e)
        setShow(true);
    }
    useEffect(() => {
        GetCostumer(pageNumber);
    }, [pageNumber]);

    const DeleteCostumer = async () => {
        const apiService = new ApiService();
        let paramaters = `${CostValue?.id}`;
        let res = await apiService.delete("DELETE_COSTUMER", paramaters);
        if (res?.data?.statusCode === 204) {
            setShow(false)
            window.location.reload(false);
            toast.success("MenuItem Successfully Deleted");
        }
        else {
            toast.warning('Waiting for menu update');
        }
    }
    const handleEdit = (val) => {
        navigate('/admin/AddCostumer', { state: { val } });
    }

    const GetCostumer = async (page) => {
        const apiService = new ApiService();
        let pagination = `?PageNo=1&PageSize=50`;
        try {
            let res = await apiService.getApiParamater("GET_COSTUMER", pagination);
            setData(res?.data?.result);
            
            // Update total pages based on API response
            setTotalPages(res?.data?.result?.totalPages || 1);
        } catch (error) {
            console.error("Error fetching customer data:", error);
            // Handle error, you might want to set total pages to 1 or display an error message
            setTotalPages(1);
        }
    };

    const singleItem = (e) => {
        navigate(`/admin/Customer/${e?.name}`, { state: e });
    }
    const renderPageLinks = () => {
        const links = [];
        for (let i = 1; i <= totalPages; i++) {
            links.push(
                <li key={i} className={`page-item ${i === pageNumber ? '' : ''}`}>
                    <a className="page-link pag" href="#" onClick={() => handlePagination(i)}>
                        {i}
                    </a>
                </li>
            );
        }
        return links;
    };
    return (
        <div className='row'>
            <div className='pt-4 col-lg-12 '>
                <CostumerGrid costumer={data} />
            </div>
            {/* <div className='d-flex justify-content-end'>
                <ul className="pagination">
                    <li className="page-item">
                        <a className="page-link paginationPrv" href="#" onClick={() => handlePagination(pageNumber - 1)}>
                            <Previous /> Previous
                        </a>
                    </li>
                    {renderPageLinks()}
                    <li className="page-item">
                        <a className="page-link paginationPrv" href="#" onClick={() => handlePagination(pageNumber + 1)}>
                            Next <Next />
                        </a>
                    </li>
                </ul>
            </div> */}
            <Modal show={show} onHide={handleClose} value={CostValue}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Costumer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you Sure you want to delete {CostValue?.name}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => DeleteCostumer(CostValue)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default CostumerList