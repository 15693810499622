import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ApiService from '../../../services/apiService';

const RequisitionItemList = ({ onRequisitionData, itemData, selectedLocation }) => {
    const dispatch = useDispatch();
    const [itemOptions, setItemOptions] = useState([]);
    const [itemRows, setItemRows] = useState([]);
    const [descriptionOptions, setDescriptionOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const apiService = new ApiService();
    const initialMount = useRef(true);
    const [rowsUpdated, setRowsUpdated] = useState(false);
    useEffect(() => {
        const getItems = async () => {
            try {
                if (initialMount.current && itemData?.prDetails && itemData.prDetails.length > 0) {
                    const res = await apiService.get('GET_ITEM');
                    const formattedRows = itemData?.prDetails?.map(detail => {
                        const selecteditem = res.data.result?.find(rec => rec.itemDescription === detail.itemDescription)
                        return {
                            requisitionNo: detail.requisitionNo,
                            srno: detail.srno,
                            itemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            branch: '',
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: selecteditem.convToInvUOM * detail.qty,
                            convRate: selecteditem.convToInvUOM,
                            baseAmount: detail.unitPrice,
                            specification: detail.specification,
                            amount: detail.amount,
                            bol: ''
                        };
                    }).filter(row => row !== null);
                    if (formattedRows.length > 0) {
                        setItemRows(formattedRows);
                    }
                    if (selectedLocation && selectedLocation.label && itemRows[0]?.itemNo && !rowsUpdated) {
                        updateRowsWithNewStock();
                        setRowsUpdated(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };
        if (itemData) {
            getItems();
        }
    }, [itemData, dispatch, selectedLocation, itemRows]);

    const updateRowsWithNewStock = async () => {
        try {
            const updatedRows = itemRows.map(row => ({ ...row }));
            const apiPromises = updatedRows.map(async (row, index) => {
                const queryParam = `itemNo=${row?.itemNo}`;
                const response = await apiService.getApiParamater(
                    'GET_ITEM',
                    `?isActive=true&Warehouse=${selectedLocation.label}&${queryParam}`
                );
                if (response?.data?.statusCode === 200) {
                    const itemData = response.data.result[0];
                    updatedRows[index].stock = itemData.stock || 0;
                    updatedRows[index].stockType = itemData.stockType || "---";
                    updatedRows[index].itemDescription = itemData.itemDescription;
                    updatedRows[index].uom = itemData.baseUnit;
                    updatedRows[index].supplierId = itemData.supplier;
                    updatedRows[index].baseQty = parseFloat(itemData.convToInvUOM);
                } else if (response?.data?.statusCode === 204) {
                    updatedRows[index] = {
                        ...row,
                        stock: 0,
                    };
                }
            });
            await Promise.all(apiPromises);
            setItemRows(updatedRows);
        } catch (error) {
            console.error('Error updating rows with new stock:', error);
        }
    }
    return (
        <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
            <table>
                <thead className='dynoTable'>
                    <tr>
                        <th>Sr no</th>
                        <th>Item No</th>
                        <th>Description</th>
                        <th>Item Specs</th>
                        <th>Qty</th>
                        <th>Packing UOM</th>
                        <th>Inventory Qty</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>Stock</th>
                        <th>BalForPo</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(itemRows) && itemRows?.map((row, index) => (
                        <tr key={index}>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.srno} style={{ width: "80px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.itemNo} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.qty} style={{ width: "100px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.baseQty} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.baseAmount} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.amount} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.stock} style={{ width: "130px" }} /></td>
                            <td className='m-0 p-0'><input className='p-2' disabled value={row.bol} style={{ width: "130px" }} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RequisitionItemList;
