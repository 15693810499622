import { React, useEffect, useState } from 'react';
import "./thirdP.css"
import cup from "../../../../assets/images/Cup.png"
import Customer from "../../../../assets/images/Customer.png"
import Orders from "../../../../assets/images/Orders.png"
import Dollar from "../../../../assets/images/Revenue.png"
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';

const RevenueCard = ({ val }) => {
    const [data, setData] = useState([]);
    const [Currencies, setCurrency] = useState([]);
    const filteredDenominations = Currencies?.find(currency => currency?.default === true);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    useEffect(() => {
        getSumData(val)
        GetCurrency(val)
    }, [val]);

    const getSumData = async () => {
        try {
            const apiService = new ApiService();
            let paramaters = `?GetBy=${val}`;
            let res = await apiService.getApiParamater("DASH_REVENUE_COUNT", paramaters);

            setData(res?.data?.result);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const GetCurrency = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CURRENCY');
            setCurrency(res?.data?.result);
            const filteredDenominations = res?.data?.result?.find(currency => currency?.default === true);
            setSelectedCurency(filteredDenominations)
            
        } catch (error) {
            console.error('Error fetching currency data:', error);
            toast.error('Error fetching currency data');
        }
    };
    return (
        <div className='d-flex justify-content-between  mbl-flx'>
            <div className=''>
                <div className='brder  d-flex  gap-4'>
                    <div>
                        <img src={cup} className='img-fluid lgoimg' />
                    </div>
                    <div>
                        <div className='income'>{data?.netItems || "0"}</div>
                        <div className='ptyName'>Menu Items</div>
                    </div>
                </div>
            </div>
            <div className=''>
                <div className='brder  d-flex  gap-4'>
                    <div className='lgoimg curency'>
                        {/* <img src={Dollar} className='img-fluid lgoimg' /> */}
                        {SelectedCurrency?.code}
                    </div>
                    <div>
                        <div className='income'>
                            {data?.netSales?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) || "0"}
                        </div>
                        {/* <div className='income'>{data?.netSales?.toFixed(2) || "0"}</div> */}
                        <div className='ptyName'>Net Sale</div>
                    </div>
                </div>
            </div>
            <div className=''>
                <div className='brder  d-flex  gap-4'>
                    <div>
                        <img src={Orders} className='img-fluid lgoimg' />
                    </div>
                    <div>
                        <div className='income'>{data?.netOrders || "0"}</div>
                        <div className='ptyName'>Net Orders</div>
                    </div>
                </div>
            </div>
            <div className=''>
                <div className='brder  d-flex  gap-4'>
                    <div>
                        <img src={Customer} className='img-fluid lgoimg' />
                    </div>
                    <div>
                        <div className='income'>{data?.orderHolders || "0"}</div>
                        <div className='ptyName'>Members</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RevenueCard;
