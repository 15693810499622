import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./order.css"
import { ReactComponent as Grid } from '../../../assets/images/svgIcons/Grid.svg';
import Section from "./D&D/section"
import Card from './D&D/orderCard';
import ApiService from '../../../services/apiService';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createHubConnection } from '../../../services/signalRService';
import { toast } from 'react-toastify';

const OrderDragNDrop = () => {
    let navigate = useNavigate();
    const handleDND = () => {
        navigate('/admin/orders');
    }
    const [orders, setOrders] = useState([]);
    const [draggedOrder, setDraggedOrder] = useState(null);

    useEffect(() => {
        GetOrders();
    }, []);
    useEffect(() => {
        const handleReceiveMessage = async (user, message) => {
            toast.info(`${message}`);
            GetOrders();
        };

        const hubConnection = createHubConnection(
            handleReceiveMessage,
        );

        return () => {
            hubConnection.stop();
        };
    }, [orders]);

    const GetOrders = async () => {
        const apiService = new ApiService();
        let res = await apiService.get("GET_ORDERS");

        const mappedOrders = res?.data?.result?.map(order => ({
            ...order,
            status: mapApiStatusToEnum(order?.orderStatus),
        }));
        setOrders(mappedOrders.reverse());
    }

    const mapApiStatusToEnum = (apiStatus) => {
        switch (apiStatus) {
            case 1:
                return OrderStatus.Accept;
            case 0:
                return OrderStatus.NewOrder;
            case 3:
                return OrderStatus.Ready;
            case 6:
                return OrderStatus.Delivered;
            case 7:
                return OrderStatus.Cancel;
            default:
                return OrderStatus.Accept;
        }
    };
    const handleDrop = async (orderId, newStatus) => {

        const statusMap = {
            [OrderStatus.Accept]: OrderStatus.Accept,
            [OrderStatus.NewOrder]: OrderStatus.NewOrder,
            [OrderStatus.Ready]: OrderStatus.Ready,
            [OrderStatus.Delivered]: OrderStatus.Delivered,
            [OrderStatus.Cancel]: OrderStatus.Cancel,
        };
        const validStatusKeys = Object.keys(OrderStatus);

        const updatedOrders = orders.map((order) =>
            order.id === orderId ? { ...order, orderStatus: validStatusKeys.includes(newStatus) ? OrderStatus[newStatus] : order.orderStatus } : order
        );

        setOrders(updatedOrders);
        setDraggedOrder(null);

        const draggedOrder = orders.find(order => order.id === orderId);

        const body = {
            id: draggedOrder.id,
            orderType: draggedOrder.orderType,
            orderSource: draggedOrder.orderSource,
            orderStatus: validStatusKeys.includes(newStatus) ? OrderStatus[newStatus] : "",
            orderAmount: draggedOrder.orderAmount,
            calculatedTax: draggedOrder.calculatedTax,
            totalDiscount: draggedOrder.totalDiscount,
            amountCollected: draggedOrder.amountCollected,
            amountReturned: draggedOrder.amountReturned,
            customerType: draggedOrder.customerType,
            customerId: draggedOrder.customerId,
            customerName: draggedOrder.customerName,
            paymentStatus: draggedOrder.paymentStatus,
            invoiceNumber: draggedOrder.invoiceNumber,
            loggedInUserId: "string",
        };
        const apiService = new ApiService();
        const response = await apiService.put('UPDATE_ORDER', body);
        GetOrders()
        if (draggedOrder?.tableId && body.orderStatus === 6) {
            handleUpdateTable(draggedOrder);
        }
    };
    const handleUpdateTable = async (draggedOrder) => {
        
        try {
            const datareq = {
                id: draggedOrder?.tableId,
                status: 1
            };

            const apiService = new ApiService();
            let res = await apiService.put("UPDATE_TABLE", datareq);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const columnStyles = {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: '16px',
    };

    const handleCancle = async (order) => {
        const body = {
            id: order.id,
            orderStatus: parseInt(7),
        };
        const apiService = new ApiService();
        const response = await apiService.put('UPDATE_ORDER', body);
        GetOrders()
    };
    const handleComplete = async (order) => {
        try {
            if (!order || order.paymentStatus !== 2) {
                toast.error(`Please Pay the Order ${order?.id} Amount`);
                return;
            }
            const body = {
                id: order.id,
                orderStatus: 6,
            };
            const apiService = new ApiService();
            const response = await apiService.put('UPDATE_ORDER', body);
            if (response && response.data && response.data.statusCode === 200) {
                toast.success("Order Completed");
                handleUpdateTable(order)
                GetOrders();
            } else {
                throw new Error("Failed to complete the order. Please try again.");
            }
        } catch (error) {
            toast.error(error.message);
        }
    };
    return (
        <div className="container-fluid m-0 p-0 px-lg-5 ">
            <div className='d-flex gap-4 p-lg-5'>
                <input type="search" className="form-control w-25" placeholder="Search" />
                <Grid className='filter' onClick={handleDND} />
            </div>
            <div className=''>
                <DndProvider backend={HTML5Backend}>
                    <div>
                        <div style={columnStyles} className='px-lg-5'>
                            {Object.keys(OrderStatus).map((statusKey) => (
                                <Section key={statusKey} status={statusKey} onDrop={handleDrop}>
                                    {orders
                                        .filter((order) => order.status === OrderStatus[statusKey])
                                        .map((order) => (
                                            <Card key={order.id} order={order} onDrop={handleDrop} handleCancle={handleCancle} handleComplete={handleComplete} />
                                        ))}
                                </Section>
                            ))}
                        </div>
                    </div>
                </DndProvider>
            </div>
        </div>
    )
}

export default OrderDragNDrop;

export const OrderStatus = {
    NewOrder: 0,
    Accept: 1,
    Ready: 3,
    Delivered: 6,
    Cancel: 7,
};