import React, { useState, useEffect } from 'react';
import CookHeader from './kitchenHeader';
import Select from 'react-select';
import "./kitchen.css"
import { ReactComponent as Refresh } from '../assets/images/svgIcons/Refresh.svg';
import OrderSection from './dropZone';
import OrderCard from './Drag';
import ApiService from '../services/apiService';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createHubConnection } from '../services/signalRService';
import { toast } from 'react-toastify';
import { ReactComponent as List } from '../assets/images/svgIcons/list.svg';
import { useNavigate } from 'react-router-dom';
import KitchenTelric from './kitchenGrid';

const KitchenHistory = ({ }) => {
    const [selected, setSelected] = useState(null);
    const [orders, setOrders] = useState([]);
    const [draggedOrder, setDraggedOrder] = useState(null);
    const [isRinging, setIsRinging] = useState(false);
    const navigate = useNavigate()
    const [status, setstatus] = useState('6');

    const filterValue = [
        { value: '', label: 'In Queue' },
        { value: '2', label: 'Preparing' },
        { value: '3', label: 'Ready For Delivery' },
        { value: '7', label: 'Canceled' },
    ];

    const handleStatusChange = (selectedOption) => {
        setstatus(selectedOption?.value);
    }

    const PageReload = () => {
        window.location.reload(false);
    }

    useEffect(() => {
        GetOrders();
    }, [status]);

    const GetOrders = async () => {
        const apiService = new ApiService();
        let parameters = `?OrderStatus=${status}`;
        let res = await apiService.getApiParamater("GET_ORDERS", parameters);
        setOrders((res?.data?.result).reverse());
    }

    const handleHistory = () => {
        navigate('/Kitchen/Orders');
    };

    return (
        <div className="">
            <div><CookHeader /></div>
            <div className='bghead d-flex justify-content-between px-lg-5' >
                <div className='d-flex gap-3'>
                    <div className='col-12'>
                        <Select
                            defaultValue={selected}
                            options={filterValue}
                            onChange={handleStatusChange}
                            value={filterValue.find(function (option) {
                                return option?.value === selected;
                            })}
                            className='reactselect'
                        />
                    </div>
                    <div className="">
                        <List className='filter' onClick={handleHistory} />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className='refbtn' onClick={() => PageReload()}>
                        <Refresh />
                        Reload Orders
                    </div>
                </div>
            </div>
            <div className='p-lg-5'>
                <KitchenTelric orders={orders}/>
            </div>
        </div>
    );
};

export default KitchenHistory;

