import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import moment from 'moment/moment';

const CostumerGrid = ({ costumer }) => {

    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YYYY");
    }
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    }
    const orderItemsRenderer = (params) => {
        const orderItems = params.data.orderItems;
        const itemNames = orderItems?.map((item) => item.itemName).join(', ');
        return itemNames;
    }
    const [colDefs, setColDefs] = useState([
        { field: "name", filter: true, floatingFilter: true },
        {
            field: "dateOfBirth",
            filter: true,
            floatingFilter: true,
            valueFormatter: dateFormatter, 
            comparator: dateComparator, 
        },
        { field: "email", filter: true, floatingFilter: true },
        { field: "mobileNumber", filter: true, floatingFilter: true },
        { field: "gender", filter: true, floatingFilter: true },
        { field: "identityCardNumber", filter: true, floatingFilter: true },
        { field: "address1", filter: true, floatingFilter: true },
        { field: "address2", filter: true, floatingFilter: true },
        { field: "address3", filter: true, floatingFilter: true },
        { field: "area", filter: true, floatingFilter: true },
        { field: "customerTaxId", filter: true, floatingFilter: true },
        { field: "faxNumber", filter: true, floatingFilter: true },
        { field: "phoneNumber", filter: true, floatingFilter: true },
        { field: "branchCode", filter: true, floatingFilter: true }
    ])

   

    return (
        <div className='brdor  mt-3' style={{ }}>

        <div className="ag-theme-alpine" style={{ height: 700, backgroundColor: "#5B93FF" }}>
            <AgGridReact
                rowData={costumer}
                columnDefs={colDefs}
                enableFilter={true}
                floatingFilter={true}
                frameworkComponents={{ orderItemsRenderer }} // Register the custom cell renderer
                rowSelection="multiple"
                pagination={true}
            />
            </div>
        </div>
    )
}
export default CostumerGrid;