import { React, useState, useEffect } from 'react'
import Profile from "../../../assets/images/profileImg.png"
import "./header.css"
import { Navigate, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../Redux/actions';
import { ReactComponent as Notification } from '../../../assets/images/svgIcons/ic_bell.svg';
import { ReactComponent as PayIn } from '../../../assets/images/svgIcons/payIn.svg';
import { ReactComponent as Calculator } from '../../../assets/images/svgIcons/calculator.svg';
import { ReactComponent as Payout } from '../../../assets/images/svgIcons/payout.svg';
import { ReactComponent as Dollar } from '../../../assets/images/svgIcons/dollar.svg';
import { ReactComponent as Chat } from '../../../assets/images/svgIcons/ic_chat.svg';
import { ReactComponent as Setting } from '../../../assets/images/svgIcons/ic_setting.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserName } from '../../../../Redux/selecters';
import { clearCart } from '../../../../Redux/actions/cartAction';
import { clearComboCart } from '../../../../Redux/actions/comboAction';
import { clearTable } from '../../../../Redux/actions/tableAction';
import { FiLogOut } from "react-icons/fi";
const CashierHeader = () => {
    const [headerText, setHeaderText] = useState('Dashboard');
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userName = useSelector(getUserName);
    useEffect(() => {
        const menuRegex = /^\/Cashier\/orderDetails\/[^\/]+$/;
        const { pathname } = location;
        if (pathname === '/Cashier/OpenCashList') {
            setHeaderText('Cash Opening');
        } else if (pathname === '/Cashier/ShiftOpen') {
            setHeaderText('Day Opening Cash');
        } else if (pathname === '/Cashier/handOverForm') {
            setHeaderText('Cash Hand Over');
        } else if (pathname === '/Cashier/DayOpen') {
            setHeaderText('Day Opening Cash');
        } else if (pathname === '/Cashier/DayClose') {
            setHeaderText('Day Closing Cash');
        } else if (pathname === '/Cashier/DayOpening') {
            setHeaderText('Day Opening');
        } else if (pathname === '/Cashier/WaiterOrders') {
            setHeaderText('Table Orders');
        } else if (pathname === '/Cashier/SaleHistory') {
            setHeaderText('Complete Orders');
        } else if (pathname === '/Cashier/HoldOrders') {
            setHeaderText('Hold Orders');
        } else if (pathname === '/Cashier/Dispute') {
            setHeaderText('Dispute');
        } else if (pathname === '/Cashier/PayOut') {
            setHeaderText('Pay-Out');
        } else if (pathname === '/Cashier/PayIn') {
            setHeaderText('Pay-In');
        } else if (pathname === '/Cashier/HandOvered') {
            setHeaderText('HandOver');
        } else if (pathname === '/Cashier/ordersD&D') {
            setHeaderText('Update Status');
        } else if (pathname === '/cashier/Tables') {
            setHeaderText('Table Booking');
        } else if (pathname === '/Cashier/UpdateOrder') {
            setHeaderText('Edit Order');
        } else if (pathname === '/Cashier/PayInForm') {
            setHeaderText('Create Pay In ');
        } else if (pathname === '/Cashier/PayOutForm') {
            setHeaderText('Create Pay Out ');
        } else if (menuRegex.test(pathname)) {
            setHeaderText('Order Details');
        }
        else if (pathname === '/Cashier/Deliveries') {
            setHeaderText(' Delivery Que List')
        }
        else {
            setHeaderText('Place Order'); // Default header text
        }
    }, [location]);

    const handleOut = () => {
        dispatch(logout())
        localStorage.clear()
        navigate('/Login')
        console.log("clicked")
        dispatch(clearCart());
        dispatch(clearComboCart());
        dispatch(clearTable());
        window.location.reload(false);
    }
    const CashierName = userName?.length > 20 ? userName.substring(0, 10) + '...' : userName;
    return (
        <nav class="navbar-expand-lg  mainBG ">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse d-flex justify-content-between" id="navbarSupportedContent">
                    <div className='dnon'>
                        <div className='Headtxt'>{headerText}</div>
                        <div className='headtxt2'></div>
                    </div>
                    <div className='d-flex gap-2 pad'>
                        <div className='gap-3 d-flex'>
                            {/* <div className='iconBG'><Dollar /></div> */}
                            <div className='iconBG'><Calculator /></div>
                            {/* <div className='iconBG'><PayIn /></div> */}
                            {/* <div className='iconBG'><Payout /></div> */}
                            <div className='iconBG'><Notification /></div>
                            <div className='iconBG'><Chat /></div>
                            {/* <div className='iconBG'><Setting /></div> */}
                        </div>
                        <div className="profilebox dropdown">
                            {CashierName}
                            {location.pathname !== "/Cashier/ShiftOpen" && (
                                <div className="dropdown-content-stats pt-1">
                                    <Link to="/Cashier/handOverForm" className="p-2">Shift Close</Link>
                                    <Link to="/Cashier/DayClose" className="p-2">Day Close</Link>
                                    <Link onClick={() => handleOut()} className="p-2">logOut</Link>
                                </div>
                            )}
                        </div>
                        <div className='adminprofileimgbox boxicon'>
                            {/* <img src={Profile} alt='profile' className='adminImg' /> */}
                            <FiLogOut className='' size={25} />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default CashierHeader