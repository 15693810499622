import React, { useEffect, useState ,Component} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Search from "../../../assets/images/search.png";
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../services/apiService';

const WaiterCall = () => {
  const [runningOrders, setRunningOrders] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    GetOrders();
  }, []);

  const GetOrders = async () => {
    const apiService = new ApiService();
    let param = `?OrderStatus=${11}`;
    let res = await apiService.getApiParamater("GET_ORDERS", param);
    setRunningOrders(res?.data?.result);
  };

  // Map the API response to match the structure of rowData
  const rowData = runningOrders?.map((order) => ({
    orderNo: order.id,
    date: order.orderDate,
    waiter: order.loggedInUserId || "N/A",
    tableNo: order.tableNo || "N/A",
    status: order.orderStatus === 2 ? "Completed" : "Pending",
    Action: (
      <button onClick={() => handleViewDetails(order)}>Details {order.id}</button>
    ),
  }));
  const handleGridDetail = (params) => {
  }
  const columnDefs = [
    { headerName: 'Order No', field: 'orderNo', filter: true, floatingFilter: true, minWidth: 280 },
    { headerName: 'Date', field: 'date', filter: true, floatingFilter: true, minWidth: 280 },
    { headerName: 'Waiter', field: 'waiter', filter: true, floatingFilter: true, minWidth: 280 },
    { headerName: 'Table No', field: 'tableNo', filter: true, floatingFilter: true, minWidth: 280 },
    { headerName: 'Status', field: 'status', filter: true, floatingFilter: true, minWidth: 280 },
    {
        headerName: 'Action',
        field: 'id',
        cellRenderer: BtnCellRenderer,
        cellRendererParams: {
            clicked: handleGridDetail,
        },
    },
  ];

  const handleViewDetails = (order) => {
    console.log('View Details:', order);
  };
  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
};
  return (
    <>
      <div className='row d-flex justify-content-between  m-5'>
        <div className='col-lg-4 col-sm-4'>
          <div>
            <img className='searchIcon ' src={Search} alt="search-icon" />
            <input className='form-control' type='search' />
          </div>
        </div>
        <div className='col-lg-2 col-sm-4 d-inline-flex gap-3'>
          <div className='btn-border'>
            PDF
          </div>
          <div className='btn-border'>
            CSV
          </div>
        </div>
      </div>
      <div className='px-5'>
      <div className='brdor  mt-3' style={{ }}>

        <div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            domLayout='autoHeight'
            rowHeight={60}  // Adjust row height as needed
            frameworkComponents={frameworkComponents}
            pagination={true}
            suppressRowClickSelection={true}
          />
        </div>
        </div>
      </div>
    </>
  );
};
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { node, clicked } = this.props;
        clicked(node.data);  // Pass the entire data to the clicked function
    }

    render() {
        return (
            <button onClick={this.btnClickedHandler} className="delbtn">Pay Now</button>
        )
    }
}
export default WaiterCall;
