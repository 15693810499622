import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import SupplierTypeGrid from './grid';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { ClipLoader } from 'react-spinners';

const SupplierType = () => {
    const [rowData, setRowData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [load, setLoad] = useState(false);
    const apiService = new ApiService();
    const [formData, setFormData] = useState({
        recordId: "",
        SupplierCode: "",
        SupplierType: "",
    });
    useEffect(() => {
        fetchRoleData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchRoleData();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Supplier Type Master', 'edit');
    // const canCreateMaster = checkPermission('Supplier Type Master', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;

    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            setFormData({
                recordId: clickedRowData?.record_Id || selectedRow?.record_Id,
                SupplierType: clickedRowData?.description || selectedRow?.description || "",
                SupplierCode: clickedRowData?.description_BI || selectedRow?.description_BI || "",
            });
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const fetchRoleData = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_SUPPLIER_TYPE', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            setRowData(response.data?.result);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const handleSave = async () => {
        setLoad(true)
        if (!formData.SupplierType || !formData.SupplierCode) {
            if (!formData.SupplierType) {
                toast.warning("Enter Supplier Type")
                setLoad(false)
            }
            if (!formData.SupplierCode) {
                toast.warning("Enter Supplier Code")
                setLoad(false)
            }
            return;
        }
        try {
            let body = {
                description: formData?.SupplierType,
                description_BI: formData?.SupplierCode,
                createdBy: 'User',
                isActive: true
            }
            const response = await apiService.post('CREATE_SUPPLIER_TYPE', body);
            if (response?.data?.statusCode == 201) {
                toast.success("Supplier type created successfully");
                handleClearFilters()
            } else if (response?.data?.statusCode === 208) {
                toast.error("Supplier type Already Exist");
                setLoad(false)
            }
            else {
                toast.error("Failed to create supplier type");
                setLoad(false)
            }
        } catch (error) {
            console.error("Error creating supplier type:", error);
            toast.error("Failed to create supplier type");
            setLoad(false)
        }
    };
    const updateFormData = async () => {
        try {
            setLoad(true)
            let body = {
                record_Id: formData?.recordId,
                description_BI: formData?.SupplierCode,
                description: formData?.SupplierType,
            }
            const res = await apiService.put(`UPDATE_SUPPLIER_TYPE`, body);
            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                handleClearFilters()
            } else {
                console.error('Failed to update form data');
                setLoad(false)
            }
        } catch (error) {
            console.error('Error updating form data:', error);
            setLoad(false)
        }
    };
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            SupplierCode: "",
            SupplierType: "",
        })
        fetchRoleData()
        setLoad(false)
        setSelectedRow(null)
    };
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Supplier Type Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className=''>
                        {selectedRow ? (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canEditMaster && (
                                        <button className='mainbtn ' onClick={updateFormData}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                    )}
                                </>
                            }
                            </>
                        ) : (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canCreateMaster === true ?
                                        <button className='mainbtn ' onClick={handleSave}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                        <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                        </button>
                                    }
                                </>
                            }
                            </>
                        )}
                    </div>
                    <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-lg-3'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <label htmlFor="validationCustom01" className="form-label">Supplier type Code</label>
                            <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="SupplierCode"
                                value={formData.SupplierCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="validationCustom02" className="form-label">Supplier Type</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom02"
                                disabled={canCreateMaster === true ? false : true}
                                name="SupplierType"
                                value={formData?.SupplierType}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg pt-3'>
                <SupplierTypeGrid fetchRoleData={fetchRoleData} rowData={rowData} canEditMaster={canEditMaster}
                    setSelectedRow={setSelectedRow} handleEdit={handleEdit} />
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchRoleData} />
            </div>
        </div>
    )
}

export default SupplierType;
