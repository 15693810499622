import React, { useState, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
const UserRoleGrid = () => {

    const gridRef = useRef();
    const rowData = [
        { id: 1, branchName: 'Main Store General ', voucherCode: 'Storage', voucherDate: '2022-01-01', counter: '01', amount: 1000, shift: "Morning", payIn: 200, remark: "Tissue", details: 'Details 1' },
        { id: 2, branchName: 'Main Store General ', voucherCode: 'Storage', voucherDate: '2022-01-02', counter: '02', amount: 1200, shift: "Evening", payIn: 250, remark: "Tissue", details: 'Details 2' },
        { id: 3, branchName: 'Main Store General ', voucherCode: 'Storage', voucherDate: '2022-01-02', counter: '02', amount: 1200, shift: "Evening", payIn: 250, remark: "Tissue", details: 'Details 2' },
        { id: 4, branchName: 'Main Store General ', voucherCode: 'Storage', voucherDate: '2022-01-02', counter: '02', amount: 1200, shift: "Evening", payIn: 250, remark: "Tissue", details: 'Details 2' },
        { id: 5, branchName: 'Main Store General ', voucherCode: 'Storage', voucherDate: '2022-01-02', counter: '02', amount: 1200, shift: "Evening", payIn: 250, remark: "Tissue", details: 'Details 2' },
        { id: 6, branchName: 'Main Store General ', voucherCode: 'Storage', voucherDate: '2022-01-02', counter: '02', amount: 1200, shift: "Evening", payIn: 250, remark: "Tissue", details: 'Details 2' },
    ];

    const columnDefs = [
        { headerName: 'Code', field: 'id', filter: true, floatingfilter: true, minWidth: 550 },
        { headerName: 'Manage User Roll', field: 'branchName', filter: true, floatingfilter: true, minWidth: 548 },
        { headerName: 'Action', field: 'voucherCode', filter: true, floatingfilter: true, minWidth: 540 },
    ];

    return (
        <div className=''>
            <div className="ag-theme-quartz mt-3" style={{ height: 700 }}>
                <AgGridReact
getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                    rowData={rowData}
                    ref={gridRef}
                    enableFilter={true}
                    floatingFilter={true}
                    rowSelection={'single'}
                    columnDefs={columnDefs}
                    pagination={false}
                    suppressExcelExport={true}
                    domLayout='autoHeight'
                    suppressRowClickSelection={true}
                />
            </div>
        </div>
    );
};

export default UserRoleGrid;
