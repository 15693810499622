const OrderStatus = {
  NewOrder: 0,
  Accepted: 1,
  Cooking: 2,
  ReadyForDelivery: 3,
  ReadyForPickup: 4,
  OutForDelivery: 5,
  Completed: 6,
  Canceled: 7,
  OnHold: 8,
};

const OrderType = {
  DineIn: 0,
  Takeout: 1,
  Delivery: 2,
  OnlineOrder: 3,
  DriveThru: 4,
  Catering: 5,
  RoomService: 6,
  Pickup: 7,
  CurbsidePickup: 8,
};
export const typeOptions = [
  { label: 'Coin', value: 'Coin', type: 0 },
  { label: 'Bank Note', value: 'BankNote', type: 1 },
  { label: 'Fils', value: 'fils', type: 2 },
  // { label: 'Digital Payment', value: 'DigitalPayment', type: 3 },
];

export const paymentOptions = [
  { label: 'Cash', value: 'Cash', type: 0 },
  { label: 'Card', value: 'Card', type: 1 },
  { label: 'Cheque', value: 'Cheque', type: 2 },
  { label: 'Online', value: 'Online', type: 3 },
];
export const shiftEnums = [
  { label: 'Morning', value: 'Morning', type: 1 },
  { label: 'Evening', value: 'Evening', type: 2 },
  { label: 'Night', value: 'Night', type: 3 },
]
export const CounterNo = [
  { label: 'Counter 1', value: 'Counter 1', type: 1 },
  { label: 'Counter 2', value: 'Counter 2', type: 2 },
  { label: 'Counter 3', value: 'Counter 3', type: 3 },
  { label: 'Counter 4', value: 'Counter 4', type: 4 },

]
export const CounterType = [
  { label: 'OrderCounter', value: 'OrderCounter', type: 1 },
  { label: 'CashCounter', value: 'CashCounter', type: 2 },
  { label: 'PickupCounter', value: 'PickupCounter', type: 3 },
  { label: 'ServiceCounter', value: 'ServiceCounter', type: 4 },
  { label: 'DeliveryCounter', value: 'DeliveryCounter', type: 5 },
  { label: 'BeverageCounter', value: 'BeverageCounter', type: 6 },
  { label: 'TakeoutCounter', value: 'TakeoutCounter', type: 7 },

]

export const CashEnums = 
  {
    DayOpen: 1,
    DayClose: 2,
    ShiftOpen: 3,
    ShiftClose: 4,
    PayIn: 5,
    PayOut: 6
  }

export const TableStatus = {
  Available: 1,
  Reserved: 2,
  Occupied: 3,
  PartiallyOccupied: 4,
  Cleaning: 5,
  OutOfService: 6
};
