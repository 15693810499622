import React, { useState } from 'react';
import UploadIcon from "../../../../assets/images/svgIcons/UploadIcon.svg";
import "./csv.css"
import CSVReader from 'react-csv-reader';
import { useDropzone } from 'react-dropzone';
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import userManagement from '../../../../services/userManagement';
const CsvUploader = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [csvData, setCsvData] = useState(null);
    let navigate = useNavigate();

    const categoryMapping = {
        "Breakfast": 1,
        "Lunch": 2,
        "Dinner": 3,
        "Brunch": 4,
    };
    const onDrop = (acceptedFiles) => {
        // Do something with the dropped file
        setCsvData(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.csv',
    });

    const handleFile = async (data, fileInfo) => {
        try {
            
            setCsvData(data.slice(1)); // Skip the header row and set the data
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const SubmitData = async (csvData) => {

        try {
            if (!csvData) {
                toast.warning('Please upload a CSV file.');
                return;
            }

            const transformedData = transformCSVData(csvData);
            
            const apiService = new ApiService();
            const response = await apiService.post('CREATE_BULK_MENU', transformedData);

            if (response.data.statusCode === 201) {
                toast.success('Menu items created successfully');
                navigate('/admin/menu');
            } else {
                toast.warning('Waiting for menu update');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };;
    const transformCSVData = csvData => {

        // Transform each row of the CSV data into the required format for the API
        const menuItems = csvData.slice(1).map((item) => {
            const [category, subCategoryId, itemName, description, price, tax, discount, addsOnId, modifierId, variantId, variantPrice] = item;

            
            // Construct menuItemMeta array
            const menuItemMeta = [];

            // Add addsOn to menuItemMeta
            if (addsOnId) {
                menuItemMeta.push({
                    metaItemId: parseInt(addsOnId, 10),
                    metaType: 2,
                });
            }

            // Add modifier to menuItemMeta
            if (modifierId) {
                menuItemMeta.push({
                    metaItemId: parseInt(modifierId, 10),
                    metaType: 3,
                });
            }

            // Add variant to menuItemMeta
            if (variantId) {
                menuItemMeta.push({
                    metaItemId: parseInt(variantId, 10),
                    metaType: 4,
                    variantPrice: parseInt(variantPrice, 10)
                });
            }
            let UserId = userManagement.getUserId()

            return {
                name: itemName,
                description: description,
                price: parseInt(price, 10),
                tax: parseInt(tax, 10),
                discount: parseInt(discount, 10),
                categoryId: category,
                subCategoryId: parseInt(subCategoryId, 10),
                active: true,
                menuItemMeta: menuItemMeta,
                loggedInUserId: UserId
            };
        });

        return { menuItems };
    };

    return (
        <div className='catlist p-5 mb-5'>
            <div className='dragbox'>
                <div className='dropBox'>
                    <div {...getRootProps()} className={`p-5 dropzone ${isDragActive ? 'active' : ''}`}>
                        <input {...getInputProps()} />
                        <img src={UploadIcon} alt="Upload" />
                        <p>Drag & Drop</p>
                    </div>
                </div>
            </div>
            <div class="separator">
                <hr />
                <div>OR</div>
                <hr />
            </div>
            <div>
                <label className="custom-file-upload mt-3">
                    <CSVReader onFileLoaded={handleFile} />
                    {/* <input type="file" onChange={handleFileBrowse} accept=".csv" /> */}
                    Browse File
                </label>
                <div className='justcsv pt-2'>CSV file only</div>
            </div>
            <div className='py-4'>
                <button onClick={() => SubmitData(csvData)} className='cnfrmBtn w-75'>
                    Save
                </button>
            </div>
        </div>
    );
};

export default CsvUploader;
