import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { setRows, addRow, removeRow, removeSingleRow, updateRow, clearRows } from '../../../Redux/actions/rowActions';
import { RxCross1 } from "react-icons/rx";
import { useCurrency } from '../../Layouts/currencyContext';
const DirectItems = ({ onRequisitionData, itemData, formData }) => {
    const rows = useSelector(state => state.rows.rows);
    const dispatch = useDispatch();
    const [itemOptions, setItemOptions] = useState([]);
    const [itemRows, setItemRows] = useState([]);
    const [descriptionOptions, setDescriptionOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const apiService = new ApiService();
    const initialMount = useRef(true);
    const { currencyPrecision } = useCurrency();
    const [rowsUpdated, setRowsUpdated] = useState(false);
    useEffect(() => {
        const getItems = async () => {
            try {
                if (initialMount.current && itemData?.prDetails && itemData.prDetails.length > 0) {
                    const formattedRows = itemData?.prDetails?.map(detail => (
                        {
                            requisitionNo: detail.requisitionNo,
                            srno: detail.srno,
                            itemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            branch: '',
                            qty: detail.qty,
                            uom: detail.uom,
                            baseQty: 0,
                            conValue: detail.baseQty,
                            baseAmount: detail.unitPrice,
                            specification: detail.specification,
                            amount: detail.amount,
                            stock: detail.stock,
                            bol: ''
                        }));
                    dispatch(setRows(formattedRows));
                    onRequisitionData(formattedRows);
                } else if (initialMount.current && itemData?.issueDetails && itemData.issueDetails.length > 0) {
                    const formattedRowsPromises = itemData?.issueDetails.map(async (detail, index) => {
                        const selecteditmstock = await apiService.getApiParamater('GET_ITEM', `?isActive=true&Warehouse=${itemData?.warehouse}&itemNo=${detail?.itemCode}`);
                        let item = selecteditmstock?.data.result?.[0]
                        return {
                            srno: detail.srno,
                            itemNo: detail.itemCode,
                            recordId: detail.itemCode,
                            itemDescription: detail.itemDescription,
                            branch: '',
                            qty: detail?.qty,
                            uom: detail?.uom,
                            baseQty: detail?.qty * 1,
                            conValue: detail?.convToInvUOM,
                            unitPrice: item?.avgPrice,
                            specification: detail.specification,
                            amount: item?.avgPrice * detail?.qty,
                            stock: item?.stock,
                            bol: '',
                            cgsAcc: item.acCode,
                            cgsAccName: item.acName,
                        };
                    }).filter(row => row !== null);
                    const formattedRows = await Promise.all(formattedRowsPromises);
                    if (formattedRows.length > 0) {
                        dispatch(setRows(formattedRows));
                        onRequisitionData(formattedRows);
                    }
                    if (formData.fromLocation && formData.fromLocation.label && rows[0].itemNo && !rowsUpdated) {
                        updateRowsWithNewStock();
                        setRowsUpdated(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };
        if (itemData) {
            getItems();
        }
    }, [itemData, dispatch, onRequisitionData, formData]);
    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
        } else {
            itemRows.forEach((row, index) => {
                dispatch(updateRow(index, row));
            });
        }
    }, [itemRows, dispatch]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await apiService.getApiParamater('GET_ITEM', '?isActive=true');
            const selecteditem = response.data.result?.filter(rec => rec.exp9 === '1')
            if (response?.data?.result?.length > 0) {
                setApiData(selecteditem);
                setItemOptions(selecteditem.map(item => ({
                    value: item.itemNo,
                    label: item.itemNo
                })));
                setDescriptionOptions(selecteditem.map(item => ({
                    value: item.itemDescription,
                    label: item.itemDescription
                })));
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleInputChange = async (index, fieldName, value) => {
        try {
            
            const isDuplicate = rows.some((row, rowIndex) => rowIndex !== index && (row.itemNo === value || row.itemDescription === value));
            if (isDuplicate) {
                toast.error('Please choose a different item.');
                return;
            }
            if (!formData.fromLocation) {
                toast.warning("Select From Location")
                return;
            }
            const updatedRows = [...rows];
            const updatedRow = { ...updatedRows[index], [fieldName]: value, srno: ("0" + (index + 1)).slice(-2) };
            if (fieldName === 'itemNo' || fieldName === 'itemDescription') {
                const queryParam = fieldName === 'itemNo' ? `itemNo=${value}` : `ItemDescription=${value}`;
                const selecteditmstock = await apiService.getApiParamater('GET_ITEM', `?isActive=true&Warehouse=${formData.fromLocation.label}&${queryParam}`);
                const selectedItem = apiData.find(item => item[fieldName] === value);
                const getGLAccounts = await apiService.getApiParamater("GET_ITEM_GL", `?ItemNo=${selectedItem.itemNo}`);
                let glAccount = getGLAccounts?.data?.result[0]
                if (selectedItem) {
                    updatedRow.itemDescription = selectedItem.itemDescription;
                    updatedRow.requisitionNo = selectedItem.itemDescription;
                    updatedRow.uom = selectedItem.unitMeasurement;
                    updatedRow.itemNo = selectedItem.itemNo;
                    updatedRow.specification = selectedItem.specification;
                    updatedRow.baseQty = 0;
                    updatedRow.unitPrice = parseFloat(selecteditmstock?.data?.result?.[0].avgPrice.toFixed(currencyPrecision));
                    updatedRow.stock = selecteditmstock?.data?.result?.[0].stock || 0;
                    updatedRow.cgsAcc = glAccount?.cgsAccountCode;
                    updatedRow.cgsAccName = glAccount?.cgsAccountName;
                }
            }
            if (fieldName === 'qty') {
                const quantity = parseFloat(value);
                const stock = updatedRow.stock;
                const unitPrice = parseFloat(updatedRow.unitPrice);
                if (quantity > stock) {
                    toast.warning('Enter a quantity less than or equal to the Stock.');
                    return
                }
                const selectedItem = apiData.find(data => data.itemNo === updatedRow.itemNo);
                const baseQty = 1;
                updatedRow.baseQty = (!isNaN(quantity) && quantity !== 0) ? (quantity * baseQty).toFixed(currencyPrecision) : 0;
                updatedRow.amount = (!isNaN(quantity) && !isNaN(unitPrice)) ? (unitPrice * quantity).toFixed(currencyPrecision) : 0;
            }
            if (fieldName === 'qty' || fieldName === 'baseAmount') {
                const quantity = parseFloat(updatedRow.qty);
                const stock = updatedRow.stock;
                if (quantity > stock) {
                    toast.warning('Enter a quantity less than or equal to the Stock.');
                    return
                }
                const unitPrice = parseFloat(updatedRow.unitPrice);
                updatedRow.amount = (!isNaN(quantity) && !isNaN(unitPrice)) ? (unitPrice * quantity).toFixed(currencyPrecision) : 0;
            }
            updatedRows[index] = updatedRow;
            dispatch(setRows(updatedRows));
            setItemRows(updatedRows);
            onRequisitionData(updatedRows);
        } catch (error) {
            console.error('Error handling input change:', error);
        }
    };
    const removeLastRow = () => {
        if (rows.length > 1) {
            dispatch(removeRow());
            setItemRows(rows.slice(0, -1));
            onRequisitionData(rows.slice(0, -1));
        }
    };
    const addNewRow = () => {
        const newRow = {
            srno: '',
            itemNo: '',
            requisitionNo: '',
            itemDescription: '',
            branch: '',
            qty: '',
            uom: '',
            baseQty: '',
            convRate: '',
            baseAmount: '',
            amount: '',
            stock: '',
            bol: '',
            recordId: '',
            specification: '',
            supplierId: '',
            cgsAcc: "",
            cgsAccName: "",
        };
        dispatch(addRow(newRow));
        const updatedRows = [...rows, newRow];
        setItemRows(updatedRows);
        onRequisitionData(updatedRows);
        dispatch(setRows(updatedRows)); // Ensure Redux state is updated here
    };
    const handleRemoveRow = (index) => {
        dispatch(removeSingleRow(index));
        const updatedRows = rows
            .filter((_, i) => i !== index)
            .map((row, i) => ({
                ...row,
                srno: i + 1
            }));
        onRequisitionData(updatedRows);
        setItemRows(updatedRows)
        dispatch(setRows(updatedRows));

    };
    const updateRowsWithNewStock = async () => {
        try {
            const updatedRows = rows.map(row => ({ ...row }));
            const apiPromises = updatedRows.map(async (row, index) => {
                const queryParam = `itemNo=${row.itemNo}`;
                const response = await apiService.getApiParamater(
                    'GET_ITEM',
                    `?isActive=true&Warehouse=${itemData.warehouse}&${queryParam}`
                );
                if (response?.data?.statusCode === 200) {
                    const itemData = response.data.result[0];
                    updatedRows[index].stock = itemData.stock || 0;
                    updatedRows[index].itemDescription = itemData.itemDescription;
                    updatedRows[index].baseAmount = parseFloat(itemData.avgPrice || 0);
                } else if (response?.data?.statusCode === 204) {
                    updatedRows[index] = {
                        ...row,
                        stock: 0,
                    };
                }
            });
            await Promise.all(apiPromises);
            dispatch(setRows([...updatedRows]));
            onRequisitionData(updatedRows);
        } catch (error) {
            console.error('Error updating rows with new stock:', error);
        }
    }
    return (
        <div className='table-container p-2 mb-5' style={{ overflowX: 'auto', height: "400px" }}>
            <table>
                <thead className='dynoTable'>
                    <tr>
                        <th></th>
                        <th>Sr no</th>
                        <th>Item No</th>
                        <th>Description</th>
                        <th>Item Specs</th>
                        <th>Inventory UOM</th>
                        <th>Qty</th>
                        <th>Inventory Qty</th>
                        <th>Stock</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>CGS Acc </th>
                        <th>CGS Acc Name </th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(itemRows) && rows?.map((row, index) => (
                        <tr key={index}>
                            {itemData?.eye ?
                                <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)", cursor: "not-allowed" }}>
                                    <div className='center'>
                                        <RxCross1 size={25} disabled />
                                    </div>
                                </td> :
                                <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                    <div onClick={() => handleRemoveRow(index)} className='center'>
                                        <RxCross1 size={25} />
                                    </div>
                                </td>
                            }
                            <td className='m-0 p-0'><input className='p-2' type="text" style={{ width: "80px" }} value={row.srno} disabled /></td>
                            <td className='m-0 p-0'>
                                {itemData?.eye ?
                                    <input className='p-2' type='text' disabled value={row?.itemNo} />
                                    :
                                    <Select
                                        options={itemOptions}
                                        value={{ value: row.itemNo, label: row.itemNo }}
                                        onChange={selectedOption => handleInputChange(index, 'itemNo', selectedOption.value)} />
                                }
                            </td>
                            <td className='m-0 p-0'>
                                {itemData?.eye ?
                                    <input className='p-2' disabled value={row.itemDescription} />
                                    :
                                    <Select
                                        options={descriptionOptions}
                                        value={{ value: row.itemDescription, label: row.itemDescription }}
                                        onChange={selectedOption => handleInputChange(index, 'itemDescription', selectedOption.value)}
                                    />
                                }
                            </td>
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.specification} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' style={{ width: "130px" }} type="text" value={row.specification} onChange={e => handleInputChange(index, 'specification', e.target.value)} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.uom} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.uom} style={{ width: "130px" }} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.qty} style={{ width: "100px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row.qty} style={{ width: "100px" }} onChange={e => handleInputChange(index, 'qty', e.target.value)} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.baseQty} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "130px" }} value={row.baseQty} onChange={e => handleInputChange(index, 'baseQty', e.target.value)} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.stock} style={{ width: "100px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.stock} style={{ width: "100px" }} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.unitPrice} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.unitPrice} style={{ width: "130px" }} onChange={e => handleInputChange(index, 'baseAmount', e.target.value)} /></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.amount} style={{ width: "120px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "120px" }} value={row.amount}/></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.cgsAcc} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.cgsAcc} style={{ width: "130px" }}/></td>
                            }
                            {itemData?.eye ?
                                <td className='m-0 p-0'><input className='p-2' disabled value={row.cgsAccName} style={{ width: "130px" }} /></td>
                                :
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.cgsAccName} style={{ width: "130px" }}/></td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
            {itemData?.eye ? "" :
                <div className='row mt-5'>
                    <div className='col-2'>
                        <button className='mainbtn ' onClick={addNewRow}>
                            Add Item
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default DirectItems;
