import { React, useState, useEffect } from 'react'
import Select from 'react-select';
import ApiService from '../../../services/apiService';
import { useLocation } from 'react-router-dom';
import user from "../../../assets/images/usr.png"
import moment from 'moment/moment';

const CostumerDetails = () => {
    const [data, setData] = useState([]);
    const { state } = useLocation();
    const CostumerData = state

    useEffect(() => {
        getCostumerDetails();
    }, []);

    const getCostumerDetails = async () => {
        const apiService = new ApiService();
        let paramaters = `${CostumerData?.id}`;
        let res = await apiService.getApiParamater("GET_COSTUMER_ID", paramaters);
        setData(res?.data?.result)
    }
    return (
        <div className='container'>
            <div className='p-lg-5'>
                <div className='p-lg-5'>
                    <div className='Headtxt py-5'>Customer Profile</div>
                    <div className='main-bG'>
                        <div className='row'>
                            <div className='col-2'>
                                <div className='profilebx'>
                                    <div className='center'>
                                        <img src={user} className='userprfl' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-10'>
                                <div className='name'>{data?.name}</div>
                                <div className='headr'>{"Male"}</div>
                                <div className='d-flex gap-3'>
                                    <div className='headr'>Email: </div>
                                    <div className='othr'>{data?.email}</div>
                                </div>

                                <div className='d-flex gap-3'>
                                    <div className='headr'>DOB: </div>
                                    <div className='othr'>{moment(data?.dateOfBirth).format('DD-MM-YYYY')}</div>
                                </div>
                                <div className='d-flex gap-3'>
                                    <div className='headr'>Phone: </div>
                                    <div className='othr'>{data?.phoneNumber}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='py-5'>
                        <div className='main-bG p-5'>
                            <div className='row'>
                                <div className='col-8'>
                                    <div className='d-flex gap-3'>
                                        <div className='headr'>Address: </div>
                                        <div className='othr'>{data?.address}</div>
                                    </div>
                                    <div className='d-flex gap-3 py-3'>
                                        <div className='headr'>Joined: </div>
                                        <div className='othr'>{moment(data?.creationDate).format('DD-MM-YYYY')}</div>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <div className='headr'>Area: </div>
                                        <div className='othr'>{data?.area}</div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='d-flex gap-3'>
                                        <div className='headr'>ID Number: </div>
                                        <div className='othr'>{data?.identityCardNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CostumerDetails