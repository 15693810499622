import React, { useState, useEffect } from "react";
import Select from 'react-select';
import ApiService from "../../../../services/apiService";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import userManagement from "../../../../services/userManagement";
import NavBox from "./navBox";

const UserAccess = ({ userID, showNavBox }) => {
    const { id } = useParams();
    
    const { state } = useLocation();
    const employeeData = useSelector(state => state?.employeData?.employeData);
    

    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null); // Add selectedUser state
    const [moduleClaims, setModuleClaims] = useState([]);
    const [crudClaims, setCrudClaims] = useState([]);
    const [activeModule, setActiveModule] = useState(null);
    const [allClaims, setAllClaims] = useState([]);
    const apiService = new ApiService();
    let token = localStorage.getItem('Token')
    const user = userManagement?.getUserId(token)
    useEffect(() => {
        if (!employeeData?.userId || state?.userId || userID) {
            getRoles();
        }
    }, [employeeData]);

    useEffect(() => {
        getClaims();

        if (employeeData?.userId || userID) {
            getUserClaims(employeeData?.userId);
        }
    }, [employeeData]);

    useEffect(() => {
        if (selectedRole) {
            // Fetch users based on the selected role
            getUsersByRole(selectedRole?.value);
        }
    }, [selectedRole]);

    const getRoles = async () => {
        try {
            const res = await apiService.get('GET_ROLE');
            const filteredRoles = res?.data?.result;
            setRoles(filteredRoles);
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };

    const getUsersByRole = async (roleName) => {
        try {

            let param = `?Role=${roleName?.name}`
            const res = await apiService.getApiParamater(`GET_USERS`, param);
            // Update the users state with fetched data
            setSelectedUser(res?.data?.result);
        } catch (error) {
            console.error('Error fetching users by role:', error);
        }
    };

    const getClaims = async (roleName) => {
        try {
            const res = await apiService.get('GET_CLAIM');
            const claimsArray = Object.values(res.data.result);
            setModuleClaims(claimsArray?.filter(claim => claim.claimType === 'module'));

            setCrudClaims(claimsArray?.filter(claim => claim.claimType === 'crud'));
            setAllClaims(claimsArray); // Set all claims
        } catch (error) {
            console.error('Error fetching Claims:', error);
        }
    };

    const getUserClaims = async (userId) => {
        try {
            let param = `?UserId=${userId}`;
            const res = await apiService.getApiParamater(`GET_USER_CLAIM`, param);
            const userClaimIds = res?.data?.result?.map(claim => claim?.claimId);

            const updatedCrudClaims = crudClaims.map(claim => ({
                ...claim,
                enabled: userClaimIds.includes(claim.id)
            }));

            setCrudClaims(updatedCrudClaims);
        } catch (error) {
            console.error('Error fetching User Claims:', error);
        }
    };



    const handleModuleClick = (moduleName) => {
        setActiveModule(moduleName);
        const moduleClaim = moduleClaims.find(claim => claim.claimValue === moduleName);
        if (moduleClaim) {
            updateCrudClaims(moduleClaim.claimValue);
        } else {
            console.error(`Module claim not found for ${moduleName}`);
        }
    };

    const updateCrudClaims = (moduleName) => {
        // Capitalize the first letter of each word in moduleName
        const capitalizedModuleName = moduleName
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        const crudPrefix = capitalizedModuleName + '.';
        const moduleCrudClaims = allClaims.filter(claim => claim.claimType === 'crud' && claim.claimValue.startsWith(crudPrefix));
        const updatedCrudClaims = moduleCrudClaims.map(claim => {
            // Extract the relevant part of the claim name (remove the module prefix)
            const displayName = claim.claimValue.substring(capitalizedModuleName.length + 1);
            // Check if the claim is enabled for the user
            const enabled = crudClaims.find(c => c.id === claim.id)?.enabled || false;
            return {
                id: claim.id,
                name: displayName, // Display the extracted name
                enabled: enabled
            };
        });
        setCrudClaims(updatedCrudClaims);
    };


    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption);
    };

    const handleSetUserChange = (selectedOption) => {
        setSelectedUser(selectedOption);
    };
    const handleCheckboxChange = (claimId) => async (e) => {
        const isChecked = e.target.checked;

        try {
            if (isChecked) {
                // Enable CRUD claim
                let body = {
                    userId: employeeData?.userId || state?.userId||userID,
                    claimId: claimId,
                    createdBy: user,
                    modifiedBy: user
                }
                let response = await apiService.post('CREATE_USER_CLAIM', body);
                if (response?.statusCode == 200 || 201) {
                    toast.success("Permission granted successfully")
                }
            } else {
                // Disable CRUD claim
                await apiService.post('DELETE_USER_CLAIM', { employeeData, claimId });
            }

            // Update the checkbox state based on the user's claims
            const updatedCrudClaims = crudClaims.map(claim => {
                if (claim.id === claimId) {
                    return {
                        ...claim,
                        enabled: isChecked
                    };
                } else {
                    return claim;
                }
            });

            setCrudClaims(updatedCrudClaims);
        } catch (error) {
            if (error?.response?.status == 400) {
                toast.warning("Select Role Or User to grant access")
            }
            console.error('Error updating user claims:', error);
        }
    };


    return (
        <div className="p-3">
            <div className="px-lg-5">
                <div className="row">

                    {!employeeData?.userId ? "" : (
                        <div className="col-3">
                            <label htmlFor="roleSelect" className="form-label">
                                Select Role
                            </label>
                            <Select
                                id="roleSelect"
                                value={selectedRole}
                                onChange={handleRoleChange}
                                options={roles?.map((role) => ({ value: role, label: role.name }))}
                            />
                        </div>
                    )}
                    {!employeeData?.userId && (
                        <div className="col-3">
                            <label htmlFor="userSelect" className="form-label">
                                Select User
                            </label>
                            <Select
                                id="userSelect"
                                value={selectedUser}
                                onChange={handleSetUserChange}
                                options={selectedUser?.map((user) => ({ value: user, label: user.name }))}
                            />
                        </div>
                    )}
                </div>
                <div className="row pt-5">
                    <div className="col-lg-3">
                        <div className="p-3 pro-sidebar">
                            {moduleClaims?.map((claim) => (
                                <div
                                    key={claim.id}
                                    className={`sidebar-text p-2 center ${activeModule === claim.claimValue ? 'active' : ''}`}
                                    onClick={() => handleModuleClick(claim.claimValue)}
                                >
                                    {claim.claimValue}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-9">
                        {activeModule &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Permission</th>
                                        <th scope="col">Acess</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {crudClaims.map(({ id, name, enabled }) => (
                                        <tr key={id}>
                                            <td>{name?.charAt(0)?.toUpperCase() + name?.slice(1)}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={enabled}
                                                    onChange={handleCheckboxChange(id)}
                                                // disabled={!enabled} // Disable checkbox if not enabled
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserAccess;
