import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import InvApiService from '../../../INVENTORY/services/apiService';
import { toast } from 'react-toastify';
import { IoAddSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import ApiService from '../../services/apiServices';
const DRNItemsGrid = ({ onRequisitionData, selectedCheck, itemData, eye }) => {
    const [rows, setRows] = useState([{
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        unitPrice: '',
        InvQty: '',
        balQty: '',
        freeQty: '',
        amount: '',
        discount: '',
        discountAmount: '',
        convRate: "",
        vat: 0,
        vatDec: '',
        vatAmount: '',
        expDate: '',
        inventoryAccountCode: "",
        inventoryAccountName: "",
    }]);
    const invApiService = new InvApiService();
    const apiService = new ApiService();
    const [apiData, setApiData] = useState([]);
    const netAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVat = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    const totalDiscountAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.discountAmount) || 0), 0)
    const totalDiscount = rows.reduce((sum, row) => sum + (parseFloat(row?.discount) || 0), 0)
    const calculatedTotal = rows.reduce((sum, row) => sum + (parseFloat(row?.amount - row?.vatAmount) || 0), 0)
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        const getItems = async () => {
            if (itemData?.selectedItems) {
                const formattedRows = itemData?.selectedItems?.deliveryNoteDetails?.map(detail => {
                    const selectedItem = apiData?.find(item => item.itemDescription === detail.itemDescription);
                    let amount = detail.unitPrice * detail.qty
                    const perAmount = (amount / 100) * detail?.vatPerc
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = selectedCheck ? excludedAmount : amount;
                    return {
                        srno: detail?.srno,
                        recordId: detail?.itemCode,
                        ItemNo: detail?.itemCode,
                        itemDescription: detail?.itemDescription,
                        specification: detail?.specification,
                        qty: detail?.qty,
                        uom: detail?.uom,
                        unitPrice: detail?.unitPrice,
                        InvQty: selectedItem?.convToInvUOM * detail?.qty,
                        balQty: detail?.balQty,
                        freeQty: 0,
                        amount: calculatedAmount,
                        convRate: selectedItem?.convToInvUOM,
                        vat: detail?.vatPerc,
                        vatDec: detail?.vatDesc,
                        vatAmount: detail?.vatAmount,
                        inventoryAccountName: selectedItem?.acName,
                        inventoryAccountCode: selectedItem?.acCode,
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows || []);
                    onRequisitionData(formattedRows || []);
                }
            } else {
                let param = `?SOrderNo=${itemData?.selectedSale?.label}`
                let res = await apiService.getApiParamater('GET_DN_SO_DETAIL', param)
                const formattedRows = res?.data?.result?.map(detail => {
                    const selectedItem = apiData?.find(item => item.itemNo === detail.itemCode);
                    let amount = detail.unitPrice * detail.qty
                    const perAmount = (amount / 100) * detail?.vatPerc
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = selectedCheck ? excludedAmount : amount;
                    return {
                        srno: detail?.srno,
                        recordId: detail?.itemCode,
                        ItemNo: detail?.itemCode,
                        itemDescription: detail?.itemDescription,
                        specification: detail?.specification,
                        qty: detail?.qty,
                        uom: detail?.uom,
                        unitPrice: detail?.unitPrice,
                        InvQty: selectedItem?.convToInvUOM * detail?.qty,
                        balQty: detail?.balQty,
                        freeQty: 0,
                        amount: calculatedAmount,
                        convRate: selectedItem?.convToInvUOM,
                        vat: detail?.vatPerc,
                        vatDec: detail?.vatDesc,
                        vatAmount: detail?.vatAmount,
                        inventoryAccountName: selectedItem?.acName,
                        inventoryAccountCode: selectedItem?.acCode,
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            }
        }
        if (itemData && apiData) {
            getItems();
        }
    }, [itemData, selectedCheck, apiData]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await invApiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            const selecteditem = response.data.result?.filter(rec => rec.exp9 === '1')
            if (response?.data?.result?.length > 0) {
                setApiData(selecteditem);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleQtyChange = (index, value) => {
        const selectedItem = apiData?.find(item => item.itemNo === rows[index].ItemNo);
        const newQty = parseInt(value) || '';
        const balQty = rows[index].balQty;
        const unitPrice = rows[index].unitPrice;
        const vatPerc = rows[index].vat;
        const UOM = selectedItem.convToInvUOM;
        if (newQty > balQty) {
            toast.warning('Enter a quantity less than or equal to the balance quantity.');
        } else {
            const amountBeforeVat = newQty * unitPrice;
            const vatAmount = (amountBeforeVat * vatPerc) / 100;
            let totalAmount;

            if (selectedCheck) {
                totalAmount = amountBeforeVat + vatAmount;
            } else {
                totalAmount = amountBeforeVat;
            }
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[index].qty = newQty;
                newRows[index].InvQty = newQty * UOM;
                newRows[index].amount = totalAmount;
                newRows[index].vatAmount = vatAmount;
                return newRows;
            });
        }
    };
    const handleDateChange = (index, expDate) => {
        const updatedRows = [...rows];
        updatedRows[index].expDate = expDate;
        const updatedSelectedRows = rows.map((row, i) =>
            i === index ? { ...row, expDate: expDate } : row
        );
        setRows(updatedSelectedRows);
        onRequisitionData(updatedSelectedRows);
    };
    const addRow = () => {
        const newRow = {
            srno: '',
            ItemNo: '',
            itemDescription: '',
            branch: '',
            qty: '',
            uom: '',
            unitPrice: '',
            amount: '',
            stock: '',
            convRate: ''
        };
        setRows([...rows, newRow]);
        onRequisitionData([...rows, newRow]);
    };
    return (
        <div>
            <div className='table-container mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th></th>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Bal Qty</th>
                            <th>free Qty</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT Desc</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                            <th>Exp Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) && rows.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}><div className='center'> <RxCross1 size={15} /></div></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row?.srno} disabled style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row?.ItemNo} disabled style={{ width: "170px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' type="text" value={row?.itemDescription} disabled style={{ width: "170px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.specification} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'>
                                    <input className='p-2' onWheel={(e) => e.target.blur()} disabled={eye === true ? true : false}
                                        type="number" style={{ width: "70px" }}
                                        value={row.qty}
                                        max={row.balQty}
                                        onChange={(e) => handleQtyChange(index, e.target.value)} />
                                </td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "140px" }} value={row.uom} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.InvQty || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="number" value={row.balQty} style={{ width: "90px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="number" value={row.freeQty} style={{ width: "90px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.unitPrice} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="number" value={row.discount} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "120px" }} value={row?.vatDec} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "80px" }} value={row?.vat} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row?.vatAmount || 0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled style={{ width: "120px" }} type="text" value={(row.amount === NaN ? 0 : row.amount || 0) || 0} /></td>
                                <td className='m-0 p-0'><input className='p-2' type='date' disabled={eye === true ? true : false}
                                    value={row?.expDate ? row.expDate.split('T')[0] : ''}
                                    onChange={(e) => handleDateChange(index, e.target.value)} style={{ width: "130px" }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>{`${totalDiscount} ` || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{calculatedTotal || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>{totalDiscountAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVat || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{netAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default DRNItemsGrid;
