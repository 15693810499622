// signalRService.js

import * as signalR from "@microsoft/signalr";

let connection;

const createHubConnection = (handleReceiveMessage, handleReceiveAck, handleReceiveRejection) => {
  const hubUrl = 'http://51.104.48.182:8080/server';

  connection = new signalR.HubConnectionBuilder().withUrl(hubUrl).build();

  connection.on("ReceiveMessage", handleReceiveMessage);
  connection.on("ReceiveAck", handleReceiveAck);
  connection.on("ReceiveRejection", handleReceiveRejection);

  connection.onclose((error) => {
    console.error('SignalR connection closed:', error);
  });

  connection.start().then(() => {
    console.log('SignalR connection established');
  }).catch((error) => {
    console.error('Error starting SignalR connection:', error);
  });

  return connection;
};

const invokeSendMessageToClient = (message, sentTo) => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    connection.invoke("SendMessageToClient", message, sentTo).catch((err) => {
      console.error('Error invoking SignalR SendMessageToClient:', err.toString());
    });
  } else {
    console.error('SignalR connection is not established.');
  }
};

const invokeLogin = (userId) => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    connection.invoke("Login", userId).catch((err) => {
      
      console.error('Error invoking SignalR Login:', err.toString());
    });
  } else {
    console.error('SignalR connection is not established.');
  }
};

const invokeBroadCastMessage = (message) => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    connection.invoke("BroadCastMessage", message).catch((err) => {
      console.error('Error invoking SignalR BroadCastMessage:', err.toString());
    });
  } else {
    console.error('SignalR connection is not established.');
  }
};

const isSignalRConnected = () => {
  return connection && connection.state === signalR.HubConnectionState.Connected;
};

export { createHubConnection, isSignalRConnected, invokeSendMessageToClient, invokeLogin, invokeBroadCastMessage, connection };
