import React from "react";
import { Link } from 'react-router-dom';
import './userMaster.css';

const NavBox = ({ id }) => {
    return (
        <div className="p-lg-5">
            <div className="topnav maoin-BG">
                <div className="container">
                    <div className="nav nav-pills overslid d-flex justify-content-between">
                        <Link to="/admin/master/userMaster/users" className={`dashitems sidebar-text center ${id === "users" ? "active" : ""}`}>
                            Users
                        </Link>
                        <Link to="/admin/master/userMaster/create" className={`dashitems sidebar-text center ${id === "create" ? "active" : ""}`}>
                            Create User
                        </Link>
                        <Link to="/admin/master/userMaster/access" className={`dashitems sidebar-text center ${id === "access" ? "active" : ""}`}>
                            User Access
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBox;
