import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { HiPencil } from "react-icons/hi2";
import { FaEye } from "react-icons/fa";
import { MdOutlineEditOff } from "react-icons/md";
import { toast } from 'react-toastify';

const StockGrid = ({ rowData, getItems, loading, canEditMaster, setSelectedRow, handleEdit }) => {

    let navigate = useNavigate();
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: "Code", field: "stockTypeCode", filter: true, floatingfilter: true, editable: false, minWidth: 500 },
        { headerName: "Stock Name", field: "stockTypeName", filter: true, floatingfilter: true, minWidth: 500, editable: false },
        {
            headerName: 'Status', field: 'active', cellRenderer: BtnCellRenderer, minWidth: 500,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, recordid, active) {
                    updateActiveStatus(field, recordid, active);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, active, data) => {
        try {
            const apiService = new ApiService();
            let body = {
                recordid: data?.recordid,
                active: active
            }
            const res = await apiService.put(`UPDATE_STOCK_TYPE`, body);
            if (res?.status === 200) {
                toast.success('Status Updated successfully');
                getItems();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'stockTypeCode') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    return (
        <div className=''>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className='gridshad' style={{ height: 600 }}>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={rowData}
                                rowSelection={'single'}
                                columnDefs={columnDefs}
                                onSelectionChanged={onSelectionChanged}
                                onCellClicked={onCellClicked}
                                suppressRowClickSelection={true}
                            />
                        </div>
                    </div>

                </>
            )}
        </div>
    );
};

class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data)
    }

    btnClickedHandler() {
        const { value, clicked, data } = this.props;
        const newActiveStatus = data.active === 1 ? 0 : 1;
        clicked(value, newActiveStatus, data);
    }

    render() {
        const { canEditMaster } = this.props;
        const { active } = this.props.data;
        return (
            <div className='d-flex gap-3'>
                {/* {canEditMaster === true ?
                    <div onClick={this.btnEditClickedHandler}><HiPencil size={25} /></div> :
                    <div><MdOutlineEditOff size={25} /></div>
                } */}
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={active === 1}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={active === 1}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default StockGrid;
