import React, { useState, useEffect } from 'react';
import "../../../../Customer/Menu/NavBar/navBar.css";
import "../../menus/menuItemcard.css";
import ApiService from '../../../../services/apiService';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import CounterGrid from './grid';
import userManagement from "../../../../services/userManagement";
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../../Redux/selecters';
import { CounterType } from '../../../../../Enums/config';

const CreateCounter = () => {
    const [Counter, setCounter] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCounter, setSelectedCounter] = useState();


    let UserId = useSelector(getUserId);

    useEffect(() => {
        GetCounter();
    }, [searchTerm]);

    const [formData, setFormData] = useState({
        counterName: '',
        ipAddress: '',
        CounterType: '',
    });


    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCreate = async () => {
        if (!formData?.counterName) { toast.warning("Enter Counter Name") }
        else if (!formData?.CounterType) { toast.warning("Enter Counter Type") }

        else {
            try {

                const datareq = {
                    loggedInUserId: UserId,
                    name: formData.counterName,
                    type: formData?.CounterType,
                    active: true,
                    ipAddress: formData.ipAddress,
                };

                const apiService = new ApiService();
                let res = await apiService.post("CREATE_COUNTER", datareq);

                if (res?.data?.statusCode === 201) {
                    toast.success(res?.data?.message);
                    GetCounter();
                    setFormData({
                        counterName: '',
                        CounterType: '',
                        ipAddress:'',
                    })
                    setSelectedCounter(null)
                } else {
                    toast.warning(res?.data?.message);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleCounterChange = (selectedOption) => {
        
        setSelectedCounter(selectedOption);
        setFormData({ ...formData, CounterType: selectedOption?.value });

    };

    const GetCounter = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_COUNTER', parameters);

            setCounter(res?.data.result);
        } catch (error) {
            console.error('Error fetching Counter data:', error);
        }
    };

    return (
        <div className="p-lg-5 container">
            <div className="row catlist">
                <div className="form-group row mt-2 justify-content-between align-content-center">
                    <div className="col-md-4">
                        <label htmlFor="validationCustomName" className="form-label">Counter Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustomName"
                            name="counterName"
                            placeholder='Enter name...'
                            value={formData.counterName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="col-md-4">
                        <div>

                            <label htmlFor="validationCustomCounter" className="form-label">Counter Type</label>
                            <Select
                                id="Counter"
                                value={CounterType ? selectedCounter : ''}
                                onChange={handleCounterChange}
                                options={CounterType?.map(counter => ({ value: counter.type, label: counter.value }))}
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group row mt-2 justify-content-between align-content-center">
                    <div className="col-md-4">
                        <label htmlFor="validationCustomName" className="form-label">IP Address</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationAdress"
                            name="ipAddress"
                            placeholder='Enter Address...'
                            value={formData.ipAddress}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>


                <div className="row justify-content-between mt-4">

                    <div
                        className="col-lg-2 col-md-2 addItem-btn p-3 mt-4 mb-3 mx-3"
                        onClick={handleCreate}
                    >
                        Save
                    </div>
                </div>
            </div>

            <div className="pt-4 mt-4">
                <div className='mt-4'>
                    <CounterGrid Counter={Counter} GetCounter={GetCounter} />
                </div>
            </div>
        </div>
    );
};

export default CreateCounter;
