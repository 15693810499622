import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import CurrencyGrid from './grid';
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";

const Currency = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [defaultSelection, setDefault] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);

    const [formData, setFormData] = useState({
        recordId: "",
        currencyCode: "",
        description: "",
        conversionRate: "",
    });

    useEffect(() => {
        fetchCurrencyData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchCurrencyData();
        }
    }, [pageSize]);

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Currency Master', 'edit');
    // const canCreateMaster = checkPermission('Currency Master', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;
    const handleEdit = (clickedRowData) => {
        if (selectedRow || clickedRowData) {
            setFormData({
                recordId: clickedRowData?.record_Id || selectedRow?.record_Id,
                description: clickedRowData?.description || selectedRow?.description || "",
                currencyCode: clickedRowData?.code || selectedRow?.code || "",
                conversionRate: clickedRowData?.conversionRate || selectedRow?.conversionRate || "",
            });
            setDefault(clickedRowData?.isDefault || selectedRow?.isDefault)
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleDefaultSelection = (e) => {
        setDefault(e);
    };
    const handleSubmit = async () => {
        if (!formData.currencyCode || !formData.description || !formData?.conversionRate) {
            if (!formData.description) { toast.warning("Enter Currency Name") }
            if (!formData.currencyCode) { toast.warning("Enter Currency Code") }
            if (!formData.conversionRate) { toast.warning("Enter Currency Conversion Rate") }
            return;
        }
        try {
            const apiService = new ApiService()
            let data = {
                code: formData?.currencyCode,
                description: formData?.description,
                conversionRate: parseInt(formData?.conversionRate),
                isDefault: defaultSelection,
            }
            const response = await apiService.post('CREATE_CURRENCY_MASTER', data);
            if (response?.data?.statusCode === 201) {
                toast.success(response?.data?.message)
                handleClearFilters()
            } else if (response?.data?.statusCode === 208) {
                toast?.success(response?.data?.message)
                fetchCurrencyData();
            }
        } catch (error) {
            toast.error("Please try Again!")
        }
    };
    const fetchCurrencyData = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService()
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_CURRENCY_MASTER', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            const CurrencyData = response.data?.result;
            setRowData(CurrencyData);
        } catch (error) {
            console.error("Error fetching Currency data:", error);
        }
    };
    const updateFormData = async () => {
        try {
            const apiService = new ApiService();
            let body = {
                record_Id: formData?.recordId,
                code: formData?.currencyCode,
                description: formData?.description,
                conversionRate: parseInt(formData?.conversionRate),
                isDefault: defaultSelection,
            }
            const res = await apiService.put(`UPDATE_CURRENCY_MASTER`, body);

            if (res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                handleClearFilters()
                window.history.replaceState({}, '')
            } else {
                console.error('Failed to update form data');
            }
        } catch (error) {
            console.error('Error updating form data:', error);
        }
    };
    const handleClearFilters = () => {
        setFormData({
            recordId: "",
            currencyCode: "",
            description: "",
            conversionRate: "",
        })
        fetchCurrencyData()
        setDefault(false)
        setSelectedRow(null)
    };
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Currency Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className='col center'>
                        {selectedRow ? (
                            <button className='mainbtn ' onClick={updateFormData}>
                                <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                        ) : (
                            <>{canCreateMaster === true ?
                                <button className='mainbtn ' type="submit" onClick={() => handleSubmit()}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                                :
                                <button className='mainbtn ' type="submit" style={{ cursor: "not-allowed" }}>
                                    <FaSave className='center mt-1 mx-2' /> <div>Save</div></button>
                            } </>
                        )}
                    </div>
                    <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-3'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className="form-group row">
                        <div className="col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Description *</label>
                            <input
                                type="text"
                                id="validationCustom01"
                                className="form-Input w-70"
                                disabled={canCreateMaster === true ? false : true}
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">Currency Code *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom02"
                                disabled={canCreateMaster === true ? false : true}
                                name="currencyCode"
                                value={formData?.currencyCode}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">( Exp: AED, USD )</label>
                        </div>
                        <div className="col-lg-3 d-flex justify-content-between">
                            <label htmlFor="validationCustom02" className="form-label  pt-2 start">Conversion Rate *</label>
                            <input
                                type="number"
                                className="form-Input w-70"
                                onWheel={(e) => e.target.blur()}
                                id="validationCustom02"
                                disabled={canCreateMaster === true ? false : true}
                                name="conversionRate"
                                value={formData?.conversionRate}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className='col-lg-2 center'>
                            <div className='d-flex pt-2'>
                                <input className="form-input"
                                    type="checkbox"
                                    id="default" style={{ width: "20px", height: "20px" }}
                                    disabled={canCreateMaster === true ? false : true}
                                    name="default"
                                    checked={defaultSelection}
                                    onChange={(e) => handleDefaultSelection(e.target.checked)}
                                />
                                <label className="form-label" htmlFor="default">Default</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg pt-3'>
                <CurrencyGrid fetchCurrencyData={fetchCurrencyData} rowData={rowData} canEditMaster={canEditMaster}
                    setSelectedRow={setSelectedRow} handleEdit={handleEdit} />
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchCurrencyData} />
            </div>
        </div>
    )
}

export default Currency;
