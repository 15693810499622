import React, { useState, useEffect } from "react";
import '../supplier/supplier.css';
import Select from 'react-select';
import "./usermaster.css";
import "./rights.css";
import { masters, Purchase, Issue } from "./data";
import ApiService from '../../services/apiService';
import ApiServicePOS from '../../../POS/services/apiService';
import { toast } from "react-toastify";
import 'react-sortable-tree/style.css';
import { useSelector } from "react-redux";
import { getUserId } from "../../../Redux/selecters";
import { hasPermission } from "../../services/roleService";

const UserRights = () => {
    const [selectedModules, setSelectedModules] = useState([]);
    const [subModuleStates, setSubModuleStates] = useState({});
    const [Roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);

    const userId = useSelector(getUserId);
    const apiService = new ApiService();
    const apiServicePos = new ApiServicePOS();
    const [formData, setFormData] = useState({
        userRole: null,
        user: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const roleRes = await apiServicePos.get('GET_ROLE');
                const userRes = await apiServicePos.get('GET_USERS');
                setRoles(roleRes.data.result);
                setUsers(userRes.data.result);
                setUsersList(userRes.data.result);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const modules = {
        Master: masters,
        Purchase: Purchase,
        Issue: Issue,
    };

    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };

    const handleModuleClick = (moduleName) => {
        setSelectedModules((prevModules) =>
            prevModules.includes(moduleName)
                ? prevModules.filter((mod) => mod !== moduleName)
                : [...prevModules, moduleName]
        );
    };

    const handleCheckboxChange = (moduleName, submoduleLabel, permission) => {
        setSubModuleStates((prevState) => {
            const updatedState = {
                ...prevState,
                [moduleName]: {
                    ...prevState[moduleName],
                    [submoduleLabel]: {
                        ...prevState[moduleName]?.[submoduleLabel],
                        [permission.toLowerCase()]: !prevState[moduleName]?.[submoduleLabel]?.[permission.toLowerCase()],
                    },
                },
            };
            return updatedState;
        });
    };

    const fetchUserPermissions = async (userId) => {
        setLoading(true);
        try {
            const param = `?User_Id=${userId}`;
            const response = await apiService.getApiParamater(`GET_USER_ROLE`, param);
            const permissionsData = response?.data?.result;

            // Check if there are permissions; if not, clear the state
            if (!permissionsData || permissionsData.length === 0) {
                setPermissions([]);
                initializeSubModuleStates([]);
            } else {
                setPermissions(permissionsData);
                initializeSubModuleStates(permissionsData);
            }
        } catch (error) {
            console.error('Error fetching user permissions:', error);
            toast.error('Error fetching user permissions. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const initializeSubModuleStates = (permissions) => {
        const initialStates = {};
        const moduleNames = Object.keys(modules);

        moduleNames.forEach((module) => {
            initialStates[module] = {};
            modules[module].forEach((submodule) => {
                const submoduleLabel = submodule.label;
                const permission = permissions.find((perm) => perm.description === submoduleLabel);

                initialStates[module][submoduleLabel] = {
                    enable: permission ? permission.saveCommand : false,
                    add: permission ? permission.newCommand : false,
                    edit: permission ? permission.editCommand : false,
                    delete: permission ? permission.deleteCommand : false,
                    query: permission ? permission.queryCommand : false,
                };
            });
        });

        if (permissions.length === 0) {
            setSubModuleStates({});
        } else {
            setSubModuleStates(initialStates);
        }
    };

    const createRole = async () => {
        try {
            if (!formData.user) {
                toast.error("Please Select User");
                return;
            }
            setLoading(true);
            const currentDatetimeUTC = new Date().toISOString();
            const userData = {
                user_Id:formData.user.value.user_Id ,
                updateUserDetails: Object.keys(subModuleStates).flatMap((module) =>
                    Object.keys(subModuleStates[module]).map((subModuleLabel) => ({
                        userId: formData.user.value.user_Id,
                        companyId: formData.user.value.company,
                        groupId: "",
                        description: subModuleLabel,
                        newCommand: subModuleStates[module][subModuleLabel].add || false,
                        editCommand: subModuleStates[module][subModuleLabel].edit || false,
                        deleteCommand: subModuleStates[module][subModuleLabel].delete || false,
                        queryCommand: subModuleStates[module][subModuleLabel].query || false,
                        saveCommand: subModuleStates[module][subModuleLabel].enable || false,
                        isActive: true,
                        createdBy: userId,
                        createdOn: currentDatetimeUTC,
                        modifiedBy: userId,
                        modifiedOn: currentDatetimeUTC,
                    }))
                ),
            };
            const response = await apiService.put("UPDATE_USER_ROLE", userData);
            if (response?.data?.statusCode === 201) {
                toast.success("Role created successfully!");
                setFormData({
                    userRole: null,
                    user: null,
                });
                setSubModuleStates({});
                setSelectedModules([]);
            } else {
                toast.error("Error creating role. Please try again.");
            }
        } catch (error) {
            console.error("Error Creating Role:", error);
            toast.error("Error Creating Role. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handleRoleChange = (selectedOption) => {
        setFormData({ ...formData, userRole: selectedOption });
        const filteredUser = users.filter(dep => dep.roleName === selectedOption.label);
        setUsersList(filteredUser);
    };

    const handleUserChange = (selectedOption) => {
        setFormData({ ...formData, user: selectedOption });
        fetchUserPermissions(selectedOption.value.user_Id);
    };

    return (
        <div className="py-lg-3">
            <div className="d-flex justify-content-between">
                <div className="col-3">
                    <label htmlFor="validationCustom01" className="form-label">Select Role</label>
                    <Select
                        value={formData?.userRole}
                        onChange={handleRoleChange}
                        options={Roles?.map((role) => ({ value: role, label: role?.name }))}
                    />
                </div>
                <div className="col-3">
                    <label htmlFor="validationCustom01" className="form-label">User Name</label>
                    <Select
                        value={formData?.user}
                        onChange={handleUserChange}
                        options={usersList?.map((usr) => ({ value: usr, label: usr?.name }))}
                    />
                </div>
            </div>
            <div className="headbx mt-3">
                <div className="col-5 "><div>Module</div></div>
                <div className="col-7 d-flex justify-content-around">
                    <div></div>
                    <div>Add</div>
                    <div>Edit</div>
                    <div>Delete</div>
                    <div>Query</div>
                </div>
            </div>
            <div className="module-container">
                <div className="module-selection">
                    {Object.keys(modules).map((module) => (
                        <div
                            key={module}
                            className={`module-option ${selectedModules.includes(module) ? "active" : ""}`}
                            onClick={() => handleModuleClick(module)}
                        >
                            {module}
                        </div>
                    ))}
                </div>
                <div className="submodule-tree">
                    {selectedModules?.map((module) => (
                        <div key={module} className="module-tree">
                            <div className="module-title">{module}</div>
                            <div className="submodule-list">
                                {modules[module].map((submodule) => (
                                    <div key={submodule.label} className="submodule-item d-flex row">
                                        <div className="submodule-title col-3">{submodule.label}</div>
                                        <div className="checkbox-group d-flex justify-content-between col-9">
                                            {[ "Add", "Edit", "Delete", "Query"].map((permission) => (
                                                <div key={permission} className="checkbox-item">
                                                    <input
                                                        type="checkbox"
                                                        style={{ width: "25px", height: "25px" }}
                                                        checked={!!subModuleStates[module]?.[submodule.label]?.[permission.toLowerCase()]}
                                                        onChange={() => handleCheckboxChange(module, submodule.label, permission)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                {Object.keys(subModuleStates).length > 0 && (
                    <div className="save-button-container">
                        <button
                            className="save-button"
                            onClick={createRole}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserRights;
