import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ApiService from '../../../INVENTORY/services/apiService'
import CategoryGridMaster from '../locationMaster/categoryGrid';
import { RiRefreshLine } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { hasPermission } from "../../services/roleService"
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { ClipLoader } from 'react-spinners';

const StockMaster = () => {
    const [rowData, setRowData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [load, setLoad] = useState(false);
    const apiService = new ApiService();

    const [formData, setFormData] = useState({
        RecordId: "",
        description: "",
    });
    useEffect(() => {
        fetchStockData();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
        if (pageSize) {
            fetchStockData();
        }
    }, [pageSize]);
    const checkPermission = (moduleName, action) => {
        return hasPermission(moduleName, action, permissions);
    };
    // const canEditMaster = checkPermission('Location Category Master', 'edit');
    // const canCreateMaster = checkPermission('Location Category Master', 'create');
    const canEditMaster = true;
    const canCreateMaster = true;
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            setFormData({
                RecordId: clickedRowData?.id || selectedRow?.id,
                description: clickedRowData?.description || selectedRow?.description || "",
            });
        }
    };
    const fetchStockData = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const response = await apiService.getApiParamater('GET_WAREHOUSE_CAT_MASTER', `?${params.toString()}`);
            setPagination(response.data.paginationData);
            const stockData = response.data;
            setRowData(stockData);
        } catch (error) {
            console.error("Error fetching stock data:", error);
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        try {
            setLoad(true)
            let data = {
                description: formData?.description,
                active: 1,
            }
            const response = await apiService.post('CREATE_WAREHOUSE_CAT_MASTER', data);
            if (response?.data?.statusCode === 201) {
                toast?.success("Location Category Created Successfully")
                handleClearFilters();
            } else if (response?.data?.statusCode === 208) {
                toast?.success(response?.data?.message)
                handleClearFilters();
            }
        } catch (error) {
            setLoad(false)
            toast.error("Please try Again!")
        }
    };
    const updateFormData = async () => {
        try {
            setLoad(true)
            let body = {
                id: formData?.RecordId,
                description: formData?.description,
            }
            const res = await apiService.put(`UPDATE_WAREHOUSE_CAT_MASTER`, body);
            if (res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
                toast.success('Form data updated successfully');
                handleClearFilters();
            } else if (res?.data?.statusCode === 208) {
                handleClearFilters();
            }
        } catch (error) {
            setLoad(false)
            console.error('Error updating item:', error);
        }
    };
    const handleClearFilters = () => {
        setFormData({
            RecordId: "",
            description: "",
        })
        fetchStockData()
        setLoad(false)
        setSelectedRow(null)
    };

    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Location Category Master</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div
                        onClick={handleEdit}
                        className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                        style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                    >
                        <GrEdit color='#fff' className='center mt-1 mx-2' />
                        <div className='center'>Edit</div>
                    </div>
                    <div className=''>
                        {selectedRow ? (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canEditMaster && (
                                        <button className='mainbtn ' onClick={updateFormData}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Update</div></button>
                                    )}
                                </>
                            }
                            </>
                        ) : (
                            <>{load === true ?
                                <button className='mainbtn '><ClipLoader size={30} color='#fff' /></button> :
                                <>
                                    {canCreateMaster === true ?
                                        <button className='mainbtn ' onClick={handleSubmit}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div></button> :
                                        <button className='mainbtn disabled' style={{ cursor: "not-allowed" }}>
                                            <FaSave className='center mt-1 mx-2' /> <div>Save</div>
                                        </button>
                                    }
                                </>
                            }
                            </>
                        )}
                    </div>
                    <button className='mainbtnRed' onClick={handleClearFilters}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                </div>
            </div>
            <div className='py-3'>
                <div className='gridBg p-3' style={{ borderRadius: "16px 16px 16px 16px" }}>
                    <div className="form-group row">
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Record Id *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                name="RecordId"
                                value={formData.RecordId}
                                onChange={handleInputChange}
                                disabled
                            />
                        </div>
                        <div className="col-md-3 col-lg-4 d-flex justify-content-between">
                            <label htmlFor="validationCustom01" className="form-label  pt-2 start">Category *</label>
                            <input
                                type="text"
                                className="form-Input w-70"
                                id="validationCustom01"
                                disabled={canCreateMaster === true ? false : true}
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='gridBg pt-3'>
                <CategoryGridMaster rowData={rowData?.result} fetchStockData={fetchStockData} canEditMaster={canEditMaster}
                    setSelectedRow={setSelectedRow} handleEdit={handleEdit} />
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={fetchStockData} />
            </div>
        </div >
    )
}
export default StockMaster;
