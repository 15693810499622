import React, { Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { HiPencil } from "react-icons/hi2";
import { MdOutlineEditOff } from "react-icons/md";

const CurrencyMasterGrid = ({ fetchCurrencyData, rowData, canEditMaster, setSelectedRow, handleEdit  }) => {
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: "Description", field: "description", filter: true, floatingfilter: true, minWidth: 315 },
        { headerName: "Currency Code", field: "code", filter: true, floatingfilter: true, minWidth: 300 },
        { headerName: "Conversion Rate", field: "conversionRate", filter: true, floatingfilter: true, minWidth: 300 },
        { headerName: "Default", field: "isDefault", filter: true, floatingfilter: true, minWidth: 300 },
        {
            headerName: 'Status', field: 'record_Id', cellRenderer: BtnCellRenderer, minWidth: 300,
            cellRendererParams: {
                canEditMaster: canEditMaster,
                handleEdit: handleEdit,
                clicked: function (field, isActive) {
                    updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const updateActiveStatus = async (id, isActive) => {
        try {
            const apiService = new ApiService();
            let body = {
                record_Id: id,
                isActive: isActive
            }
            const res = await apiService.put(`UPDATE_CURRENCY_MASTER`, body);
            if (res?.status === 200) {
                console.log('Updated successfully');
                fetchCurrencyData();
            } else {
                console.error('Failed to update');
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    const onCellClicked = (event) => {
        if (event.colDef.field === 'description') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };

    return (
        <div className='gridshad' style={{ height: 550 }}>
            <div className="ag-theme-quartz" style={{ height: 550 }}>
                <AgGridReact
                    getRowStyle={(params) => {
                        if (params.node.rowIndex % 2 === 0) {
                            return { background: '#F0EDF5' };
                        } else {
                            return { background: '#fff' };
                        }
                    }}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onSelectionChanged={onSelectionChanged}
                    onCellClicked={onCellClicked}
                />
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this); // Bind edit button handler
    }

    btnEditClickedHandler() {
        const { data, handleEdit } = this.props;
        handleEdit(data); // Pass rowData to handleEdit function
    }

    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value, !this.props.data.isActive); // Toggle isActive value
    }

    render() {
        const { isActive } = this.props.data;
        const { canEditMaster } = this.props;
        return (
            <div className='d-flex gap-3'>
                {canEditMaster === true ?
                    <div className="form-check form-switch center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                            onClick={this.btnClickedHandler}
                        />
                    </div>
                    :
                    <div className="form-check form-switch center">
                        <input disabled
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheck`}
                            checked={isActive}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default CurrencyMasterGrid;
